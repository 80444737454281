export const industryVerticals = [
  {
    category: 'Software and Services',
    title: 'IT Services',
  },
  {
    category: 'Software and Services',
    title: 'Software',
  },
  {
    category: 'Software and Services',
    title: 'Adtech',
  },
  {
    category: 'Software and Services',
    title: 'Artificial Intelligence and Machine Learning',
  },
  {
    category: 'Software and Services',
    title: 'Augmented Reality',
  },
  {
    category: 'Software and Services',
    title: 'B2B Payments',
  },
  {
    category: 'Software and Services',
    title: 'Big Data',
  },
  {
    category: 'Software and Services',
    title: 'Car Sharing',
  },
  {
    category: 'Software and Services',
    title: 'Cloudtech and DevOps',
  },
  {
    category: 'Software and Services',
    title: 'Cybersecurity',
  },
  {
    category: 'Software and Services',
    title: 'Digital Health',
  },
  {
    category: 'Software and Services',
    title: 'Ecommerce',
  },
  {
    category: 'Software and Services',
    title: 'Edtech',
  },
  {
    category: 'Software and Services',
    title: 'eSports',
  },
  {
    category: 'Software and Services',
    title: 'FinTech',
  },
  {
    category: 'Software and Services',
    title: 'Legal tech',
  },
  {
    category: 'Software and Services',
    title: 'Software as a service (SaaS)',
  },
  {
    category: 'Pharmaceuticals, Biotechnology and Life Sciences',
    title: 'Biotechnology',
  },
  {
    category: 'Pharmaceuticals, Biotechnology and Life Sciences',
    title: 'Life Sciences Tools and Services',
  },
  {
    category: 'Pharmaceuticals, Biotechnology and Life Sciences',
    title: 'Pharmaceuticals',
  },
  {
    category: 'Pharmaceuticals, Biotechnology and Life Sciences',
    title: 'Oncology',
  },
  {
    category: 'Pharmaceuticals, Biotechnology and Life Sciences',
    title: 'Life sciences',
  },
  {
    category: 'Automobiles and Components',
    title: 'Automobiles',
  },
  {
    category: 'Banks',
    title: 'Banks',
  },
  {
    category: 'Banks',
    title: 'Thrifts and Mortgage Finance',
  },
  {
    category: 'Banks',
    title: 'Mortgage tech',
  },
  {
    category: 'Cannabis',
    title: 'Cannabis',
  },
  {
    category: 'Capital Goods',
    title: 'Building Products',
  },
  {
    category: 'Capital Goods',
    title: 'Construction and Engineering',
  },
  {
    category: 'Capital Goods',
    title: 'Electrical Equipment',
  },
  {
    category: 'Capital Goods',
    title: 'Machinery',
  },
  {
    category: 'Capital Goods',
    title: 'Trading Companies and Distributors',
  },
  {
    category: 'Commercial and Professional Services',
    title: 'Professional Services',
  },
  {
    category: 'Commercial and Professional Services',
    title: 'Construction Technology',
  },
  {
    category: 'Consumer Durables and Apparel',
    title: 'Household Durables',
  },
  {
    category: 'Consumer Durables and Apparel',
    title: 'Leisure Products',
  },
  {
    category: 'Consumer Durables and Apparel',
    title: 'Textiles, Apparel and Luxury Goods',
  },
  {
    category: 'Consumer Services',
    title: 'Hotels, Restaurants and Leisure',
  },
  {
    category: 'Diversified Financials',
    title: 'Capital Markets',
  },
  {
    category: 'Diversified Financials',
    title: 'Consumer Finance',
  },
  {
    category: 'Diversified Financials',
    title: 'Diversified Financial Services',
  },
  {
    category: 'Diversified Financials',
    title: 'Mortgage Real Estate Investment Trusts (REITs)',
  },
  {
    category: 'Energy',
    title: 'Energy Equipment and Services',
  },
  {
    category: 'Energy',
    title: 'Oil, Gas and Consumable Fuels',
  },
  {
    category: 'Energy',
    title: 'Cleantech',
  },
  {
    category: 'Energy',
    title: 'Climate Tech',
  },
  {
    category: 'Food and Staples Retailing',
    title: 'Food and Staples Retailing',
  },
  {
    category: 'Food, Beverage and Tobacco',
    title: 'Beverages',
  },
  {
    category: 'Food, Beverage and Tobacco',
    title: 'Food Products',
  },
  {
    category: 'Food, Beverage and Tobacco',
    title: 'Tobacco',
  },
  {
    category: 'Health Care Equipment and Services',
    title: 'Healthcare Technology',
  },
  {
    category: 'Health Care Equipment and Services',
    title: 'Healthcare Equipment and Supplies',
  },
  {
    category: 'Health Care Equipment and Services',
    title: 'Healthcare Providers and Services',
  },
  {
    category: 'Household and Personal Products',
    title: 'Personal Products',
  },
  {
    category: 'Insurance',
    title: 'Insurance',
  },
  {
    category: 'Materials',
    title: 'Chemicals',
  },
  {
    category: 'Materials',
    title: 'Construction Materials',
  },
  {
    category: 'Materials',
    title: 'Containers and Packaging',
  },
  {
    category: 'Materials',
    title: 'Metals and Mining',
  },
  {
    category: 'Materials',
    title: 'Advanced Manufacturing',
  },
  {
    category: 'Materials',
    title: 'Paper and Forest Products',
  },
  {
    category: 'Materials',
    title: 'Supply chain technology',
  },
  {
    category: 'Media and Entertainment',
    title: 'Media',
  },
  {
    category: 'Media and Entertainment',
    title: 'Entertainment',
  },
  {
    category: 'Media and Entertainment',
    title: 'Interactive Media and Services',
  },
  {
    category: 'Real Estate',
    title: 'Equity Real Estate Investment Trusts (REITs)',
  },
  {
    category: 'Real Estate',
    title: 'Real Estate Management and Development',
  },
  {
    category: 'Retailing',
    title: 'Distributors',
  },
  {
    category: 'Retailing',
    title: 'Internet and Direct Marketing Retail',
  },
  {
    category: 'Retailing',
    title: 'Multiline Retail',
  },
  {
    category: 'Retailing',
    title: 'Specialty Retail',
  },
  {
    category: 'Semiconductors and Semiconductor Equipment',
    title: 'Semiconductors and Semiconductor Equipment',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Communications Equipment',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Technology Hardware, Storage and Peripherals',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Electronic Equipment, Instruments and Components',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Agtech',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Audiotech',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Autonomous Cars',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'IoT',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Nanotechnology',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Space tech',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Virtual reality (VR)',
  },
  {
    category: 'Telecommunication Services',
    title: 'Diversified Telecommunication Services',
  },
  {
    category: 'Telecommunication Services',
    title: 'Wireless Telecommunication Services',
  },
  {
    category: 'Telecommunication Services',
    title: 'Mobile',
  },
  {
    category: 'Telecommunication Services',
    title: 'Mobile commerce',
  },
  {
    category: 'Telecommunication Services',
    title: 'Technology, media and telecommunications (TMT)',
  },
  {
    category: 'Transportation',
    title: 'Air Freight and Logistics',
  },
  {
    category: 'Transportation',
    title: 'Airlines',
  },
  {
    category: 'Transportation',
    title: 'Marine',
  },
  {
    category: 'Transportation',
    title: 'Road and Rail',
  },
  {
    category: 'Transportation',
    title: 'Transportation Infrastructure',
  },
  {
    category: 'Utilities',
    title: 'Electric Utilities',
  },
  {
    category: 'Utilities',
    title: 'Gas Utilities',
  },
  {
    category: 'Utilities',
    title: 'Independent Power Producers and Energy Traders',
  },
  {
    category: 'Utilities',
    title: 'Multi-Utilities',
  },
  {
    category: 'Utilities',
    title: 'Water Utilities',
  },
];

export const investorFirmList = [
  '01 Advisors',
  '4DX Ventures',
  '7BC Venture Capital',
  '7wire Ventures',
  '8VC',
  '40 North Ventures',
  '408 Ventures',
  '468 Capital',
  '500 Global',
  '500 Startups Japan',
  '500 Startups Vietnam',
  '1414 Ventures',
  '1517 Fund',
  '1863 Ventures',
  '8090 Partners',
  'A-Level Capital',
  'A. M. Pappas & Associates',
  "A'Z Angels",
  'AAF Management Ltd.',
  'Acacia Venture Partners',
  'AccelFoods, LLC',
  'Access Ventures',
  'Accomplice',
  'Acrew Capital',
  'Acronym Venture Capital',
  'Act One Ventures',
  'AFSquare',
  'Album VC',
  'Alerion Ventures',
  'AlleyCorp',
  'Allston Venture Fund',
  'Almaz Capital',
  'Alpaca VC',
  'Alpha Edison',
  'Alter Global',
  'Amadeo Global',
  'American Family Ventures',
  'Aperture Venture Capital',
  'Arcanum Capital',
  'Arka Venture Labs',
  'Arrington XRP Capital',
  'Ascend Venture Capital',
  'Aspect Ventures',
  'Asymmetry Ventures',
  'at.inc/',
  'Atomic',
  'Atreides Management',
  'AU21 Capital',
  'Avid Ventures',
  'B Capital Group',
  'Balance Point Capital Partners',
  'Base10 Partners',
  'Bedrock Capital',
  'Bee Partners',
  'Benchmark',
  'Betaspring',
  'Better Tomorrow Ventures',
  'Big Basin Capital',
  'BIP Capital',
  'Blackhorn Ventures',
  'Blockchange Ventures',
  'Bloomberg Beta',
  'BlueRun Ventures',
  'Bluestein Ventures',
  'Bond',
  'Boost VC',
  'BootstrapLabs',
  'Borderless Capital',
  'Boro Capital',
  'Boulder Food Group ("BFG")',
  'Bowery Capital',
  'Bragiel Brothers',
  'Branded Hospitality Ventures',
  'BRC Innovation',
  'Breakwater Investment Management',
  'Breyer Capital',
  'Brook Venture Partners',
  'Bulldog Innovation Group LLC',
  'Bullpen Capital',
  'Buoyant Ventures',
  'C2 Ventures',
  'Cadenza Capital',
  'Caffeinated Capital',
  'Canapi Ventures',
  'Candou Ventures',
  'Cantos',
  'Canvas Ventures',
  'Canyon Creek Capital',
  'Capital One Ventures',
  'Capital Z Partners',
  'Center Electric',
  'CFV Ventures',
  'Chaac Ventures',
  'Chapter One Ventures',
  'Charge Ventures',
  'Chicago Ventures',
  'Chingona Ventures',
  'City Light Capital',
  'Cleo Capital',
  'CMFG Ventures',
  'CoFound Partners',
  'Cofounders Capital',
  'Coinfund',
  'Collab+Currency',
  'Collaborative Fund',
  'Comeback Capital',
  'Comet Labs',
  'Communitas Capital',
  'Company Ventures',
  'Compound',
  'Concrete Rose Capital',
  'Congruent Ventures',
  'Connectivity Ventures Fund',
  'ConsenSys',
  'Continue Capital',
  'Contour Venture Partners',
  'Contrary',
  'Converge',
  'Core Innovation Capital',
  'Cortado Ventures',
  'Costanoa Ventures',
  'Cowboy Ventures',
  'CRE Venture Capital',
  'Creative Ventures',
  'CreativeCo Capital',
  'Crimson Seed Capital',
  'Crosslink Capital',
  'Cultivation Capital',
  'Dallas Venture Capital',
  'Darling Ventures',
  'DCM Ventures',
  'Decathlon Capital Partners',
  'Deciens Capital',
  'DeepWork Capital',
  'DGNL Ventures',
  'Diamond State Ventures',
  'Differential Ventures',
  'Distributed Global',
  'Dragonfly Capital Partners',
  'Draper Triangle',
  'Dreamit Ventures',
  'Drive Capital',
  'Drummond Road Capital',
  'Dynamk Capital',
  'Ecliptic Capital',
  'Electric Capital',
  'Elementum Ventures',
  'Elysian Park Ventures',
  'Emagen Investment Group Inc.',
  'Embark Ventures',
  'Emerald Development Managers',
  'Emergent Ventures',
  'EMVC',
  'Ensemble VC',
  'EQx Fund',
  'Excelerate Health Ventures',
  'Expa',
  'Factor[e] Ventures',
  'Felicis Ventures',
  'Fika Ventures',
  'Financial Venture Studio',
  'FinTech Collective',
  'Fintech Ventures Fund',
  'FINTOP Capital',
  'Firebolt Ventures',
  'Firebrand Ventures',
  'First Launch Capital Fund',
  'First Rays Venture Partners',
  'First Round Capital',
  'FirstMark Capital',
  'Flatiron Venture Partners',
  'Floodgate',
  'Flori Ventures',
  'Florida Funders',
  'Flourish Ventures',
  'Flybridge',
  'Flywheel Fund',
  'Folius Ventures',
  'Founder Collective',
  'Founders First',
  'Foundry Group',
  'FourCities Capital',
  'Freestyle Capital',
  'Frontier Growth',
  'FundNV',
  'Future Africa',
  'Future Perfect Ventures',
  'Fyrfly Venture Partners',
  'Gambit Ventures',
  'gbeta',
  'Gefen Capital',
  'Genblock Capital',
  'Gilgamesh Ventures',
  'Good Capital',
  'Gradient Ventures',
  'Grand Ventures',
  'Graph Ventures',
  'Gratitude Railroad',
  'Great North Ventures',
  'GreatPoint Ventures',
  'Groove Capital',
  'Grotech Ventures',
  'GSR Ventures',
  'GTMFund',
  'Halogen Ventures',
  'Harbor Light Capital Partners',
  'Hard Yaka',
  'Heavybit',
  'Hemi Ventures',
  'Hewlett Packard Pathfinder',
  'High Alpha',
  'Hike Ventures',
  'Homebrew',
  'Hudson Valley Startup Fund',
  'Human Ventures',
  'Hustle Fund',
  'I2BF Global Ventures',
  'IA Ventures',
  'IDEA Fund Partners',
  'Ideaship',
  'IDEO CoLab Ventures',
  'IMO Ventures',
  'Impellent Ventures',
  'Inertia Ventures',
  'Initialized Capital',
  'Inspiration Ventures',
  'Inspired Capital Partners',
  'Interlace Ventures',
  'Interlock Partners',
  'Jackson Square Ventures',
  'January Ventures',
  'Javelin Venture Partners',
  'JDS Sports',
  'Johnson Venture Partners',
  'Jumpstart Foundry',
  'K2 Global',
  'Kairos',
  'Karlin Ventures',
  'KEC Ventures',
  'KG Investments',
  'Kickstart',
  'LAB Ventures',
  'LAT VC',
  'Launchpad Digital Health',
  'LEAP Global Partners',
  'Left Lane Capital',
  'Lightstone Ventures',
  'Link Ventures',
  'LionTree',
  'Liquid 2 Ventures',
  'LiveOak Venture Partners',
  'LocalGlobe',
  'Lombardstreet Ventures',
  'Long Journey Ventures',
  'Longevity Vision Fund',
  'Lowercarbon Capital',
  'Ludlow Ventures',
  'Luma Launch',
  'M13',
  'MaC Venture Capital',
  'Maccabee Ventures',
  'MAGIC Fund',
  'Maiden Lane Ventures',
  'Mantis VC',
  'Maschmeyer Group Ventures',
  'Matrix Partners',
  'Maveron',
  'Max Ventures',
  'Mayfield Fund',
  'MGV Capital Group',
  'mHUB',
  'Mighty Capital',
  'Milkbox Partners',
  'Milliways Ventures',
  'Miramar Ventures',
  'MissionOG',
  'Moai Capital',
  'Moderne Ventures',
  'Momentum 6',
  'Moneta Ventures',
  'Montage Ventures',
  'Morpheus Ventures',
  'Mosaik Partners',
  'Mount Wilson Ventures',
  'MS&AD Ventures',
  'Neotribe Ventures',
  'New Form Capital',
  'New Ground Ventures',
  'NewRoad Capital Partners, LLC',
  'New Stack Ventures',
  'NewDo Venture',
  'NextEquity Partners',
  'NextView Ventures',
  'NextWorld Capital',
  'NFX',
  'NGC Ventures',
  'Night Ventures',
  'NOEMIS Ventures',
  'North Island Ventures',
  'Notation Capital',
  'Off the Grid Ventures',
  'Ohio Innovation Fund',
  'Omidyar Technology Ventures',
  'One Way Ventures',
  'Operator Partners',
  'Optum Ventures',
  'Oregon Venture Fund',
  'OS Fund',
  'Oui Capital',
  'Outbound Ventures',
  'Overline VC',
  'Overton Venture Capital',
  'OVO Fund',
  'Oxford Angel Fund',
  'Oyster Ventures',
  'Palm Drive Capital',
  'Pantera Capital',
  'Parade Ventures',
  'ParaFi Capital',
  'Pareto Holdings',
  'Partner Fund Management',
  'Pear',
  'Penta Mezzanine Fund',
  'Pereg Ventures',
  'Pioneer Fund',
  'PLG Ventures',
  'Plug & Play',
  'Plug and Play Tech Center',
  'Point72 Ventures',
  'Polychain Capital',
  'Powerhouse Ventures',
  'Primer Sazze Partners',
  'Propellant Ventures',
  'Proton Enterprises',
  'Prototype Capital',
  'Quona Capital',
  'Race Capital',
  'Radicle Growth',
  'Radicle Impact',
  'Rainfall Ventures',
  'Rally Ventures',
  'Reach Capital',
  'Reciprocal Ventures',
  'Red Dog Capital',
  'Red Swan Ventures',
  'Redwood Ventures',
  'Reflective Venture Partners',
  'Remote First Capital',
  'Resolute Ventures',
  'Rethink Impact',
  'Revolution’s Rise of the Rest Seed Fund',
  'Raviga Capital Managment',
  'Right Side Capital Management',
  'RightVentures',
  'rocketship.vc',
  'Root and Shoot Ventures',
  'RTP Ventures',
  'Rucker Park Capital',
  'S Capital',
  'S-Cubed Capital',
  'S2 Capital',
  'Salesforce',
  'Scale Asia Ventures',
  'Scale Venture Partners',
  'SciFi VC',
  'Scotia Capital',
  'Scrum Ventures',
  'Secocha Ventures',
  'SemperVirens Venture Capital',
  'SenaHill Partners',
  'Seven Seven Six',
  'Sfermion',
  'Shasta Ventures',
  'Shima Capital',
  'Sierra Ventures',
  'SierraMaya360',
  'Silicon Catalyst',
  'Silicon Road Ventures',
  'Silverton Partners',
  'SixThirty',
  'Sixty8 Capital',
  'SmartHub',
  'Social Capital',
  'Social Leverage',
  'Solar Eco Fund',
  'Solasta Ventures',
  'SOSV',
  'SoundBoard Venture Fund',
  'South Park Commons',
  'SparkLabs Global Ventures',
  'Springbank Collective',
  'SpringTime Ventures',
  'Sputnik ATX',
  'Starta VC',
  'StartFast Ventures',
  'Starting Line',
  'StartUp Health',
  'SteelSky Ventures',
  'Sterling Road',
  'Sterling.VC',
  'Stony Lonesome Group',
  'Story Ventures',
  'StratMinds',
  'Stray Dog Capital',
  'Streamlined Ventures',
  'Struck Crypto',
  'Subversive Capital',
  'Sugar Capital',
  'Summit Peak Investments',
  'Sure Ventures',
  'Surface Ventures',
  'Susa Ventures',
  'SWAT Equity Partners',
  'Sweater Ventures',
  'Switch Ventures',
  'Tandem Capital',
  'Taurus Ventures',
  'Tech Square Ventures',
  'Techstars',
  'Tectonic Capital',
  'Tenacity Venture Capital',
  'Tensility Venture Partners',
  'TEXO Ventures',
  'The Artemis Fund',
  'The Engine',
  'The Fund',
  'The House Fund',
  'The LegalTech Fund',
  'The Untitled Venture Company',
  'TheVentureCity',
  'Think +',
  'Third Act Ventures',
  'Third Kind Venture Capital',
  'Third Prime',
  'Three Tree Ventures',
  'TIAA',
  'Tola Capital',
  'Torch Capital',
  'TRAC',
  'Triangle Capital Corporation',
  'Tribeca Venture Partners',
  'Trilogy Equity Partners',
  'Trust Ventures',
  'TTV Capital',
  'Tusk Venture Partners',
  'Two Lanterns Venture Partners',
  'Two Sigma Ventures',
  'Ulu Ventures',
  'Uncommon Denominator',
  'Uncorrelated Ventures',
  'Underscore VC',
  'Unlock Venture Partners',
  'Unpopular Ventures',
  'Unusual Ventures',
  'UP2398',
  'UpWest',
  'Valence Ventures',
  'Valor Equity Partners',
  'ValueStream Ventures',
  'Vanterra Capital',
  'Varana Capital',
  'Variant',
  'Vector Capital',
  'Village Capital',
  'Village Global',
  'Vine St. Ventures',
  'Vine Ventures',
  'Wasabi Ventures',
  'Wayfinder Ventures',
  'Weclikd',
  'Weekend Fund',
  'West Quad Ventures',
  'Weston Presidio Capital',
  'WestWave Capital',
  'White Star Capital',
  'Windforce Ventures',
  'Work-Bench',
  'Worklife',
  'WorldQuant Ventures LLC',
  'Xplorer Capital',
  'XRC Labs',
  'XYZ Venture Capital',
  'Yash Godiwala',
  'ZenStone Venture Capital',
  'Zillionize',
  'Zoma Capital',
];

export const darkInitials = [
  '#A4C9FF',
  '#9AA4FF',
  '#FFC043',
  '#FFB4A2',
  '#A4ABC0',
  '#E1E2EC',
  '#FFDF94',
  '#D1E4FF',
  '#BDC2FF',
  '#DFE0FF',
  '#88DDC6',
  '#CFF1E8',
  '#11BB8D',
];

export const sortByList = [
  'Most-recent',
  'Alphabetical',
  'Info due date',
  'Audit date',
  'High to low priority',
  'Low to high priority',
];

export const dataEntryFilterBy = [
  'View all',
  'Client not started 820',
  'Client in-progress',
  '820 Data entry',
  'High-priority',
  'Low-priority',
];

export const preparerFilterBy = [
  'View all',
  '820 Analysis',
  '820 Review',
  'Draft in-progress',
  'Draft review',
  'High-priority',
  'Low-priority',
];

export const deliverFilterBy = [
  'View all',
  'Draft delivered',
  'Iterate draft',
  'Audit draft',
  'Financial statements ready',
  'High-priority',
  'Low-priority',
];

export default {
  industryVerticals,
};
