import React, {
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import TableElement from './Components/TableElement';

import { ErrorMessageContext } from '../../lib/contextLib';

import {
  defaultPreferredTableData,
  defaultSafeConvertibleTableData,
  defaultCommonTableData,
  defaultOptionsTableData,
  defaultWarrantsTableData,
  defaultRemainingOptionsPoolTableData,
  preferredTableSchema,
  createPreferredTable,
  safeConvertibleTableSchema,
  createSafeConvertibleTable,
  commonTableSchema,
  createCommonTable,
  optionsTableSchema,
  createOptionsTable,
  warrantsTableSchema,
  createWarrantsTable,
  remainingOptionPoolTableSchema,
  createRemainingOptionsPoolTable,
} from './tablesData';

import { allocateDBData, checkForErrorOnTable, convertToDBData } from './calcTablesUtils';

import {
  copy,
  removeCommas,
  commaEvery3rdChar,
} from '../../utils';

import { createFetchHeaders } from '../../utils/apiCalls';

import { getUserId } from '../../utils/auth';

import './CapStructure.scss';

export default function CapStructure({
  userData,
  saveCapData,
  setSaveCapData,
}) {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  // Tab1 Data
  const [preferredTableData, setPreferredTableData] =
    useState([defaultPreferredTableData]);
  const [safeConvertibleTableData, setSafeConvertibleTableData] =
    useState([defaultSafeConvertibleTableData]);
  const [commonTableData, setCommonTableData] =
    useState([defaultCommonTableData]);
  const [optionsTableData, setOptionsTableData] =
    useState([defaultOptionsTableData]);
  const [warrantsTableData, setWarrantsTableData] =
    useState([defaultWarrantsTableData]);
  const [remainingOptionsPoolTableData, setRemainingOptionsPoolTableData] =
    useState([defaultRemainingOptionsPoolTableData]);

  const [capStructureSummary, setCapStructureSummary] = useState(null);

  // For handling loading states
  const [calcTableHasError, setCalcTableHasError] = useState(false);

  // Error Handling for Preferred and Safe Convertible
  const [inputsHaveError, setInputsHaveError] = useState([{}]);
  const [safeInputsHaveError, setSafeInputsHaveError] = useState([{}]);

  const [changeInProgress, setChangeInProgress] = useState(false);
  const [changeHasBeenMade, setChangeHasBeenMade] = useState(false);

  const changeInProgressRef = useRef(false);
  const changeHasBeenMadeRef = useRef(false);

  // Format the data from the DB to the front-end format
  useEffect(() => {
    function assignRemainingOptionsPoolValues() {
      const newArray = [];
      const newObject = copy(defaultRemainingOptionsPoolTableData);
      newArray.push({
        ...newObject,
        available: {
          ...newObject.available,
          value: commaEvery3rdChar(userData.capData.remainingOptionsPool),
        },
      });
      return newArray;
    }
    if (userData.capData) {
      setPreferredTableData(userData.capData.preferred ?
        allocateDBData(userData.capData.preferred, preferredTableSchema, defaultPreferredTableData) :
        [defaultPreferredTableData]);
      setSafeConvertibleTableData(userData.capData.safeConvertible ?
        allocateDBData(
          userData.capData.safeConvertible,
          safeConvertibleTableSchema,
          defaultSafeConvertibleTableData,
        ) : [defaultSafeConvertibleTableData]);
      setCommonTableData(userData.capData.common ?
        allocateDBData(userData.capData.common, commonTableSchema, defaultCommonTableData, 'common') :
        [defaultCommonTableData]);

      setOptionsTableData(userData.capData.option ?
        allocateDBData(
          userData.capData.option,
          optionsTableSchema,
          defaultOptionsTableData,
          'options',
        ) :
        [defaultOptionsTableData]);
      setWarrantsTableData(userData.capData.warrant ?
        allocateDBData(
          userData.capData.warrant,
          warrantsTableSchema,
          defaultWarrantsTableData,
          'warrants',
        ) :
        [defaultWarrantsTableData]);
      setRemainingOptionsPoolTableData(
        userData.capData.remainingOptionsPool ?
          assignRemainingOptionsPoolValues() : [defaultRemainingOptionsPoolTableData],
      );
      setCapStructureSummary(userData.capData.summary ? userData.capData.summary : null);
    }
  }, [userData.capData]);

  useEffect(() => {
    changeInProgressRef.current = changeInProgress;
    changeHasBeenMadeRef.current = changeHasBeenMade;
  }, [changeInProgress, changeHasBeenMade]);

  async function saveData(autoSave = false) {
    if (!changeHasBeenMadeRef.current || changeInProgressRef.current) {
      setSaveCapData(false);
      return;
    }
    // Format Data to DB format
    let commonData = null;
    commonTableData.forEach((row) => {
      let title = '';
      let values = {};
      if (row.outstanding.value.length !== 0 || autoSave) {
        title = `${row.common.value ? row.common.value : ''}`;
        values = {
          common: row.outstanding.value ? removeCommas(row.outstanding.value.toString()) : null,
          issuePrice: null,
          participationRights: null,
          prior: null,
        };
        if (!commonData) commonData = {};
        commonData = {
          ...commonData,
          ...title && { [title]: values },
        };
      }
    });

    // TODO  possibly have them be all in the one function in utils with different arguments
    let optionsData = null;
    optionsTableData.forEach((row) => {
      let title = '';
      let values = {};
      if (
        (
          (row.outstanding.value.length !== 0) &&
          (row.strikePrice.value.length !== 0)
        ) ||
        (row.options.value.length > 0)
      ) {
        title = `${row.options.value ? row.options.value : ''} Option @ ${row.strikePrice.value}`;
        values = {
          quantity: row.outstanding.value ? removeCommas(row.outstanding.value.toString()) : null,
          strikePrice: row.strikePrice.value ? parseFloat(row.strikePrice.value.replace(/[$,]/g, '')).toString() : null,
          type: row.options.type ? row.options.type : null,
          preferredClass: row.options.value ? row.options.value : null,
        };
        if (!optionsData) optionsData = {};
        optionsData = {
          ...optionsData,
          [title.trim()]: values,
        };
      }
    });
    let warrantsData = null;
    warrantsTableData.forEach((row) => {
      let title = '';
      let values = {};
      if (
        (
          (row.outstanding.value.length !== 0) &&
          (row.strikePrice.value.length !== 0)
        ) ||
        (row.warrants.value.length > 0)
      ) {
        title = `${row.warrants.value ? row.warrants.value : ''} Warrant @ ${row.strikePrice.value}`;
        values = {
          quantity: row.outstanding.value ? removeCommas(row.outstanding.value.toString()) : null,
          strikePrice: row.strikePrice.value ? parseFloat(row.strikePrice.value.replace(/[$,]/g, '')).toString() : null,
          type: row.warrants.type ? row.warrants.type : null,
          preferredClass: row.warrants.value ? row.warrants.value : null,
        };
        if (!warrantsData) warrantsData = {};
        warrantsData = {
          ...warrantsData,
          [title.trim()]: values,
        };
      }
    });
    const preferred = convertToDBData(preferredTableData);
    const safeConvertible = convertToDBData(safeConvertibleTableData);
    const preferredErrorCheck = checkForErrorOnTable(preferredTableData);
    const safeErrorCheck = checkForErrorOnTable(safeConvertibleTableData);
    if (safeErrorCheck.tableErrors.length && !autoSave) setSafeInputsHaveError(safeErrorCheck.tableErrors);
    if (preferredErrorCheck.tableErrors.length && !autoSave) setInputsHaveError(preferredErrorCheck.tableErrors);
    const newTableData = {
      ...userData.capData,
      portfolioCompanyId: userData.metaData.portfolioCompanyId,
      preferred,
      safeConvertible,
      common: commonData,
      option: optionsData,
      warrant: warrantsData,
      remainingOptionsPool: remainingOptionsPoolTableData[0].available.value ?
        parseFloat(remainingOptionsPoolTableData[0].available.value.replaceAll(',', '')) : null,
    };
    if (autoSave || (safeErrorCheck.tableErrors.every((row) => !Object.keys(row).length) &&
      preferredErrorCheck.tableErrors.every((row) => !Object.keys(row).length) && !calcTableHasError)) {
      try {
        const requestUserId = getUserId();
        let capData = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/calc-engine/update-cap-structure-object`,
          await createFetchHeaders('post', { ...newTableData, requestUserId, status: 'in progress' }, true),
        );
        capData = await capData.json();
        setCapStructureSummary(capData.summary ? capData.summary : null);
      } catch (e) {
        setShowErrorMessage(e.toString());
      } finally {
        setChangeHasBeenMade(false);
        setSaveCapData(false);
      }
    } else {
      setChangeHasBeenMade(false);
      setSaveCapData(false);
    }
  }

  useEffect(() => { if (saveCapData) { saveData(); } }, [saveCapData]);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => { savedCallback.current = callback; }, [callback]);

    useEffect(() => {
      const func = () => { savedCallback.current(); };
      if (delay !== null) {
        const id = setInterval(func, delay);
        return () => clearInterval(id);
      }
      return null;
    }, [delay]);
  }

  useInterval(() => { saveData(true); }, 5000);

  return (
    <main className="CapStructure">
      <TableElement
        tableData={preferredTableData}
        setTableData={setPreferredTableData}
        tableSchema={preferredTableSchema}
        createTable={createPreferredTable}
        canEditFirstColumn
        canAddOrDeleteRow
        isPreferredTable
        setCalcTableHasError={setCalcTableHasError}
        inputsHaveError={inputsHaveError}
        setInputsHaveError={setInputsHaveError}
        setChangeInProgress={setChangeInProgress}
        setChangeHasBeenMade={setChangeHasBeenMade}
      />
      <TableElement
        tableData={safeConvertibleTableData}
        setTableData={setSafeConvertibleTableData}
        tableSchema={safeConvertibleTableSchema}
        createTable={createSafeConvertibleTable}
        canEditFirstColumn
        canAddOrDeleteRow
        isSafeTable
        setCalcTableHasError={setCalcTableHasError}
        safeInputsHaveError={safeInputsHaveError}
        setSafeInputsHaveError={setSafeInputsHaveError}
        setChangeInProgress={setChangeInProgress}
        setChangeHasBeenMade={setChangeHasBeenMade}
      />
      <div className="inline-tables">
        <div>
          <TableElement
            tableData={commonTableData}
            setTableData={setCommonTableData}
            tableSchema={commonTableSchema}
            createTable={createCommonTable}
            canEditFirstColumn
            canAddOrDeleteRow
            isCommonTable
            setCalcTableHasError={setCalcTableHasError}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
          <TableElement
            tableData={remainingOptionsPoolTableData}
            setTableData={setRemainingOptionsPoolTableData}
            tableSchema={remainingOptionPoolTableSchema}
            createTable={createRemainingOptionsPoolTable}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            isRemainingOptionPoolTable
          />
        </div>
        <div>
          <TableElement
            tableData={optionsTableData}
            setTableData={setOptionsTableData}
            tableSchema={optionsTableSchema}
            createTable={createOptionsTable}
            preferredData={preferredTableData}
            commonData={commonTableData}
            canAddOrDeleteRow
            firstColumnIsDropdown
            isOptionTable
            setCalcTableHasError={setCalcTableHasError}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
          <TableElement
            tableData={warrantsTableData}
            setTableData={setWarrantsTableData}
            tableSchema={warrantsTableSchema}
            createTable={createWarrantsTable}
            preferredData={preferredTableData}
            commonData={commonTableData}
            canAddOrDeleteRow
            firstColumnIsDropdown
            isWarrantTable
            setCalcTableHasError={setCalcTableHasError}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
        </div>
      </div>
      <div className="cap-summary">
        <div>
          <span>{capStructureSummary ? commaEvery3rdChar(parseFloat(capStructureSummary?.totalOutstandingShares).toFixed(0)) : '-'}</span>
          <p>Total Outstanding Shares</p>
        </div>
        <div>
          <span>{capStructureSummary ? commaEvery3rdChar(parseFloat(capStructureSummary?.totalOutstandingWithROP).toFixed(0)) : '-'}</span>
          <p>
            Total Outstanding Shares
            <br />
            + Remaining Options
          </p>
        </div>
        <div>
          <span>{capStructureSummary ? commaEvery3rdChar(parseFloat(capStructureSummary?.totalAsConvertedShares).toFixed(0)) : '-'}</span>
          <p>Total As Converted Shares</p>
        </div>
        <div>
          <span>{capStructureSummary ? commaEvery3rdChar(parseFloat(capStructureSummary?.totalAsConvertedSharesWithROP).toFixed(0)) : '-'}</span>
          <p>
            Total As Converted Shares
            <br />
            + Remaining Options
          </p>
        </div>
      </div>
    </main>
  );
}

CapStructure.propTypes = {
  userData: PropTypes.object.isRequired,
  saveCapData: PropTypes.bool.isRequired,
  setSaveCapData: PropTypes.func.isRequired,
};
