import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import { Storage } from 'aws-amplify';
import PropTypes from 'prop-types';
import moment from 'moment';
import AnimateHeight from 'react-animate-height';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import SelectAllIcon from '@mui/icons-material/SelectAll';
// import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AddIcon from '@mui/icons-material/Add';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import FlagIcon from '@mui/icons-material/Flag';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import FilterMenus from './FilterMenus';
import CompTable from './CompTable';
import SummariesTable from './SummariesTable';
import EquityInputs from './EquityInputs';
import AddCompModal from './AddCompModal';

import { createFetchHeaders } from '../../../utils/apiCalls';

import { getUserId, getUserCompanyId } from '../../../utils/auth';

import { ErrorMessageContext } from '../../../lib/contextLib';

import { copy } from '../../../utils';

import './index.scss';

export default function Comps({
  userData,
  setUserData,
  tabToView,
  setTabToView,
  runningComps,
  setRunningComps,
  setShowRunningCompSnackbar,
  setCompSuccess,
  setCheckForMissingCompInputs,
}) {
  const [fetchingComps, setFetchingComps] = useState(true);
  const [reSummarizing, setReSummarizing] = useState(false);
  const [identifyingOutliers, setIdentifyingOutliers] = useState(false);
  const [identifyingOutliersSuccess, setIdentifyingOutliersSuccess] = useState(false);

  const [compData, setCompData] = useState({});
  const [summariesData, setSummariesData] = useState({});
  const [transactionData, setTransactionData] = useState(userData.transactionData || {});

  const [industries, setIndustries] = useState(['']);
  const [searchIndustryInput, setSearchIndustryInput] = useState(['']);
  const [currentIndustries, setCurrentIndustries] = useState(['']);

  const [showAddCompModal, setShowAddCompModal] = useState(false);
  const [compsToAdd, setCompsToAdd] = useState([{ rowId: 0, compInput: '' }]);

  const [showIndustriesModal, setShowIndustriesModal] = useState(false);

  const [viewAllShortDescriptions, setViewAllShortDescriptions] = useState(false);
  const [viewAllLongDescriptions, setViewAllLongDescriptions] = useState(false);

  const [transactionDate, setTransactionDate] = useState('');
  const [valuationDate, setValuationDate] = useState('');

  // Filter options
  const growthFilterOptions = ['Market growth', 'Revenue growth', 'EBITDA growth', 'Gross Profit growth', 'EBITDA Margin', 'Gross Margin'];
  const multiplesFilterOptions = ['Revenue multiples', 'EBITDA multiples'];
  const yearsFilterOptions = [...Array(40)].map((val, index) => index / 4 + 0.25 <= 1 ? `${index / 4 + 0.25} year` : `${index / 4 + 0.25} years`);
  const fiscalPeriodFilterOptions = ['Last fiscal year -1 (LFY-1)', 'Last fiscal year (LFY)', 'Last 12 months (LTM)',
    'Current fiscal year (CFY)', 'Next 12 months (NTM)', 'Next fiscal year (NFY)', 'Next fiscal year +1 (NFY+1)', 'Next fiscal year +2 (NFY+2)'];

  const [filterSelected, setFilterSelected] = useState(false);
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const [showRevenue, setShowRevenue] = useState(false);
  const [showEBITDA, setShowEBITDA] = useState(false);
  const [showGrossProfit, setShowGrossProfit] = useState(false);
  const [showMarketCap, setShowMarketCap] = useState(false);
  const [showVolatilityValDate, setShowVolatilityValDate] = useState(false);
  const [showVolatilityTrDate, setShowVolatilityTrDate] = useState(false);
  const [growthFilterValues, setGrowthFilterValues] = useState([]);
  const [multiplesFilterValues, setMultiplesFilterValues] = useState([]);
  const [yearsFilterValues, setYearsFilterValues] = useState([]);
  const [fiscalPeriodFilterValues, setFiscalPeriodFilterValues] = useState([]);
  const [industryError, setIndustryError] = useState(false);

  const [showVolatilityFootnote, setShowVolatilityFootnote] = useState(false);
  const [showBacksolveFootnote, setShowBacksolveFootnote] = useState(false);

  const [showComps, setShowComps] = useState(false);

  const [showWaitLongerMsg, setShowWaitLongerMsg] = useState(false);

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const headerScroll = useRef(null);
  const compTableRightScroll = useRef(null);
  const compTableLeftScroll = useRef(null);
  const summariesScroll = useRef(null);
  const subjectCompanyScroll = useRef(null);

  const checkCompStatusRef = useRef(null);
  const runningCompsRef = useRef(false);

  function handleRightCompScroll() {
    headerScroll.current.scrollLeft = compTableRightScroll.current.scrollLeft;
    subjectCompanyScroll.current.scrollLeft = compTableRightScroll.current.scrollLeft;
    summariesScroll.current.scrollLeft = compTableRightScroll.current.scrollLeft;
    compTableLeftScroll.current.scrollTop = compTableRightScroll.current.scrollTop;
  }

  function handleLeftCompScroll() {
    compTableRightScroll.current.scrollTop = compTableLeftScroll.current.scrollTop;
  }

  function handleSummariesScroll() {
    headerScroll.current.scrollLeft = summariesScroll.current.scrollLeft;
    compTableRightScroll.current.scrollLeft = summariesScroll.current.scrollLeft;
  }

  useEffect(() => { runningCompsRef.current = runningComps; }, [runningComps]);

  async function checkCompStatus() {
    if (runningCompsRef.current) {
      const userId = getUserId();
      const enterpriseCompanyId = getUserCompanyId();
      try {
        let transactionDBData = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}` +
          '/transactions/asc820/get-enterprise-transaction-data/' +
          `${enterpriseCompanyId}&${userData.transactionData.portfolioCompanyId}&${userData.transactionData.transactionId}&${userId}`,
          await createFetchHeaders('get', {}, true),
        );
        transactionDBData = await transactionDBData.json();
        transactionDBData = transactionDBData.transactionData;
        if (transactionDBData.compStatus === 'complete' && transactionDBData.compsLocation.length > 0) {
          const s3Bucket = `${process.env.REACT_APP_CLIENT_FILES_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`;
          const compURL = await Storage.get(transactionDBData.compsLocation.replace(`s3://${s3Bucket}/820Comps`, ''), {
            customPrefix: { public: '820Comps' },
            bucket: s3Bucket,
          });
          let compJSONData = await fetch(compURL);
          compJSONData = await compJSONData.json();
          const summariesURL = await Storage.get(transactionDBData.compSummaryLocation.replace(`s3://${s3Bucket}/820Comps`, ''), {
            customPrefix: { public: '820Comps' },
            bucket: s3Bucket,
          });
          let summariesJSONData = await fetch(summariesURL);
          summariesJSONData = await summariesJSONData.json();
          setTransactionData(transactionDBData);
          setIndustries(userData.transactionData.basicInfo.companyIndustry && userData.transactionData.basicInfo.companyIndustry.length ?
            userData.transactionData.basicInfo.companyIndustry : ['']);
          setSearchIndustryInput(userData.transactionData.basicInfo.companyIndustry && userData.transactionData.basicInfo.companyIndustry.length ?
            userData.transactionData.basicInfo.companyIndustry : ['']);
          setCurrentIndustries(userData.transactionData.basicInfo.companyIndustry && userData.transactionData.basicInfo.companyIndustry.length ?
            userData.transactionData.basicInfo.companyIndustry : ['']);
          setTransactionDate(transactionDBData.basicInfo.transactionDate);
          setValuationDate(transactionDBData.basicInfo.valuationDate);
          setCompData(compJSONData);
          setSummariesData(summariesJSONData);
          setShowComps(true);
          clearInterval(checkCompStatusRef.current);
          setCompsToAdd([{ rowId: 0, compInput: '' }]);
          setRunningComps(false);
          setShowRunningCompSnackbar(false);
          setCompSuccess(true);
          setShowAddCompModal(false);
          setShowWaitLongerMsg(false);
          let capResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/calc-engine/get-cap-structure-object/` +
            `${userData.metaData.portfolioCompanyId}&${userData.metaData.transactionId}&${userId}`,
            await createFetchHeaders('get', {}, true),
          );
          let calcResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/calc-engine/get-calc-engine-object/` +
            `${userData.metaData.portfolioCompanyId}&${userData.metaData.transactionId}&${userId}`,
            await createFetchHeaders('get', {}, true),
          );
          if (calcResponse.status === 200 && capResponse.status === 200) {
            calcResponse = await calcResponse.json();
            capResponse = await capResponse.json();
            setUserData({
              ...userData,
              transactionData: transactionDBData,
              calcData: calcResponse,
              capData: capResponse,
            });
          }
        } else if (transactionDBData.compStatus === 'error') {
          setShowErrorMessage(transactionDBData.compError);
          setRunningComps(false);
          setShowAddCompModal(false);
          setCompsToAdd([{ rowId: 0, compInput: '' }]);
          clearInterval(checkCompStatusRef.current);
        }
      } catch (e) {
        setShowErrorMessage(e.toString());
      }
    }
  }

  async function parseTransactionInfo() {
    if (userData.transactionData?.compsLocation && userData.transactionData?.compSummaryLocation) {
      const s3Bucket = `${process.env.REACT_APP_CLIENT_FILES_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`;
      const compURL = await Storage.get(userData.transactionData.compsLocation.replace(`s3://${s3Bucket}/820Comps`, ''), {
        customPrefix: { public: '820Comps' },
        bucket: s3Bucket,
      });
      let compJSONData = await fetch(compURL);
      compJSONData = await compJSONData.json();
      const summariesURL = await Storage.get(userData.transactionData.compSummaryLocation.replace(`s3://${s3Bucket}/820Comps`, ''), {
        customPrefix: { public: '820Comps' },
        bucket: s3Bucket,
      });
      let summariesJSONData = await fetch(summariesURL);
      summariesJSONData = await summariesJSONData.json();
      setTransactionData(userData.transactionData);
      setIndustries(userData.transactionData.basicInfo.companyIndustry && userData.transactionData.basicInfo.companyIndustry.length ?
        userData.transactionData.basicInfo.companyIndustry : ['']);
      setSearchIndustryInput(userData.transactionData.basicInfo.companyIndustry && userData.transactionData.basicInfo.companyIndustry.length ?
        userData.transactionData.basicInfo.companyIndustry : ['']);
      setCurrentIndustries(userData.transactionData.basicInfo.companyIndustry && userData.transactionData.basicInfo.companyIndustry.length ?
        userData.transactionData.basicInfo.companyIndustry : ['']);
      setTransactionDate(userData.transactionData.basicInfo.transactionDate);
      setValuationDate(userData.transactionData.basicInfo.valuationDate);
      setCompData(compJSONData);
      setSummariesData(summariesJSONData);
      setRunningComps(userData.transactionData.compStatus === 'running');
      if (userData.transactionData.compStatus === 'running') checkCompStatusRef.current = setInterval(() => { checkCompStatus(); }, 10000);
      setShowComps(true);
    }
    setFetchingComps(false);
  }

  useEffect(() => { if (userData.transactionData) parseTransactionInfo(); }, [userData.transactionData]);

  const compTableLeftRef = useCallback((node) => {
    if (compTableLeftScroll?.current) compTableLeftScroll.current.removeEventListener('scroll', handleLeftCompScroll);
    if (node) node.addEventListener('scroll', handleLeftCompScroll, { passive: true });
    compTableLeftScroll.current = node;
  }, []);

  const compTableRightRef = useCallback((node) => {
    if (compTableRightScroll?.current) compTableRightScroll.current.removeEventListener('scroll', handleRightCompScroll);
    if (node) node.addEventListener('scroll', handleRightCompScroll, { passive: true });
    compTableRightScroll.current = node;
  }, []);

  const summariesRef = useCallback((node) => {
    if (summariesScroll?.current) summariesScroll.current.removeEventListener('scroll', handleSummariesScroll);
    if (node) node.addEventListener('scroll', handleSummariesScroll, { passive: true });
    summariesScroll.current = node;
  }, []);

  async function runComps() {
    try {
      setRunningComps(true);
      setShowRunningCompSnackbar(true);
      const userId = getUserId();
      const transactionDBData = {
        ...transactionData,
        basicInfo: {
          ...transactionData.basicInfo,
          companyIndustry: industries,
        },
        requestUserId: userId,
        version: parseInt(transactionData.version, 10),
      };
      delete transactionDBData.lastModified;
      let transactionResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/transactions/asc820/enterprise-update-transaction`,
        await createFetchHeaders('post', transactionDBData, true),
      );
      transactionResponse = await transactionResponse.json();
      const newCompData = {
        companyId: userData.metaData.portfolioCompanyId,
        transactionId: userData.metaData.transactionId,
        transactionDate: transactionData.basicInfo.transactionDate ?
          transactionData.basicInfo.transactionDate :
          userData.transactionData.basicInfo.transactionDate,
        valuationDate: transactionData.basicInfo.valuationDate ?
          transactionData.basicInfo.valuationDate :
          userData.transactionData.basicInfo.transactionDate,
        ...compsToAdd[0].compInput && {
          companies: [...compsToAdd
            .filter((comp) => comp.compInput !== '')
            .map((compToAdd) => compToAdd.compInput),
          ],
        },
        reRun: 0,
        requestUserId: userId,
      };
      setUserData({ ...userData, transactionData: { ...transactionResponse, compStatus: 'running' } });
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/comps/run-comps`,
        await createFetchHeaders('post', newCompData, true),
      );
      checkCompStatusRef.current = setInterval(() => { checkCompStatus(); }, 10000);
    } catch (e) {
      setShowErrorMessage(e.toString());
      setRunningComps(false);
      clearInterval(checkCompStatusRef.current);
      setUserData({ ...userData, transactionData: { ...userData.transactionData, compStatus: 'complete' } });
    }
  }

  async function refreshComps() {
    setRunningComps(true);
    setShowRunningCompSnackbar(true);
    try {
      const userId = getUserId();
      const compDataObject = {
        companyId: transactionData.companyId,
        transactionId: transactionData.transactionId,
        enterpriseCompanyId: transactionData.enterpriseCompanyId,
        compData,
        transaction: userData.transactionData,
        requestUserId: userId,
      };
      setUserData({ ...userData, transactionData: { ...userData.transactionData, compStatus: 'running' } });
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/comps/refresh-comps`,
        await createFetchHeaders('post', compDataObject, true),
      );
      checkCompStatusRef.current = setInterval(() => { checkCompStatus(); }, 10000);
    } catch (e) {
      setShowErrorMessage(e.toString());
      setRunningComps(false);
      clearInterval(checkCompStatusRef.current);
      setUserData({ ...userData, transactionData: { ...userData.transactionData, compStatus: 'complete' } });
    }
  }

  async function identifyOutliers() {
    setIdentifyingOutliers(true);
    try {
      const userId = getUserId();
      const compDataObject = {
        companyId: transactionData.companyId,
        transactionId: transactionData.transactionId,
        enterpriseCompanyId: transactionData.enterpriseCompanyId,
        compData: { ...compData, Outlier: [...compData.Outlier].map(() => 0) },
        requestUserId: userId,
      };
      let outliersRes = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/comps/identify-outliers`,
        await createFetchHeaders('post', compDataObject, true),
      );
      outliersRes = await outliersRes.json();
      setSummariesData(outliersRes?.summary);
      setCompData(outliersRes?.comps);
      setIdentifyingOutliersSuccess(true);
      setTimeout(() => { setIdentifyingOutliersSuccess(false); }, 3000);
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setIdentifyingOutliers(false);
    }
  }

  async function reSummarize(indexChanged, typeChanged) {
    setReSummarizing(true);
    try {
      const userId = getUserId();
      let newActiveArr = [...compData.Active];
      let newMultiplesArr = [...compData.Multiples];
      const newOutlierArr = [...compData.Outlier];
      if (indexChanged === 'all') {
        if (typeChanged === 'active') {
          newActiveArr = newActiveArr.some((active) => active) ? newActiveArr.map(() => 0) : newActiveArr.map(() => 1);
        } else {
          newMultiplesArr = newMultiplesArr.some((active) => active) ? newMultiplesArr.map(() => 0) : newMultiplesArr.map(() => 1);
        }
      } else if (typeChanged === 'add-both') {
        newActiveArr[indexChanged] = 1;
        newMultiplesArr[indexChanged] = 1;
      } else if (typeChanged === 'active') {
        newActiveArr[indexChanged] = newActiveArr[indexChanged] ? 0 : 1;
      } else if (typeChanged === 'multiple') {
        newMultiplesArr[indexChanged] = newMultiplesArr[indexChanged] ? 0 : 1;
      } else {
        newOutlierArr[indexChanged] = newOutlierArr[indexChanged] ? 0 : 1;
      }
      setCompData({
        ...compData,
        Active: newActiveArr,
        Multiples: newMultiplesArr,
        Outlier: newOutlierArr,
      });
      const compDataObject = {
        companyId: transactionData.companyId,
        transactionId: transactionData.transactionId,
        enterpriseCompanyId: transactionData.enterpriseCompanyId,
        compData: {
          ...compData,
          Active: newActiveArr,
          Multiples: newMultiplesArr,
          Outlier: newOutlierArr,
        },
        requestUserId: userId,
      };
      let summariesRes = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/comps/summarize-and-save-comps`,
        await createFetchHeaders('post', compDataObject, true),
      );
      summariesRes = await summariesRes.json();
      setSummariesData(summariesRes);
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setReSummarizing(false);
    }
  }

  async function removeIndustryFromComp(industryToRemove) {
    setReSummarizing(true);
    try {
      const userId = getUserId();
      const compDataObject = {
        companyId: transactionData.companyId,
        transactionId: transactionData.transactionId,
        enterpriseCompanyId: transactionData.enterpriseCompanyId,
        requestUserId: userId,
        compData,
        industry: industryToRemove,
      };
      let removeResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/comps/remove-industry`,
        await createFetchHeaders('post', compDataObject, true),
      );
      removeResponse = await removeResponse.json();
      const summariesRes = removeResponse?.summary;
      setSummariesData(summariesRes);
      const compResponse = removeResponse?.comps;
      setCompData(compResponse);
      setCurrentIndustries([...currentIndustries.filter((el) => el !== industryToRemove)]);
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setReSummarizing(false);
    }
  }

  if (!fetchingComps) {
    return (
      <div className="Comps">
        {transactionData.basicInfo?.valuationDate && transactionData.basicInfo?.transactionDate ? (
          <>
            {tabToView === 5 && (
              <div className="header-info-block">
                <h4>
                  <SelectAllIcon />
                  Select industries, comps & equity
                </h4>
                <p>
                  Add companies, select comparable companies, and weight scenarios at the bottom of this page before you head over
                  <br />
                  to the Calculations tab to calculate Backsolve, PubCo and M&A methods.
                  <span> Need to update the data in the company list?</span>
                  <Button onClick={() => { refreshComps(); }}>Click here</Button>
                  .
                </p>
              </div>
            )}
            {tabToView === 4 && (
              <div className="header-info-block">
                <h4>
                  <QueryStatsIcon />
                  Choose volatility
                </h4>
              </div>
            )}
            {tabToView === 4 && (
              <div className="volatility-inputs-wrapper">
                <div className="volatility-inputs-header">
                  <span>Choose a volatility percentile</span>
                  <p>
                    The volatility percentile can be selected and edited from this tab and from the Calculations tab.
                    Our system updates both tabs simultaneously. Don&apos;t worry about being precise,
                    you&apos;ll be able to run a range of volatilities and make a final selection from the Calculations tab,
                    plus you can edit this and rerun models on the Calculations tab at any time.
                  </p>
                </div>
                <div className="volatility-inputs-content">
                  <div className={`volatility-input-wrapper ${showVolatilityFootnote ? ' show-radius' : ''}`}>
                    <div className="volatility-input-top">
                      <TextField
                        label="Volatility percentile"
                        value={userData.calcData?.limits.volatility.quantile !== '' ?
                          ((userData.calcData?.limits.volatility.quantile !== 'None' && userData.calcData?.limits.volatility.quantile) ?
                            userData.calcData?.limits.volatility.quantile : `${userData.calcData?.limits.volatility.default}%`) : ''}
                        onChange={(e) => {
                          const newData = copy(userData);
                          newData.calcData.limits.volatility.quantile = e.target.value;
                          setUserData(newData);
                        }}
                        select
                        SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                      >
                        {[
                          { value: 'min', label: 'Minimum' },
                          { value: '5%', label: '5th percentile' },
                          { value: '10%', label: '10th percentile' },
                          { value: '25%', label: '25th percentile' },
                          { value: '50%', label: 'Median' },
                          { value: '75%', label: '75th percentile' },
                          { value: '90%', label: '90th percentile' },
                          { value: '95%', label: '95th percentile' },
                          { value: 'max', label: 'Maximum' },
                          { value: 'mean', label: 'Average' },
                        ].map((menuItem) => (
                          <MenuItem key={menuItem.label} value={menuItem.value}>
                            {menuItem.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Button onClick={() => setShowVolatilityFootnote(!showVolatilityFootnote)}>
                        {`${showVolatilityFootnote ? 'Hide' : 'Show'} Footnote`}
                      </Button>
                    </div>
                    <AnimateHeight duration={300} height={showVolatilityFootnote ? 'auto' : 0}>
                      <div className="volatility-input-footnote">
                        <span>Volatility footnote&nbsp;</span>
                        <span>will appear in client&apos;s report. </span>
                        <TextField />
                        <div className="footnote-characters-left">{`${0}/1000`}</div>
                      </div>
                    </AnimateHeight>
                  </div>
                  <div className={`volatility-input-wrapper ${showBacksolveFootnote ? ' show-radius' : ''}`}>
                    <div className="volatility-input-top">
                      <TextField
                        label="Backsolve volatility (optional)"
                        value={userData.calcData?.methods.backsolve.manualTransactionDateVolatility || ''}
                        onChange={(e) => {
                          const newData = copy(userData);
                          newData.calcData.methods.backsolve.manualTransactionDateVolatility = e.target.value;
                          setUserData(newData);
                        }}
                        onBlur={(e) => {
                          const newData = copy(userData);
                          newData.calcData.methods.backsolve.manualTransactionDateVolatility =
                            e.target.value ? `${e.target.value.replaceAll('%', '')}%` : '';
                          setUserData(newData);
                        }}
                      />
                      <Button onClick={() => setShowBacksolveFootnote(!showBacksolveFootnote)}>
                        {`${showBacksolveFootnote ? 'Hide' : 'Show'} Footnote`}
                      </Button>
                    </div>
                    <AnimateHeight duration={300} height={showBacksolveFootnote ? 'auto' : 0}>
                      <div className="volatility-input-footnote">
                        <span>Backsolve volatility footnote&nbsp;</span>
                        <span>will appear in client&apos;s report. </span>
                        <TextField />
                        <div className="footnote-characters-left">{`${0}/1000`}</div>
                      </div>
                    </AnimateHeight>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="header-info-block">
            <h4>
              <SelectAllIcon />
              Industries, comps & equity
            </h4>
            <p>
              <span>Unlock this tab&nbsp;</span>
              - click &apos;check for missing information&apos;, then:
            </p>
            <ul>
              <li>
                if all necessary information has been filled in on the &apos;820 inputs&apos; tab,
                like the transaction date and valuation date, this tab will unlock.
              </li>
              <li>
                if you haven&apos;t submitted all necessary information, you&apos;ll be redirected to where all missing information is located.
                All required information will be highlighted for your convenience.
              </li>
            </ul>
            <Button
              className="reroute-btn"
              onClick={() => {
                setCheckForMissingCompInputs(true);
                setTabToView(2);
              }}
            >
              Check for missing information now
            </Button>
          </div>
        )}
        {transactionData.basicInfo?.valuationDate && transactionData.basicInfo?.transactionDate ? (
          <div className="industries-select-wrapper">
            <div className="add-companies-view">
              <div className="add-companies-description">
                <h6>Add companies</h6>
              </div>
              <div className="submit-btns-container">
                <p>
                  Click &apos;Add industries&apos; to add pre-curated industry list of companies.
                  To add individual companies or to add companies in bulk, click &apos;Add companies&apos;.
                </p>
                <Button
                  className="industries-btn"
                  // disabled={runningComps}
                  onClick={() => {
                    setIndustryError(false);
                    setShowIndustriesModal(true);
                  }}
                >
                  <AddBusinessOutlinedIcon />
                  Add industries
                </Button>
                <Button
                  className="submit-btn"
                  // disabled={runningComps}
                  onClick={() => {
                    setIndustryError(false);
                    setShowAddCompModal(true);
                  }}
                >
                  <AddIcon />
                  Add companies
                </Button>
              </div>
            </div>
          </div>
        ) : <p className="no-comps-to-show">Company and industry info will show here.</p>}
        {showComps && transactionData.basicInfo.valuationDate && transactionData.basicInfo.transactionDate && (
          <>
            <div className="comps-wrapper">
              <div className="comps-header">
                <div className="comps-header-text">
                  <div>
                    {tabToView === 4 && <h6>Select volatility guideline companies</h6>}
                    {tabToView === 5 && <h6>Select comparable companies</h6>}
                    <p>
                      Click the checkbox next to a company&apos;s name to select and deselect individual companies.
                      To select or deselect all, click the checkbox in the Company list header.
                    </p>
                  </div>
                  {identifyingOutliersSuccess ? (
                    <div className="outlier-status">
                      <CheckCircleOutlineIcon />
                      Outliers flagged
                    </div>
                  ) : identifyingOutliers ? (
                    <div className="outlier-status">
                      <span className="dots-circle-spinner" />
                      Checking for outliers
                    </div>
                  ) : (
                    <Tooltip
                      title={(
                        <>
                          This will override any currently selected outliers.
                          <br />
                          It will automatically flag outliers using the volatility&apos;s
                          <br />
                          Inter-Quartial (IQR) method.
                          <br />
                          To read more about IQR,&nbsp;
                          <a className="iqr-link" target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Interquartile_range">
                            click here
                          </a>
                          .
                        </>
                      )}
                      PopperProps={{ className: 'bottom-arrow-tooltip iqr-tooltip' }}
                      placement="top"
                      arrow
                    >
                      <Button onClick={() => identifyOutliers()}>
                        <FlagIcon />
                        Check for outliers
                      </Button>
                    </Tooltip>
                  )}
                </div>
                {/* <div className="industry-tooltip">
                  <LightbulbOutlinedIcon />
                  <div>
                    We&apos;ve used a lot of abbreviations.
                    <br />
                    <Button className="tooltip-link">Click here</Button>
                    to see what they stand for.
                  </div>
                </div> */}
              </div>
              <FilterMenus
                tabToView={tabToView}
                setFilterSelected={setFilterSelected}
                growthFilterOptions={growthFilterOptions}
                multiplesFilterOptions={multiplesFilterOptions}
                yearsFilterOptions={yearsFilterOptions}
                fiscalPeriodFilterOptions={fiscalPeriodFilterOptions}
                showCompanyInfo={showCompanyInfo}
                setShowCompanyInfo={setShowCompanyInfo}
                showRevenue={showRevenue}
                setShowRevenue={setShowRevenue}
                showEBITDA={showEBITDA}
                setShowEBITDA={setShowEBITDA}
                showGrossProfit={showGrossProfit}
                setShowGrossProfit={setShowGrossProfit}
                showVolatilityValDate={showVolatilityValDate}
                setShowVolatilityValDate={setShowVolatilityValDate}
                showVolatilityTrDate={showVolatilityTrDate}
                setShowVolatilityTrDate={setShowVolatilityTrDate}
                showMarketCap={showMarketCap}
                setShowMarketCap={setShowMarketCap}
                growthFilterValues={growthFilterValues}
                setGrowthFilterValues={setGrowthFilterValues}
                multiplesFilterValues={multiplesFilterValues}
                setMultiplesFilterValues={setMultiplesFilterValues}
                yearsFilterValues={yearsFilterValues}
                setYearsFilterValues={setYearsFilterValues}
                fiscalPeriodFilterValues={fiscalPeriodFilterValues}
                setFiscalPeriodFilterValues={setFiscalPeriodFilterValues}
                setViewAllLongDescriptions={setViewAllLongDescriptions}
                setViewAllShortDescriptions={setViewAllShortDescriptions}
                viewAllLongDescriptions={viewAllLongDescriptions}
                viewAllShortDescriptions={viewAllShortDescriptions}
              />
              <CompTable
                tabToView={tabToView}
                compData={compData}
                setCompData={setCompData}
                filterSelected={filterSelected}
                showCompanyInfo={showCompanyInfo}
                showRevenue={showRevenue}
                showEBITDA={showEBITDA}
                showGrossProfit={showGrossProfit}
                showMarketCap={showMarketCap}
                showVolatilityValDate={showVolatilityValDate}
                showVolatilityTrDate={showVolatilityTrDate}
                growthFilterValues={growthFilterValues}
                multiplesFilterValues={multiplesFilterValues}
                yearsFilterValues={yearsFilterValues}
                fiscalPeriodFilterValues={fiscalPeriodFilterValues}
                setShowAddCompModal={setShowAddCompModal}
                headerScroll={headerScroll}
                subjectCompanyScroll={subjectCompanyScroll}
                compTableLeftRef={compTableLeftRef}
                compTableRightRef={compTableRightRef}
                valuationDate={moment(valuationDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                transactionDate={moment(transactionDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                // eslint-disable-next-line react/jsx-no-bind
                reSummarize={reSummarize}
                viewAllLongDescriptions={viewAllLongDescriptions}
                viewAllShortDescriptions={viewAllShortDescriptions}
                runningComps={runningComps}
              />
              <SummariesTable
                tabToView={tabToView}
                compData={compData}
                summariesData={summariesData}
                reSummarizing={reSummarizing}
                filterSelected={filterSelected}
                showCompanyInfo={showCompanyInfo}
                showRevenue={showRevenue}
                showEBITDA={showEBITDA}
                showGrossProfit={showGrossProfit}
                showMarketCap={showMarketCap}
                showVolatilityValDate={showVolatilityValDate}
                showVolatilityTrDate={showVolatilityTrDate}
                growthFilterValues={growthFilterValues}
                multiplesFilterValues={multiplesFilterValues}
                yearsFilterValues={yearsFilterValues}
                fiscalPeriodFilterValues={fiscalPeriodFilterValues}
                summariesRef={summariesRef}
                valuationDate={moment(valuationDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                transactionDate={moment(transactionDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
              />
            </div>
            {tabToView === 5 && <EquityInputs userData={userData} setUserData={setUserData} />}
          </>
        )}
        <AddCompModal
          showAddCompModal={showAddCompModal}
          setShowAddCompModal={setShowAddCompModal}
          showIndustriesModal={showIndustriesModal}
          setShowIndustriesModal={setShowIndustriesModal}
          runningComps={runningComps}
          compsToAdd={compsToAdd}
          setCompsToAdd={setCompsToAdd}
          runComps={() => runComps()}
          setIndustryError={setIndustryError}
          industryError={industryError}
          userData={userData}
          industries={industries}
          setIndustries={setIndustries}
          searchIndustryInput={searchIndustryInput}
          setSearchIndustryInput={setSearchIndustryInput}
          currentIndustries={currentIndustries}
          // eslint-disable-next-line
          removeIndustryFromComp={removeIndustryFromComp}
          // eslint-disable-next-line
          refreshComps={refreshComps}
          compData={compData}
          showWaitLongerMsg={showWaitLongerMsg}
          setShowWaitLongerMsg={setShowWaitLongerMsg}
        />
      </div>
    );
  }
}

Comps.propTypes = {
  userData: PropTypes.object.isRequired,
  tabToView: PropTypes.number.isRequired,
  setTabToView: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  runningComps: PropTypes.bool.isRequired,
  setRunningComps: PropTypes.func.isRequired,
  setShowRunningCompSnackbar: PropTypes.func.isRequired,
  setCompSuccess: PropTypes.func.isRequired,
  setCheckForMissingCompInputs: PropTypes.func.isRequired,
};
