import React, { useState } from 'react';

import Button from '@mui/material/Button';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import './ProfileSettings.scss';
import Security from './Security';
import AccountInfo from './BasicInfo';

export default function ProfileSettings() {
  const [tabToView, setTabToView] = useState('basic-info');

  return (
    <main className="ProfileSettings">
      <div className="profile-settings-container">
        <div className="top-tabs-nav">
          <Button
            className={`tab-button ${tabToView === 'basic-info' ? 'active-tab' : ''}`}
            onClick={() => setTabToView('basic-info')}
          >
            <PersonOutlineOutlinedIcon />
            Basic Info
          </Button>
          <Button
            className={`tab-button ${tabToView === 'security' ? 'active-tab' : ''}`}
            onClick={() => setTabToView('security')}
          >
            <SettingsOutlinedIcon />
            Security
          </Button>
        </div>
        {tabToView === 'basic-info' && <AccountInfo />}
        {tabToView === 'security' && <Security />}
      </div>
    </main>
  );
}
