import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import CloseIcon from '@mui/icons-material/Close';
import InputIcon from '@mui/icons-material/Input';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { createFetchHeaders } from '../../utils/apiCalls';

import { getUserId, getUserCompanyId } from '../../utils/auth';

import { ErrorMessageContext } from '../../lib/contextLib';

import { copy, commaEvery3rdChar } from '../../utils';

import './Calculations.scss';

export default function Calculations({
  userData,
  setUserData,
}) {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [calcData, setCalcData] = useState(null);
  const [gridData, setGridData] = useState(null);

  // const [showMAndA, setShowMAndA] = useState(false);

  const [showFootnotes, setShowFootnotes] = useState(false);

  const [backsolveWeight, setBacksolveWeight] = useState('100%');
  const [pubCoWeight, setPubCoWeight] = useState('0%');
  const [mnaWeight, setMnaWeight] = useState('0%');
  const [methodWeightWasChanged, setMethodWeightWasChanged] = useState(false);

  const [selectedGridValue, setSelectedGridValue] = useState({});
  const [gridValueSelected, setGridValueSelected] = useState(false);

  const [calcInProgress, setCalcInProgress] = useState(false);
  const [calcComplete, setCalcComplete] = useState(false);
  const [showWeightNotification, setShowWeightNotification] = useState(false);

  const gridHeaderScroll = useRef(null);
  const gridRightScroll = useRef(null);
  const gridLeftScroll = useRef(null);

  const volatilityLabels = [
    { value: 'min', label: 'Minimum' },
    { value: '5%', label: '5th percentile' },
    { value: '10%', label: '10th percentile' },
    { value: '25%', label: '25th percentile' },
    { value: '50%', label: 'Median' },
    { value: '75%', label: '75th percentile' },
    { value: '90%', label: '90th percentile' },
    { value: '95%', label: '95th percentile' },
    { value: 'max', label: 'Maximum' },
    { value: 'mean', label: 'Average' },
  ];

  function handleGridScroll() {
    gridHeaderScroll.current.scrollLeft = gridRightScroll.current.scrollLeft;
    gridLeftScroll.current.scrollTop = gridRightScroll.current.scrollTop;
  }

  const gridRightRef = useCallback((node) => {
    if (gridRightScroll?.current) gridRightScroll.current.removeEventListener('scroll', handleGridScroll);
    if (node) node.addEventListener('scroll', handleGridScroll, { passive: true });
    gridRightScroll.current = node;
  }, []);

  useEffect(() => {
    if (userData.calcData) {
      if (!userData.calcData?.limits.term.singleTerm) {
        const newData = copy(userData);
        newData.calcData.limits.term.singleTerm = '5.0';
        setUserData(newData);
      }
    }
  }, []);

  useEffect(() => {
    if (userData.gridData?.defaultScenarios) {
      setCalcData(userData.gridData.defaultScenarios);
      setGridData(userData.gridData.grid);
      setBacksolveWeight(`${userData.gridData.backsolveWeight * 100}%`);
      setPubCoWeight(`${userData.gridData.pubcoWeight * 100}%`);
      setMnaWeight(`${userData.gridData.mnaWeight * 100}%`);
    }
  }, [userData.gridData]);

  function calculateTotalScenarios() {
    let totalScenarios;
    if (userData.gridData?.totalScenariosRun) {
      if (userData.gridData?.defaultScenarios?.backsolveEquityValue &&
        userData.gridData?.defaultScenarios?.mnaEquityValue &&
        userData.gridData?.defaultScenarios?.pubcoEquityValue) {
        totalScenarios = userData.gridData.totalScenariosRun * 3;
      } else if (userData.gridData?.defaultScenarios?.backsolveEquityValue &&
        userData.gridData?.defaultScenarios?.pubcoEquityValue) {
        totalScenarios = userData.gridData.totalScenariosRun * 2;
      } else {
        totalScenarios = userData.gridData?.totalScenariosRun;
      }
      return totalScenarios;
    }
    return null;
  }

  async function runMethods() {
    try {
      const requestUserId = getUserId();
      const enterpriseCompanyId = getUserCompanyId();
      const methodsData = {
        requestUserId,
        enterpriseCompanyId,
        portfolioCompanyId: userData.metaData.portfolioCompanyId,
        transactionId: userData.metaData.transactionId,
        weights: {
          backsolve: backsolveWeight ? parseFloat(backsolveWeight.replace('%', '')) : 100,
          pubco: pubCoWeight ? parseFloat(pubCoWeight.replace('%', '')) : 0,
          mna: mnaWeight ? parseFloat(mnaWeight.replace('%', '')) : 0,
        },
        term: selectedGridValue.term,
        volatility: selectedGridValue.volatility,
      };
      let methodsResponse = await fetch(
        `${process.env.REACT_APP_CALC_ENGINE_URL}/calcEngine/weigh-asc820-methods`,
        await createFetchHeaders('post', methodsData, true),
      );
      methodsResponse = await methodsResponse.json();
      setUserData({
        ...userData,
        gridData: {
          ...methodsResponse,
          calculationTime: userData.gridData.calculationTime,
          totalScenariosRun: userData.gridData.totalScenariosRun,
          grid: gridData,
        },
      });
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setGridValueSelected(false);
    }
  }

  async function runASC820(withAnalysis) {
    try {
      setCalcInProgress(true);
      const requestUserId = getUserId();
      const enterpriseCompanyId = getUserCompanyId();
      const newCalcData = copy(userData.calcData);
      newCalcData.limits.volatility.calculateRange = withAnalysis ? 1 : 0;
      newCalcData.limits.term.calculateRange = withAnalysis ? 1 : 0;
      newCalcData.methods.backsolve.weight = backsolveWeight ? parseFloat(backsolveWeight.replace('%', '')) : 100;
      newCalcData.methods.pubCo.weight = pubCoWeight ? parseFloat(pubCoWeight.replace('%', '')) : 0;
      newCalcData.methods.mna.weight = mnaWeight ? parseFloat(mnaWeight.replace('%', '')) : 0;
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/calc-engine/update-calc-engine-object`,
        await createFetchHeaders('post', newCalcData, true),
      );
      const methodDBData = {
        requestUserId,
        portfolioCompanyId: userData.metaData.portfolioCompanyId,
        transactionId: userData.metaData.transactionId,
        methods: ['backsolve', 'pubco', 'mna'],
        enterpriseCompanyId,
      };
      let runResponse = await fetch(
        `${process.env.REACT_APP_CALC_ENGINE_URL}/calcEngine/run_asc820`,
        await createFetchHeaders('post', methodDBData, true),
      );
      if (runResponse.status === 200) {
        runResponse = await runResponse.json();
        if (withAnalysis) setUserData({ ...userData, calcData: newCalcData, gridData: runResponse });
        else setUserData({ ...userData, calcData: newCalcData, gridData: { ...runResponse, grid: gridData } });
        setCalcComplete(true);
        setTimeout(() => setCalcComplete(false), 5000);
      } else if (runResponse.status === 400) {
        const errorResponse = await runResponse.json();
        const errorMessage = errorResponse;
        setShowErrorMessage(errorMessage);
      } else {
        setUserData({ ...userData, calcData: newCalcData });
      }
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setCalcInProgress(false);
    }
  }

  async function runPortfolioCompanyReport() {
    try {
      const requestUserId = getUserId();
      const { metaData } = userData;
      const scenarios = userData.gridData.defaultScenarios;
      const reportFetchData = {
        enterpriseCompanyId: metaData.enterpriseCompanyId,
        investorCompanyId: metaData.investorCompanyId,
        portfolioCompanyId: metaData.portfolioCompanyId,
        transactionId: metaData.transactionId,
        term: selectedGridValue.term,
        volatility: selectedGridValue.volatility,
        scenarios,
        requestUserId,
      };
      let runResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/reports/asc820/generate-portfolio-company-report`,
        await createFetchHeaders('post', reportFetchData, true),
      );
      if (runResponse.status === 200) {
        runResponse = await runResponse.json();
      } else if (runResponse.status === 400) {
        const errorResponse = await runResponse.json();
        const errorMessage = errorResponse;
        setShowErrorMessage(errorMessage);
      }
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  useEffect(() => { if (gridValueSelected) runMethods(); }, [gridValueSelected]);

  useEffect(() => {
    if (methodWeightWasChanged) {
      if ((parseFloat(backsolveWeight.replace('%', '')) || 0) +
        (parseFloat(pubCoWeight.replace('%', '')) || 0) +
        (parseFloat(mnaWeight.replace('%', '')) || 0) !== 100) {
        setShowWeightNotification(true);
      } else {
        runMethods();
        setShowWeightNotification(false);
      }
    }
    setMethodWeightWasChanged(false);
  }, [methodWeightWasChanged]);

  function determineMethod() {
    const backsolve = userData?.gridData?.defaultScenarios?.backsolve;
    const pubCo = userData?.gridData?.defaultScenarios?.pubCo;
    const mna = userData?.gridData?.defaultScenarios?.mna;

    const conditions = [
      { condition: backsolve && !pubCo && !mna, result: 'Backsolve' },
      { condition: !backsolve && pubCo && !mna, result: 'PubCo' },
      { condition: !backsolve && !pubCo && mna, result: 'M&A' },
      { condition: backsolve && pubCo && mna, result: 'Backsolve + PubCo + M&A' },
      { condition: backsolve && pubCo && !mna, result: 'Backsolve + PubCo' },
      { condition: !backsolve && pubCo && mna, result: 'Pubco + M&A' },
      { condition: backsolve && !pubCo && mna, result: 'Backsolve + M&A' },
    ];

    return conditions.find(({ condition }) => condition)?.result || '';
  }

  return (
    <div className="Calculations">
      <div className="generate-report-button">
        <Button
          onClick={() => { runPortfolioCompanyReport(); }}
        >
          <MenuBookOutlinedIcon />
          Generate report
        </Button>
      </div>
      <div className="header-info-block">
        <h4>
          <CalculateOutlinedIcon />
          Calculations
        </h4>
        {!userData.transactionData?.compsLocation ? (
          <>
            <p>This Calculations tab is not yet available.&nbsp;</p>
            <p>It will be available and you will be able to run Backsolve, PubCo and M&A methods once:</p>
            <ul>
              <li>all required information has been entered into all other tabs,</li>
              <li>
                most importantly, you calculate equity at the bottom of the Comps tab,
                the total weight equals 100%, and you run the calculation engine.
              </li>
            </ul>
          </>
        ) : (
          <div className="header-content">
            <p>
              The total weight at the bottom of the comps tab must equal 100% to run the calc engine. When ready, run shorter
              <br />
              ranges, and bigger increments, to save calculation time. We provide you with the most recent calculation&apos;s time, plus
              <br />
              the total number of scenarios ran, to help give you a feel for how long future calculations may take.
            </p>
            <div className="info-block">
              <AccessTimeOutlinedIcon />
              <h5>Calculation time</h5>
              <span className="user-data">
                {userData.gridData?.calculationTime ?
                  `${Math.round(userData.gridData.calculationTime, 0)} seconds` : '-'}
              </span>
            </div>
            <div className="info-block">
              <span>#</span>
              <h5>Total scenarios ran</h5>
              <span className="user-data">
                {calculateTotalScenarios() ? commaEvery3rdChar(calculateTotalScenarios()) : '-'}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="calc-wrapper">
        <div className="calc-header">
          <h4>
            <ScaleOutlinedIcon />
            Weight methods & run the calc engine
          </h4>
          <p>
            Run the calc engine to see calculated, weighted values. You can edit volatility below or from the volatility tab.
            <br />
            Changing it here will automatically change it on the volatility tab.
          </p>
          <Button
            className={!userData.gridData?.defaultScenarios ? 'run-calc-btn' : 're-run-calc-btn'}
            disabled={showWeightNotification || calcInProgress}
            onClick={() => { runASC820(); }}
          >
            <CalculateOutlinedIcon />
            Run calc engine
          </Button>
        </div>
        {calcInProgress && (
          <div className="calc-in-progress">
            <span className="dots-circle-spinner" />
            <span>Calculation in progress. It can take a few minutes. If you make edits during calculation, you&apos;ll need to recalculate.</span>
            <IconButton
              className="close-icon"
              onClick={() => setCalcInProgress(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
        {calcComplete && !calcInProgress && (
          <div className="calc-complete">
            <div className="check-icon"><CheckRoundedIcon /></div>
            <span>Calculation complete. Calc engine outputs have been updated.</span>
            <IconButton
              className="close-icon"
              onClick={() => setCalcComplete(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
        {showWeightNotification && (
          <div className="weight-notification">
            <NotificationImportantOutlinedIcon />
            <span>Weights must equal 100%</span>
          </div>
        )}
        <div className="calc-content">
          <div className="inputs-wrapper">
            <div className="input-section">
              <h6>
                <InputIcon />
                Define inputs
              </h6>
              <div className="term-volatility-market-adjust">
                <div className="input-block">
                  <div className="input-title-and-icon">
                    <h6>Term</h6>
                    <Tooltip
                      disableInteractive
                      title="Term from valuation date"
                      PopperProps={{ className: 'bottom-arrow-tooltip' }}
                      placement="top"
                      arrow
                    >
                      <div className="round-icon-wrapper"><ErrorIcon /></div>
                    </Tooltip>
                  </div>
                  <TextField
                    value={userData.calcData?.limits.term.singleTerm || ''}
                    onChange={(e) => {
                      const newData = copy(userData);
                      newData.calcData.limits.term.singleTerm = e.target.value;
                      setUserData(newData);
                    }}
                    select
                    SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                  >
                    {[...Array(20)].map((el, index) => (
                      <MenuItem key={`${index + 1}`} value={((index + 1) * 0.25).toString()}>
                        {(index + 1) * 0.25}
                        &nbsp;year
                        {(index + 1) * 0.25 > 1 ? 's' : ''}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="input-block">
                  <h6>Volatility</h6>
                  <TextField
                    value={userData.calcData?.limits.volatility.quantile !== '' ?
                      ((userData.calcData?.limits.volatility.quantile !== 'None' && userData.calcData?.limits.volatility.quantile) ?
                        userData.calcData?.limits.volatility.quantile : userData.calcData?.limits.volatility.default) : ''}
                    onChange={(e) => {
                      const newData = copy(userData);
                      newData.calcData.limits.volatility.quantile = e.target.value;
                      setUserData(newData);
                    }}
                    select
                    SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                  >
                    {volatilityLabels.map((menuItem) => (
                      <MenuItem key={menuItem.label} value={menuItem.value}>
                        {menuItem.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="input-block">
                  <h6>Market equity adjustment</h6>
                  <TextField
                    value={userData.calcData?.methods.backsolve.marketAdjustment || '0.0%'}
                    onChange={(e) => {
                      const newData = copy(userData);
                      newData.calcData.methods.backsolve.marketAdjustment = e.target.value;
                      setUserData(newData);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="vl" />
            <div className="input-section">
              <h6>
                <ScaleOutlinedIcon />
                Weight methods
              </h6>
              <div className="weights-inputs">
                <div className="weight-input-block">
                  <h6>Backsolve</h6>
                  <TextField
                    label="Weight"
                    value={backsolveWeight || ''}
                    onChange={(e) => setBacksolveWeight(e.target.value)}
                    onBlur={(e) => {
                      setBacksolveWeight(!e.target.value.includes('%') ? `${e.target.value}%` : e.target.value);
                      setMethodWeightWasChanged(true);
                    }}
                  />
                </div>
                <div className="vl" />
                <div className="weight-input-block">
                  <h6>PubCo</h6>
                  <TextField
                    label="Weight"
                    value={pubCoWeight || ''}
                    onChange={(e) => setPubCoWeight(e.target.value)}
                    onBlur={(e) => {
                      setPubCoWeight(!e.target.value.includes('%') ? `${e.target.value}%` : e.target.value);
                      setMethodWeightWasChanged(true);
                    }}
                  />
                </div>
                <div className="vl" />
                <div className="weight-input-block">
                  <h6>M&A</h6>
                  <TextField
                    label="Weight"
                    value={mnaWeight || ''}
                    onChange={(e) => setMnaWeight(e.target.value)}
                    onBlur={(e) => {
                      setMnaWeight(!e.target.value.includes('%') ? `${e.target.value}%` : e.target.value);
                      setMethodWeightWasChanged(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {userData.gridData?.defaultScenarios && (
            <div className="sensitivity-analysis-wrapper">
              <div className="sensitivity-analysis">
                <div className="sensitivity-analysis-header">
                  <span className="sensitivity-analysis-header title">
                    Term and volatility ranges for sensitivity analysis
                  </span>
                  <p className="sensitivity-analysis-header copy">
                    Without running the calc engine, click on cells in the sensitivity analysis table
                    and see how equity and share prices change in the outputs section below.
                    Run the calc engine if you want to change inputs above or make changes to other tabs.
                  </p>
                </div>
                <div className="range-inputs">
                  <div className="term-input">
                    <h6>Term range</h6>
                    <div>
                      <TextField
                        select
                        label="Minimum"
                        value={userData.calcData?.limits?.term.min || ''}
                        onChange={(e) => {
                          const newData = copy(userData);
                          newData.calcData.limits.term.min = e.target.value;
                          setUserData(newData);
                        }}
                        SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                      >
                        {[...[...Array(5)].map((el, index) => `${(index + 1)} year${index + 1 > 1 ? 's' : ''}`)].map((range) => (
                          <MenuItem key={range} value={`${range.replace(/\D/g, '')}.0`}>{range}</MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        select
                        label="Maximum"
                        value={userData.calcData?.limits?.term.max || ''}
                        onChange={(e) => {
                          const newData = copy(userData);
                          newData.calcData.limits.term.max = e.target.value;
                          setUserData(newData);
                        }}
                        SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                      >
                        {[...[...Array(5)].map((el, index) => `${(index + 1)} year${index + 1 > 1 ? 's' : ''}`)].map((range) => (
                          <MenuItem key={range} value={`${range.replace(/\D/g, '')}.0`}>{range}</MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        select
                        label="Increment"
                        value={userData.calcData?.limits?.term.increment || ''}
                        onChange={(e) => {
                          const newData = copy(userData);
                          newData.calcData.limits.term.increment = e.target.value;
                          setUserData(newData);
                        }}
                        SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                      >
                        {[...[...Array(5)].map((el, index) => `${(index / 4)} year`)].map((range, index) => {
                          if (index !== 0) return <MenuItem key={range} value={`${range.replace(' year', '')}`}>{range}</MenuItem>;
                          return null;
                        })}
                      </TextField>
                    </div>
                  </div>
                  <div className="vl" />
                  <div className="volatility-input">
                    <h6>Volatility range</h6>
                    <TextField
                      select
                      label="Buffer (-)"
                      value={parseFloat(userData.calcData?.limits?.volatility.lowerBuffer) ?
                        userData.calcData?.limits.volatility.lowerBuffer : ''}
                      onChange={(e) => {
                        const newData = copy(userData);
                        newData.calcData.limits.volatility.lowerBuffer = e.target.value;
                        setUserData(newData);
                      }}
                      SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                    >
                      {['-5%', '-10%', '-20%'].map((range) => (
                        <MenuItem key={range} value={range.replace('-', '').replace('%', '')}>{range}</MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      select
                      label="Buffer (+)"
                      value={parseFloat(userData.calcData?.limits?.volatility.upperBuffer) ?
                        userData.calcData?.limits?.volatility.upperBuffer : ''}
                      onChange={(e) => {
                        const newData = copy(userData);
                        newData.calcData.limits.volatility.upperBuffer = e.target.value;
                        setUserData(newData);
                      }}
                      SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                    >
                      {['5%', '10%', '20%'].map((range) => (
                        <MenuItem key={range} value={range.replace('+', '').replace('%', '')}>{range}</MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      select
                      label="Increment"
                      value={userData.calcData?.limits.volatility.increment || ''}
                      onChange={(e) => {
                        const newData = copy(userData);
                        newData.calcData.limits.volatility.increment = e.target.value;
                        setUserData(newData);
                      }}
                      SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                    >
                      {['1%', '2.5%', '5%', '10%'].map((range) => (
                        <MenuItem key={range} value={range.replace('%', '')}>{range}</MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div className="run-analysis-btn">
                  <Button onClick={() => { runASC820(true); }} disabled={showWeightNotification || calcInProgress}>
                    {calcInProgress ? (
                      <>
                        <span className="dots-circle-spinner" />
                        Calculating...
                      </>
                    ) : (
                      <>
                        <AnalyticsOutlinedIcon />
                        {Object.values(gridData || []).every((data) => data && data.length > 0) &&
                          (Object.values(gridData || []).some((data) => data && data.length > 1) ||
                            Object.keys(gridData || []).length > 2) ? 'Rerun analysis' : 'Run Analysis'}
                      </>
                    )}
                  </Button>
                </div>
                {Object.values(gridData || []).every((data) => data && data.length > 0) &&
                  (Object.values(gridData || []).some((data) => data && data.length > 1) ||
                    Object.keys(gridData || []).length > 2) &&
                  (
                    <>
                      <div className="vol-table-header">
                        {volatilityLabels.find((vol) => vol.value === userData.calcData?.limits.volatility.quantile).label}
                        &nbsp;sensitivity analysis
                      </div>
                      <div className="volatility-table">
                        <div className="fixed-left">
                          <div className="table-header">
                            <div className="table-header-top-titles">
                              <div className="term" />
                            </div>
                            <div className="table-sub-headers">
                              <div className="sub-header-group term">
                                <div className="sub-header">
                                  <h6>Term</h6>
                                  <span>(years)</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="table-rows" ref={gridLeftScroll}>
                            {(gridData?.terms || []).map((termVal) => (
                              <div className="table-row-data" key={termVal}>
                                <div className="row-data-group term">
                                  <div className="table-cell">{termVal}</div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="right-scrollable">
                          <div className="table-header" ref={gridHeaderScroll}>
                            <div className="table-header-top-titles">
                              {Object.keys(gridData || []).filter((volatility) => (
                                volatility !== 'terms' && gridData?.[volatility].length
                              )).sort((a, b) => (
                                parseFloat(a) - parseFloat(b)
                              )).map((volatility) => (
                                <div className="volatility" key={volatility}>
                                  <h6>
                                    {volatility !== '0' ? (volatility > 0 ? `+${volatility}%` : `${volatility}%`) :
                                      volatilityLabels.find((vol) => vol.value === userData.calcData?.limits.volatility.quantile).label}
                                  </h6>
                                </div>
                              ))}
                            </div>
                            <div className="table-sub-headers">
                              <div className="sub-header-group volatility">
                                {Object.keys(gridData || []).map((volatility) => {
                                  if (volatility !== 'terms' && gridData?.[volatility].length) {
                                    return (
                                      <div className="sub-header" key={volatility}>
                                        <span>$</span>
                                        <span>%</span>
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="table-rows" ref={gridRightRef}>
                            {(gridData?.terms || []).map((term, rowIndex) => (
                              <div className="table-row-data" key={term}>
                                <div className="row-data-group volatility">
                                  {Object.keys(gridData || []).filter((volatility) => (
                                    volatility !== 'terms' && gridData?.[volatility].length
                                  )).sort((a, b) => (
                                    parseFloat(a) - parseFloat(b)
                                  )).map((volatility) => (
                                    <div
                                      role="button"
                                      className={`table-cell${selectedGridValue?.term === term &&
                                        selectedGridValue?.volatility === gridData?.[volatility]?.[rowIndex]?.[1].toFixed(1) ?
                                        ' selected' : ''}${gridData?.[volatility]?.[rowIndex]?.[0] ? ' can-hover' : ' no-data'}`}
                                      key={volatility}
                                      onClick={() => {
                                        setSelectedGridValue({
                                          term,
                                          volatility: gridData?.[volatility]?.[rowIndex]?.[1].toFixed(1),
                                        });
                                        setGridValueSelected(true);
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                          setSelectedGridValue({
                                            term,
                                            volatility: gridData?.[volatility]?.[rowIndex]?.[1].toFixed(1),
                                          });
                                          setGridValueSelected(true);
                                        }
                                      }}
                                      tabIndex={gridData?.[volatility]?.[rowIndex]?.[0] ? 0 : -1}
                                    >
                                      <span>
                                        {gridData?.[volatility]?.[rowIndex]?.[0] ?
                                          `$${commaEvery3rdChar(gridData?.[volatility]?.[rowIndex]?.[0].toFixed())}` : '-'}
                                        <CheckCircleOutlineRoundedIcon />
                                      </span>
                                      <span>
                                        {gridData?.[volatility]?.[rowIndex]?.[1] ?
                                          `${gridData?.[volatility]?.[rowIndex]?.[1].toFixed(1)}%` : '-'}
                                      </span>
                                    </div>
                                  ))}
                                  {Object.keys(gridData || []).length <= 6 && <div className="table-cell filler-cell" />}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          )}
          <Button
            className="footnote-btn"
            onClick={() => setShowFootnotes(!showFootnotes)}
          >
            <DescriptionOutlinedIcon />
            {`${showFootnotes ? 'Hide' : 'Show'} all footnotes`}
          </Button>
          <AnimateHeight duration={500} height={showFootnotes ? 'auto' : 0}>
            <div className="footnotes">
              {['Term', 'Backsolve', 'Volatility', 'PubCo', 'DLOM', 'M&A', 'Market equity adjustment', 'General']
                .map((footnoteTitle) => (
                  <div className="footnote" key={footnoteTitle}>
                    <h6>
                      {footnoteTitle}
                      &nbsp;footnote
                    </h6>
                    <TextField multiline maxRows={2} placeholder="Type note (optional)" />
                    <div className="footnote-characters-left">{`${0}/900`}</div>
                  </div>
                ))}
            </div>
          </AnimateHeight>
          <hr />
          <div className="calc-engine-outputs">
            <div className="calc-engine-outputs-header">
              <h6>
                <InputIcon />
                Calc engine outputs
              </h6>
              {userData?.gridData?.defaultScenarios ? (
                <p>
                  When you think you&apos;re ready, generate a report from here or from the report tab and view all exhibits.
                  <br />
                  Don&apos;t worry, you can generate as many reports as you wish, and then choose which report to send to your client.
                </p>
              ) : (
                <p>
                  Play around with the above inputs, and weight methods then run the calc engine to see calculated, weighted holdings below.
                  <br />
                  You can run the calc engine as many times as you want.
                </p>
              )}
            </div>
            {!userData.gridData?.defaultScenarios ? (
              <div className="no-outputs-to-show">
                {calcInProgress ? <span className="dots-circle-spinner" /> : 'Calc engine outputs will show here'}
              </div>
            ) : (
              <>
                <div className="equity-values-row">
                  <div className="value-block">
                    <span>
                      {userData.gridData?.defaultScenarios?.backsolveEquityValue ?
                        `$${commaEvery3rdChar(parseFloat(userData.gridData?.defaultScenarios?.backsolveEquityValue).toFixed(0))}` : '-'}
                    </span>
                    <span>
                      Backsolve
                      <br />
                      calculated equity
                    </span>
                  </div>
                  <div className="value-block">
                    <span>
                      {userData.calcData?.methods?.pubCo?.equityValue ?
                        `$${commaEvery3rdChar(parseFloat(userData.calcData?.methods?.pubCo?.equityValue).toFixed(0))}` : '-'}
                    </span>
                    <span>
                      PubCo
                      <br />
                      calculated equity
                    </span>
                  </div>
                  <div className="value-block">
                    <span>
                      {userData.calcData?.methods?.pubCo?.enterpriseValue ?
                        `$${commaEvery3rdChar(parseFloat(userData.calcData?.methods?.pubCo?.enterpriseValue).toFixed(0))}` : '-'}
                    </span>
                    <span>
                      Calculated
                      <br />
                      equity value
                    </span>
                  </div>
                  {Object.values(gridData || []).some((data) => !data || data.length <= 1) && Object.keys(gridData || []).length <= 2 && (
                    <p>
                      Last calculation ran did not include a range for Term nor Volatility.
                      <br />
                      Run a term and volatility range to see the most options below.
                    </p>
                  )}
                </div>
                <div className="tables-wrapper">
                  <div className={`methods-table${calcData?.mna ? 'three-tables' : ''}`}>
                    <div className="backsolve-table">
                      <div className="table-header">
                        <div className="table-header-top-titles">
                          <div className="backsolve"><h6>Weighted Backsolve</h6></div>
                        </div>
                        <div className="table-sub-headers">
                          <div className="sub-header-group backsolve">
                            <div className="sub-header">
                              <h6>Class</h6>
                            </div>
                            <div className="sub-header">
                              <h6>Per share</h6>
                            </div>
                            <div className="sub-header">
                              <h6>Aggregate value</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table-rows">
                        {Object.keys(
                          calcData?.backsolve?.perShareConclusion['Ownership Class'] || [],
                        ).map((className, rowIndex) => (
                          <div className="table-row-data" key={className}>
                            <div className="row-data-group backsolve">
                              <div className="table-cell">
                                {calcData?.backsolve?.perShareConclusion['Ownership Class']?.[rowIndex].length >= 25 ? (
                                  <Tooltip title={calcData?.backsolve?.perShareConclusion['Ownership Class']?.[rowIndex]} placement="top">
                                    <span>{`${calcData?.backsolve?.perShareConclusion['Ownership Class']?.[rowIndex].slice(0, 25).trim()}...`}</span>
                                  </Tooltip>
                                ) : calcData?.backsolve?.perShareConclusion['Ownership Class']?.[rowIndex]}
                              </div>
                              <div className="table-cell">
                                {`$${calcData?.backsolve?.perShareConclusion['Allocated Per Share Value']?.[rowIndex].toFixed(4)}`}
                              </div>
                              <div className="table-cell">
                                {`$${commaEvery3rdChar(
                                  calcData?.backsolve?.perShareConclusion['Aggregate Value']?.[rowIndex].toFixed(0),
                                )}`}
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="total-row">
                          <div className="table-cell" />
                          <div className="table-cell">
                            {`$${commaEvery3rdChar(calcData?.backsolve?.backsolveTotalValue?.toFixed(0) || 0)}`}
                          </div>
                        </div>
                      </div>
                    </div>
                    {calcData?.pubco && (
                      <div className="pubCo-table">
                        <div className="table-header">
                          <div className="table-header-top-titles">
                            <div className="pubCo"><h6>Weighted PubCo</h6></div>
                          </div>
                          <div className="table-sub-headers">
                            <div className="sub-header-group pubCo">
                              <div className="sub-header">
                                <h6>Class</h6>
                              </div>
                              <div className="sub-header">
                                <h6>Per share</h6>
                              </div>
                              <div className="sub-header">
                                <h6>Aggregate value</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="table-rows">
                          {Object.keys(calcData?.pubco?.perShareConclusion['Ownership Class'] || []).map((className, rowIndex) => (
                            <div className="table-row-data" key={className}>
                              <div className="row-data-group pubCo">
                                <div className="table-cell">
                                  {calcData?.pubco?.perShareConclusion['Ownership Class']?.[rowIndex]}
                                </div>
                                <div className="table-cell">
                                  {`$${calcData?.pubco?.perShareConclusion['Allocated Per Share Value']?.[rowIndex].toFixed(4)}`}
                                </div>
                                <div className="table-cell">
                                  {`$${commaEvery3rdChar(
                                    calcData?.pubco?.perShareConclusion['Aggregate Value']?.[rowIndex].toFixed(0),
                                  )}`}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="total-row">
                          <div className="table-cell" />
                          <div className="table-cell">
                            {`$${commaEvery3rdChar(calcData?.pubco?.pubcoTotalValue?.toFixed(0) || 0)}`}
                          </div>
                        </div>
                      </div>
                    )}
                    {calcData?.mna && (
                      <div className="mAndA-table">
                        <div className="table-header">
                          <div className="table-header-top-titles">
                            <div className="mAndA"><h6>M&A method</h6></div>
                          </div>
                          <div className="table-sub-headers">
                            <div className="sub-header-group mAndA">
                              <div className="sub-header">
                                <h6>Class</h6>
                              </div>
                              <div className="sub-header">
                                <h6>Per share</h6>
                              </div>
                              <div className="sub-header">
                                <h6>Aggregate value</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="table-rows">
                          {Object.keys(calcData?.mna?.['Ownership Class'] || []).map((className, rowIndex) => (
                            <div className="table-row-data" key={className}>
                              <div className="row-data-group mAndA">
                                <div className="table-cell">
                                  {calcData?.mna?.['Ownership Class']?.[rowIndex]}
                                </div>
                                <div className="table-cell">
                                  {`$${calcData?.mna?.['Allocated Per Share Value']?.[rowIndex].toFixed(4)}`}
                                </div>
                                <div className="table-cell">
                                  {`$${commaEvery3rdChar(calcData?.mna?.['Aggregate Value']?.[rowIndex].toFixed(0))}`}
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="total-row">
                            <div className="table-cell" />
                            <div className="table-cell">Data</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="concluded-table">
                    <div className="table-header">
                      <div className="table-header-top-titles">
                        <div className="concluded method-title">
                          <h6>Weighted holding values</h6>
                          <p>
                            {determineMethod()}
                          </p>
                        </div>
                      </div>
                      <div className="table-sub-headers">
                        <div className="sub-header-group concluded">
                          <div className="sub-header">
                            <h6>Class of stock</h6>
                          </div>
                          <div className="sub-header">
                            <h6>Per share</h6>
                          </div>
                          <div className="sub-header">
                            <h6>Total shares</h6>
                          </div>
                          <div className="sub-header">
                            <h6>Total value</h6>
                          </div>
                          <div className="sub-header">
                            <h6>Shares held</h6>
                          </div>
                          <div className="sub-header">
                            <h6>Holding value</h6>
                          </div>
                          <div className="sub-header">
                            <h6>Assumed holding value</h6>
                          </div>
                          <div className="sub-header">
                            <h6>Difference</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-rows">
                      {Object.keys(calcData?.concluded?.object?.['Class of stock'] || []).map((className, rowIndex) => (
                        <div className="table-row-data" key={className}>
                          <div className="row-data-group concluded">
                            <div className="table-cell">
                              {calcData.concluded.object['Class of stock'][rowIndex]}
                            </div>
                            <div className="table-cell">
                              {`$${calcData.concluded.object['Per share'][rowIndex].toFixed(2)}`}
                            </div>
                            <div className="table-cell">
                              {`${commaEvery3rdChar(calcData.concluded.object['Total shares'][rowIndex])}`}
                            </div>
                            <div className="table-cell">
                              {`$${commaEvery3rdChar(calcData.concluded.object['Total Value'][rowIndex].toFixed(0))}`}
                            </div>
                            <div className="table-cell">
                              {`${commaEvery3rdChar(calcData.concluded.object['Shares held'][rowIndex])}`}
                            </div>
                            <div className="table-cell">
                              {calcData.concluded.object['Holding value'][rowIndex] ?
                                `$${commaEvery3rdChar(calcData.concluded.object['Holding value'][rowIndex].toFixed(0))}` : 'N/A'}
                            </div>
                            <div className="table-cell">
                              {calcData.concluded.object['Assumed holding value'][rowIndex] ?
                                `$${commaEvery3rdChar(
                                  calcData.concluded.object['Assumed holding value'][rowIndex].toFixed(0),
                                )}` : 'N/A'}
                            </div>
                            <div className="table-cell">
                              {`${(calcData.concluded.object.Difference[rowIndex] * 100).toFixed(2)}%`}
                            </div>
                            <div className="table-cell" />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="total-row">
                      <div className="table-cell" />
                      <div className="table-cell">
                        {calcData ? `$${commaEvery3rdChar(calcData.concluded.totalValue?.toFixed(0) || 0)}` : ''}
                      </div>
                      <div className="table-cell">
                        {calcData ? `$${commaEvery3rdChar(calcData.concluded.totalHoldingValue?.toFixed(0) || 0)}` : ''}
                      </div>
                      <div className="table-cell">
                        {calcData ? `$${commaEvery3rdChar(calcData.concluded.totalAssumedHoldingValue?.toFixed(0) || 0)}` : ''}
                      </div>
                      <div className="table-cell">
                        {calcData ? `${(calcData.concluded.difference * 100).toFixed(2)}%` : ''}
                      </div>
                      <div className="table-cell" />
                    </div>
                    <div className="final-row">
                      <div className="table-cell" />
                      <div className="table-cell">Fund ownership:</div>
                      <div className="table-cell">{calcData ? `${(calcData.concluded.fundOwnership * 100).toFixed(2)}%` : ''}</div>
                      <div className="table-cell" />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="calc-footer" />
      </div>
    </div>
  );
}

Calculations.propTypes = {
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
};
