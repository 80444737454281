import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { getUserCompanyId, getUserId } from '../../utils/auth';
import { createFetchHeaders } from '../../utils/apiCalls';
import { ErrorMessageContext } from '../../lib/contextLib';
import determineBadgeColor from './utils';

const statuses = [
  'Client not started 820',
  'Client in-progress',
  '820 Data entry',
  '820 Analysis',
  '820 Review',
  'Draft',
  'Financial statements ready',
  '820 Done',
];

const blockerStatuses = [
  'On hold',
  'Stuck',
];

const draftStatuses = [
  'Draft review',
  'Draft delivered',
  'Draft iteration',
  'Draft audit',
];

export default function StatusBadgeDropdown({
  company,
  setProjectCompanies,
  projectCompanies,
  setInsideElementHover,
  currentStatus,
  setCurrentStatus,
  blockerStatus,
  setBlockerStatus,
}) {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subDraftMenu, setSubDraftMenu] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);

  async function handleUpdateTransactionStatus() {
    const projectCompaniesCopy = [...projectCompanies];
    try {
      const requestUserId = getUserId();
      const enterpriseCompanyId = await getUserCompanyId();
      const statusData = {
        enterpriseCompanyId,
        investorCompanyId: company.investorCompanyId,
        portfolioCompanyId: company.portfolioCompanyId,
        status: currentStatus,
        blockerStatus,
        projectId: company.projectId,
        transactionId: company.transactionId,
        requestUserId,
      };
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/transactions/asc820/change-transaction-status`,
        await createFetchHeaders('post', statusData, true),
      );
      setProjectCompanies(projectCompaniesCopy);
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  function handleStatusChange(status) {
    if (status === blockerStatus) {
      setBlockerStatus('');
    } else if (blockerStatuses.includes(status)) {
      setBlockerStatus(status);
    } else if (currentStatus !== status) {
      setCurrentStatus(status);
      setBlockerStatus('');
    }
    setUpdateStatus(true);
    setAnchorEl(null);
  }

  useEffect(() => { if (company.blockerStatus.length) setBlockerStatus(company.blockerStatus); }, []);

  useEffect(() => { if (updateStatus) handleUpdateTransactionStatus(); }, [updateStatus]);

  return (
    <div className="status-badge-wrapper">
      {blockerStatus.length > 0 && (
        <span className={`status-badge ${determineBadgeColor(blockerStatus)} sub-status`}>
          {blockerStatus}
        </span>
      )}
      <IconButton
        className="status-badge-btn"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <span className={`status-badge ${determineBadgeColor(currentStatus)}`}>
          {currentStatus}
          <KeyboardArrowDownOutlinedIcon />
        </span>
      </IconButton>
      <Menu
        id="company-status-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          setInsideElementHover(false);
          setSubDraftMenu(false);
        }}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {[...statuses, ...blockerStatuses].map((status) => (
          status !== 'Draft' ? (
            <MenuItem
              key={status}
              className={`cmp-status ${status === currentStatus ? 'active' : ''} ${status === blockerStatus ? 'sub-status-active' : ''}`}
              onClick={() => {
                handleStatusChange(status);
                setInsideElementHover(false);
              }}
            >
              <ListItemText primary={status} />
            </MenuItem>
          ) : (
            <Tooltip
              key={status}
              className={draftStatuses.includes(currentStatus) ? 'active' : ''}
              id="sub-menu"
              open={subDraftMenu}
              placement="right"
              arrow
              title={(
                <>
                  {draftStatuses.map((draftStatus) => (
                    <MenuItem
                      key={draftStatus}
                      className={`cmp-status ${draftStatus === currentStatus ? 'active' : ''}`}
                      onClick={() => {
                        handleStatusChange(draftStatus);
                        setInsideElementHover(false);
                        setSubDraftMenu(false);
                      }}
                    >
                      <ListItemText primary={draftStatus} />
                    </MenuItem>
                  ))}
                </>
              )}
            >
              <MenuItem
                key="draft"
                onClick={() => setSubDraftMenu(true)}
              >
                <ListItemText primary="Draft" />
                <ArrowRightIcon />
              </MenuItem>
            </Tooltip>
          )
        ))}
      </Menu>
    </div>
  );
}

StatusBadgeDropdown.propTypes = {
  company: PropTypes.object.isRequired,
  setProjectCompanies: PropTypes.func.isRequired,
  projectCompanies: PropTypes.array.isRequired,
  setInsideElementHover: PropTypes.func,
  currentStatus: PropTypes.string.isRequired,
  setCurrentStatus: PropTypes.func.isRequired,
  blockerStatus: PropTypes.string.isRequired,
  setBlockerStatus: PropTypes.func.isRequired,
};

StatusBadgeDropdown.defaultProps = {
  setInsideElementHover: () => { },
};
