import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { IconButton, OutlinedInput } from '@mui/material';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { onlyDates } from '../../utils';

import './ProjectDates.scss';

export default function ProjectDates({
  setInputValues,
  inputValues,
  label,
  setUpdateDates,
  dbKey,
}) {
  const [labelHover, setLabelHover] = useState(false);
  const [isEditActive, setEditActive] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);

  const formatInputDate = (input) => {
    const numericValue = input.replace(/\D/g, '');
    if (moment(numericValue, 'MMDDYYYY')) {
      return moment(numericValue, 'MMDDYYYY').format('MM/DD/YY');
    }
    return moment(numericValue, 'MMDDYY').format('MM/DD/YY');
    // more cases can be added
  };

  const handleBlur = () => {
    const formattedDate = formatInputDate(value);
    if (value.length > 1 && formattedDate === 'Invalid date') {
      setError(true);
    } else if (formattedDate !== 'Invalid date' && formattedDate !== inputValues[dbKey] && value.length > 5) {
      setEditActive(false);
      setError(false);
      setInputValues((prevValues) => ({
        ...prevValues,
        [dbKey]: moment(formattedDate, 'MM/DD/YY').format('YYYY-MM-DD'),
      }));
      setValue(formattedDate);
      setUpdateDates(true);
    } else {
      setValue('');
      setEditActive(false);
    }
  };

  useEffect(() => {
    if (inputValues[dbKey]) {
      setValue(moment(inputValues[dbKey], 'YYYY-MM-DD').format('MM/DD/YY'));
    }
  }, [inputValues]);

  return (
    <div className="date-item">
      <div
        className="date-label"
        onMouseOver={() => { if (!labelHover) setLabelHover(true); }}
        onMouseLeave={() => setLabelHover(false)}
        onFocus={() => setLabelHover(true)}
      >
        <span>
          <span className="label-text">
            {label}
            :
          </span>
          {isEditActive ? (
            <span className="date-input">
              <OutlinedInput
                type="text"
                value={value}
                onChange={(e) => setValue(onlyDates(e.target.value))}
                onFocus={() => setError(false)}
                onBlur={handleBlur}
                inputProps={{ maxLength: 10 }}
                autoFocus
                onFocusCapture={(e) => e.target.select(e.target.value.length)}
                error={error}
                onKeyDown={(e) => (e.key === 'Enter' && handleBlur(e.target.value))}
              />
              <IconButton
                size="small"
                onClick={() => {
                  if (!error) setInputValues((prevValues) => ({ ...prevValues, [dbKey]: '' }));
                  else {
                    setInputValues((prevValues) => ({ ...prevValues, [dbKey]: '' }));
                    setValue('');
                  }
                  setEditActive(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </span>
          ) : (
            <>
              <span
                className="input-value"
                onFocus={() => setLabelHover(true)}

              >
                {value || ''}
              </span>
              <div className="edit-btn-wrapper">
                <IconButton
                  className="edit-icon-btn"
                  onFocus={() => setLabelHover(true)}
                  onKeyDown={(e) => e.key === 'Tab' && setLabelHover(false)}
                  onClick={() => setEditActive(true)}
                >
                  <EditIcon style={{ display: labelHover ? 'block' : 'none' }} />
                </IconButton>
              </div>
            </>
          )}
        </span>
      </div>
    </div>
  );
}

ProjectDates.propTypes = {
  setInputValues: PropTypes.func.isRequired,
  inputValues: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  setUpdateDates: PropTypes.func.isRequired,
  dbKey: PropTypes.string,
};

ProjectDates.defaultProps = {
  dbKey: '',
};
