import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  IsSigningOutContext,
  ErrorMessageContext,
  SuccessModalContext,
  SubNavStateContext,
} from './contextLib';

export default function ContextWrapper({ children }) {
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState({
    showModal: false,
    // TODO refactor to not use this
    firstNamePOC: '',
    lastNamePOC: '',
    emailPOC: '',
  });
  const [subNavState, setSubNavState] = useState({});

  const isSigningOutProviderValue = useMemo(() => ({ isSigningOut, setIsSigningOut }), [isSigningOut]);
  const showErrorMessageProviderValue = useMemo(() => ({ showErrorMessage, setShowErrorMessage }), [showErrorMessage]);
  const showSuccessModalProviderValue = useMemo(() => ({ showSuccessModal, setShowSuccessModal }), [showSuccessModal]);
  const subNavStateProviderValue = useMemo(() => ({ subNavState, setSubNavState }), [subNavState]);

  return (
    <IsSigningOutContext.Provider value={isSigningOutProviderValue}>
      <ErrorMessageContext.Provider value={showErrorMessageProviderValue}>
        <SubNavStateContext.Provider value={subNavStateProviderValue}>
          <SuccessModalContext.Provider value={showSuccessModalProviderValue}>
            {children}
          </SuccessModalContext.Provider>
        </SubNavStateContext.Provider>
      </ErrorMessageContext.Provider>
    </IsSigningOutContext.Provider>
  );
}

ContextWrapper.propTypes = { children: PropTypes.element.isRequired };
