import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import { Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import InformationTile from '../../components/InformationTile';

import { createFetchHeaders } from '../../utils/apiCalls';
import { getUserCompanyId, getUserId } from '../../utils/auth';

import { ErrorMessageContext } from '../../lib/contextLib';

import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';
import './InvestorCompanyDetails.scss';

export default function InvestorCompanyDetails({ investorDetails }) {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);
  const [companyInfo, setCompanyInfo] = useState(investorDetails);
  const [editingCompanyInfo, setEditingCompanyInfo] = useState(false);
  const [primaryAccount, setPrimaryAccount] = useState(null);
  const [, setInvestorUsers] = useState([]);
  const [companyInfoDropdownHeight, setCompanyInfoDropdownHeight] = useState('auto');
  const [companyInfoIsSaving, setCompanyInfoIsSaving] = useState(false);
  const [basicAccountInformationHeight, setBasicAccountInformationHeight] = useState('auto');


  async function getInvestorUsers() {
    try {
      const enterpriseCompanyId = getUserCompanyId();
      const requestUserId = getUserId();
      const urlParams = `accounts/get-investor-users/${enterpriseCompanyId}&${investorDetails.investorCompanyId}&${requestUserId}`;
      let response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/${urlParams}`,
        await createFetchHeaders('get', {}, true),
      );
      response = await response.json();
      if (response.accountId) {
        const primaryInvestor = response.find((account) => account.accountId === investorDetails.primaryAccountId);
        setPrimaryAccount(primaryInvestor);
      }
      setInvestorUsers(response);
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  useEffect(() => { getInvestorUsers(); }, []);

  return (
    <main className="InvestorCompanyDetails">
      <div className="page-header">
        <h4>{investorDetails.investorFirmName}</h4>
      </div>
      <div className="investor-company-details-container">
        <div className="info-dropdown">
          <div
            role="button"
            className="header-row"
            onClick={() => { setCompanyInfoDropdownHeight(companyInfoDropdownHeight ? 0 : 'auto'); }}
            onKeyDown={(e) => { if (e.key === 'Enter') setCompanyInfoDropdownHeight(companyInfoDropdownHeight ? 0 : 'auto'); }}
            tabIndex={0}
          >
            <IconButton
              className="chevron-btn"
              onClick={() => { setCompanyInfoDropdownHeight(companyInfoDropdownHeight ? 0 : 'auto'); }}
            >
              <ExpandCircleDownOutlinedIcon className={`${companyInfoDropdownHeight ? 'upward' : 'downward'}-circle-chevron`} />
            </IconButton>
            <h5>Basic account information</h5>
            {!editingCompanyInfo && !companyInfoIsSaving ? (
              <IconButton
                className="edit-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditingCompanyInfo(true);
                }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <div className="btns-container">
                <Button
                  className="save-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCompanyInfoIsSaving(true);
                  }}
                >
                  {!companyInfoIsSaving ? (
                    <>
                      <SaveOutlinedIcon />
                      Save
                    </>
                  ) : (
                    <>
                      <LoadingSpinner className="loading-spinner" />
                      Saving
                    </>
                  )}
                </Button>
                <Button
                  className="cancel-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditingCompanyInfo(false);
                  }}
                >
                  <CancelOutlinedIcon />
                  Cancel
                </Button>
              </div>
            )}
          </div>
          <div>
            <AnimateHeight duration={500} height={companyInfoDropdownHeight}>
              <div className="data-tiles-grid">
                <InformationTile
                  label="Sign up date"
                  dataName="createdDate"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setNewValues={setCompanyInfo}
                />
                <InformationTile
                  label="Company name"
                  dataName="investorFirmName"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setNewValues={setCompanyInfo}
                />
                <InformationTile
                  label="Doing Business As (DBA)"
                  dataName="dba"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setNewValues={setCompanyInfo}
                />
              </div>
            </AnimateHeight>
          </div>
        </div>
        <div className="info-dropdown">
          <div
            role="button"
            className="header-row"
            onClick={() => { setBasicAccountInformationHeight(basicAccountInformationHeight ? 0 : 'auto'); }}
            onKeyDown={(e) => { if (e.key === 'Enter') setBasicAccountInformationHeight(basicAccountInformationHeight ? 0 : 'auto'); }}
            tabIndex={0}
          >
            <IconButton
              className="chevron-btn"
              onClick={() => { setBasicAccountInformationHeight(basicAccountInformationHeight ? 0 : 'auto'); }}
            >
              <ExpandCircleDownOutlinedIcon
                className={`${basicAccountInformationHeight ? 'upward' : 'downward'}-circle-chevron`}
              />
            </IconButton>
            <h5>Basic account information</h5>
          </div>
          <div>
            <AnimateHeight duration={500} height={companyInfoDropdownHeight}>
              <div className="data-tiles-grid">
                {primaryAccount && (
                  <>
                    <InformationTile
                      label="Name - Primary admin"
                      dataName="firstName"
                      isEditing={editingCompanyInfo}
                      values={primaryAccount}
                      setNewValues={setPrimaryAccount}
                    />
                    <InformationTile
                      label="Phone number - Primary admin"
                      dataName="phone"
                      isEditing={editingCompanyInfo}
                      values={primaryAccount}
                      setNewValues={setPrimaryAccount}
                    />
                    <InformationTile
                      label="Email - Primary admin"
                      dataName="email"
                      isEditing={editingCompanyInfo}
                      values={primaryAccount}
                      setNewValues={setPrimaryAccount}
                    />
                    <InformationTile
                      label="Email - Primary admin"
                      dataName="email"
                      isEditing={editingCompanyInfo}
                      values={primaryAccount}
                      setNewValues={setPrimaryAccount}
                    />
                  </>
                )}
              </div>
            </AnimateHeight>
          </div>
        </div>
      </div>
    </main>
  );
}

InvestorCompanyDetails.propTypes = {
  investorDetails: PropTypes.object.isRequired,
};
