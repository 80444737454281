import React, { useState, useEffect, useContext } from 'react';
import { Auth } from 'aws-amplify';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';

import UpdateIcon from '@mui/icons-material/Update';
import CloseIcon from '@mui/icons-material/Close';

import IdleTimer from './IdleTimer';

import { IsSigningOutContext } from './contextLib';

import './InactivityTimeout.scss';

export default function InactivityTimeout() {
  // Timeout delay before modal (in seconds)
  const modalTimeout = 1500;
  // Extra-timeout delay in modal (in seconds)
  const extraDelay = 120;
  // Total timeout delay before logout
  const signoutTimeout = modalTimeout + extraDelay;

  const { setIsSigningOut } = useContext(IsSigningOutContext);

  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [timeBeforeLogOut, setTimeBeforeLogOut] = useState(extraDelay);
  const [resetTimer, setResetTimer] = useState(false);

  function convertSecondsToMinAndSec(seconds) {
    const minutes = Math.floor(seconds / 60);
    const extraSeconds = seconds % 60;
    return `${minutes}m ${extraSeconds}s`;
  }

  async function handleLogout(showInactivityText) {
    setIsSigningOut(true);
    await Auth.signOut();
    window.location.replace(`${process.env.REACT_APP_AUTH_HOSTNAME}${showInactivityText ? '/?inactivity=true' : ''}`);
  }

  useEffect(() => {
    setResetTimer(false);
    setShowTimeoutModal(false);
    setTimeBeforeLogOut(extraDelay);
    const logoutTimer = new IdleTimer({
      timeout: signoutTimeout,
      onTimeout: () => handleLogout(true),
      global: true,
    });
    const modalTimer = new IdleTimer({
      timeout: modalTimeout,
      onTimeout: () => { setShowTimeoutModal(true); },
    });
    return () => {
      logoutTimer.cleanUp();
      modalTimer.cleanUp();
    };
  }, [resetTimer]);

  useEffect(() => { if (timeBeforeLogOut <= 0) setTimeout(() => { handleLogout(true); }, 2000); }, [timeBeforeLogOut]);

  useEffect(() => {
    let modalTimer;
    if (showTimeoutModal) {
      const extraDelayTimer = new IdleTimer({
        timeout: extraDelay,
        onTimeout: () => handleLogout(true),
      });
      modalTimer = setInterval(() => {
        setTimeBeforeLogOut((timeLeft) => timeLeft > 0 && timeLeft - 1);
      }, 1000);
      return () => {
        extraDelayTimer.cleanUp();
        clearInterval(modalTimer);
      };
    }
    return clearInterval(modalTimer);
  }, [showTimeoutModal]);

  return (
    <Dialog
      open={showTimeoutModal}
      className="session-timed-out-dialog"
      disableScrollLock
    >
      <IconButton
        className="close-icon"
        onClick={() => {
          setResetTimer(true);
          setTimeBeforeLogOut(extraDelay);
        }}
      >
        <CloseIcon />
      </IconButton>
      <div className="box-header">
        <UpdateIcon />
        <h4>Session time-out</h4>
      </div>
      <p>
        You&apos;ve been inactive for close to half an hour.
      </p>
      <p>
        For your security, in
        {' '}
        <span>{convertSecondsToMinAndSec(timeBeforeLogOut)}</span>
        , we&apos;ll automatically log you out.
        Click continue to keep working or &apos;Log out&apos; if you&apos;re done.
      </p>
      <div className="dialog-box-buttons">
        <Button onClick={() => handleLogout()}>Log out</Button>
        <Button
          onClick={() => {
            setResetTimer(true);
            setTimeBeforeLogOut(extraDelay);
          }}
        >
          Continue
        </Button>
      </div>
    </Dialog>
  );
}
