import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Autocomplete } from '@mui/material';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';

import { ReactComponent as BlueCalendar } from '../../images/icons/blue_calendar.svg';

import {
  commaEvery3rdChar,
  copy,
  formatUSCurrencyWithoutDollarSign,
  onlyNums,
} from '../../utils';
import { cleanValue } from './utils';

export default function Step2({
  transactionsData,
  setTransactionsData,
  index,
  setActiveStep,
}) {
  const [focussedTextFields, setFocussedTextFields] = useState([]);

  const [blockData, setBlockData] = useState({});

  function handleIntakeChange(dataName, dataValue) {
    const newBlockData = copy(blockData);
    setBlockData({ ...newBlockData, [dataName]: dataValue });
  }

  function handleOnBlurChange(dataName, dataValue, dataIndex) {
    setActiveStep(2);
    const newData = copy(transactionsData);
    newData[dataIndex].fundingInfo = {
      ...newData[dataIndex].fundingInfo,
      [dataName]: Number.isNaN(parseFloat(dataValue)) || dataName === 'latestRoundDate' ?
        dataValue : formatUSCurrencyWithoutDollarSign.format(parseFloat(cleanValue(dataValue))),
    };
    setTransactionsData(newData);
  }

  function setValue(data) {
    if (data) {
      const dataCopy = copy(data);
      Object.entries(dataCopy).forEach((value) => {
        if (value[0] === 'totalAmountRaised') dataCopy[value[0]] = formatUSCurrencyWithoutDollarSign.format(parseFloat(cleanValue(value[1])));
      });
      return dataCopy;
    }
    return transactionsData[index]?.fundingInfo;
  }

  useEffect(() => setBlockData(setValue(transactionsData[index]?.fundingInfo) || {}), [transactionsData]);

  return (
    <>
      <h4>Funding</h4>
      <TextField
        label="Total amount the company has raised since its inception"
        className="total-amount"
        value={blockData?.totalAmountRaised || ''}
        onChange={(e) => { handleIntakeChange('totalAmountRaised', commaEvery3rdChar(e.target.value)); }}
        onFocus={() => {
          setFocussedTextFields([...focussedTextFields, 'totalAmountRaised']);
          setActiveStep(2);
        }}
        onBlur={() => {
          setFocussedTextFields(focussedTextFields.filter((field) => field !== 'totalAmountRaised'));
          handleOnBlurChange('totalAmountRaised', blockData?.totalAmountRaised, index);
        }}
        InputLabelProps={{
          shrink: focussedTextFields.includes('totalAmountRaised') ||
            !!transactionsData[index]?.fundingInfo?.totalAmountRaised,
          className: focussedTextFields.includes('totalAmountRaised') ||
            !!transactionsData[index]?.fundingInfo?.totalAmountRaised ? '' : 'inputLabelNoShrink',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          ),
        }}
      />
      <div className="form-grid">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Date of latest round of funding"
            inputFormat="MM/DD/YYYY"
            onOpen={() => setActiveStep(2)}
            value={transactionsData[index]?.fundingInfo?.latestRoundDate ?
              moment(transactionsData[index].fundingInfo.latestRoundDate, 'YYYY-MM-DD') : null}
            onChange={(val) => { handleOnBlurChange('latestRoundDate', val.format('YYYY-MM-DD'), index); }}
            slotProps={{ textField: { variant: 'outlined', helperText: 'MM/DD/YYYY' } }}
            slots={{ openPickerIcon: BlueCalendar }}
            maxDate={moment().subtract(1, 'day')}
          />
        </LocalizationProvider>
        <Autocomplete
          disablePortal
          freeSolo
          onOpen={() => setActiveStep(2)}
          options={[
            'SAFE Note',
            'Convertible Note',
            'Angel',
            'Pre-Seed',
            'Series Seed',
            'Series A',
            'Series B',
            'Series C',
            'Series D',
            'Series E',
            'Series F',
            'No funding',
          ]}
          groupBy={(option, i) => option[i]}
          inputValue={transactionsData[index]?.fundingInfo?.latestRoundType || ''}
          onInputChange={(e, value) => {
            handleOnBlurChange('latestRoundType', value, index);
          }}
          renderInput={(params) => <TextField {...params} label="Type of latest round of funding" helperText=" " />}
          onKeyDown={(e) => { e.stopPropagation(); }}
        />
      </div>
      {(transactionsData[index]?.fundingInfo?.latestRoundType === 'SAFE Note' ||
        transactionsData[index]?.fundingInfo?.latestRoundType === 'Convertible Note') &&
        (
          <div>
            <div className="safe-convertible-extra-info">
              <p>
                You selected
                {transactionsData[index]?.fundingInfo?.latestRoundType === 'SAFE Note' ? ' SAFE ' : ' Convertible '}
                Note for the latest round of funding, so we&apos;ll need a bit more information about
                {' '}
                <span style={{ textDecoration: 'underline' }}>this latest round</span>
                :
              </p>
              <div className="form-grid">
                <TextField
                  label="Total amount raised"
                  value={commaEvery3rdChar(blockData?.safeOrConvertibleAmountRaised) || ''}
                  onChange={(e) => { handleIntakeChange('safeOrConvertibleAmountRaised', commaEvery3rdChar(e.target.value)); }}
                  onFocus={() => {
                    setFocussedTextFields([...focussedTextFields, 'safeOrConvertibleAmountRaised']);
                    setActiveStep(2);
                  }}
                  onBlur={() => {
                    setFocussedTextFields(focussedTextFields.filter((field) => field !== 'safeOrConvertibleAmountRaised'));
                    handleOnBlurChange('safeOrConvertibleAmountRaised', blockData?.safeOrConvertibleAmountRaised, index);
                  }}
                  InputLabelProps={{
                    shrink: focussedTextFields.includes('safeOrConvertibleAmountRaised') ||
                      !!transactionsData[index]?.fundingInfo?.safeOrConvertibleAmountRaised,
                    className: focussedTextFields.includes('safeOrConvertibleAmountRaised') ||
                      !!transactionsData[index]?.fundingInfo?.safeOrConvertibleAmountRaised ? '' : 'inputLabelNoShrink',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Interest rate"
                  value={blockData?.interestRate || ''}
                  onChange={(e) => { handleIntakeChange('interestRate', onlyNums(e.target.value)); }}
                  onFocus={() => {
                    setFocussedTextFields([...focussedTextFields, 'interestRate']);
                    setActiveStep(2);
                  }}
                  onBlur={() => {
                    setFocussedTextFields(focussedTextFields.filter((field) => field !== 'interestRate'));
                    handleOnBlurChange('interestRate', blockData?.interestRate, index);
                  }}
                  InputLabelProps={{
                    shrink: focussedTextFields.includes('interestRate') ||
                      !!transactionsData[index]?.fundingInfo?.interestRate,
                    className: focussedTextFields.includes('interestRate') ||
                      !!transactionsData[index]?.fundingInfo?.interestRate ? '' : 'inputLabelNoShrink',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Valuation Cap"
                  value={blockData?.valuationCap || ''}
                  onChange={(e) => { handleIntakeChange('valuationCap', commaEvery3rdChar(e.target.value)); }}
                  onFocus={() => {
                    setFocussedTextFields([...focussedTextFields, 'valuationCap']);
                    setActiveStep(2);
                  }}
                  onBlur={() => {
                    setFocussedTextFields(focussedTextFields.filter((field) => field !== 'valuationCap'));
                    handleOnBlurChange('valuationCap', blockData?.valuationCap, index);
                  }}
                  InputLabelProps={{
                    shrink: focussedTextFields.includes('valuationCap') ||
                      !!transactionsData[index]?.fundingInfo?.valuationCap,
                    className: focussedTextFields.includes('valuationCap') ||
                      !!transactionsData[index]?.fundingInfo?.valuationCap ? '' : 'inputLabelNoShrink',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <div>
                  <p>Is valuation cap based on pre or post-money?</p>
                  <RadioGroup
                    row
                    value={transactionsData[index]?.fundingInfo?.preOrPostMoney || ''}
                    onChange={(e) => handleOnBlurChange('preOrPostMoney', e.target.value, index)}
                  >
                    <FormControlLabel value="pre-money" control={<Radio />} label="Pre-money" />
                    <FormControlLabel value="post-money" control={<Radio />} label="Post-money" />
                  </RadioGroup>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
}

Step2.propTypes = {
  transactionsData: PropTypes.array.isRequired,
  setTransactionsData: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
};
