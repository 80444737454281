import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import moment from 'moment';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import './ASC820Dialog.scss';
import {
  commaEvery3rdChar,
  copy,
  formatPhoneNumber,
  isAValidUSPhoneNumber,
  stripDenomination,
} from '../utils';
import { ErrorMessageContext, SuccessModalContext } from '../lib/contextLib';

import successCheckmark from '../images/checkmark-art.svg';
import rocketFrame from '../images/rocket-frame.svg';
import { ReactComponent as BlueCalendar } from '../images/icons/blue_calendar.svg';
import AddCompanyBlock from './AddCompanyBlock';
import { createFetchHeaders, sendHtmlEmail } from '../utils/apiCalls';
import { getUserCompanyId, getUserId, getUserName } from '../utils/auth';

export default function ASC820Dialog({
  startASC820,
  setStartASC820,
  setEnterpriseProjects,
  enterpriseProjects,
  enterpriseUsers,
  investorCompanies,
  portfolioCompanies,
  investorCompaniesNames,
  portfolioCompaniesNames,
}) {
  const dialogScrollRef = useRef(null);
  const companyListRef = useRef(null);
  const clientListRef = useRef(null);
  const enterpriseCompanyId = getUserCompanyId();
  const requestUserId = getUserId();
  const { setShowErrorMessage } = useContext(ErrorMessageContext);
  const { showSuccessModal, setShowSuccessModal } = useContext(SuccessModalContext);
  const [createASC820InProgress, setCreateASC820InProgress] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [clients, setClients] = useState([
    {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
  ]);
  const [clientInputsHaveErrors, setClientInputsHaveError] = useState([
    {
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
    },
  ]);
  const [inputsHaveErrors, setInputsHaveErrors] = useState({
    investorFirmName: false,
    projectName: false,
    clientInfoDueDate: false,
  });
  const [ASC820Info, setASC820Info] = useState({});

  const [projectCompanies, setProjectCompanies] = useState([]);

  const [blockHasError, setBlockHasError] = useState(false);
  const [companyNameErrorList, setCompanyNameErrorList] = useState([]);
  const [rolesDescription, setRolesDescription] = useState('');
  const [selectedInvestorName, setSelectedInvestorName] = useState('');
  const [investorOptions, setInvestorOptions] = useState([]);
  // const [capIqFromDB, setCapIqFromDB] = useState(false);
  // TODO implement when design is finalized
  // const [investorUsersList, setInvestorUsersList] = useState({});


  function clearSetters(setters) {
    setters.forEach(([value, setter]) => {
      const type = typeof value;
      let emptyValue;
      switch (type) {
        case 'boolean':
          emptyValue = false;
          break;
        case 'object':
          if (Array.isArray(value)) emptyValue = [];
          else emptyValue = {};
          break;
        case 'string':
          emptyValue = '';
          break;
        case 'number':
          emptyValue = 0;
          break;
        default:
          emptyValue = '';
      }
      setter(emptyValue);
    });
  }

  function handleDataClear() {
    const setters = [
      [startASC820, setStartASC820],
      [projectCompanies, setProjectCompanies],
      [inputValue, setInputValue],
      [blockHasError, setBlockHasError],
      [companyNameErrorList, setCompanyNameErrorList],
      [clientInputsHaveErrors, setClientInputsHaveError],
      [inputsHaveErrors, setInputsHaveErrors],
      [ASC820Info, setASC820Info],
    ];
    clearSetters(setters);
    setClients([{
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    }]);
    // setCapIqFromDB(false);
  }

  async function createASC820(e) {
    e.preventDefault();
    const errorArray = [];
    projectCompanies.forEach((company) => {
      if (company.companyName.length < 1) errorArray.push(true);
      else errorArray.push(false);
    });
    setCompanyNameErrorList(errorArray);
    setClientInputsHaveError((prevErrors) => {
      const updatedErrors = [...prevErrors];
      clientInputsHaveErrors.forEach((clientErrors, index) => {
        updatedErrors[index] = {
          ...clientErrors,
          firstName: clients[index].firstName === '',
          lastName: clients[index].lastName === '',
          email: clients[index].email === '',
          phone: clients[index].phone.length > 0 ? !isAValidUSPhoneNumber(clients[index].phone) : false,
        };
        if (clients[index].email === '') {
          setEmailErrorMessage('Please enter a valid email address');
        }
      });
      setBlockHasError(true);
      return updatedErrors;
    });
    if (
      errorArray.includes(true) ||
      !ASC820Info.investorFirmName ||
      !ASC820Info.projectName ||
      !ASC820Info.clientInfoDueDate ||
      !ASC820Info.valuationDate ||
      !ASC820Info.draftDueDate
    ) {
      setInputsHaveErrors((prevErrors) => ({
        ...prevErrors,
        investorFirmName: !ASC820Info.investorFirmName,
        projectName: !ASC820Info.projectName,
        clientInfoDueDate: !ASC820Info.clientInfoDueDate,
        valuationDate: !ASC820Info.valuationDate,
        draftDueDate: !ASC820Info.draftDueDate,
      }));
      if (ASC820Info.email === '') {
        setEmailErrorMessage('Please enter a valid email address');
        setInputsHaveErrors((prevErrors) => ({
          ...prevErrors,
          email: ASC820Info.email === '',
        }));
      }
      setBlockHasError(true);
      return;
    }
    if (blockHasError) return;
    try {
      setCreateASC820InProgress(true);
      const userName = getUserName();
      const usersFirstLastName = userName.split(' ');
      const updatedCompanies = projectCompanies.map((company) => {
        const companyCopy = { ...company };
        if (portfolioCompanies[companyCopy.companyName]) {
          companyCopy.companyId = portfolioCompanies[companyCopy.companyName].companyId;
          companyCopy.capIqIdentifier = portfolioCompanies[companyCopy.companyName].capIqIdentifier;
        } else {
          companyCopy.companyId = '';
        }
        return companyCopy;
      });
      const newASC820Data = {
        enterpriseCompanyId,
        ...ASC820Info,
        clients,
        enterpriseOwned: 0,
        companies: updatedCompanies,
        requestUserId,
      };
      if (newASC820Data.totalFee) newASC820Data.totalFee = stripDenomination(newASC820Data.totalFee);
      const create820Response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/projects/asc820/start-820-valuation`,
        await createFetchHeaders('post', newASC820Data, true),
      );
      if (create820Response.status !== 200) setCreateASC820InProgress(false);
      const create820ResponseJson = await create820Response.json();
      const { investorCompany, projectCard } = create820ResponseJson;
      const enterpriseProjectsCopy = copy(enterpriseProjects);
      enterpriseProjectsCopy.push(projectCard);
      setEnterpriseProjects(enterpriseProjectsCopy);
      clients.forEach(async (client) => {
        await sendHtmlEmail(
          client.email,
          'newASC820Project',
          [
            ASC820Info.investorFirmName,
            encodeURIComponent(client.firstName),
            usersFirstLastName[0],
            usersFirstLastName[1],
            // TODO need endpoint to get account information from API
            'Redwood Valuations',
            encodeURIComponent(client.email),
            encodeURIComponent(client.lastName),
            encodeURIComponent(client.phone),
            encodeURIComponent(investorCompany.companyId),
          ],
        );
      });
      setStartASC820(false);
      setShowSuccessModal({
        showModal: true,
        firstName: clients[0].firstName,
        lastName: clients[0].lastName,
        email: clients[0].email,
      });
      handleDataClear();
      setCreateASC820InProgress(false);
    } catch (err) {
      setShowErrorMessage(err.toString());
    } finally {
      setCreateASC820InProgress(false);
    }
  }

  function handleNewClientOnChange(value, client, valueName, clientIndex) {
    setClients((prevClients) =>
      prevClients.map((newClient, index) =>
        index === clientIndex ?
          { ...client, [valueName]: value } :
          newClient));
  }

  function handleNewClientAddition() {
    setClients([
      ...clients,
      {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },
    ]);
    setClientInputsHaveError([
      ...clientInputsHaveErrors,
      {
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
      },
    ]);
  }

  function handleErrorClear(clientValue, index) {
    setClientInputsHaveError((prevClients) => {
      const updatedClients = [...prevClients];
      updatedClients[index] = {
        ...updatedClients[index],
        [clientValue]: false,
      };
      return updatedClients;
    });
    setBlockHasError(false);
  }

  function handleRemoveClient(index) {
    const clientCopy = copy(clients);
    const clientErrorCopy = copy(clientInputsHaveErrors);
    clientCopy.splice(index, 1);
    clientErrorCopy.splice(index, 1);
    setClientInputsHaveError(clientErrorCopy);
    setClients(clientCopy);
  }

  async function getInvestorUsers() {
    const invCoId = ASC820Info.investorCompanyId;
    let investorUsersResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/accounts/get-investor-users/${enterpriseCompanyId}&${invCoId}&${requestUserId}`,
      await createFetchHeaders('get', {}, true),
    );
    if (investorUsersResponse.status === 200) {
      investorUsersResponse = await investorUsersResponse.json();
      // TODO implement later when design is finalized
      // setInvestorUsersList(investorUsersResponse);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (dialogScrollRef.current) {
        dialogScrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);
    setTimeout(() => {
      if (companyListRef.current) {
        companyListRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);
  }, [projectCompanies]);

  useEffect(() => {
    setTimeout(() => {
      if (clientListRef.current) {
        clientListRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);
  }, [clients]);

  useEffect(() => {
    const asc820Copy = copy(ASC820Info);
    if (selectedInvestorName.length) {
      const id = investorCompanies[selectedInvestorName]?.companyId;
      if (id) {
        const capIq = investorCompanies[selectedInvestorName]?.capIqIdentifier;
        if (capIq !== null) {
          asc820Copy.investorCapIqIdentifier = capIq;
          // setCapIqFromDB(true);
        } else {
          asc820Copy.investorCapIqIdentifier = null;
          // setCapIqFromDB(false)
        }
        asc820Copy.investorCompanyId = id;
        setASC820Info(asc820Copy);
        getInvestorUsers();
      }
    }
    setASC820Info(asc820Copy);
  }, [selectedInvestorName]);

  useEffect(() => {
    if (investorCompaniesNames.length > 0) {
      const options = investorCompaniesNames.map((option) => {
        const firstLetter = option[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          option,
        };
      });
      setInvestorOptions(options);
    }
  }, [investorCompaniesNames]);

  return (
    <main className="ASC820Dialog">
      <Dialog
        className="start-ASC-820"
        open={startASC820}
        disableScrollLock
      >
        <div className="dialog-header-wrapper">
          <img src={rocketFrame} alt="rocket icon" />
          <div className="header-intro-text">
            <h4>Start an ASC 820 valuation</h4>
            <p className="header-instructions">
              Filling out this form will&#58; 1&#41; add a new investor to our system so you can track transactions
              quarter over quarter, 2&#41; add this new project to your ASC 820 list. Required fields have an asterisk.
            </p>
          </div>
          <IconButton
            className="close-icon"
            onClick={() => handleDataClear()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form onSubmit={(e) => createASC820(e)}>
          <div className="input-grid">
            <TextField
              label="Project name*"
              value={ASC820Info.projectName || ''}
              onChange={(e) => setASC820Info({ ...ASC820Info, projectName: e.target.value })}
              onFocus={() => {
                setInputsHaveErrors({ ...inputsHaveErrors, projectName: false });
                setBlockHasError(false);
              }}
              error={inputsHaveErrors.projectName}
              helperText={inputsHaveErrors.projectName ? (
                <>
                  <ErrorIcon />
                  Missing input
                </>
              ) : ' '}
            />
            <Autocomplete
              freeSolo
              options={investorOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
              onInputChange={(event, value) => {
                setASC820Info((prevAsc820Info) => {
                  const newAsc820Info = {
                    ...prevAsc820Info,
                    investorFirmName: value,
                  };
                  return newAsc820Info;
                });
              }}
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.option}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line
                  {...params}
                  label="Investor firm name*"
                  value={ASC820Info.investorFirmName || ''}
                  onChange={(e) => {
                    setSelectedInvestorName(e.target.value);
                    setASC820Info((prevAsc820Info) => {
                      const newAsc820Info = {
                        ...prevAsc820Info,
                        investorFirmName: e.target.value,
                      };
                      return newAsc820Info;
                    });
                  }}
                  onClick={(e) => {
                    setSelectedInvestorName(e.target.value);
                    setASC820Info((prevAsc820Info) => {
                      const newAsc820Info = {
                        ...prevAsc820Info,
                        investorFirmName: e.target.value,
                      };
                      return newAsc820Info;
                    });
                  }}
                  onFocus={() => {
                    setInputsHaveErrors({ ...inputsHaveErrors, investorFirmName: false });
                    setBlockHasError(false);
                  }}
                  onBlur={(e) => {
                    setSelectedInvestorName(e.target.value);
                    setASC820Info((prevAsc820Info) => {
                      const newAsc820Info = {
                        ...prevAsc820Info,
                        investorFirmName: e.target.value,
                      };
                      return newAsc820Info;
                    });
                  }}
                  error={inputsHaveErrors.investorFirmName}
                  helperText={inputsHaveErrors.investorFirmName ? (
                    <>
                      <ErrorIcon />
                      Missing input
                    </>
                  ) : ' '}
                />
              )}
            />
            <TextField
              label="CapIQ ID"
              InputLabelProps={{ shrink: ASC820Info.investorCapIqIdentifier ? ASC820Info.investorCapIqIdentifier.length > 0 : false }}
              disabled
              value={ASC820Info.investorCapIqIdentifier || ''}
              onChange={(e) => setASC820Info({ ...ASC820Info, investorCapIqIdentifier: e.target.value })}
            />
            <TextField
              label="Total fee"
              value={ASC820Info.totalFee || ''}
              onChange={(e) => setASC820Info({
                ...ASC820Info,
                totalFee: `${ASC820Info?.totalFee && ASC820Info.totalFee.length > 1 ? '$' : ''}${commaEvery3rdChar(e.target.value)}`,
              })}
            />
            <div className="assignments">
              <TextField
                label="Assign 'Data entry'"
                value={ASC820Info?.assignees?.dataEntry || ''}
                onChange={(e) => setASC820Info({ ...ASC820Info, assignees: { ...ASC820Info?.assignees, dataEntry: e.target.value } })}
                select
                SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
              >
                {enterpriseUsers?.map((user) => (
                  <MenuItem key={user?.accountId} value={user}>
                    {user.firstName}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Assign 'Preparer'"
                value={ASC820Info?.assignees?.preparer || ''}
                onChange={(e) => setASC820Info({ ...ASC820Info, assignees: { ...ASC820Info?.assignees, preparer: e.target.value } })}
                select
                SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
              >
                {enterpriseUsers?.map((user) => (
                  <MenuItem key={user?.accountId} value={user}>
                    {user.firstName}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Assign 'Reviewer'"
                value={ASC820Info?.assignees?.reviewer || ''}
                onChange={(e) => setASC820Info({ ...ASC820Info, assignees: { ...ASC820Info?.assignees, reviewer: e.target.value } })}
                select
                SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
              >
                {enterpriseUsers?.map((user) => (
                  <MenuItem key={user?.accountId} value={user}>
                    {user.firstName}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className="roles-tip">
            <TipsAndUpdatesOutlinedIcon />
            <Button
              onClick={() => {
                setRolesDescription(!rolesDescription);
                setBlockHasError(false);
              }}

            >
              Tell me about these roles
            </Button>
            {rolesDescription && (
              <p className="roles-description">
                &apos;Data entry&apos; is assigned to the person who inputs and checks accuracy of data.
                &apos;Preparer&apos; makes selections to come to initial values.
                &apos;Reviewer&apos; reviews the analysis to determine whether assumptions and conclusions are reasonable.
              </p>
            )}
          </div>
          <div className="ancillary-info">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                clearable
                className="date-picker"
                label="Client info due*"
                value={ASC820Info.clientInfoDueDate ? moment(ASC820Info.clientInfoDueDate, 'YYYY-MM-DD') : null}
                onChange={(val) => setASC820Info({ ...ASC820Info, clientInfoDueDate: val ? val.format('YYYY-MM-DD') : '' })}
                onOpen={() => {
                  setInputsHaveErrors({ ...inputsHaveErrors, clientInfoDueDate: false });
                  setBlockHasError(false);
                }}
                slots={{ openPickerIcon: BlueCalendar }}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    error: inputsHaveErrors.clientInfoDueDate,
                    helperText: inputsHaveErrors.clientInfoDueDate ? (
                      <>
                        <ErrorIcon />
                        Missing input
                      </>
                    ) : (' '),
                  },
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                clearable
                className="date-picker"
                label="Valuation date*"
                value={ASC820Info.valuationDate ? moment(ASC820Info.valuationDate, 'YYYY-MM-DD') : null}
                onChange={(val) => setASC820Info({ ...ASC820Info, valuationDate: val ? val.format('YYYY-MM-DD') : '' })}
                onOpen={() => {
                  setInputsHaveErrors({ ...inputsHaveErrors, valuationDate: false });
                  setBlockHasError(false);
                }}
                slots={{ openPickerIcon: BlueCalendar }}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    error: inputsHaveErrors.valuationDate,
                    helperText: inputsHaveErrors.valuationDate ? (
                      <>
                        <ErrorIcon />
                        Missing input
                      </>
                    ) : (' '),
                  },
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                clearable
                className="date-picker"
                label="Draft due*"
                value={ASC820Info.draftDueDate ? moment(ASC820Info.draftDueDate, 'YYYY-MM-DD') : null}
                onChange={(val) => setASC820Info({ ...ASC820Info, draftDueDate: val ? val.format('YYYY-MM-DD') : '' })}
                onOpen={() => {
                  setInputsHaveErrors({ ...inputsHaveErrors, draftDueDate: false });
                  setBlockHasError(false);
                }}
                slots={{ openPickerIcon: BlueCalendar }}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    error: inputsHaveErrors.draftDueDate,
                    helperText: inputsHaveErrors.draftDueDate ? (
                      <>
                        <ErrorIcon />
                        Missing input
                      </>
                    ) : (' '),
                  },
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                clearable
                className="date-picker"
                label="Audit start"
                value={ASC820Info.auditDate ? moment(ASC820Info.auditDate, 'YYYY-MM-DD') : null}
                onChange={(val) => setASC820Info({ ...ASC820Info, auditDate: val ? val.format('YYYY-MM-DD') : '' })}
                slotProps={{ textField: { variant: 'outlined' } }}
                slots={{ openPickerIcon: BlueCalendar }}
              />
            </LocalizationProvider>
          </div>
          <p className="selections-copy">
            Selections made here will be applied to all companies in the is project.
            If you need to edit the information for any reason, you&apos;ll be able to make edits for the entire
            project from the project&apos;s company list or
            edit them for a single company on that company&apos;s &apos;General info&apos; tab.
          </p>
          <div className="additional-clients">
            {clients.map((client, clientIndex) => (
              <div className="client-input" key={`${clientIndex + 1}`}>
                <TextField
                  key={`${clientIndex + 1}`}
                  label={`${clientIndex > 0 ? `${clientIndex + 1}. Client's first name` : "Primary client's first name*"}`}
                  value={client.firstName}
                  onChange={(e) => handleNewClientOnChange(e.target.value, client, 'firstName', clientIndex)}
                  onFocus={() => handleErrorClear('firstName', clientIndex)}
                  inputProps={{
                    onAnimationStart: (e) => {
                      if (e.animationName === 'mui-auto-fill') handleErrorClear('firstName', clientIndex);
                      if (e.animationName === 'mui-auto-fill-cancel') handleErrorClear('firstName', clientIndex);
                    },
                  }}
                  error={clientInputsHaveErrors[clientIndex]?.firstName}
                  helperText={clientInputsHaveErrors[clientIndex]?.firstName ? (
                    <>
                      <ErrorIcon />
                      Missing input
                    </>
                  ) : ' '}
                />
                <TextField
                  label={`${clientIndex > 0 ? "Client's last name" : "Primary client's last name*"}`}
                  value={client.lastName}
                  onChange={(e) => handleNewClientOnChange(e.target.value, client, 'lastName', clientIndex)}
                  inputProps={{
                    onAnimationStart: (e) => {
                      if (e.animationName === 'mui-auto-fill') handleErrorClear('lastName', clientIndex);
                      if (e.animationName === 'mui-auto-fill-cancel') handleErrorClear('lastName', clientIndex);
                    },
                  }}
                  onFocus={() => handleErrorClear('lastName', clientIndex)}
                  error={clientInputsHaveErrors[clientIndex]?.lastName}
                  helperText={clientInputsHaveErrors[clientIndex]?.lastName ? (
                    <>
                      <ErrorIcon />
                      Missing input
                    </>
                  ) : ' '}
                />
                {clientIndex > 0 ? (
                  <div className="remove-client">
                    <Button onClick={() => handleRemoveClient(clientIndex)}>
                      <RemoveOutlinedIcon />
                      Remove
                    </Button>
                  </div>
                ) : (
                  ''
                )}
                <TextField
                  label={`${clientIndex > 0 ? "Client's email address" : "Primary client's email address*"}`}
                  value={client.email}
                  onChange={(e) => handleNewClientOnChange(e.target.value, client, 'email', clientIndex)}
                  onFocus={() => handleErrorClear('email', clientIndex)}
                  inputProps={{
                    onAnimationStart: (e) => {
                      if (e.animationName === 'mui-auto-fill') handleErrorClear('email', clientIndex);
                      if (e.animationName === 'mui-auto-fill-cancel') handleErrorClear('email', clientIndex);
                    },
                  }}
                  error={clientInputsHaveErrors[clientIndex]?.email}
                  helperText={clientInputsHaveErrors[clientIndex]?.email ? (
                    <>
                      <ErrorIcon />
                      {emailErrorMessage}
                    </>
                  ) : ' '}
                />
                <TextField
                  label={`${clientIndex > 0 ? "Client's phone number" : "Primary client's phone number"}`}
                  value={client.phone}
                  onChange={(e) => handleNewClientOnChange(formatPhoneNumber(e.target.value), client, 'phone', clientIndex)}
                  onFocus={() => handleErrorClear('phone', clientIndex)}
                  error={clientInputsHaveErrors[clientIndex]?.phone}
                  helperText={clientInputsHaveErrors[clientIndex]?.phone ? (
                    <>
                      <ErrorIcon />
                      Invalid US Phone number format
                    </>
                  ) : ' '}
                />
                <div ref={clientListRef} />
              </div>
            ))}
          </div>
          {clients.length < 2 && (
            <hr className="dialog-spacer" />
          )}
          <div className="add-client-wrapper">
            <Button
              onClick={() => handleNewClientAddition()}
            >
              Add another client
            </Button>
            <p>Everyone you add will receive an email and can access and fill out the same form.</p>
          </div>
          <hr className="dialog-spacer" />
          <div className="add-companies-block">
            <h4>Add company list (optional)</h4>
            <p className={projectCompanies.length > 0 ? 'added' : 'no-companies'}>
              The form we send to your
              {' '}
              {clients.length > 1 ? 'clients' : 'client'}
              {' '}
              will include the companies you add here. Your client will be able
              <br />
              to add additional companies as they fill out the form.
            </p>
            {projectCompanies && (
              <div className="company-block-wrapper">
                {projectCompanies.map((company, index) => (
                  <AddCompanyBlock
                    key={company.companyId}
                    projectCompanies={projectCompanies}
                    setProjectCompanies={setProjectCompanies}
                    company={company}
                    index={index}
                    setBlockHasError={setBlockHasError}
                    companyNameErrorList={companyNameErrorList}
                    setCompanyNameErrorList={setCompanyNameErrorList}
                    portfolioCompanies={portfolioCompanies}
                    portfolioCompaniesNames={portfolioCompaniesNames}
                  />
                ))}
                <div ref={companyListRef} />
              </div>
            )}
            <div className="add-company-btn-info-wrapper">
              <Button
                className="add-company-btn"
                onClick={() => {
                  const newCompany = {
                    companyId: uuidv4(),
                    companyName: '',
                    capIqIdentifier: '',
                    note: '',
                    noteCreatedDatetime: moment().format('YYYY-MM-DD HH:mm:ss'),
                    noteAuthor: getUserName(),
                  };
                  setProjectCompanies([...projectCompanies, newCompany]);
                }}
              >
                <AddBusinessOutlinedIcon />
                {`${projectCompanies.length > 0 ? 'Add another company' : 'Add a company'}`}
              </Button>
            </div>
          </div>
          <hr className="dialog-spacer" />
          <div className="dialog-footer" ref={dialogScrollRef}>
            <Button
              className="send-email-btn"
              type="submit"
              disabled={blockHasError}
            >
              {!createASC820InProgress ? 'Send email' : (
                <>
                  <div className="dots-circle-spinner" />
                  Sending email...
                </>
              )}
            </Button>
          </div>
        </form>
      </Dialog>
      <Dialog className="create-ASC820-success-dialog" open={showSuccessModal.showModal}>
        <IconButton
          className="close-icon"
          onClick={() => {
            setShowSuccessModal({
              showModal: false,
              firstName: '',
              lastName: '',
              email: '',
            });
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="box-header">
          <h4>Success!</h4>
        </div>
        <div className="img-wrapper">
          <img src={successCheckmark} alt="success" />
        </div>
        <div className="success-msg-container">
          <p>
            An email has been sent to&nbsp;
            {showSuccessModal.firstName}
            &nbsp;
            {showSuccessModal.lastName}
            &nbsp;at&nbsp;
            {showSuccessModal.email}
            &nbsp;and we&apos;ve add this project to
            <br />
            your homepage.
            We&apos;ll update this ASC 820 project on a company by company basis once they&apos;ve
            <br />
            successfully submitted all required information.
          </p>
        </div>
      </Dialog>
    </main>
  );
}

ASC820Dialog.propTypes = {
  startASC820: PropTypes.bool.isRequired,
  setStartASC820: PropTypes.func.isRequired,
  setEnterpriseProjects: PropTypes.func,
  enterpriseProjects: PropTypes.arrayOf(PropTypes.object),
  enterpriseUsers: PropTypes.array,
  investorCompanies: PropTypes.object,
  portfolioCompanies: PropTypes.object,
  investorCompaniesNames: PropTypes.array,
  portfolioCompaniesNames: PropTypes.array,
};

ASC820Dialog.defaultProps = {
  setEnterpriseProjects: () => { },
  enterpriseProjects: [],
  enterpriseUsers: [],
  investorCompanies: {},
  investorCompaniesNames: [],
  portfolioCompanies: {},
  portfolioCompaniesNames: [],
};
