import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';

import successCheckmark from '../../images/checkmark-art.svg';
import './SuccessfulIntake.scss';

// eslint-disable-next-line
export default function SuccessfulIntake({ projectData, setSuccessMessage, fetchingTransactionList }) {
  return (
    <main className="SuccessfulIntake">
      {fetchingTransactionList ? (
        <div className="dots-circle-spinner" />
      ) : (
        <>
          <div className="header-info">
            <p>ASC 820 information submission</p>
            <p>{projectData.investorFirmName}</p>
          </div>
          <div className="success-content">
            <h2>Success!</h2>
            <div className="img-wrapper">
              <img src={successCheckmark} alt="success" />
            </div>
            <div className="success-msg-container">
              <p>
                You&apos;ve successfully submitted
                {' '}
                {projectData?.investorFirmName}
                &apos;s
                ASC 820 information before the
                {' '}
                {moment(projectData?.clientInfoDueDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                &nbsp;information due date.
                <br />
                <br />
                Please be on the look out for our emails. We count on you to let us know when your valuations
                <br />
                have been approved. We promise to make valuation approval as easy as a click of a button!
              </p>
            </div>
            <div className="success-msg-container">
              <div className="next-steps">
                <b><u>Next steps:</u></b>
                <ul>
                  <li>We&apos;ll review all information and contact you with any follow-up questions, concerns or requests</li>
                  <li>In 2-4 weeks you&apos;ll receive an email with your draft valuations</li>
                  <li>You&apos;ll review, we&apos;ll likely iterate, and you&apos;ll let us know they&apos;re approved</li>
                  <li>
                    You&apos;ll send the approved draft valuations to your auditors by
                    {' '}
                    {moment(projectData.auditDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                  </li>
                  <li>Auditor questions will be addressed, auditors will sign off on valuations</li>
                  <li>You&apos;ll receive an email with the final ASC 820 valuation</li>
                </ul>
              </div>
            </div>
            <Button
              onClick={() => setSuccessMessage(false)}
            >
              Review submitted information
            </Button>
          </div>
        </>
      )}
    </main>
  );
}

SuccessfulIntake.propTypes = {
  setSuccessMessage: PropTypes.func.isRequired,
  projectData: PropTypes.object.isRequired,
  fetchingTransactionList: PropTypes.bool.isRequired,
};
