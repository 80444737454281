import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import DataThresholdingOutlinedIcon from '@mui/icons-material/DataThresholdingOutlined';

import { SubNavStateContext } from '../lib/contextLib';

import './SubNav.scss';

export default function SubNav() {
  const { subNavState, setSubNavState } = useContext(SubNavStateContext);

  const nav = useNavigate();

  function handlePageStep() {
    if (subNavState.pagesVisited.length) {
      const pagesVisited = [...subNavState.pagesVisited];
      const pageToNavTo = pagesVisited.pop();
      if (pageToNavTo === 'company-list') {
        nav(`/?pId=${subNavState.projectId}&iId=${subNavState.investorCompanyId}`);
        nav(0);
      } else if (pageToNavTo === 'project-list') {
        nav('/');
        nav(0);
        setSubNavState({});
      }
      setSubNavState({ ...subNavState, pagesVisited });
    } else {
      nav('/');
      nav(0);
      setSubNavState({});
    }
  }

  if (subNavState.projectId) {
    return (
      <div className="SubNav">
        <Button
          onClick={() => handlePageStep()}
        >
          <ArrowBackIcon />
        </Button>
        <Button
          onClick={() => {
            nav('/');
            nav(0);
            setSubNavState({});
          }}
        >
          <HomeOutlinedIcon />
          Home
        </Button>
        <Button className="no-pointer-events">
          <AccountBalanceOutlinedIcon />
          {subNavState.investorFirmName}
        </Button>
        <Button
          className={`${subNavState.currentPage === 'company-list' ? 'active' : ''}`}
          onClick={() => {
            nav(`/?pId=${subNavState.projectId}&iId=${subNavState.investorCompanyId}`);
            nav(0);
          }}
        >
          <DataThresholdingOutlinedIcon />
          {`ASC 820 | ${subNavState.createdDate}`}
        </Button>
        {subNavState.currentPage === 'calc-inputs' && (
          <Button className="active">
            <StorefrontIcon />
            {subNavState.companyName}
          </Button>
        )}
        <div className="top-nav-right">
          {/* */}
        </div>
      </div>
    );
  }
}
