import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';

import { getUserCompanyId, getUserId } from '../../utils/auth';
import { createFetchHeaders } from '../../utils/apiCalls';
import { ErrorMessageContext, SubNavStateContext } from '../../lib/contextLib';
import './ProjectCompanies.scss';
import CompanyCard from './CompanyCard';
import SortBy from '../../components/SortBy';
import CalcInputs from '../CalcInputs';
import AddCompanyToProject from '../../components/AddCompanyToProject';
import { formatUSCurrencyWithTwoDecimals } from '../../utils';
import ProjectDates from './ProjectDates';

function CustomDroppable({ children, ...props }) {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) return null;

  return <Droppable {...props}>{children}</Droppable>;
}

CustomDroppable.propTypes = { children: PropTypes.func.isRequired };

export default function ProjectCompanies({
  enterpriseUsers,
  projectCompaniesData,
  portfolioCompaniesList,
  portfolioCompaniesNames,
  investorCompaniesList,
  investorCompaniesNames,
}) {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);
  const { subNavState, setSubNavState } = useContext(SubNavStateContext);
  const [projectCompanies, setProjectCompanies] = useState([]);
  const [transactionToView, setTransactionToView] = useState(null);
  const [sortedOrFilteredCompanies, setSortedOrFilteredCompanies] = useState([]);
  const [sumOfAllCompanyFees, setSumOfAllCompanyFees] = useState();
  const [totalPaidToDate, setTotalPaidToDate] = useState();
  const [dateInputValues, setDateInputValues] = useState({});
  const [updateAssignee, setUpdateAssignee] = useState(null);
  const [updateDates, setUpdateDates] = useState(false);
  const [companiesLoading, setCompaniesLoading] = useState(true);

  const [anchorEl, setAnchorEl] = useState(null);
  const [dataEntryWorkTo, setDataEntryWorkTo] = useState(false);
  const [preparerWorkTo, setPreparerWorkTo] = useState(false);
  const [reviewerWorkTo, setReviewerWorkTo] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [numberOfCompanies, setNumberOfCompanies] = useState(null);

  const [addCompany, setAddCompany] = useState(false);
  const [sortBy, setSortBy] = useState('Most recent');
  const [filteredUser, setFilteredUser] = useState('All users');
  const [filteredStatus, setFilteredStatus] = useState('All statuses');
  const sortByList = ['Most recent', 'Alphabetical', 'Info due date', 'Audit date', 'Priority'];
  const [filterByUsers, setFilterByUsers] = useState(['All users']);
  const filterByStatus = [
    'All statuses',
    'Client not started 820',
    'Client in-progress',
    '820 Data entry',
    '820 Analysis',
    '820 Review',
    'Draft in-progress',
    'Draft review',
    'Draft delivered',
    'Draft iteration',
    'Draft audit',
    'Financial statements ready',
  ];

  const nav = useNavigate();


  async function getProjectCompanies() {
    const urlParams = new URLSearchParams(window.location.search);
    try {
      const userId = getUserId();
      const enterpriseCompanyId = await getUserCompanyId();
      const DBParams = `${enterpriseCompanyId}&` +
        `${projectCompaniesData?.investorCompanyId || urlParams.get('iId')}&` +
        `${projectCompaniesData?.projectId}&` +
        `${userId}`;
      const DBProjectList = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/transactions/asc820/list-820s/${DBParams}`,
        await createFetchHeaders('get', {}, true),
      );
      const DBProjectListParsed = await DBProjectList.json();
      setFilterByUsers([...filterByUsers, ...enterpriseUsers.map((user) => `${user.firstName} ${user.lastName}`)]);
      setProjectCompanies(DBProjectListParsed);
      setNumberOfCompanies(DBProjectListParsed.length);
      if (DBProjectListParsed.length > 0) {
        projectCompanies.forEach((company) => {
          if (company.paidToDate) {
            setTotalPaidToDate(totalPaidToDate ? totalPaidToDate + parseInt(company?.paidToDate, 10) : parseInt(company?.paidToDate, 10));
          }
          if (company.totalCompanyFee) {
            setSumOfAllCompanyFees(
              sumOfAllCompanyFees ?
                sumOfAllCompanyFees + parseInt(company?.totalCompanyFee, 10) :
                parseInt(company?.totalCompanyFee, 10),
            );
          }
        });
      } else {
        setProjectCompanies([]);
      }
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setCompaniesLoading(false);
    }
  }

  function parseFeeData() {
    if (projectCompanies) {
      let paidToDate = 0;
      let totalCompanyFee = 0;
      projectCompanies.forEach((company) => {
        if (company.paidToDate) paidToDate += parseFloat(company.paidToDate.replaceAll(',', ''));
        if (company.totalCompanyFee) totalCompanyFee += parseFloat(company.totalCompanyFee.replaceAll(',', ''));
      });
      setSumOfAllCompanyFees(formatUSCurrencyWithTwoDecimals.format(totalCompanyFee));
      setTotalPaidToDate(formatUSCurrencyWithTwoDecimals.format(paidToDate));
    }
  }

  function parseOutDates() {
    const dateProperties = [
      'clientInfoDueDate',
      'auditDate',
      'publishFinancialsDate',
      'draftDueDate',
    ];
    const updatedDateInputValues = { ...dateInputValues };
    Object.keys(projectCompaniesData).forEach((key) => {
      dateProperties.forEach((property) => {
        if (key === property) {
          updatedDateInputValues[property] = projectCompaniesData[property];
        }
      });
    });
    setDateInputValues(updatedDateInputValues);
  }

  function getCookie(name) {
    const cookieName = `${name}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i += 1) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return JSON.parse(cookie.substring(cookieName.length, cookie.length));
      }
    }
    return null;
  }

  function setCookie(name, value, daysToExpire) {
    const date = new Date();
    date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    const stringValue = JSON.stringify(value);
    document.cookie = `${name}=${stringValue};${expires};path=/`;
  }

  function handleSave() {
    setIsSaving(true);
    const transactionOrderMap = {};
    for (let i = 0; i < sortedOrFilteredCompanies.length; i += 1) {
      const transaction = sortedOrFilteredCompanies[i];
      transactionOrderMap[transaction.transactionId] = i;
    }
    setCookie('transactionOrder', transactionOrderMap, 7);
    setTimeout(() => {
      setIsSaving(false);
    }, 1500);
  }

  useEffect(() => {
    if (projectCompanies) {
      parseFeeData();
      parseOutDates();
    }
  }, [projectCompanies]);

  useEffect(() => { getProjectCompanies(); }, []);

  useEffect(() => {
    if (!companiesLoading) {
      let projectCompaniesSorted = projectCompanies.sort((a, b) => a.portfolioCompanyName.localeCompare(b.portfolioCompanyName));
      const savedOrder = getCookie('transactionOrder');
      /* eslint-disable */
      if (sortBy === 'Alphabetical') projectCompaniesSorted = projectCompanies.sort((a, b) => a.portfolioCompanyName.localeCompare(b.portfolioCompanyName));
      if (sortBy === 'Info due date') projectCompaniesSorted = projectCompanies.sort((a, b) => new Date(a.infoDueDate) - new Date(b.infoDueDate));
      if (sortBy === 'Audit date') projectCompaniesSorted = projectCompanies.sort((a, b) => new Date(a.auditDate) - new Date(b.auditDate));
      if (sortBy === 'Priority') projectCompaniesSorted = projectCompanies.sort((a, b) => a.priority - b.priority);

      if (filteredUser !== 'All users') {
        projectCompaniesSorted = projectCompaniesSorted.filter((company) => {
          const dataEntryAssignment = enterpriseUsers.find((u) => u.accountId === company.assignees.dataEntry.accountId);
          const preparerAssignment = enterpriseUsers.find((u) => u.accountId === company.assignees.preparer.accountId);
          const reviewerAssignment = enterpriseUsers.find((u) => u.accountId === company.assignees.reviewer.accountId);
          if (dataEntryAssignment) {
            if (`${dataEntryAssignment.firstName.toLowerCase()} ${dataEntryAssignment.lastName.toLowerCase()}` === filteredUser.toLowerCase()) return true;
          }
          if (preparerAssignment) {
            if (`${preparerAssignment.firstName.toLowerCase()} ${preparerAssignment.lastName.toLowerCase()}` === filteredUser.toLowerCase()) return true;
          }
          if (reviewerAssignment) {
            if (`${reviewerAssignment.firstName.toLowerCase()} ${reviewerAssignment.lastName.toLowerCase()}` === filteredUser.toLowerCase()) return true;
          }
          return false;
        });
      }
      /* eslint-enable */

      if (filteredStatus !== 'All statuses') {
        projectCompaniesSorted = projectCompaniesSorted.filter((company) => company.status.toLowerCase() === filteredStatus.toLowerCase());
      }
      if (savedOrder && projectCompanies) {
        projectCompaniesSorted = projectCompaniesSorted.sort((a, b) => {
          const orderA = savedOrder[a.transactionId];
          const orderB = savedOrder[b.transactionId];
          return orderA - orderB;
        });
        setSortedOrFilteredCompanies(projectCompaniesSorted);
      }
      setSortedOrFilteredCompanies(projectCompaniesSorted);
    }
  }, [companiesLoading, sortBy, filteredUser, filteredStatus]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const transactionId = urlParams.get('tId');
    if (transactionId) setTransactionToView(projectCompanies.find((company) => company.transactionId === transactionId));
  }, [projectCompanies]);

  useEffect(() => {
    if (transactionToView) {
      const urlParams = new URLSearchParams(window.location.search);
      const tabToView = urlParams.get('tabToView');
      if (tabToView) {
        nav(`?tabToView=${tabToView}&pId=${transactionToView.projectId}` +
          `&iId=${transactionToView.investorCompanyId}&tId=${transactionToView.transactionId}`);
      }
      setSubNavState({
        ...projectCompaniesData,
        companyName: transactionToView.portfolioCompanyName,
        currentPage: 'calc-inputs',
        pagesVisited: [...subNavState.pagesVisited, 'company-list'],
      });
    }
  }, [transactionToView]);

  function handleDragDrop(result) {
    handleSave();
    const { source, destination } = result;
    if (!destination) return;
    const reorderedCompanies = [...sortedOrFilteredCompanies];
    const [removedCompany] = reorderedCompanies.splice(source.index, 1);
    reorderedCompanies.splice(destination.index, 0, removedCompany);
    setSortedOrFilteredCompanies(reorderedCompanies);
    setProjectCompanies(reorderedCompanies);
  }

  async function updateProjectDates() {
    try {
      const enterpriseCompanyId = getUserCompanyId();
      const requestUserId = getUserId();
      const updateCardData = {
        enterpriseCompanyId,
        requestUserId,
        payload: dateInputValues,
        companyId: enterpriseCompanyId,
        resource: `project_card_${projectCompaniesData.projectId}`,
      };
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/objects/update-object`,
        await createFetchHeaders('post', updateCardData, true),
      );
      const updateProjectData = {
        enterpriseCompanyId,
        requestUserId,
        payload: dateInputValues,
        companyId: enterpriseCompanyId,
        resource: `project_asc820_${projectCompaniesData.projectId}`,
      };
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/objects/update-object`,
        await createFetchHeaders('post', updateProjectData, true),
      );
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setUpdateDates(false);
    }
  }

  async function updateAssigneeAcrossTransactions(assignee) {
    try {
      const enterpriseCompanyId = getUserCompanyId();
      const requestUserId = getUserId();
      const { accountId, role } = assignee;
      const { investorCompanyId } = sortedOrFilteredCompanies[0];
      const updateAllTransactions = {
        enterpriseCompanyId,
        investorCompanyId,
        assigneeUserId: accountId,
        role,
        requestUserId,
      };
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/transactions/asc820/update-role/all-transactions-in-project`,
        await createFetchHeaders('post', updateAllTransactions, true),
      );
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  useEffect(() => {
    const updatedProjectCompanies = sortedOrFilteredCompanies.map((company) => {
      const updatedCompany = { ...company }; // Create a copy of the company
      if (updateAssignee) {
        if (updateAssignee.role === 'dataEntry') {
          updatedCompany.assignees = { ...updatedCompany.assignees, dataEntry: updateAssignee };
        }
        if (updateAssignee.role === 'preparer') {
          updatedCompany.assignees = { ...updatedCompany.assignees, preparer: updateAssignee };
        }
        if (updateAssignee.role === 'reviewer') {
          updatedCompany.assignees = { ...updatedCompany.assignees, reviewer: updateAssignee };
        }
      }
      return updatedCompany; // Return the updated company
    });
    setSortedOrFilteredCompanies(updatedProjectCompanies); // Update the state
    if (updateAssignee) updateAssigneeAcrossTransactions(updateAssignee);
  }, [updateAssignee]);

  useEffect(() => { if (updateDates) updateProjectDates(); }, [updateDates]);

  if (transactionToView) return <CalcInputs transactionToView={transactionToView} />;

  return (
    <>
      <main className="ProjectCompanies">
        <div className="util-header">
          <div className="util-controls">
            <div className="anc-company-info">
              <span>
                Total companies:&nbsp;
                {numberOfCompanies}
              </span>
              <span>
                Total fees:&nbsp;
                {sumOfAllCompanyFees}
              </span>
              <span>
                Paid to-date:&nbsp;
                {totalPaidToDate}
              </span>
            </div>
            <Button
              className="save-run-btns save"
              onClick={() => handleSave()}
            >
              {isSaving ? (
                <p className="save-run-btns saving">
                  <div className="dots-circle-spinner" />
                  Saving
                </p>
              ) : (
                <>
                  <SaveOutlinedIcon />
                  Save
                </>
              )}
            </Button>
          </div>
          <div className="project-companies-header">
            <p>{projectCompaniesData?.investorFirmName}</p>
            <span>
              820 |&nbsp;
              {projectCompaniesData?.createdDate}
            </span>
          </div>
          <hr className="header-spacer" />
          <div className="date-inputs">
            <ProjectDates
              setInputValues={setDateInputValues}
              inputValues={dateInputValues}
              setUpdateDates={setUpdateDates}
              label="Client data due"
              dbKey="clientInfoDueDate"
            />
            <ProjectDates
              setInputValues={setDateInputValues}
              inputValues={dateInputValues}
              setUpdateDates={setUpdateDates}
              label="Draft due"
              dbKey="draftDueDate"
            />
            <ProjectDates
              setInputValues={setDateInputValues}
              inputValues={dateInputValues}
              setUpdateDates={setUpdateDates}
              label="Audit start"
              dbKey="auditDate"
            />
            <ProjectDates
              setInputValues={setDateInputValues}
              inputValues={dateInputValues}
              setUpdateDates={setUpdateDates}
              label="Publish financials date"
              dbKey="publishFinancialsDate"
            />
          </div>
          <div className="add-company-btn">
            <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
              <AssignmentIndOutlinedIcon />
              Assign all
            </Button>
            <Menu
              id="assign-all-menu"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={() => {
                setAnchorEl(null);
                setTimeout(() => { setAnchorEl(false); }, 200);
                setDataEntryWorkTo(false);
                setPreparerWorkTo(false);
                setReviewerWorkTo(false);
              }}
              disableScrollLock
            >
              {[
                ['Data entry work to', dataEntryWorkTo, setDataEntryWorkTo],
                ['Preparer work to', preparerWorkTo, setPreparerWorkTo],
                ['Reviewer work to', reviewerWorkTo, setReviewerWorkTo],
              ].map((role) => (
                <Tooltip
                  key={role[0]}
                  id="all-user-menu"
                  open={role[1]}
                  placement="right"
                  arrow
                  title={(
                    <>
                      {enterpriseUsers.map((user) => (
                        <Button
                          key={user.accountId}
                          onClick={(e) => {
                            e.stopPropagation();
                            role[2](false);
                            setAnchorEl(null);
                            setUpdateAssignee({
                              accountId: user.accountId,
                              email: user.email,
                              role: role[0] === 'Data entry work to' ? 'dataEntry' : role[0] === 'Preparer work to' ? 'preparer' : 'reviewer',
                            });
                          }}
                        >
                          {user.firstName}
                          {' '}
                          {user.lastName}
                        </Button>
                      ))}
                    </>
                  )}
                >
                  <MenuItem
                    className={
                      role[0] === 'Data entry work to' ?
                        dataEntryWorkTo ? 'active' : '' :
                        role[0] === 'Preparer work to' ?
                          preparerWorkTo ? 'active' : '' :
                          reviewerWorkTo ? 'active' : ''
                    }
                    onClick={() => {
                      role[2](true);
                      if (reviewerWorkTo) setReviewerWorkTo(!reviewerWorkTo);
                      if (preparerWorkTo) setPreparerWorkTo(!preparerWorkTo);
                      if (dataEntryWorkTo) setDataEntryWorkTo(!dataEntryWorkTo);
                    }}
                  >
                    {role[0]}
                  </MenuItem>
                </Tooltip>
              ))}
            </Menu>
            <Button
              onClick={() => setAddCompany(true)}
            >
              <AddOutlinedIcon />
              Add company
            </Button>
          </div>
          <div className="companies-list-header">
            <h3>
              <StoreOutlinedIcon />
              Company list
            </h3>
          </div>
          <div className="sort-by-select">
            <SortBy
              sortBy={filteredStatus}
              setSortBy={setFilteredStatus}
              sortByList={filterByStatus}
              type="Filter by status"
            />
            <SortBy
              sortBy={filteredUser}
              setSortBy={setFilteredUser}
              sortByList={filterByUsers}
              type="Filter by user"
            />
            <SortBy
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortByList={sortByList}
              type="Sort by"
            />
          </div>
        </div>
        <div className="list-of-companies">
          <div className="project-list-wrapper">
            {companiesLoading ? (
              <div className="dots-circle-spinner" />
            ) : (
              <DragDropContext onDragEnd={(result) => { handleDragDrop(result); }}>
                <CustomDroppable droppableId="ROOT" type="group">
                  {(provided) => (
                    // eslint-disable-next-line
                    <div className="wrapper-of-draggable" {...provided.droppableProps} ref={provided.innerRef}>
                      {sortedOrFilteredCompanies.map((company, index) => (
                        <CompanyCard
                          key={company.transactionId}
                          company={company}
                          index={index}
                          enterpriseUsers={enterpriseUsers}
                          setTransactionToView={setTransactionToView}
                          setProjectCompanies={setSortedOrFilteredCompanies}
                          projectCompanies={sortedOrFilteredCompanies}
                          updateAssignee={updateAssignee}
                          setUpdateAssignee={setUpdateAssignee}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </CustomDroppable>
              </DragDropContext>
            )}
          </div>
        </div>
      </main>
      <AddCompanyToProject
        addCompany={addCompany}
        setAddCompany={setAddCompany}
        projectId={projectCompaniesData.projectId}
        investorCompanyId={projectCompaniesData.investorCompanyId}
        setCompanies={setSortedOrFilteredCompanies}
        companies={sortedOrFilteredCompanies}
        numberOfCompanies={projectCompanies.length}
        setNumberOfCompanies={setNumberOfCompanies}
        portfolioCompanies={portfolioCompaniesList}
        portfolioCompaniesNames={portfolioCompaniesNames}
        investorCompanies={investorCompaniesList}
        investorCompaniesNames={investorCompaniesNames}
      />
    </>
  );
}

ProjectCompanies.propTypes = {
  enterpriseUsers: PropTypes.arrayOf(PropTypes.object),
  projectCompaniesData: PropTypes.object.isRequired,
  portfolioCompaniesNames: PropTypes.array,
  portfolioCompanies: PropTypes.array,
  portfolioCompaniesList: PropTypes.object,
  investorCompaniesList: PropTypes.object,
  investorCompaniesNames: PropTypes.array,
};

ProjectCompanies.defaultProps = {
  enterpriseUsers: [],
  portfolioCompaniesNames: [],
  portfolioCompanies: [],
  portfolioCompaniesList: {},
  investorCompaniesList: {},
  investorCompaniesNames: [],
};
