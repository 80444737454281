import React, {
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import EditIcon from '@mui/icons-material/Edit';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

import InfoTile from './Components/InfoTile';
import UploadTile from './Components/UploadTile';


import { commaEvery3rdChar, copy } from '../../utils';

import { createFetchHeaders } from '../../utils/apiCalls';

import { getUserId } from '../../utils/auth';

import { ErrorMessageContext } from '../../lib/contextLib';

import './ClientInputs.scss';

export default function ClientInputs({
  userData,
  setUserData,
  checkForMissingCompInputs,
  setCheckForMissingCompInputs,
  exitingToTab,
  setExitingToTab,
  setTabToView,
}) {
  const [isEditing, setIsEditing] = useState(false);

  const [originalTransactionData, setOriginalTransactionData] = useState(false);

  const [savingEdits, setSavingEdits] = useState(false);

  const [showTooltip, setShowTooltip] = useState(true);

  const [transactionData, setTransactionData] = useState({});
  const [basicInfo, setBasicInfo] = useState({});
  const [enterpriseFinancialInfo, setEnterpriseFinancialInfo] = useState({});
  const [clientFinancialInfo, setClientFinancialInfo] = useState({});
  const [capTableUploads, setCapTableUploads] = useState([]);
  const [articlesUploads, setArticlesUploads] = useState([]);
  const [optionLedgerUploads, setOptionLedgerUploads] = useState([]);
  const [secondaryTransactionUploads, setSecondaryTransactionUploads] = useState([]);
  const [termSheetsUploads, setTermSheetsUploads] = useState([]);
  const [safeOrConvertibleNoteUploads, setSafeOrConvertibleNoteUploads] = useState([]);
  const [financialForecastUploads, setFinancialForecastUploads] = useState([]);
  const [additionalFinancialUploads, setAdditionalFinancialUploads] = useState([]);
  const [holdings, setHoldings] = useState([{ rowID: 0 }]);
  const [holdingInputId, setHoldingInputId] = useState(0);

  const [errorStates, setErrorStates] = useState({ valuationDate: false, transactionDate: false });

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [changeInProgress, setChangeInProgress] = useState(false);
  const [changeHasBeenMade, setChangeHasBeenMade] = useState(false);

  const changeInProgressRef = useRef(false);
  const changeHasBeenMadeRef = useRef(false);


  async function parseTransactionData() {
    setBasicInfo(userData.transactionData.basicInfo || {});
    setEnterpriseFinancialInfo(userData.transactionData.enterpriseFinancialInfo || {});
    setClientFinancialInfo(userData.transactionData.clientFinancialInfo || {});
    setCapTableUploads(userData.transactionData.capTableUploads || []);
    setArticlesUploads(userData.transactionData.articlesUploads || []);
    setOptionLedgerUploads(userData.transactionData?.optionLedgerUploads || []);
    setSecondaryTransactionUploads(userData.transactionData?.secondaryTransactionUploads || []);
    setTermSheetsUploads(userData.transactionData?.termSheetsUploads || []);
    setSafeOrConvertibleNoteUploads(userData.transactionData?.safeOrConvertibleNoteUploads || []);
    setFinancialForecastUploads(userData.transactionData?.financialForecastUploads || []);
    setAdditionalFinancialUploads(userData.transactionData?.additionalFinancialUploads || []);
    setHoldings(userData.transactionData.holdings?.length ?
      userData.transactionData.holdings.map((v, i) => {
        const formattedHoldings = { ...v };
        formattedHoldings.sharesOwned = commaEvery3rdChar(v.sharesOwned);
        formattedHoldings.pricePerShare = commaEvery3rdChar(v.pricePerShare);
        formattedHoldings.carryingValue = commaEvery3rdChar(v.carryingValue);
        return ({ ...formattedHoldings, rowID: i });
      }) : [{ rowID: 0 }]);
    setHoldingInputId(userData.transactionData.holdings?.length || 1);
  }

  useEffect(() => { if (userData.transactionData) { parseTransactionData(); } }, [userData]);

  useEffect(() => {
    if (checkForMissingCompInputs) {
      setErrorStates({
        valuationDate: !userData.transactionData.basicInfo.valuationDate,
        transactionDate: !userData.transactionData.basicInfo.transactionDate,
      });
      setIsEditing(true);
    }
    setCheckForMissingCompInputs(false);
  }, [checkForMissingCompInputs]);

  useEffect(() => {
    changeInProgressRef.current = changeInProgress;
    changeHasBeenMadeRef.current = changeHasBeenMade;
  }, [changeInProgress, changeHasBeenMade]);

  async function saveData(autoSave, exitingTab) {
    if (autoSave && (!changeHasBeenMadeRef.current || changeInProgressRef.current)) return;
    if (!autoSave) setSavingEdits(true);
    setErrorStates({ transactionDate: false, valuationDate: false });
    const userId = getUserId();
    const holdingsToSave = copy(holdings);
    holdingsToSave.forEach((val, i) => {
      delete holdingsToSave[i].rowID;
      holdingsToSave[i].sharesOwned = parseInt(holdingsToSave[i].sharesOwned?.replaceAll(',', ''), 10);
      holdingsToSave[i].carryingValue = holdingsToSave[i].carryingValue?.replaceAll(',', '');
    });

    const transactionDBData = {
      ...userData.transactionData,
      projectId: userData.metaData.projectId,
      basicInfo,
      enterpriseFinancialInfo,
      clientFinancialInfo,
      capTableUploads,
      articlesUploads,
      optionLedgerUploads,
      secondaryTransactionUploads,
      termSheetsUploads,
      safeOrConvertibleNoteUploads,
      financialForecastUploads,
      additionalFinancialUploads,
      holdings: holdingsToSave,
      requestUserId: userId,
      version: parseInt(userData.transactionData.version, 10),
    };
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/transactions/asc820/enterprise-update-transaction`,
        await createFetchHeaders('post', transactionDBData, true),
      );
      if (!autoSave) setUserData({ ...userData, transactionData: transactionDBData });
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
    setSavingEdits(false);
    setChangeHasBeenMade(false);
    if (!autoSave) setIsEditing(false);
    if (exitingTab) setTabToView(exitingToTab);
  }

  useEffect(() => {
    if (exitingToTab && isEditing) saveData(false, true);
    else if (exitingToTab) setTabToView(exitingToTab);
    setExitingToTab(false);
  }, [exitingToTab]);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => { savedCallback.current = callback; }, [callback]);

    useEffect(() => {
      const func = () => { savedCallback.current(); };
      if (delay !== null) {
        const id = setInterval(func, delay);
        return () => clearInterval(id);
      }
      return null;
    }, [delay]);
  }

  useInterval(() => { saveData(true); }, 5000);

  return (
    <div className="ClientInputs">
      {showTooltip && (
        <div className="tooltip-info-block">
          <LightbulbOutlinedIcon />
          <div className="tooltip-info-block-text">
            <p>
              The information below will fill in as the client fills in their ASC 820 submission form.
              That&apos;s the secure form we sent them when you started the ASC 820.
            </p>
            <p>Click edit to fill in information for the client or to correct incorrect information.</p>
          </div>
          <IconButton
            className="close-icon"
            onClick={() => setShowTooltip(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
      {/* TODO Fix this */}
      {/* {userData.transactionData?.status?.toLowerCase() === 'new' && (
        <div className="no-inputs-to-show">
          <p>No client inputs to show.</p>
          <p>
            Client has not started to fill out information for this company.
            Click edit to fill in information for the client.
          </p>
        </div>

      )} */}
      {Object.values(errorStates).some((errorState) => errorState) && (
        <div className="error-info-block">
          <ErrorIcon />
          <p>
            Missing required fields&nbsp;
            {
              `${errorStates.transactionDate && errorStates.valuationDate ?
                "'Valuation date' and 'Transaction date'" :
                errorStates.valuationDate ?
                  "'Valuation date'" :
                  errorStates.transactionDate ?
                    "'Transaction date'" :
                    ''
              }`
            }
            . Please provide missing information then &apos;Submit inputs&apos;.
          </p>
        </div>
      )}
      <div className="edit-btn-wrapper">
        {!isEditing ? (
          <Button
            className="edit-btn"
            onClick={() => {
              setIsEditing(true);
              setOriginalTransactionData(userData.transactionData);
            }}
          >
            <EditIcon />
            Edit
          </Button>
        ) : (
          <>
            <Button onClick={() => {
              setUserData({ ...userData, transactionData: originalTransactionData });
              setIsEditing(false);
              setChangeHasBeenMade(true);
              setErrorStates({ valuationDate: false, transactionDate: false });
            }}
            >
              <CancelOutlinedIcon />
              Cancel
            </Button>
            <Button onClick={() => { saveData(); }}>
              {savingEdits ? (
                <>
                  <span className="dots-circle-spinner" />
                  Saving
                </>
              ) : (
                <>
                  <SaveOutlinedIcon />
                  Save edits
                </>
              )}
            </Button>
          </>
        )}
      </div>
      {/* {Object.values(errorStates).some((errorState) => errorState) && (
        <div className="error-info-block">
          <ErrorIcon />
          <p>Missing required fields. Please provide missing information then &apos;Run comps&apos;.</p>
        </div>
      )} */}
      <div className="input-block">
        <div className="block-header">
          <h5>Holdings</h5>
        </div>
        <div className="holdings-titles">
          <span>Class of stock</span>
          <span>Fund name</span>
          <span>Shares owned</span>
          <span>Price per share</span>
          <span>Carrying Value</span>
        </div>
        {holdings.map((holding, holdingIndex) =>
          !isEditing ? (
            <div key={holding.rowID} className="holdings-data">
              <span className="holding-data">{holding.classOfStock || '-'}</span>
              {holding.fundName?.length >= 40 ? (
                <Tooltip title={holding.fundName} placement="top">
                  <span className="holding-data">{`${holding.fundName.slice(0, 40).trim()}...`}</span>
                </Tooltip>
              ) : <span className="holding-data">{holding.fundName || '-'}</span>}
              <span className="holding-data">{commaEvery3rdChar(holding.sharesOwned) || '-'}</span>
              <span className="holding-data">
                {holding.pricePerShare ? `$${parseFloat(holding.pricePerShare.replaceAll('$', '').replaceAll(',', '')).toFixed(4)}` : '-'}
              </span>
              <span className="holding-data">
                {holding.carryingValue ?
                  `$${commaEvery3rdChar(parseFloat(holding.carryingValue.replaceAll('$', '').replaceAll(',', '')).toFixed(4))}` : '-'}
              </span>
            </div>
          ) : (
            <React.Fragment key={holding.rowID}>
              <div className="holdings-data">
                {['classOfStock', 'fundName', 'sharesOwned', 'pricePerShare', 'carryingValue'].map((dataName) => (
                  <TextField
                    key={dataName}
                    value={holding[dataName] || ''}
                    onChange={(e) => {
                      const newHoldingData = [...holdings];
                      newHoldingData[holdingIndex] = {
                        ...newHoldingData[holdingIndex],
                        [dataName]: ['sharesOwned', 'carryingValue', 'pricePerShare'].includes(dataName) ?
                          commaEvery3rdChar(e.target.value) : e.target.value,
                      };
                      setHoldings(newHoldingData);
                    }}
                    onFocus={() => setChangeInProgress(true)}
                    onBlur={() => {
                      setChangeInProgress(false);
                      setTransactionData({ ...transactionData, holdings });
                    }}
                  />
                ))}
                <IconButton
                  className="clear-icon"
                  onClick={() => {
                    if (holdings.length === 1) {
                      setHoldings([{ rowID: holdingInputId + 1 }]);
                      setHoldingInputId(holdingInputId + 1);
                    } else {
                      const newHoldingData = copy(holdings);
                      newHoldingData.splice(holdingIndex, 1);
                      setHoldings(newHoldingData);
                      setTransactionData({ ...transactionData, holdings: newHoldingData });
                    }
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </div>
              {holdingIndex === holdings.length - 1 && (
                <div className="holdings-data">
                  <Button
                    className="add-holding-btn"
                    onClick={() => {
                      setHoldings([...holdings, { rowID: holdingInputId + 1 }]);
                      setHoldingInputId(holdingInputId + 1);
                    }}
                  >
                    <AddCircleOutlineIcon />
                    Add Holding Class
                  </Button>
                </div>
              )}
            </React.Fragment>
          ))}
      </div>
      <div className="input-block">
        <div className="block-header">
          <h5>Industry and expected exit</h5>
        </div>
        <div className="input-tiles block-two">
          <InfoTile
            label="Valuation date"
            dataName="valuationDate"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            errorStates={errorStates}
            setErrorStates={setErrorStates}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            isDate
          />
          <InfoTile
            label="Transaction date"
            dataName="transactionDate"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            errorStates={errorStates}
            setErrorStates={setErrorStates}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            isDate
          />
          <InfoTile
            label="Financials ‘as of’ date"
            dataName="financialsAsOfDate"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            errorStates={errorStates}
            setErrorStates={setErrorStates}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            isDate
          />
          <InfoTile
            label="Company industry"
            dataName="companyIndustry"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
          <InfoTile
            label="Most likely type of exit event"
            dataName="typeOfExit"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            dropdownValues={[
              'Merger and acquisition (M&A)',
              'Initial public offering (IPO)',
              'Don\'t know',
              'Other',
            ]}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
          <InfoTile
            label="Most likely timing of exit event"
            dataName="exitTiming"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            dropdownValues={[
              'Less than 1 year',
              '1 year',
              '2 years',
              '3 years',
              '4 years',
              '5 years or more',
            ]}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
          <InfoTile
            label="Capitalization table provider"
            dataName="capTableProvider"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            dropdownValues={[
              'Carta',
              'Pulley',
              'Shareworks',
              'Other',
            ]}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
          />
          <InfoTile
            label="Capitalization 'as of' date"
            dataName="capTableDate"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            isDate
          />
        </div>
      </div>
      <div className="input-block block-three">
        <div className="block-header">
          <h5>Financials and option pool</h5>
        </div>
        <div className="input-tiles">
          <InfoTile
            label="Total invested capital"
            dataName="totalInvestedCapital"
            isEditing={isEditing}
            values={clientFinancialInfo}
            setValues={setClientFinancialInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            inputTracking
            add$
            addCommas
          />
          <InfoTile
            label="Total convertible debt"
            dataName="convertibleDebt"
            isEditing={isEditing}
            values={clientFinancialInfo}
            setValues={setClientFinancialInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            inputTracking
            add$
            addCommas
          />
          <InfoTile
            label="Total non-convertible debt"
            dataName="nonConvertibleDebt"
            isEditing={isEditing}
            values={clientFinancialInfo}
            setValues={setClientFinancialInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            inputTracking
            add$
            addCommas
          />
          <InfoTile
            label="Debt as of transaction date (if applicable)"
            dataName="debtAsOfTransactionDate"
            isEditing={isEditing}
            values={basicInfo}
            setValues={setBasicInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            add$
            addCommas
          />
          <InfoTile
            label="Total current cash"
            dataName="currentCash"
            isEditing={isEditing}
            values={clientFinancialInfo}
            setValues={setClientFinancialInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            inputTracking
            add$
            addCommas
          />
          <InfoTile
            label="Cash runway (in months)"
            dataName="monthlyCashBurn"
            isEditing={isEditing}
            values={clientFinancialInfo}
            setValues={setClientFinancialInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            inputTracking
            addMonths
          />
          <InfoTile
            label="Company has or will generate revenue over $1 million in the next year?"
            dataName="EBITDAChecked"
            isEditing={isEditing}
            values={enterpriseFinancialInfo}
            setValues={setEnterpriseFinancialInfo}
            setChangeInProgress={setChangeInProgress}
            setChangeHasBeenMade={setChangeHasBeenMade}
            isRadio
          />
        </div>
      </div>
      <div className="input-block block-four">
        <div className="block-header header-four">
          {[
            ['Last fiscal year - 1', 'LFY-1'],
            ['Last fiscal year', 'LFY'],
            ['Last twelve months', 'LTM'],
            ['Next 12 months', 'NTM'],
            ['Current fiscal year', 'CFY'],
            ['Next fiscal year', 'NFY'],
            ['Next fiscal year + 1', 'NFY +1'],
            ['Next fiscal year + 2', 'NFY +2'],
          ].map((label) => (
            <span key={label[0]}>
              {label[0]}
              <br />
              <p>
                {label[1]}
                &nbsp;($)
              </p>
            </span>
          ))
          }
        </div>
        <div className="input-tiles fiscal-periods">
          {['Revenue', 'GrossProfit', 'EBITDA'].map((rowName, idx) => (
            <div key={rowName} className={`indicator-rows${idx % 2 === 0 ? ' even' : ' odd'}`}>
              <span className={`${rowName.toLowerCase()}`}>{rowName}</span>
              {[
                `lastFiscalYear${rowName}M1`,
                `lastFiscalYear${rowName}`,
                `last12Months${rowName}`,
                `next12Months${rowName}`,
                `currentFiscalYear${rowName}`,
                `nextFiscalYear${rowName}`,
                `nextFiscalYear${rowName}P1`,
                `nextFiscalYear${rowName}P2`,
              ].map((dataName) => (
                <InfoTile
                  key={dataName}
                  label=""
                  dataName={dataName}
                  isEditing={isEditing}
                  values={enterpriseFinancialInfo}
                  setValues={setEnterpriseFinancialInfo}
                  setChangeInProgress={setChangeInProgress}
                  setChangeHasBeenMade={setChangeHasBeenMade}
                  inputTracking
                  add$
                  addCommas
                />
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="input-block">
        <div className="block-header">
          <h5>Document upload</h5>
        </div>
        <div className="input-tiles">
          <div className="info-tile">
            <p>Upload: Cap table & option ledger</p>
            <UploadTile
              uploadData={capTableUploads}
              setUploadData={setCapTableUploads}
              isEditing={isEditing}
              portfolioCompanyId={userData.metaData.portfolioCompanyId}
              transactionId={userData.metaData.transactionId}
            />
          </div>
          <div className="info-tile">
            <p>Upload: Articles of incorporation</p>
            <UploadTile
              uploadData={articlesUploads}
              setUploadData={setArticlesUploads}
              isEditing={isEditing}
              portfolioCompanyId={userData.metaData.portfolioCompanyId}
              transactionId={userData.metaData.transactionId}
            />
          </div>
          <div className="info-tile">
            <p>Upload: Option ledger</p>
            <UploadTile
              uploadData={optionLedgerUploads}
              setUploadData={setOptionLedgerUploads}
              isEditing={isEditing}
              portfolioCompanyId={userData.metaData.portfolioCompanyId}
              transactionId={userData.metaData.transactionId}
            />
          </div>
          <div className="info-tile">
            <p>Upload: Secondary Transaction</p>
            <UploadTile
              uploadData={secondaryTransactionUploads}
              setUploadData={setSecondaryTransactionUploads}
              isEditing={isEditing}
              portfolioCompanyId={userData.metaData.portfolioCompanyId}
              transactionId={userData.metaData.transactionId}
            />
          </div>
          <div className="info-tile">
            <p>Upload: Term sheets</p>
            <UploadTile
              uploadData={termSheetsUploads}
              setUploadData={setTermSheetsUploads}
              isEditing={isEditing}
              portfolioCompanyId={userData.metaData.portfolioCompanyId}
              transactionId={userData.metaData.transactionId}
            />
          </div>
          <div className="info-tile">
            <p>Upload: Safe or Convertible Notes</p>
            <UploadTile
              uploadData={safeOrConvertibleNoteUploads}
              setUploadData={setSafeOrConvertibleNoteUploads}
              isEditing={isEditing}
              portfolioCompanyId={userData.metaData.portfolioCompanyId}
              transactionId={userData.metaData.transactionId}
            />
          </div>
          <div className="info-tile">
            <p>Upload: Financial forecast</p>
            <UploadTile
              uploadData={financialForecastUploads}
              setUploadData={setFinancialForecastUploads}
              isEditing={isEditing}
              portfolioCompanyId={userData.metaData.portfolioCompanyId}
              transactionId={userData.metaData.transactionId}
            />
          </div>
          <div className="info-tile">
            <p>Upload: Additional financial uploads</p>
            <UploadTile
              uploadData={additionalFinancialUploads}
              setUploadData={setAdditionalFinancialUploads}
              isEditing={isEditing}
              portfolioCompanyId={userData.metaData.portfolioCompanyId}
              transactionId={userData.metaData.transactionId}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

ClientInputs.propTypes = {
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
  checkForMissingCompInputs: PropTypes.bool.isRequired,
  setCheckForMissingCompInputs: PropTypes.func.isRequired,
  exitingToTab: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]).isRequired,
  setExitingToTab: PropTypes.func.isRequired,
  setTabToView: PropTypes.func.isRequired,
};
