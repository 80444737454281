import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import EditIcon from '@mui/icons-material/Edit';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import './EditButton.scss';

export default function EditButton({
  isEditing,
  setIsEditing,
  infoSaving,
  setInfoSaving,
  updateFunction,
}) {
  return (
    !isEditing && !infoSaving ? (
      <IconButton
        className="edit-btn"
        onClick={(e) => {
          e.stopPropagation();
          setIsEditing(true);
        }}
      >
        <EditIcon />
      </IconButton>
    ) : (
      <div className="btns-container">
        <Button
          className="save-btn"
          onClick={(e) => {
            e.stopPropagation();
            setInfoSaving(true);
            updateFunction();
          }}
        >
          {!infoSaving ? (
            <>
              <SaveOutlinedIcon />
              Save
            </>
          ) : (
            <>
              <div className="dots-circle-spinner" />
              Saving
            </>
          )}
        </Button>
        <Button
          className="cancel-btn"
          onClick={(e) => {
            e.stopPropagation();
            setIsEditing(false);
          }}
        >
          <CancelOutlinedIcon />
          Cancel
        </Button>
      </div>
    )
  );
}

EditButton.propTypes = {
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func,
  infoSaving: PropTypes.bool,
  setInfoSaving: PropTypes.func,
  updateFunction: PropTypes.func,
};

EditButton.defaultProps = {
  isEditing: false,
  setIsEditing: () => { },
  infoSaving: false,
  setInfoSaving: () => { },
  updateFunction: () => { },
};
