import React, { useState } from 'react';
import {
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

import './SortBy.scss';

export default function SortBy({
  sortBy,
  setSortBy,
  sortByList,
  type,
  isButton,
  tooltipCopy,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  return (
    <span className="SortByDropDown">
      {!isButton ? (
        <>
          <span className="sort-by-label">
            {`${type}:`}
          </span>
          <IconButton
            className="sort-by-btn"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <span className="sort-by-btn-text">
              {sortBy}
              <KeyboardArrowDownOutlinedIcon />
            </span>
          </IconButton>
        </>
      ) : (
        <Tooltip
          id="sort-by-tooltip"
          title={tooltipCopy}
          placement="top"
          arrow
        >
          <IconButton
            className={`dropdown-btn${dropdownIsOpen ? ' is-open' : ''}`}
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setDropdownIsOpen(!dropdownIsOpen);
            }}
          >
            <span className="sort-by-btn-text">
              {type}
              <KeyboardArrowDownOutlinedIcon />
            </span>
          </IconButton>
        </Tooltip>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          setDropdownIsOpen(false);
        }}
        value={sortBy}
        menuprops={{ disableScrollLock: true }}
        iconcomponent={KeyboardArrowDownOutlinedIcon}
        id="SortBy"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: `${!isButton ? 'right' : 'center'}`,
        }}
        disableScrollLock
      >
        {sortByList.map((item, idx) => (
          <MenuItem
            key={`${item}+=+=${idx * Math.random(0, 100)}`}
            className={`sort-by-item${item === sortBy ? ' active' : ''}`}
            onClick={() => {
              setSortBy(!isButton ? item : [item, idx]);
              setAnchorEl(null);
              setDropdownIsOpen(false);
            }}
          >
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Menu>
    </span>
  );
}

SortBy.propTypes = {
  sortBy: PropTypes.string.isRequired,
  setSortBy: PropTypes.func.isRequired,
  sortByList: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  isButton: PropTypes.bool,
  tooltipCopy: PropTypes.string,
};

SortBy.defaultProps = {
  isButton: false,
  tooltipCopy: '',
};
