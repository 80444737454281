import React, {
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DoneIcon from '@mui/icons-material/Done';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';

import Step1 from './Step1_BasicInfo';
import Step2 from './Step2_FundingAndSecondaryTransactions';
import Step3 from './Step3_Uploads';
import Step4 from './Step4_Holdings';

import { cleanSingleObject } from './utils';

import { copy } from '../../utils';

import { getUserId } from '../../utils/auth';

import { createFetchHeaders } from '../../utils/apiCalls';

import { ErrorMessageContext } from '../../lib/contextLib';

import './IntakeBlock.scss';

export default function IntakeBlock({
  companiesData,
  setCompaniesData,
  transactionsData,
  setTransactionsData,
  enterpriseCompanyData,
  dropdownHeights,
  setDropdownHeights,
  index,
  setSaveTransactionData,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [newActiveStep, setNewActiveStep] = useState(0);
  const [stepsVisited, setStepsVisited] = useState([]);

  const [confirmSubmitInfo, setConfirmSubmitInfo] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [userHasAccess, setUserHasAccess] = useState(true);
  const [showContact, setShowContact] = useState(false);
  const [submittingTransaction, setSubmittingTransaction] = useState(false);

  const firstStepRef = useRef(null);
  const secondStepRef = useRef(null);
  const thirdStepRef = useRef(null);
  const fourthStepRef = useRef(null);

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  function handleIntakeChange(dataName, dataValue, dataIndex) {
    if (dataName === 'userHasAccess' && dataValue === '0') setUserHasAccess(false);
    else {
      setActiveStep(0);
      setUserHasAccess(true);
    }
    const newData = copy(transactionsData);
    newData[dataIndex] = { ...newData[dataIndex], [dataName]: parseInt(dataValue, 10) };
    setTransactionsData(newData);
  }


  function determineIcon(isWizardComplete, stepIndex, blockIndex) {
    const isCurrentWizComplete = isWizardComplete(blockIndex);
    let status = 'not-started';
    if (isCurrentWizComplete) status = 'completed';
    else if (activeStep === stepIndex && transactionsData[blockIndex]?.userHasAccess) status = 'is-next';
    else if (stepsVisited.includes(stepIndex)) status = 'has-started';

    return { icon: isCurrentWizComplete && <DoneIcon />, status };
  }

  async function submitTransaction(blockIndex) {
    try {
      setSubmittingTransaction(true);
      const userId = getUserId();
      let transactionData = {
        ...transactionsData[blockIndex],
        insertUserId: userId,
        userHasAccess: parseInt(transactionsData[blockIndex].userHasAccess, 10),
        version: parseInt(transactionsData[blockIndex].version, 10),
        basicInfo: {
          ...transactionsData[index].basicInfo,
          transactionDate: transactionsData[index].fundingInfo?.latestRoundDate,
        },
        requestUserId: userId,
      };
      delete transactionData.companyIndex;
      delete transactionData.companyName;
      delete transactionData.lastModified;
      transactionData = cleanSingleObject(transactionData);
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/transactions/asc820/submit-asc820-info`,
        await createFetchHeaders('post', transactionData, true),
      );
      if (Boolean(parseInt(transactionsData[index]?.userHasAccess, 10)) && transactionsData[index]?.submittedByUser === '1') {
        setShowContact(true);
      }
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      const newCompaniesData = copy(companiesData);
      newCompaniesData[blockIndex].submittedByUser = '1';
      setCompaniesData(newCompaniesData);
      setConfirmSubmitInfo(false);
      setSubmitSuccess(true);
      setUserHasAccess(true);
      const newDropdownHeights = copy(dropdownHeights);
      if (newDropdownHeights.includes(index)) newDropdownHeights.splice(newDropdownHeights.indexOf(index), 1);
      else newDropdownHeights.push(index);
      setDropdownHeights(newDropdownHeights);
      setSubmittingTransaction(false);
    }
  }

  const steps = [
    {
      label: 'Has Permission',
      isComplete: (blockIndex) => parseInt(transactionsData[blockIndex]?.userHasAccess, 10) === 1,
    },
    {
      label: 'Basic information',
      isComplete: (blockIndex) => transactionsData[blockIndex]?.basicInfo?.valuationDate &&
        transactionsData[blockIndex]?.basicInfo?.companyIndustry &&
        transactionsData[blockIndex]?.basicInfo?.typeOfExit && transactionsData[blockIndex]?.basicInfo?.exitTiming &&
        transactionsData[blockIndex]?.basicInfo?.capTableDate && transactionsData[blockIndex]?.basicInfo?.capTableProvider,
    },
    {
      label: 'Funding',
      isComplete: (blockIndex) => transactionsData[blockIndex]?.fundingInfo?.totalAmountRaised &&
        transactionsData[blockIndex]?.fundingInfo?.latestRoundDate && transactionsData[blockIndex]?.fundingInfo?.latestRoundType &&
        ((transactionsData[index]?.fundingInfo?.latestRoundType !== 'SAFE Note' &&
          transactionsData[index]?.fundingInfo?.latestRoundType !== 'Convertible Note') ||
          ((transactionsData[index]?.fundingInfo?.latestRoundType === 'SAFE Note' ||
            transactionsData[index]?.fundingInfo?.latestRoundType === 'Convertible Note') &&
            transactionsData[blockIndex]?.fundingInfo?.safeOrConvertibleAmountRaised && transactionsData[blockIndex]?.fundingInfo?.interestRate &&
            transactionsData[blockIndex]?.fundingInfo?.valuationCap && transactionsData[blockIndex]?.fundingInfo?.preOrPostMoney)),
    },
    {
      label: 'Document upload',
      isComplete: (blockIndex) => transactionsData[blockIndex]?.capTableUploads?.length > 0 &&
        transactionsData[blockIndex]?.articlesUploads?.length > 0,
    },
    {
      label: 'Holdings',
      isComplete: (blockIndex) => transactionsData[blockIndex]?.holdings?.length > 0 &&
        transactionsData[blockIndex]?.holdings?.every((holding) => Object.values(holding).every((input) => input !== '')),
    },
  ];


  const allStepsCompleted = () => steps.every((step) => step.isComplete(index));

  useEffect(() => {
    if (parseInt(transactionsData[index]?.userHasAccess, 10) === 0 && companiesData[index]?.submittedByUser === '1') {
      setShowContact(true);
      setUserHasAccess(false);
    } else {
      setShowContact(false);
    }
    if (transactionsData[index]?.userHasAccess === null) {
      const transactionCopy = copy(transactionsData[index]);
      transactionCopy.userHasAccess = 1;
      setTransactionsData([...transactionsData.slice(0, index), transactionCopy, ...transactionsData.slice(index + 1)]);
    }
    setNewActiveStep(steps.findIndex(({ isComplete }) => !isComplete(index)));
  }, [transactionsData, companiesData]);

  useEffect(() => {
    if (newActiveStep !== activeStep) {
      if (newActiveStep === 1) firstStepRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      else if (newActiveStep === 2) secondStepRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      else if (newActiveStep === 3) thirdStepRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      else if (newActiveStep === 4) fourthStepRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setActiveStep(newActiveStep);
    }
  }, [newActiveStep]);

  useEffect(() => {
    if (!stepsVisited.includes(activeStep)) {
      const newStepsVisitedArray = copy(stepsVisited);
      newStepsVisitedArray.push(activeStep);
      setStepsVisited(newStepsVisitedArray);
    }
  }, [activeStep]);

  return (
    <div className="IntakeBlock" role="button" onClick={(e) => { e.stopPropagation(); }} onKeyDown={() => { }} tabIndex={-1}>
      <div className="left-stepper-container">
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map(({ label, isComplete }, i) => {
            if (i !== 0) {
              const { icon, status } = determineIcon(isComplete, i, index);
              return (
                <Step
                  key={label}
                  disabled={false}
                  className="individual-step"
                >
                  <StepLabel className={status} StepIconComponent={() => icon || i}>
                    {label}
                    {status === 'completed' && companiesData[index].submittedByUser !== '1' && (
                      <IconButton disabled={!userHasAccess} onClick={() => setNewActiveStep(i)}>
                        <EditOutlinedIcon />
                      </IconButton>
                    )}
                  </StepLabel>
                </Step>
              );
            }
            return null;
          })}
        </Stepper>
        {userHasAccess ? (
          <Button
            className="left-submit-btn"
            disabled={!allStepsCompleted() || companiesData[index].submittedByUser === '1'}
            onClick={() => setConfirmSubmitInfo(true)}
          >
            Submit company info
          </Button>
        ) : (
          <Button
            className="left-submit-btn"
            disabled={companiesData[index].submittedByUser === '1'}
            onClick={() => setConfirmSubmitInfo(true)}
          >
            Submit
          </Button>
        )}
      </div>
      <div className="intake-blocks">
        <div
          className={`intake-block ${activeStep === 0 ? ' active-step' : ''}` +
            `${companiesData[index].submittedByUser === '1' || companiesData[index].isLocked === '1' ? ' no-permission' : ''}`}
        >
          {!showContact ? (
            <>
              <h4>Before you get started</h4>
              <p>
                Before entering any information for
                {' '}
                {companiesData[index]?.companyName}
                , please review all information
                being requested (below), then indicate whether or not you have access to all required information.
              </p>
              <p>
                If you don&apos;t have access, you still have options, but we&apos;ll need to meet with you
                to discuss them and the potential costs related to
                {' '}
                {enterpriseCompanyData?.companyName}
                {' '}
                collecting the
                information and documents on your behalf.
              </p>
              <p>
                Do you have access and/or permission to access
                {' '}
                {companiesData[index]?.companyName}
                &apos;s information?
              </p>
              <RadioGroup
                row
                value={userHasAccess ? 1 : 0}
                onChange={(e) => handleIntakeChange('userHasAccess', e.target.value, index)}
              >
                <FormControlLabel value={1} control={<Radio />} label="Yes" />
                <FormControlLabel value={0} control={<Radio />} label="No" />
              </RadioGroup>
            </>
          ) : (
            <div>
              <h4 className="no-access-header">
                <ContactPhoneOutlinedIcon />
                &nbsp;
                Contact us
              </h4>
              <p>
                Let&apos;s talk about your options for
                {' '}
                {companiesData[index].companyName}
                &apos;s information collection.
                We&apos;ll contact you within two business days from when you&apos; done with all companies.
                Want to talk sooner?  Here&apos; our contact information:
              </p>
              <p className="no-access-contact-info">
                Phone: (555) 555-5555 &nbsp;&nbsp;|&nbsp;&nbsp;Email: name@redwoodvaluation.com
                <br />
                We&apos;re available: Monday to Friday 9 am to 5pm PST
              </p>
            </div>
          )}
        </div>
        {userHasAccess && (
          <>
            <div className={`intake-block ${activeStep === 1 ? ' active-step' : ''}` +
              `${companiesData[index].submittedByUser === '1' || companiesData[index].isLocked === '1' ? ' no-permission' : ''}`}
              ref={firstStepRef}
            >
              <Step1
                transactionsData={transactionsData}
                setTransactionsData={setTransactionsData}
                setActiveStep={setActiveStep}
                index={index}
              />
            </div>
            <div className={`intake-block ${activeStep === 2 ? ' active-step' : ''}` +
              `${companiesData[index].submittedByUser === '1' || companiesData[index].isLocked === '1' ? ' no-permission' : ''}`}
              ref={secondStepRef}
            >
              <Step2
                transactionsData={transactionsData}
                setTransactionsData={setTransactionsData}
                setActiveStep={setActiveStep}
                index={index}
              />
            </div>
            <div className={`intake-block ${activeStep === 3 ? ' active-step' : ''}` +
              `${companiesData[index].submittedByUser === '1' || companiesData[index].isLocked === '1' ? ' no-permission' : ''}`}
              ref={thirdStepRef}
            >
              <Step3
                transactionsData={transactionsData}
                setTransactionsData={setTransactionsData}
                setActiveStep={setActiveStep}
                index={index}
                setSaveTransactionData={setSaveTransactionData}
              />
            </div>
            <div className={`intake-block ${activeStep === 4 ? ' active-step' : ''}` +
              `${companiesData[index].submittedByUser === '1' || companiesData[index].isLocked === '1' ? ' no-permission' : ''}`}
              ref={fourthStepRef}
            >
              <Step4
                transactionsData={transactionsData}
                setTransactionsData={setTransactionsData}
                setActiveStep={setActiveStep}
                index={index}
              />
            </div>
          </>
        )}
        {userHasAccess ? (
          <Button
            className="submit-btn"
            disabled={!allStepsCompleted() || companiesData[index].submittedByUser === '1'}
            onClick={() => setConfirmSubmitInfo(true) && setUserHasAccess(false)}
          >
            Submit company info
          </Button>
        ) : (
          <Button
            className="submit-btn"
            disabled={companiesData[index].submittedByUser === '1' || companiesData[index].isLocked === '1'}
            onClick={() => {
              setConfirmSubmitInfo(true);
              setUserHasAccess(false);
            }}
          >
            Submit
          </Button>
        )}
      </div>
      <Dialog
        open={confirmSubmitInfo}
        className="confirm-submit-dialog"
        disableScrollLock
      >
        <div className="box-header">
          <WarningAmberOutlinedIcon />
          <h4>
            Submit
            {' '}
            {companiesData[index]?.companyName}
            &apos;s information?
          </h4>
        </div>
        <p>
          You won&apos;t be able to make additional edits once it&apos;s been submitted.
        </p>
        <br />
        <p>
          If you wait to submit this information our capacity could change and your ASC 820 could take longer to process.
        </p>
        <div className="box-buttons">
          <Button
            className="cancel-btn"
            onClick={() => {
              setConfirmSubmitInfo(false);
              setUserHasAccess(true);
            }}
          >
            Cancel
          </Button>
          <Button
            className="confirm-btn"
            onClick={() => submitTransaction(index)}
          >
            {submittingTransaction ? (
              <>
                <div className="dots-circle-spinner" />
                Submitting info...
              </>
            ) : (
              <>
                Submit info now
              </>
            )}
          </Button>
        </div>
      </Dialog>
      <Dialog
        open={submitSuccess}
        className="submit-success-dialog"
        disableScrollLock
      >
        <IconButton
          className="close-icon"
          onClick={() => setSubmitSuccess(false)}
        >
          <CloseIcon />
        </IconButton>
        <div className="box-header submit-success">
          <CheckIcon />
          <h4>Successfully submitted</h4>
        </div>
        <p>
          The submitted information will soon be reviewed by
          {' '}
          {enterpriseCompanyData.companyName}
          .
        </p>
        <p>
          If
          {' '}
          {enterpriseCompanyData.companyName}
          {' '}
          needs more information to complete this ASC 820,
          you&apos;ll be hearing from them shortly. If they have all they need,
          you will receive
          {' '}
          {transactionsData[index]?.portfolioCompanyName}
          &apos;s ASC 820 in approximately 2 weeks.
        </p>
      </Dialog>
    </div>
  );
}

IntakeBlock.propTypes = {
  companiesData: PropTypes.array.isRequired,
  setCompaniesData: PropTypes.func.isRequired,
  transactionsData: PropTypes.array.isRequired,
  setTransactionsData: PropTypes.func.isRequired,
  enterpriseCompanyData: PropTypes.object.isRequired,
  dropdownHeights: PropTypes.array.isRequired,
  setDropdownHeights: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setSaveTransactionData: PropTypes.func.isRequired,
};
