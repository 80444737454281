import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AnimateHeight from 'react-animate-height';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DoneIcon from '@mui/icons-material/Done';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import CompaniesList from './CompaniesList';
import IntakeBlock from './IntakeBlock';
import SuccessfulIntake from './SuccessfulIntake';

import { createFetchHeaders } from '../../utils/apiCalls';

import { getUserId, getUserCompanyId, getUserName } from '../../utils/auth';

import { ErrorMessageContext } from '../../lib/contextLib';

import { copy } from '../../utils';

import './Intake.scss';
import { cleanObjectValues } from './utils';
import AddCompanyToProject from '../../components/AddCompanyToProject';


function CustomDroppable({ children, ...props }) {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) return null;

  return <Droppable {...props}>{children}</Droppable>;
}

CustomDroppable.propTypes = { children: PropTypes.func.isRequired };

export default function Intake() {
  const [dropdownHeights, setDropdownHeights] = useState([]);

  const [companiesData, setCompaniesData] = useState([{ companyIndex: 0, companyName: '' }]);
  const [viewCompanyList, setViewCompanyList] = useState(false);

  const [fetchingTransactionList, setFetchingTransactionsList] = useState(false);

  const [transactionsData, setTransactionsData] = useState([]);

  const [transactionDataIsSaving, setTransactionDataIsSaving] = useState(false);

  const [successMessage, setSuccessMessage] = useState(false);

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [enterpriseCompanyData, setEnterpriseCompanyData] = useState({});
  const [projectData, setProjectData] = useState({});

  const [addCompany, setAddCompany] = useState(false);
  const [companiesDataForAddition, setCompaniesDataForAddition] = useState({
    portfolioCompanyId: '',
    enterpriseCompanyId: '',
    projectId: '',
    requestUserId: getUserId(),
  });

  const [saveTransactionData, setSaveTransactionData] = useState(false);

  function getCookie(name) {
    const cookieName = `${name}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i += 1) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return JSON.parse(cookie.substring(cookieName.length, cookie.length));
      }
    }
    return null;
  }

  async function getTransactionsList() {
    setFetchingTransactionsList(true);
    try {
      const userId = getUserId();
      const companyId = getUserCompanyId();
      let companyData = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/homepages/investor/${companyId}&${userId}`,
        await createFetchHeaders('get', {}, true),
      );
      if (companyData.status === 200) {
        companyData = await companyData.json();
        const { enterpriseCompanyId, investorCompanyId, projectId } = companyData[0];
        if (companyData.every((company) => company.submittedByUser === '1')) {
          setSuccessMessage(true);
        }
        let newCompanyData = [];
        companyData.forEach((company, i) => {
          newCompanyData.push({
            companyIndex: i,
            companyName: company.portfolioCompanyName,
            portfolioCompanyId: company.portfolioCompanyId,
            transactionId: company.transactionId,
            status: company.status,
            submittedByUser: company.submittedByUser,
            isLocked: company.isLocked,
          });
        });
        let enterpriseCompData = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/companies/enterprise/get-enterprise-company-info/${enterpriseCompanyId}&${userId}`,
          await createFetchHeaders('get', {}, true),
        );
        enterpriseCompData = await enterpriseCompData.json();
        setEnterpriseCompanyData(enterpriseCompData);
        const urlParams = `${enterpriseCompanyId}&${investorCompanyId}&${projectId}&${userId}`;
        setCompaniesDataForAddition({
          ...companiesDataForAddition,
          investorCompanyId,
          enterpriseCompanyId,
          projectId,
        });
        let enterpriseProjectData = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/projects/asc820/get-project-details/${urlParams}`,
          await createFetchHeaders('get', {}, true),
        );
        enterpriseProjectData = await enterpriseProjectData.json();
        const savedOrder = getCookie('transactionOrder');
        if (savedOrder) {
          newCompanyData = newCompanyData.sort((a, b) => {
            const orderA = savedOrder[a.transactionId];
            const orderB = savedOrder[b.transactionId];
            return orderA - orderB;
          });
        }
        setProjectData(enterpriseProjectData);
        setCompaniesData(newCompanyData);
      } else if (companyData.status === 206) {
        companyData = await companyData.json();
        const projectId = companyData.resource.split('_');
        setCompaniesDataForAddition({
          ...companiesDataForAddition,
          enterpriseCompanyId: companyData.companyId,
          investorCompanyId: companyData.investorCompanyId,
          projectId: projectId[projectId.length - 1],
        });
      }
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setFetchingTransactionsList(false);
    }
  }

  async function saveData() {
    const cleanedTransactions = cleanObjectValues(transactionsData);
    cleanedTransactions.forEach(async (transaction) => {
      if (transaction) {
        const userId = getUserId();
        const transactionData = {
          ...transaction,
          requestUserId: userId,
          version: parseInt(transaction.version, 10),
          basicInfo: {
            ...transaction.basicInfo,
            transactionDate: transaction.fundingInfo?.latestRoundDate,
          },
        };
        delete transactionData.companyIndex;
        delete transactionData.companyName;
        delete transactionData.lastModified;
        if (!transactionData.holdings) transactionData.holdings = [];
        setTransactionDataIsSaving(true);
        try {
          await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/transactions/asc820/investor-update-transaction`,
            await createFetchHeaders('post', transactionData, true),
          );
        } catch (e) {
          setShowErrorMessage(e.toString());
        } finally {
          setTransactionDataIsSaving(false);
        }
      }
    });
  }

  function setCookie(name, value, daysToExpire) {
    const date = new Date();
    date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    const stringValue = JSON.stringify(value);
    document.cookie = `${name}=${stringValue};${expires};path=/`;
  }

  function handleTransactionOrderSave(transactionList) {
    setTransactionDataIsSaving(true);
    const transactionOrderMap = {};
    for (let i = 0; i < transactionList.length; i += 1) {
      const transaction = transactionList[i];
      transactionOrderMap[transaction.transactionId] = i;
    }
    setCookie('transactionOrder', transactionOrderMap, 7);
    setTimeout(() => {
      setTransactionDataIsSaving(false);
    }, 1500);
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const newCompanyList = copy(companiesData);
    const newTransactionList = copy(transactionsData);

    const [reorderedCompany] = newCompanyList.splice(result.source.index, 1);
    const [reorderedTransaction] = newTransactionList.splice(result.source.index, 1);
    newCompanyList.splice(result.destination.index, 0, reorderedCompany);
    newTransactionList.splice(result.destination.index, 0, reorderedTransaction);

    setCompaniesData(newCompanyList);
    setTransactionsData(newTransactionList);
    handleTransactionOrderSave(newCompanyList);
  }

  async function handleDropdownToggle(index) {
    if (!transactionsData[index]) {
      const userId = getUserId();
      const investorCompanyId = getUserCompanyId();
      try {
        let transactionDBData = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}` +
          '/transactions/asc820/get-investor-transaction-data/' +
          `${investorCompanyId}&${companiesData[index].portfolioCompanyId}&${companiesData[index].transactionId}&${userId}`,
          await createFetchHeaders('get', {}, true),
        );
        transactionDBData = await transactionDBData.json();
        const newTransactionsData = copy(transactionsData);
        newTransactionsData[index] = transactionDBData;
        setTransactionsData(newTransactionsData);
      } catch (e) {
        setShowErrorMessage(e.toString());
      }
    }
    const newDropdownHeights = copy(dropdownHeights);
    if (newDropdownHeights.includes(index)) newDropdownHeights.splice(newDropdownHeights.indexOf(index), 1);
    else newDropdownHeights.push(index);
    setDropdownHeights(newDropdownHeights);
  }

  useEffect(() => { if (saveTransactionData) saveData(); }, [saveTransactionData]);

  useEffect(() => { getTransactionsList(); }, []);

  if ((!companiesData[0].companyName || viewCompanyList) && !companiesData) return (
    <CompaniesList
      companiesData={companiesData}
      setCompaniesData={setCompaniesData}
      setViewCompanyList={setViewCompanyList}
      setTransactionDataIsSaving={setTransactionDataIsSaving}
    />
  );

  if (successMessage) {
    return <SuccessfulIntake projectData={projectData} setSuccessMessage={setSuccessMessage} fetchingTransactionList={fetchingTransactionList} />;
  }

  return (
    <main className="Intake">
      <AddCompanyToProject
        addCompany={addCompany}
        setAddCompany={setAddCompany}
        projectId={companiesDataForAddition?.projectId}
        investorCompanyId={companiesDataForAddition?.investorCompanyId}
        setCompanies={setCompaniesData}
        companies={companiesData}
        enterpriseCompanyIdent={companiesDataForAddition?.enterpriseCompanyId}
        investor
        portfolioCompaniesNames={[]}
      />
      <div className="page-header">
        <div className="header-nav">
          <p>ASC 820 information</p>
          <p>{getUserName()}</p>
          <Button
            className="add-company-btn"
            onClick={() => setAddCompany(true)}
          >
            <AddOutlinedIcon />
            Add company
          </Button>
          <Button
            className="save-btn"
            onClick={() => { saveData(); }}
          >
            {!transactionDataIsSaving ? (
              <>
                <SaveOutlinedIcon />
                Save
              </>
            ) : (
              <>
                <LoadingSpinner className="loading-spinner" />
                Saving
              </>
            )}
          </Button>
          <Button
            className="done-btn"
            disabled={!companiesData.every((company) => company.submittedByUser === '1')}
            onClick={() => {
              if (companiesData.every((company) => company.submittedByUser === '1')) {
                setSuccessMessage(true);
              }
            }}
          >
            Done!
          </Button>
        </div>
        <div className="header-text">
          <p>Click on any company below to get started. You can work on multiple companies at a time, and save your progress as you go.</p>
          <p>Once you&apos;ve provided and submitted all required information for every company listed below, click &apos;Done!&apos;.</p>
        </div>
      </div>
      <DragDropContext onDragEnd={(result) => { handleOnDragEnd(result); }}>
        <CustomDroppable droppableId="company-list" type="group">
          {(provided) => (
            <div className="company-list" {...provided.droppableProps} ref={provided.innerRef}>
              {companiesData.map((company, index) => {
                if (company.companyName) {
                  return (
                    <Draggable key={company.companyIndex} draggableId={company.companyIndex.toString()} index={index}>
                      {/* eslint-disable-next-line no-shadow */}
                      {(provided) => (
                        <div
                          role="button"
                          className="company-btn"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          onClick={() => { handleDropdownToggle(company.companyIndex); }}
                          onKeyDown={(e) => { if (e.key === 'Enter') handleDropdownToggle(company.companyIndex); }}
                          tabIndex={0}
                        >
                          <div className={`company-btn-head-content${dropdownHeights.includes(company.companyIndex) ? ' is-open' : ''}`}>
                            <div className="left-btn-side">
                              {company.submittedByUser === '1' || company.isLocked === '1' ?
                                <div className="done-icon"><DoneIcon /></div> :
                                <div className="number-icon">{index + 1}</div>}
                              <span>{company.companyName}</span>
                            </div>
                            <div className="right-btn-side">
                              <span>
                                {company.submittedByUser === '1' || company.isLocked === '1' ? 'Information submitted' :
                                  `${dropdownHeights.includes(company.companyIndex) ? 'Provide all' : 'Expand to provide all'} info for this company`}
                              </span>
                              <IconButton
                                className="right-chevron-btn"
                                onClick={() => { handleDropdownToggle(company.companyIndex); }}
                              >
                                <ExpandMoreIcon
                                  className={`rotating-chevron${dropdownHeights.includes(company.companyIndex) ? ' upward' : ' downward'}`}
                                />
                              </IconButton>
                              <IconButton
                                className="right-handle-btn"
                                {...provided.dragHandleProps}
                              >
                                <DragHandleIcon />
                              </IconButton>
                            </div>
                          </div>
                          <AnimateHeight duration={750} height={dropdownHeights.includes(company.companyIndex) ? 'auto' : 0}>
                            <IntakeBlock
                              companiesData={companiesData}
                              setCompaniesData={setCompaniesData}
                              transactionsData={transactionsData}
                              setTransactionsData={setTransactionsData}
                              enterpriseCompanyData={enterpriseCompanyData}
                              dropdownHeights={dropdownHeights}
                              setDropdownHeights={setDropdownHeights}
                              setSuccessMessage={setSuccessMessage}
                              index={index}
                              setSaveTransactionData={setSaveTransactionData}
                            />
                          </AnimateHeight>
                        </div>
                      )}
                    </Draggable>
                  );
                }
                return null;
              })}
              {provided.placeholder}
            </div>
          )}
        </CustomDroppable>
      </DragDropContext>
    </main>
  );
}
