/* eslint-disable jsx-a11y/mouse-events-have-key-events */
// TODO Handle accessibility for mouse over events
import React, {
  useState,
  useEffect,
  useRef,
  createRef,
} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import moment from 'moment';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import AddIcon from '@mui/icons-material/Add';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FlagIcon from '@mui/icons-material/Flag';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';

import { commaEvery3rdChar, copy } from '../../../utils';

import './CompTable.scss';

function SortingChevron({
  sortedColumn,
  columnSorting,
  setColumnSorting,
  columnHeaderHovered,
}) {
  return (
    <IconButton
      className={`chevron-icon${columnSorting.column === sortedColumn ? ' active' : ''}` +
        `${columnHeaderHovered !== sortedColumn ? ' hidden' : ''}`}
      onClick={() => {
        if (columnSorting.column !== sortedColumn || columnSorting.order === 'ascending') {
          setColumnSorting({ column: sortedColumn, order: 'descending' });
        } else {
          setColumnSorting({ column: sortedColumn, order: 'ascending' });
        }
      }}
    >
      {columnSorting.column !== sortedColumn || columnSorting.order === 'descending' ?
        <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
    </IconButton>
  );
}

SortingChevron.propTypes = {
  columnSorting: PropTypes.object.isRequired,
  setColumnSorting: PropTypes.func.isRequired,
  columnHeaderHovered: PropTypes.string.isRequired,
  sortedColumn: PropTypes.string.isRequired,
};

export default function CompTable({
  tabToView,
  filterSelected,
  compData,
  setCompData,
  showCompanyInfo,
  showRevenue,
  showEBITDA,
  showGrossProfit,
  showMarketCap,
  showVolatilityValDate,
  showVolatilityTrDate,
  growthFilterValues,
  multiplesFilterValues,
  yearsFilterValues,
  fiscalPeriodFilterValues,
  setShowAddCompModal,
  headerScroll,
  subjectCompanyScroll,
  compTableLeftRef,
  compTableRightRef,
  valuationDate,
  transactionDate,
  reSummarize,
  viewAllLongDescriptions,
  viewAllShortDescriptions,
  runningComps,
}) {
  const [descriptionsHeights, setDescriptionsHeights] = useState([]);
  const [descriptionsToShow, setDescriptionsToShow] = useState([]);

  const [showConfirmationModal, setShowConfirmationModal] = useState(-1);

  const [shortDescBtnFocused, setShortDescBtnFocused] = useState(-1);
  const [shortDescBtnHovered, setShortDescBtnHovered] = useState(-1);
  const [longDescBtnFocused, setLongDescBtnFocused] = useState(-1);
  const [longDescBtnHovered, setLongDescBtnHovered] = useState(-1);

  const [columnHeaderHovered, setColumnHeaderHovered] = useState('');

  const [checkboxHovered, setCheckboxHovered] = useState(-1);

  const [showOutlierFlagBtn, setShowOutlierFlagBtn] = useState(-1);

  const [columnSorting, setColumnSorting] = useState({});

  const descriptionsRef = useRef([]);
  const checkboxesRef = useRef([]);

  useEffect(() => {
    if (compData.Identifier?.length) {
      descriptionsRef.current = compData.Identifier.map((_, i) => descriptionsRef.current[i] ?? createRef());
      checkboxesRef.current = compData.Identifier.map((_, i) => checkboxesRef.current[i] ?? createRef());
      setDescriptionsToShow([...Array(compData.Identifier.length).map(() => '')]);
    }
  }, [compData]);

  useEffect(() => {
    setDescriptionsHeights(descriptionsRef.current.map((el, i) => {
      if (descriptionsToShow[i] === 'long' || descriptionsToShow[i] === 'short') return el.current?.clientHeight;
      return 0;
    }));
  }, [descriptionsToShow]);

  useEffect(() => {
    if (viewAllLongDescriptions) setDescriptionsToShow([...descriptionsToShow].map(() => 'long'));
    if (viewAllShortDescriptions) setDescriptionsToShow([...descriptionsToShow].map(() => 'short'));
    if (!viewAllShortDescriptions && !viewAllLongDescriptions) setDescriptionsToShow([...descriptionsToShow].map(() => ''));
  }, [viewAllLongDescriptions, viewAllShortDescriptions]);

  useEffect(() => {
    if (columnSorting.column) {
      const sortByDate = ['Last FYE Date', 'IPO Date'].includes(columnSorting.column);
      const sortAlphabetically = ['Name', 'Identifier', 'Ticker', 'Industry'].includes(columnSorting.column);
      const indices = [...compData.Identifier.keys()];
      indices.shift();
      indices.sort((a, b) => {
        const sortOrder = columnSorting.order === 'descending' ? [a, b] : [b, a];
        if (!compData[columnSorting.column][a]) return 1;
        if (!compData[columnSorting.column][b]) return -1;
        if (sortByDate) {
          return moment(compData[columnSorting.column][sortOrder[0]], 'MM/DD/YYYY') -
            moment(compData[columnSorting.column][sortOrder[1]], 'MM/DD/YYYY');
        }
        if (sortAlphabetically) {
          return compData[columnSorting.column][sortOrder[0]].localeCompare(compData[columnSorting.column][sortOrder[1]]);
        }
        return compData[columnSorting.column][sortOrder[0]] - compData[columnSorting.column][sortOrder[1]];
      });
      indices.unshift(0);
      const sortedCompData = copy(compData);
      Object.keys(compData).forEach((compDataKey) => {
        sortedCompData[compDataKey] = indices.map((i) => sortedCompData[compDataKey][i]);
      });
      setCompData(sortedCompData);
    }
  }, [columnSorting]);

  const fiscalYears = [
    {
      title: 'Last fiscal year -1',
      legend: 'LFY -1',
    },
    {
      title: 'Last fiscal year',
      legend: 'LFY',
    },
    {
      title: 'Last twelve months',
      legend: 'LTM',
    },
    {
      title: 'Current fiscal year',
      legend: 'CFY',
    },
    {
      title: 'Next twelve months',
      legend: 'NTM',
    },
    {
      title: 'Next fiscal year',
      legend: 'NFY',
    },
    {
      title: 'Next fiscal year +1',
      legend: 'NFY +1',
    },
    {
      title: 'Next fiscal year +2',
      legend: 'NFY +2',
    },
  ];

  const yearsColumnWidth = (yearsFilterValuesLength) => {
    if (!yearsFilterValuesLength) return `${40 * 109.6}px`;
    if (yearsFilterValuesLength === 1) return `${yearsFilterValuesLength * 95}px`;
    if (yearsFilterValuesLength === 2) return `${yearsFilterValuesLength * 100}px`;
    if (yearsFilterValuesLength < 5) return `${yearsFilterValuesLength * 105}px`;
    if (yearsFilterValuesLength < 8) return `${yearsFilterValuesLength * 107}px`;
    if (yearsFilterValuesLength < 13) return `${yearsFilterValuesLength * 108}px`;
    if (yearsFilterValuesLength < 20) return `${yearsFilterValuesLength * 109}px`;
    return `${yearsFilterValuesLength * 109.6}px`;
  };

  const returnCompName = (index) => {
    if (compData.Name[index].replace(/ *\([^)]*\) */g, '').trim().length > 35) {
      return `${compData.Name[index].replace(/ *\([^)]*\) */g, '').trim().substring(0, 35)}...`;
    }
    return compData.Name[index].replace(/ *\([^)]*\) */g, '').trim();
  };

  const getYear = (dataIndex) => {
    const increment = dataIndex / 4 + 0.25;
    return `${increment.toFixed(increment.toFixed(2).toString().charAt(increment.toFixed(2).toString().length - 1) === '0' ? 1 : 2)} year`;
  };

  const getPrice = (dataName, index) => compData[dataName]?.[index] ? commaEvery3rdChar((compData[dataName][index] * 1000000).toFixed(0)) : '-';

  const getPerc = (dataName, index, isGrossMargin) => compData[dataName]?.[index] ?
    `${(compData[dataName][index] * (!isGrossMargin ? 100 : 1)).toFixed(2)}%` : '-';

  const getMultiple = (dataName, index) => compData[dataName]?.[index] ? `${(compData[dataName][index]).toFixed(2)}x` : '-';

  const rowClassName = (index) => {
    let className = 'comp-table-row-data';
    if (checkboxHovered === index) className += ' checkbox-hovered';
    if ((tabToView === 5 && !compData.Multiples[index]) || (tabToView === 4 && !compData.Active[index])) className += ' red-inactive-row';
    if (tabToView === 5 && compData.Multiples[index] && !compData.Active[index]) className += ' yellow-multiples-disabled-row';
    if ((index + 1) % 2 === 0) className += ' even';
    else className += ' odd';
    return className;
  };


  return (
    <main className="CompTable">
      <div className="comp-table">
        <div className="fixed-left">
          <div className="table-header">
            <div className="table-header-top-titles">
              <div className="comp-list"><h6>Company list</h6></div>
            </div>
            <div className="table-sub-headers">
              <div className="sub-header-group comp-list">
                <Tooltip
                  PopperProps={{ className: 'bottom-arrow-tooltip select-deselect top-start' }}
                  title="Selects and deselects all"
                  placement="top-start"
                  arrow
                >
                  <Checkbox
                    checked={tabToView === 5 ? compData.Active.some((active) => active) &&
                      compData.Multiples.some((active) => active) : compData.Active.some((active) => active)}
                    onChange={() => { reSummarize('all', tabToView === 4 ? 'active' : 'multiple'); }}
                    icon={<div className="unchecked-icon" />}
                    checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
                  />
                </Tooltip>
                <div
                  className="sub-header"
                  onMouseOver={() => { if (columnHeaderHovered !== 'Name') setColumnHeaderHovered('Name'); }}
                  onMouseLeave={() => setColumnHeaderHovered('')}
                >
                  <div className="sub-header-title">
                    <h6>Full Company name</h6>
                    <span>&nbsp;</span>
                  </div>
                  <SortingChevron
                    sortedColumn="Name"
                    columnSorting={columnSorting}
                    setColumnSorting={setColumnSorting}
                    columnHeaderHovered={columnHeaderHovered}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="comp-table-rows sub-company">
            <div
              className="comp-table-row-data even"
            >
              <div className="row-data-group comp-list sub-company">
                <div className="table-cell">
                  {returnCompName(0)}
                </div>
              </div>
            </div>
          </div>
          <div className="comp-table-rows" ref={compTableLeftRef}>
            {compData.Identifier?.length && compData.Identifier.map((comp, index) => (
              <div key={`${compData.Identifier[index] + Math.random(1, 100) * Math.random(1, 100)}`}>
                {index !== 0 && (
                  <>
                    <div className={rowClassName(index)}>
                      <div
                        className="row-data-group comp-list"
                        onMouseOver={() => { if (showOutlierFlagBtn === -1) setShowOutlierFlagBtn(index); }}
                        onMouseLeave={() => setShowOutlierFlagBtn(-1)}
                      >
                        <div
                          className="table-cell"
                          onMouseOver={() => { if (checkboxHovered === -1) setCheckboxHovered(index); }}
                          onMouseLeave={() => setCheckboxHovered(-1)}
                        >
                          <Tooltip
                            PopperProps={{ className: 'bottom-arrow-tooltip select-deselect' }}
                            title={
                              compData.Active[index] && compData.Multiples[index] ?
                                (
                                  <span>
                                    Included on Comps list.
                                    <br />
                                    Click to remove.
                                  </span>
                                ) : tabToView === 5 && !compData.Active[index] ? (
                                  <span>
                                    Automatically removed when
                                    <br />
                                    removed from volatility tab.
                                  </span>
                                ) : (
                                  <span>
                                    Removed from Comps list.
                                    <br />
                                    Click to include.
                                  </span>
                                )
                            }
                            placement="top"
                            arrow
                          >
                            <Checkbox
                              inputRef={checkboxesRef.current[index]}
                              className={tabToView === 5 && compData.Multiples[index] && !compData.Active[index] ? 'yellow' : ''}
                              checked={tabToView === 5 ? !!compData.Active[index] && !!compData.Multiples[index] : !!compData.Active[index]}
                              onChange={() => {
                                if (tabToView === 5 && compData.Multiples[index] && !compData.Active[index]) {
                                  setShowConfirmationModal(index);
                                  return;
                                }
                                reSummarize(index, tabToView === 4 ? 'active' : 'multiple');
                              }}
                              icon={<div className="remove-check-icon"><RemoveRoundedIcon /></div>}
                              checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
                            />
                          </Tooltip>
                        </div>
                        <div className="table-cell">
                          {index}
                          {'. '}
                          {returnCompName(index)}
                        </div>
                        <div className="table-cell">
                          {compData.Outlier[index] ? (
                            <IconButton
                              className="outlier-flag-icon is-outlier"
                              onClick={() => { reSummarize(index, 'outlier'); }}
                            >
                              <FlagIcon />
                            </IconButton>
                          ) : (
                            <Tooltip
                              disableInteractive
                              title="Flag as outlier"
                              PopperProps={{ className: 'bottom-arrow-tooltip' }}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                className={`outlier-flag-icon${showOutlierFlagBtn !== index ? ' hidden' : ''}`}
                                onClick={() => { reSummarize(index, 'outlier'); }}
                              >
                                <FlagOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                    <AnimateHeight duration={300} height={descriptionsHeights[index] || 0}>
                      <div
                        className={`description-space${(index + 1) % 2 === 0 ? ' even' : ' odd'}`}
                        style={{ height: descriptionsRef.current[index]?.current?.clientHeight || 0 }}
                      />
                    </AnimateHeight>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="right-scrollable">
          <div className="table-header" ref={headerScroll}>
            <div className="table-header-top-titles">
              {(!filterSelected || showCompanyInfo || tabToView === 4) &&
                <div className="basic-info" style={{ minWidth: tabToView === 5 ? '860px' : '242px' }}><h6>Basic information</h6></div>}
              {tabToView === 4 && (!filterSelected || showVolatilityValDate) && (
                <div
                  className="val-volatility"
                  style={{ minWidth: yearsColumnWidth(yearsFilterValues.length) }}
                >
                  <h6>{!yearsFilterValues.length || yearsFilterValues.length >= 3 ? 'Volatility since valuation date' : 'Val. Vol.'}</h6>
                </div>
              )}
              {tabToView === 4 && (!filterSelected || showVolatilityTrDate) && (
                <div
                  className="tr-volatility"
                  style={{ minWidth: yearsColumnWidth(yearsFilterValues.length) }}
                >
                  <h6>{!yearsFilterValues.length || yearsFilterValues.length >= 3 ? 'Volatility since transaction date' : 'Tr. Vol.'}</h6>
                </div>
              )}
              {(!filterSelected || showMarketCap) &&
                <div className="market-cap"><h6>Market capitalization</h6></div>}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Market growth')) &&
                <div className="market-growth"><h6>Market growth</h6></div>}
              {(!filterSelected || showRevenue) && (
                <div
                  className="revenue"
                  style={{ minWidth: !fiscalPeriodFilterValues.length ? `${8 * 164}px` : `${fiscalPeriodFilterValues.length * 164}px` }}
                >
                  <h6>Revenue</h6>
                </div>
              )}
              {tabToView === 5 && (!filterSelected || showEBITDA) && (
                <div
                  className="EBITDA"
                  style={{ minWidth: !fiscalPeriodFilterValues.length ? `${8 * 164}px` : `${fiscalPeriodFilterValues.length * 164}px` }}
                >
                  <h6>EBITDA</h6>
                </div>
              )}
              {tabToView === 5 && (!filterSelected || showGrossProfit) && (
                <div
                  className="gross-profit"
                  style={{ minWidth: !fiscalPeriodFilterValues.length ? `${8 * 164}px` : `${fiscalPeriodFilterValues.length * 164}px` }}
                >
                  <h6>Gross Profit</h6>
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Revenue growth')) && (
                <div
                  className="revenue-growth"
                  style={{ minWidth: !fiscalPeriodFilterValues.length ? `${8 * 164}px` : `${fiscalPeriodFilterValues.length * 164}px` }}
                >
                  <h6>Revenue growth rate</h6>
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('EBITDA growth')) && (
                <div
                  className="EBITDA-growth"
                  style={{ minWidth: !fiscalPeriodFilterValues.length ? `${8 * 164}px` : `${fiscalPeriodFilterValues.length * 164}px` }}
                >
                  <h6>EBITDA growth rate</h6>
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Gross Profit growth')) && (
                <div
                  className="EBITDA-growth"
                  style={{ minWidth: !fiscalPeriodFilterValues.length ? `${8 * 164}px` : `${fiscalPeriodFilterValues.length * 164}px` }}
                >
                  <h6>Gross Profit growth rate</h6>
                </div>
              )}
              {tabToView === 5 && (!filterSelected || multiplesFilterValues.includes('Revenue multiples')) && (
                <div
                  className="revenue-multiples"
                  style={{ minWidth: !fiscalPeriodFilterValues.length ? `${8 * 164}px` : `${fiscalPeriodFilterValues.length * 164}px` }}
                >
                  <h6>Revenue multiples</h6>
                </div>
              )}
              {tabToView === 5 && (!filterSelected || multiplesFilterValues.includes('EBITDA multiples')) && (
                <div
                  className="ebitda-multiples"
                  style={{ minWidth: !fiscalPeriodFilterValues.length ? `${8 * 164}px` : `${fiscalPeriodFilterValues.length * 164}px` }}
                >
                  <h6>EBITDA multiples</h6>
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('EBITDA Margin')) && (
                <div
                  className="EBITDA-margin"
                  style={{ minWidth: !fiscalPeriodFilterValues.length ? `${8 * 164}px` : `${fiscalPeriodFilterValues.length * 164}px` }}
                >
                  <h6>EBITDA Margin</h6>
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Gross Margin')) && (
                <div
                  className="gross-margin"
                  style={{ minWidth: !fiscalPeriodFilterValues.length ? `${8 * 164}px` : `${fiscalPeriodFilterValues.length * 164}px` }}
                >
                  <h6>Gross Margin</h6>
                </div>
              )}
            </div>
            <div className="table-sub-headers">
              {(!filterSelected || showCompanyInfo || tabToView === 4) && (
                <div className="sub-header-group basic-info">
                  <div
                    className="sub-header"
                    onMouseOver={() => { if (columnHeaderHovered !== 'Identifier') setColumnHeaderHovered('Identifier'); }}
                    onMouseLeave={() => setColumnHeaderHovered('')}
                  >
                    <div className="sub-header-title">
                      <h6>Capital IQ</h6>
                      <span>Identifier</span>
                    </div>
                    <SortingChevron
                      sortedColumn="Identifier"
                      columnSorting={columnSorting}
                      setColumnSorting={setColumnSorting}
                      columnHeaderHovered={columnHeaderHovered}
                    />
                  </div>
                  <div
                    className="sub-header"
                    onMouseOver={() => { if (columnHeaderHovered !== 'Ticker') setColumnHeaderHovered('Ticker'); }}
                    onMouseLeave={() => setColumnHeaderHovered('')}
                  >
                    <div className="sub-header-title">
                      <h6>Ticker</h6>
                      <span>(Market:Ticker)</span>
                    </div>
                    <SortingChevron
                      sortedColumn="Ticker"
                      columnSorting={columnSorting}
                      setColumnSorting={setColumnSorting}
                      columnHeaderHovered={columnHeaderHovered}
                    />
                  </div>
                  {tabToView === 5 && (
                    <>
                      <div
                        className="sub-header"
                        onMouseOver={() => { if (columnHeaderHovered !== 'Industry') setColumnHeaderHovered('Industry'); }}
                        onMouseLeave={() => setColumnHeaderHovered('')}
                      >
                        <div className="sub-header-title">
                          <h6>Industry</h6>
                          <span>(Primary)</span>
                        </div>
                        <SortingChevron
                          sortedColumn="Industry"
                          columnSorting={columnSorting}
                          setColumnSorting={setColumnSorting}
                          columnHeaderHovered={columnHeaderHovered}
                        />
                      </div>
                      {/* <div className="sub-header">
                        <div className="sub-header-title">
                          <h6>Last FQE Date</h6>
                          <span>(mm/dd/yyyy)</span>
                        </div>
                      </div> */}
                      <div
                        className="sub-header"
                        onMouseOver={() => { if (columnHeaderHovered !== 'Last FYE Date') setColumnHeaderHovered('Last FYE Date'); }}
                        onMouseLeave={() => setColumnHeaderHovered('')}
                      >
                        <div className="sub-header-title">
                          <h6>Last FYE Date</h6>
                          <span>(mm/dd/yyyy)</span>
                        </div>
                        <SortingChevron
                          sortedColumn="Last FYE Date"
                          columnSorting={columnSorting}
                          setColumnSorting={setColumnSorting}
                          columnHeaderHovered={columnHeaderHovered}
                        />
                      </div>
                      <div
                        className="sub-header"
                        onMouseOver={() => { if (columnHeaderHovered !== 'IPO Date') setColumnHeaderHovered('IPO Date'); }}
                        onMouseLeave={() => setColumnHeaderHovered('')}
                      >
                        <div className="sub-header-title">
                          <h6>IPO Date</h6>
                          <span>(mm/dd/yyyy)</span>
                        </div>
                        <SortingChevron
                          sortedColumn="IPO Date"
                          columnSorting={columnSorting}
                          setColumnSorting={setColumnSorting}
                          columnHeaderHovered={columnHeaderHovered}
                        />
                      </div>
                      <div className="sub-header">
                        <div className="sub-header-title">
                          <h6>Description</h6>
                          <span>(Short)</span>
                        </div>
                      </div>
                      <div className="sub-header">
                        <div className="sub-header-title">
                          <h6>Description</h6>
                          <span>(Long)</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              {tabToView === 4 && (!filterSelected || showVolatilityValDate) && (
                <div className="sub-header-group val-volatility">
                  {[...Array(40)].map((el, dataIndex) => (
                    (!yearsFilterValues.length ||
                      yearsFilterValues.includes(dataIndex / 4 + 0.25 <= 1 ? `${dataIndex / 4 + 0.25} year` : `${dataIndex / 4 + 0.25} years`)) && (
                      <div
                        key={getYear(dataIndex)}
                        className="sub-header"
                        onMouseOver={() => {
                          const columnName = `Volatility - Valuation Date | ${getYear(dataIndex)}`;
                          if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                        }}
                        onMouseLeave={() => setColumnHeaderHovered('')}
                      >
                        <div className="sub-header-title">
                          <h6>&nbsp;</h6>
                          <span>{dataIndex / 4 + 0.25 <= 1 ? `${dataIndex / 4 + 0.25} year` : `${dataIndex / 4 + 0.25} years`}</span>
                        </div>
                        <SortingChevron
                          sortedColumn={`Volatility - Valuation Date | ${getYear(dataIndex)}`}
                          columnSorting={columnSorting}
                          setColumnSorting={setColumnSorting}
                          columnHeaderHovered={columnHeaderHovered}
                        />
                      </div>
                    )
                  ))}
                </div>
              )}
              {tabToView === 4 && (!filterSelected || showVolatilityTrDate) && (
                <div className="sub-header-group tr-volatility">
                  {[...Array(40)].map((el, dataIndex) => (
                    (!yearsFilterValues.length ||
                      yearsFilterValues.includes(dataIndex / 4 + 0.25 <= 1 ? `${dataIndex / 4 + 0.25} year` : `${dataIndex / 4 + 0.25} years`)) && (
                      <div
                        key={getYear(dataIndex)}
                        className="sub-header"
                        onMouseOver={() => {
                          const columnName = `Volatility - Transaction Date | ${getYear(dataIndex)}`;
                          if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                        }}
                        onMouseLeave={() => setColumnHeaderHovered('')}
                      >
                        <div className="sub-header-title">
                          <h6>&nbsp;</h6>
                          <span>{dataIndex / 4 + 0.25 <= 1 ? `${dataIndex / 4 + 0.25} year` : `${dataIndex / 4 + 0.25} years`}</span>
                        </div>
                        <SortingChevron
                          sortedColumn={`Volatility - Transaction Date | ${getYear(dataIndex)}`}
                          columnSorting={columnSorting}
                          setColumnSorting={setColumnSorting}
                          columnHeaderHovered={columnHeaderHovered}
                        />
                      </div>
                    )
                  ))}
                </div>
              )}
              {(!filterSelected || showMarketCap) && (
                <div className="sub-header-group market-cap">
                  <div
                    className="sub-header"
                    onMouseOver={() => {
                      const columnName = `Market Capitalization | Transaction Date - ${transactionDate}`;
                      if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                    }}
                    onMouseLeave={() => setColumnHeaderHovered('')}
                  >
                    <div className="sub-header-title">
                      <h6>&nbsp;</h6>
                      <span>Transaction date ($)</span>
                    </div>
                    <SortingChevron
                      sortedColumn={`Market Capitalization | Transaction Date - ${transactionDate}`}
                      columnSorting={columnSorting}
                      setColumnSorting={setColumnSorting}
                      columnHeaderHovered={columnHeaderHovered}
                    />
                  </div>
                  <div
                    className="sub-header"
                    onMouseOver={() => {
                      const columnName = `Market Capitalization | Valuation Date - ${valuationDate}`;
                      if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                    }}
                    onMouseLeave={() => setColumnHeaderHovered('')}
                  >
                    <div className="sub-header-title">
                      <h6>&nbsp;</h6>
                      <span>Valuation date ($)</span>
                    </div>
                    <SortingChevron
                      sortedColumn={`Market Capitalization | Valuation Date - ${valuationDate}`}
                      columnSorting={columnSorting}
                      setColumnSorting={setColumnSorting}
                      columnHeaderHovered={columnHeaderHovered}
                    />
                  </div>
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Market growth')) && (
                <div className="sub-header-group market-growth">
                  <div
                    className="sub-header"
                    onMouseOver={() => {
                      const columnName = 'Market Growth | Transaction Date to Valuation Date';
                      if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                    }}
                    onMouseLeave={() => setColumnHeaderHovered('')}
                  >
                    <div className="sub-header-title">
                      <h6>Transaction date</h6>
                      <span>to valuation date</span>
                    </div>
                    <SortingChevron
                      sortedColumn="Market Growth | Transaction Date to Valuation Date"
                      columnSorting={columnSorting}
                      setColumnSorting={setColumnSorting}
                      columnHeaderHovered={columnHeaderHovered}
                    />
                  </div>
                </div>
              )}
              {(!filterSelected || showRevenue) && (
                <div className="sub-header-group revenue">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div
                          key={fiscalYear.title}
                          className="sub-header"
                          onMouseOver={() => {
                            const columnName = `Revenue | ${fiscalYear.legend.replace(' ', '')}`;
                            if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                          }}
                          onMouseLeave={() => setColumnHeaderHovered('')}
                        >
                          <div className="sub-header-title">
                            <h6>{fiscalYear.title}</h6>
                            <span>{`${fiscalYear.legend} ($)`}</span>
                          </div>
                          <SortingChevron
                            sortedColumn={`Revenue | ${fiscalYear.legend.replace(' ', '')}`}
                            columnSorting={columnSorting}
                            setColumnSorting={setColumnSorting}
                            columnHeaderHovered={columnHeaderHovered}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || showEBITDA) && (
                <div className="sub-header-group EBITDA">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div
                          key={fiscalYear.title}
                          className="sub-header"
                          onMouseOver={() => {
                            const columnName = `EBITDA | ${fiscalYear.legend.replace(' ', '')}`;
                            if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                          }}
                          onMouseLeave={() => setColumnHeaderHovered('')}
                        >
                          <div className="sub-header-title">
                            <h6>{fiscalYear.title}</h6>
                            <span>{`${fiscalYear.legend} ($)`}</span>
                          </div>
                          <SortingChevron
                            sortedColumn={`EBITDA | ${fiscalYear.legend.replace(' ', '')}`}
                            columnSorting={columnSorting}
                            setColumnSorting={setColumnSorting}
                            columnHeaderHovered={columnHeaderHovered}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || showGrossProfit) && (
                <div className="sub-header-group gross-profit">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div
                          key={fiscalYear.title}
                          className="sub-header"
                          onMouseOver={() => {
                            const columnName = `Gross Profit | ${fiscalYear.legend.replace(' ', '')}`;
                            if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                          }}
                          onMouseLeave={() => setColumnHeaderHovered('')}
                        >
                          <div className="sub-header-title">
                            <h6>{fiscalYear.title}</h6>
                            <span>{`${fiscalYear.legend} ($)`}</span>
                          </div>
                          <SortingChevron
                            sortedColumn={`Gross Profit | ${fiscalYear.legend.replace(' ', '')}`}
                            columnSorting={columnSorting}
                            setColumnSorting={setColumnSorting}
                            columnHeaderHovered={columnHeaderHovered}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Revenue growth')) && (
                <div className="sub-header-group revenue-growth">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div
                          key={fiscalYear.title}
                          className="sub-header"
                          onMouseOver={() => {
                            const columnName = `Revenue Growth Rate | ${fiscalYear.legend.replace(' ', '')}`;
                            if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                          }}
                          onMouseLeave={() => setColumnHeaderHovered('')}
                        >
                          <div className="sub-header-title">
                            <h6>{fiscalYear.title}</h6>
                            <span>{`${fiscalYear.legend} ($)`}</span>
                          </div>
                          <SortingChevron
                            sortedColumn={`Revenue Growth Rate | ${fiscalYear.legend.replace(' ', '')}`}
                            columnSorting={columnSorting}
                            setColumnSorting={setColumnSorting}
                            columnHeaderHovered={columnHeaderHovered}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('EBITDA growth')) && (
                <div className="sub-header-group EBITDA-growth">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div
                          key={fiscalYear.title}
                          className="sub-header"
                          onMouseOver={() => {
                            const columnName = `EBITDA Growth Rate | ${fiscalYear.legend.replace(' ', '')}`;
                            if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                          }}
                          onMouseLeave={() => setColumnHeaderHovered('')}
                        >
                          <div className="sub-header-title">
                            <h6>{fiscalYear.title}</h6>
                            <span>{`${fiscalYear.legend} ($)`}</span>
                          </div>
                          <SortingChevron
                            sortedColumn={`EBITDA Growth Rate | ${fiscalYear.legend.replace(' ', '')}`}
                            columnSorting={columnSorting}
                            setColumnSorting={setColumnSorting}
                            columnHeaderHovered={columnHeaderHovered}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Gross Profit growth')) && (
                <div className="sub-header-group gross-profit-growth">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div
                          key={fiscalYear.title}
                          className="sub-header"
                          onMouseOver={() => {
                            const columnName = `Gross Profit Growth Rate | ${fiscalYear.legend.replace(' ', '')}`;
                            if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                          }}
                          onMouseLeave={() => setColumnHeaderHovered('')}
                        >
                          <div className="sub-header-title">
                            <h6>{fiscalYear.title}</h6>
                            <span>{`${fiscalYear.legend} ($)`}</span>
                          </div>
                          <SortingChevron
                            sortedColumn={`Gross Profit Growth Rate | ${fiscalYear.legend.replace(' ', '')}`}
                            columnSorting={columnSorting}
                            setColumnSorting={setColumnSorting}
                            columnHeaderHovered={columnHeaderHovered}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || multiplesFilterValues.includes('Revenue multiples')) && (
                <div className="sub-header-group revenue-multiples">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div
                          key={fiscalYear.title}
                          className="sub-header"
                          onMouseOver={() => {
                            const columnName = `Revenue Multiple${fiscalYear.legend.includes('NTM') ? 's' : ''} | ` +
                              `${fiscalYear.legend.replace(' ', '')}`;
                            if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                          }}
                          onMouseLeave={() => setColumnHeaderHovered('')}
                        >
                          <div className="sub-header-title">
                            <h6>{fiscalYear.title}</h6>
                            <span>{`${fiscalYear.legend} ($)`}</span>
                          </div>
                          <SortingChevron
                            sortedColumn={`Revenue Multiple${fiscalYear.legend.includes('NTM') ? 's' : ''} | ${fiscalYear.legend.replace(' ', '')}`}
                            columnSorting={columnSorting}
                            setColumnSorting={setColumnSorting}
                            columnHeaderHovered={columnHeaderHovered}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || multiplesFilterValues.includes('EBITDA multiples')) && (
                <div className="sub-header-group EBITDA-multiples">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div
                          key={fiscalYear.title}
                          className="sub-header"
                          onMouseOver={() => {
                            const columnName = `EBITDA Multiple${fiscalYear.legend.includes('NTM') ? 's' : ''} | ` +
                              `${fiscalYear.legend.replace(' ', '')}`;
                            if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                          }}
                          onMouseLeave={() => setColumnHeaderHovered('')}
                        >
                          <div className="sub-header-title">
                            <h6>{fiscalYear.title}</h6>
                            <span>{`${fiscalYear.legend} ($)`}</span>
                          </div>
                          <SortingChevron
                            sortedColumn={`EBITDA Multiple${fiscalYear.legend.includes('NTM') ? 's' : ''} | ${fiscalYear.legend.replace(' ', '')}`}
                            columnSorting={columnSorting}
                            setColumnSorting={setColumnSorting}
                            columnHeaderHovered={columnHeaderHovered}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('EBITDA Margin')) && (
                <div className="sub-header-group EBITDA-margin">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div
                          key={fiscalYear.title}
                          className="sub-header"
                          onMouseOver={() => {
                            const columnName = `EBITDA Margin | ${fiscalYear.legend.replace(' ', '')}`;
                            if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                          }}
                          onMouseLeave={() => setColumnHeaderHovered('')}
                        >
                          <div className="sub-header-title">
                            <h6>{fiscalYear.title}</h6>
                            <span>{`${fiscalYear.legend} ($)`}</span>
                          </div>
                          <SortingChevron
                            sortedColumn={`EBITDA Margin | ${fiscalYear.legend.replace(' ', '')}`}
                            columnSorting={columnSorting}
                            setColumnSorting={setColumnSorting}
                            columnHeaderHovered={columnHeaderHovered}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Gross Margin')) && (
                <div className="sub-header-group gross-margin">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div
                          key={fiscalYear.title}
                          className="sub-header"
                          onMouseOver={() => {
                            const columnName = `Gross Margin | ${fiscalYear.legend.replace(' ', '')}`;
                            if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                          }}
                          onMouseLeave={() => setColumnHeaderHovered('')}
                        >
                          <div className="sub-header-title">
                            <h6>{fiscalYear.title}</h6>
                            <span>{`${fiscalYear.legend} ($)`}</span>
                          </div>
                          <SortingChevron
                            sortedColumn={`Gross Margin | ${fiscalYear.legend.replace(' ', '')}`}
                            columnSorting={columnSorting}
                            setColumnSorting={setColumnSorting}
                            columnHeaderHovered={columnHeaderHovered}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="comp-table-rows subject-company" ref={subjectCompanyScroll}>
            <div className="comp-table-row-data even">
              {(!filterSelected || showCompanyInfo || tabToView === 4) && (
                <div className="row-data-group basic-info">
                  <div className="table-cell">{compData.Identifier[0]}</div>
                  <div className="table-cell">{compData.Ticker[0]}</div>
                  {tabToView === 5 && (
                    <>
                      <div className="table-cell">{compData.Industry[0]}</div>
                      {/* <div className="table-cell">xx/xx/xxxx</div> */}
                      <div className="table-cell">{compData['Last FYE Date'][0] || '-'}</div>
                      <div className="table-cell">{compData['IPO Date'][0] || '-'}</div>
                      <div className="table-cell">-</div>
                      <div className="table-cell">-</div>
                    </>
                  )}
                </div>
              )}
              {tabToView === 4 && (!filterSelected || showVolatilityValDate) && (
                <div className="row-data-group val-volatility sub-comp">
                  {[...Array(40)].map((el, dataIndex) => (
                    (!yearsFilterValues.length || yearsFilterValues.includes(dataIndex / 4 + 0.25 <= 1 ?
                      `${dataIndex / 4 + 0.25} year` : `${dataIndex / 4 + 0.25} years`)) && (
                      <div key={getYear(dataIndex)} className="table-cell">
                        {compData[`Volatility - Valuation Date | ${getYear(dataIndex)}`][0] ?
                          `${((compData[`Volatility - Valuation Date | ${getYear(dataIndex)}`][0]) * 100).toFixed(2)}%` : '-'}
                      </div>
                    )))}
                </div>
              )}
              {tabToView === 4 && (!filterSelected || showVolatilityTrDate) && (
                <div className="row-data-group tr-volatility sub-comp">
                  {[...Array(40)].map((el, dataIndex) => (
                    (!yearsFilterValues.length || yearsFilterValues.includes(dataIndex / 4 + 0.25 <= 1 ?
                      `${dataIndex / 4 + 0.25} year` : `${dataIndex / 4 + 0.25} years`)) && (
                      <div key={getYear(dataIndex)} className="table-cell">
                        {compData[`Volatility - Transaction Date | ${getYear(dataIndex)}`][0] ?
                          `${((compData[`Volatility - Transaction Date | ${getYear(dataIndex)}`][0]) * 100).toFixed(2)}%` : '-'}
                      </div>
                    )
                  ))}
                </div>
              )}
              {(!filterSelected || showMarketCap) && (
                <div className="row-data-group market-cap sub-comp">
                  <div className="table-cell">{getPrice(`Market Capitalization | Transaction Date - ${transactionDate}`, 0)}</div>
                  <div className="table-cell">{getPrice(`Market Capitalization | Valuation Date - ${valuationDate}`, 0)}</div>
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Market growth')) && (
                <div className="row-data-group market-growth sub-comp">
                  <div className="table-cell">{getPerc('Market Growth | Transaction Date to Valuation Date', 0)}</div>
                </div>
              )}
              {(!filterSelected || showRevenue) && (
                <div className="row-data-group revenue sub-comp">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getPrice(`Revenue | ${fiscalYear.legend.replace(' ', '')}`, 0)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || showEBITDA) && (
                <div className="row-data-group EBITDA sub-comp">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getPrice(`EBITDA | ${fiscalYear.legend.replace(' ', '')}`, 0)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || showGrossProfit) && (
                <div className="row-data-group gross-profit sub-comp">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getPrice(`Gross Profit | ${fiscalYear.legend.replace(' ', '')}`, 0)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Revenue growth')) && (
                <div className="row-data-group revenue-growth sub-comp">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getPerc(`Revenue Growth Rate | ${fiscalYear.legend.replace(' ', '')}`, 0)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('EBITDA growth')) && (
                <div className="row-data-group EBITDA-growth sub-comp">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getPerc(`EBITDA Growth Rate | ${fiscalYear.legend.replace(' ', '')}`, 0)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Gross Profit growth')) && (
                <div className="row-data-group gross-profit-growth sub-comp">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getPerc(`Gross Profit Growth Rate | ${fiscalYear.legend.replace(' ', '')}`, 0)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || multiplesFilterValues.includes('Revenue multiples')) && (
                <div className="row-data-group revenue-multiples sub-comp">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getMultiple(`Revenue Multiple${fiscalYear.legend.includes('NTM') ? 's' : ''} | ` +
                            `${fiscalYear.legend.replace(' ', '')}`, 0)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || multiplesFilterValues.includes('EBITDA multiples')) && (
                <div className="row-data-group EBITDA-multiples sub-comp">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getMultiple(`EBITDA Multiple${fiscalYear.legend.includes('NTM') ? 's' : ''} | ${fiscalYear.legend.replace(' ', '')}`, 0)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('EBITDA Margin')) && (
                <div className="row-data-group EBITDA-margin sub-comp">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getPerc(`EBITDA Margin | ${fiscalYear.legend.replace(' ', '')}`, 0)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Gross Margin')) && (
                <div className="row-data-group gross-margin sub-comp">
                  {fiscalYears.map((fiscalYear) => {
                    if (!fiscalPeriodFilterValues.length ||
                      fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getPerc(`Gross Margin | ${fiscalYear.legend.replace(' ', '')}`, 0, true)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="comp-table-rows" ref={compTableRightRef}>
            {compData.Identifier?.length && compData.Identifier.map((comp, index) => (
              <div key={`${compData.Identifier[index] + Math.random(1, 100) * Math.random(1, 100)}`}>
                {index !== 0 && (
                  <>
                    <div className={rowClassName(index)}>
                      {(!filterSelected || showCompanyInfo || tabToView === 4) && index !== 0 && (
                        <div className="row-data-group basic-info">
                          <div className="table-cell">{compData.Identifier[index]}</div>
                          <div className="table-cell">{compData.Ticker[index]}</div>
                          {tabToView === 5 && (
                            <>
                              <div className="table-cell industry">
                                {compData.Industry[index].length >= 18 ? (
                                  <Tooltip title={compData.Industry[index]} placement="top">
                                    <span>{`${compData.Industry[index].slice(0, 18).trim()}...`}</span>
                                  </Tooltip>
                                ) : compData.Industry[index]}
                              </div>
                              {/* <div className="table-cell">xx/xx/xxxx</div> */}
                              <div className="table-cell">{compData['Last FYE Date'][index] || '-'}</div>
                              <div className="table-cell">{compData['IPO Date'][index] || '-'}</div>
                              <div className="table-cell">
                                <Button
                                  onMouseDown={(e) => {
                                    if (descriptionsToShow[index] !== 'short') {
                                      const newDescriptionToShow = [...descriptionsToShow];
                                      newDescriptionToShow[index] = 'short';
                                      setDescriptionsToShow(newDescriptionToShow);
                                    } else {
                                      const newDescriptionToShow = [...descriptionsToShow];
                                      newDescriptionToShow[index] = '';
                                      setDescriptionsToShow(newDescriptionToShow);
                                    }
                                    if (!e.target.className.includes('Mui-focusVisible')) e.preventDefault();
                                  }}
                                  onFocus={() => setShortDescBtnFocused(index)}
                                  onBlur={() => setShortDescBtnFocused(-1)}
                                  onMouseOver={() => { if (shortDescBtnHovered !== index) setShortDescBtnHovered(index); }}
                                  onMouseLeave={() => setShortDescBtnHovered(-1)}
                                >
                                  {descriptionsToShow[index] === 'short' ? 'Hide' : 'View'}
                                </Button>
                              </div>
                              <div className="table-cell">
                                <Button
                                  onMouseDown={(e) => {
                                    if (descriptionsToShow[index] !== 'long') {
                                      const newDescriptionToShow = [...descriptionsToShow];
                                      newDescriptionToShow[index] = 'long';
                                      setDescriptionsToShow(newDescriptionToShow);
                                    } else {
                                      const newDescriptionToShow = [...descriptionsToShow];
                                      newDescriptionToShow[index] = '';
                                      setDescriptionsToShow(newDescriptionToShow);
                                    }
                                    if (!e.target.className.includes('Mui-focusVisible')) e.preventDefault();
                                  }}
                                  onFocus={() => setLongDescBtnFocused(index)}
                                  onBlur={() => setLongDescBtnFocused(-1)}
                                  onMouseOver={() => { if (longDescBtnHovered !== index) setLongDescBtnHovered(index); }}
                                  onMouseLeave={() => setLongDescBtnHovered(-1)}
                                >
                                  {descriptionsToShow[index] === 'long' ? 'Hide' : 'View'}
                                </Button>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {tabToView === 4 && (!filterSelected || showVolatilityValDate) && index !== 0 && (
                        <div className="row-data-group val-volatility">
                          {[...Array(40)].map((el, dataIndex) => (
                            (!yearsFilterValues.length || yearsFilterValues.includes(dataIndex / 4 + 0.25 <= 1 ?
                              `${dataIndex / 4 + 0.25} year` : `${dataIndex / 4 + 0.25} years`)) && (
                              <div key={getYear(dataIndex)} className="table-cell">
                                {compData[`Volatility - Valuation Date | ${getYear(dataIndex)}`][index] ?
                                  `${((compData[`Volatility - Valuation Date | ${getYear(dataIndex)}`][index]) * 100).toFixed(2)}%` : '-'}
                              </div>
                            )))}
                        </div>
                      )}
                      {tabToView === 4 && (!filterSelected || showVolatilityTrDate) && index !== 0 && (
                        <div className="row-data-group tr-volatility">
                          {[...Array(40)].map((el, dataIndex) => (
                            (!yearsFilterValues.length || yearsFilterValues.includes(dataIndex / 4 + 0.25 <= 1 ?
                              `${dataIndex / 4 + 0.25} year` : `${dataIndex / 4 + 0.25} years`)) && (
                              <div key={getYear(dataIndex)} className="table-cell">
                                {compData[`Volatility - Transaction Date | ${getYear(dataIndex)}`][index] ?
                                  `${((compData[`Volatility - Transaction Date | ${getYear(dataIndex)}`][index]) * 100).toFixed(2)}%` : '-'}
                              </div>
                            )
                          ))}
                        </div>
                      )}
                      {(!filterSelected || showMarketCap) && index !== 0 && (
                        <div className="row-data-group market-cap">
                          <div className="table-cell">{getPrice(`Market Capitalization | Transaction Date - ${transactionDate}`, index)}</div>
                          <div className="table-cell">{getPrice(`Market Capitalization | Valuation Date - ${valuationDate}`, index)}</div>
                        </div>
                      )}
                      {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Market growth')) && index !== 0 && (
                        <div className="row-data-group market-growth">
                          <div className="table-cell">{getPerc('Market Growth | Transaction Date to Valuation Date', index)}</div>
                        </div>
                      )}
                      {(!filterSelected || showRevenue) && index !== 0 && (
                        <div className="row-data-group revenue">
                          {fiscalYears.map((fiscalYear) => {
                            if (!fiscalPeriodFilterValues.length ||
                              fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                                .replace('12', 'twelve') === fiscalYear.title)) {
                              return (
                                <div key={fiscalYear.title} className="table-cell">
                                  {getPrice(`Revenue | ${fiscalYear.legend.replace(' ', '')}`, index)}
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      )}
                      {tabToView === 5 && (!filterSelected || showEBITDA) && index !== 0 && (
                        <div className="row-data-group EBITDA">
                          {fiscalYears.map((fiscalYear) => {
                            if (!fiscalPeriodFilterValues.length ||
                              fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                                .replace('12', 'twelve') === fiscalYear.title)) {
                              return (
                                <div key={fiscalYear.title} className="table-cell">
                                  {getPrice(`EBITDA | ${fiscalYear.legend.replace(' ', '')}`, index)}
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      )}
                      {tabToView === 5 && (!filterSelected || showGrossProfit) && index !== 0 && (
                        <div className="row-data-group gross-profit">
                          {fiscalYears.map((fiscalYear) => {
                            if (!fiscalPeriodFilterValues.length ||
                              fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                                .replace('12', 'twelve') === fiscalYear.title)) {
                              return (
                                <div key={fiscalYear.title} className="table-cell">
                                  {getPrice(`Gross Profit | ${fiscalYear.legend.replace(' ', '')}`, index)}
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      )}
                      {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Revenue growth')) && index !== 0 && (
                        <div className="row-data-group revenue-growth">
                          {fiscalYears.map((fiscalYear) => {
                            if (!fiscalPeriodFilterValues.length ||
                              fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                                .replace('12', 'twelve') === fiscalYear.title)) {
                              return (
                                <div key={fiscalYear.title} className="table-cell">
                                  {getPerc(`Revenue Growth Rate | ${fiscalYear.legend.replace(' ', '')}`, index)}
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      )}
                      {tabToView === 5 && (!filterSelected || growthFilterValues.includes('EBITDA growth')) && index !== 0 && (
                        <div className="row-data-group EBITDA-growth">
                          {fiscalYears.map((fiscalYear) => {
                            if (!fiscalPeriodFilterValues.length ||
                              fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                                .replace('12', 'twelve') === fiscalYear.title)) {
                              return (
                                <div key={fiscalYear.title} className="table-cell">
                                  {getPerc(`EBITDA Growth Rate | ${fiscalYear.legend.replace(' ', '')}`, index)}
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      )}
                      {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Gross Profit growth')) && index !== 0 && (
                        <div className="row-data-group gross-profit-growth">
                          {fiscalYears.map((fiscalYear) => {
                            if (!fiscalPeriodFilterValues.length ||
                              fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                                .replace('12', 'twelve') === fiscalYear.title)) {
                              return (
                                <div key={fiscalYear.title} className="table-cell">
                                  {getPerc(`Gross Profit Growth Rate | ${fiscalYear.legend.replace(' ', '')}`, index)}
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      )}
                      {tabToView === 5 && (!filterSelected || multiplesFilterValues.includes('Revenue multiples')) && index !== 0 && (
                        <div className="row-data-group revenue-multiples">
                          {fiscalYears.map((fiscalYear) => {
                            if (!fiscalPeriodFilterValues.length ||
                              fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                                .replace('12', 'twelve') === fiscalYear.title)) {
                              return (
                                <div key={fiscalYear.title} className="table-cell">
                                  {getMultiple(`Revenue Multiple${fiscalYear.legend.includes('NTM') ? 's' : ''} | ` +
                                    `${fiscalYear.legend.replace(' ', '')}`, index)}
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      )}
                      {tabToView === 5 && (!filterSelected || multiplesFilterValues.includes('EBITDA multiples')) && index !== 0 && (
                        <div className="row-data-group EBITDA-multiples">
                          {fiscalYears.map((fiscalYear) => {
                            if (!fiscalPeriodFilterValues.length ||
                              fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                                .replace('12', 'twelve') === fiscalYear.title)) {
                              return (
                                <div key={fiscalYear.title} className="table-cell">
                                  {getMultiple(`EBITDA Multiple${fiscalYear.legend.includes('NTM') ? 's' : ''} | ` +
                                    `${fiscalYear.legend.replace(' ', '')}`, index)}
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      )}
                      {tabToView === 5 && (!filterSelected || growthFilterValues.includes('EBITDA Margin')) && index !== 0 && (
                        <div className="row-data-group EBITDA-margin">
                          {fiscalYears.map((fiscalYear) => {
                            if (!fiscalPeriodFilterValues.length ||
                              fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                                .replace('12', 'twelve') === fiscalYear.title)) {
                              return (
                                <div key={fiscalYear.title} className="table-cell">
                                  {getPerc(`EBITDA Margin | ${fiscalYear.legend.replace(' ', '')}`, index)}
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      )}
                      {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Gross Margin')) && index !== 0 && (
                        <div className="row-data-group gross-margin">
                          {fiscalYears.map((fiscalYear) => {
                            if (!fiscalPeriodFilterValues.length ||
                              fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                                .replace('12', 'twelve') === fiscalYear.title)) {
                              return (
                                <div key={fiscalYear.title} className="table-cell">
                                  {getPerc(`Gross Margin | ${fiscalYear.legend.replace(' ', '')}`, index, true)}
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      )}
                    </div>
                    <AnimateHeight
                      duration={300}
                      height={(descriptionsToShow[index] === 'long' || descriptionsToShow[index] === 'short') ? 'auto' : 0}
                    >
                      {descriptionsToShow[index] === 'short' && (
                        <div
                          ref={descriptionsRef.current[index]}
                          className={`short-comp-description${(index + 1) % 2 === 0 ? ' even' : ' odd'}`}
                        >
                          <p
                            className={`description${shortDescBtnFocused === index ? ' focused' : ''}` +
                              `${shortDescBtnHovered === index ? ' hovered' : ''}`}
                          >
                            {compData['Short Description'][index]}
                          </p>
                        </div>
                      )}
                      {descriptionsToShow[index] === 'long' && (
                        <div
                          ref={descriptionsRef.current[index]}
                          className={`long-comp-description${(index + 1) % 2 === 0 ? ' even' : ' odd'}`}
                        >
                          <p
                            className={`description${longDescBtnFocused === index ? ' focused' : ''}` +
                              `${longDescBtnHovered === index ? ' hovered' : ''}`}
                          >
                            {compData['Long Description'][index]}
                          </p>
                        </div>
                      )}
                    </AnimateHeight>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="table-footer">
        <Button disabled={runningComps} onClick={() => setShowAddCompModal(true)}>
          <AddIcon />
          Add Companies
        </Button>
      </div>
      <Dialog open={showConfirmationModal >= 0 || showConfirmationModal === 'all'} className="active-comp-confirmation-modal">
        <div className="dialog-header">
          <WarningAmberRoundedIcon />
          <span>Are you sure?</span>
        </div>
        {showConfirmationModal === 'all' ? (
          <>
            <p>Some companies were originally removed from the Volatility tab.</p>
            <p>If you add them back here, you&apos;ll also automatically add them back to the Volatility tab&apos;s Company list.</p>
          </>
        ) : (
          <>
            <p>This company was originally removed from the Volatility tab.</p>
            <p>If you add it back here, you&apos;ll also automatically add it back to the Volatility tab&apos;s Company list.</p>
          </>
        )}
        <div className="bottom-btns">
          <Button onClick={() => setShowConfirmationModal(-1)}>Cancel</Button>
          <Button
            onClick={() => {
              setShowConfirmationModal(-1);
              reSummarize(showConfirmationModal, 'add-both');
            }}
          >
            Add to both tab
          </Button>
        </div>
      </Dialog>
    </main>
  );
}

CompTable.propTypes = {
  tabToView: PropTypes.number.isRequired,
  filterSelected: PropTypes.bool.isRequired,
  showCompanyInfo: PropTypes.bool.isRequired,
  showRevenue: PropTypes.bool.isRequired,
  showEBITDA: PropTypes.bool.isRequired,
  showGrossProfit: PropTypes.bool.isRequired,
  showMarketCap: PropTypes.bool.isRequired,
  showVolatilityValDate: PropTypes.bool.isRequired,
  showVolatilityTrDate: PropTypes.bool.isRequired,
  growthFilterValues: PropTypes.array.isRequired,
  multiplesFilterValues: PropTypes.array.isRequired,
  yearsFilterValues: PropTypes.array.isRequired,
  setShowAddCompModal: PropTypes.func.isRequired,
  compData: PropTypes.object.isRequired,
  setCompData: PropTypes.func.isRequired,
  headerScroll: PropTypes.object.isRequired,
  subjectCompanyScroll: PropTypes.object.isRequired,
  compTableLeftRef: PropTypes.func.isRequired,
  compTableRightRef: PropTypes.func.isRequired,
  valuationDate: PropTypes.string.isRequired,
  transactionDate: PropTypes.string.isRequired,
  reSummarize: PropTypes.func.isRequired,
  fiscalPeriodFilterValues: PropTypes.array.isRequired,
  viewAllLongDescriptions: PropTypes.bool.isRequired,
  viewAllShortDescriptions: PropTypes.bool.isRequired,
  runningComps: PropTypes.bool.isRequired,
};
