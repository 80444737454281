import React, {
  useState,
  useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Storage } from 'aws-amplify';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';

import { ErrorMessageContext } from '../../../lib/contextLib';

import { copy } from '../../../utils';

export default function UploadTile({
  uploadData,
  setUploadData,
  isEditing,
  portfolioCompanyId,
  transactionId,
  genInfo,
}) {
  const [fileUploading, setFileUploading] = useState(false);
  const [fileValue, setFileValue] = useState('');

  const [uploadProgress, setUploadProgress] = useState(null);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [readyToUpload, setReadyToUpload] = useState(true);

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const uploadEl = useRef(null);

  async function getS3Link(file) {
    window.open(await Storage.get(file.location, {
      customPrefix: { public: '820Uploads' },
      bucket: `${process.env.REACT_APP_UPLOADS_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`,
    }));
  }

  async function handleUpload(event, fileToUpload) {
    event.preventDefault();
    if (!fileToUpload) return;
    if (fileToUpload.size >= 35000000) {
      setShowErrorMessage('File size is too big');
      return;
    }
    // TODO Handle different file types here...
    setFileUploading(true);
    setReadyToUpload(false);
    try {
      const fileName = fileToUpload.name;
      const dateTime = moment().format('YYYY-MM-DD');
      const location = `/${portfolioCompanyId}/${transactionId}/${dateTime}-${fileName}`;
      const newFileList = copy(uploadData);
      newFileList.push({ fileName, dateTime, location });
      setUploadData(newFileList);
      await Storage.put(location, fileToUpload, {
        customPrefix: { public: '820Uploads' },
        bucket: `${process.env.REACT_APP_UPLOADS_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`,
        progressCallback(progress) { setUploadProgress(progress); },
      });
      setTimeout(() => {
        setUploadCompleted(true);
        setUploadProgress(null);
        setFileUploading(false);
      }, 1000);
      setTimeout(() => {
        setUploadCompleted(false);
        setReadyToUpload(true);
      }, 3000);
    } catch (e) {
      setShowErrorMessage(e.toString());
      setReadyToUpload(true);
      setUploadProgress(null);
      setFileUploading(false);
    }
  }

  async function removeFile(file, fileIndex) {
    await Storage.remove(`/${portfolioCompanyId}/${transactionId}/${file.dateTime}-${file.fileName}`, {
      customPrefix: { public: '820Uploads' },
      bucket: `${process.env.REACT_APP_UPLOADS_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`,
    });
    const newFileList = copy(uploadData);
    newFileList.splice(fileIndex, 1);
    setUploadData(newFileList);
  }

  return (
    <div className="upload-tile">
      <div className="uploaded-files">
        {uploadData.map((file, fileIndex) => (
          <div className={`file${uploadCompleted ? ' completed' : ''}`} key={`${file.fileName}-${file.dateTime}`}>
            {isEditing ? (
              <div className="file-and-progress">
                <div className="file-name">
                  {uploadCompleted && fileIndex === uploadData.length - 1 && <CheckCircleOutlineIcon />}
                  {fileUploading && fileIndex === uploadData.length - 1 && !uploadCompleted && <div className="dots-circle-spinner" />}
                  <div>
                    <span>
                      {uploadCompleted && fileIndex === uploadData.length - 1 ? 'Uploaded ' :
                        fileUploading && fileIndex === uploadData.length - 1 ?
                          'Uploading ' : ''}
                    </span>
                    {genInfo ? <InsertDriveFileOutlinedIcon /> : ''}
                    {(file.fileName.length >= 30) ? `${file.fileName.slice(0, 30).trim()}...` : file.fileName}
                  </div>
                </div>
                {fileUploading && fileIndex === uploadData.length - 1 && (
                  <LinearProgress variant="determinate" value={uploadProgress ? ((uploadProgress.loaded / uploadProgress.total) * 100) : 0} />
                )}
              </div>
            ) : (
              <Button onClick={() => getS3Link(file)}>
                {genInfo ? <InsertDriveFileOutlinedIcon /> : ''}
                {(file.fileName.length >= 30) ? `${file.fileName.slice(0, 30).trim()}...` : file.fileName}
              </Button>
            )}
            {isEditing && readyToUpload && (
              <IconButton
                className="clear-icon"
                onClick={() => removeFile(file, fileIndex)}
              >
                <DeleteOutlineIcon />
                {genInfo ? 'Delete file' : ''}
              </IconButton>
            )}
          </div>
        ))}
      </div>
      {isEditing && readyToUpload && (
        <>
          <Button
            className="upload-button"
            onClick={() => {
              setFileValue('');
              uploadEl.current.click();
            }}
          >
            <UploadOutlinedIcon />
            {genInfo ? 'Upload file' : 'Upload new file(s)'}
          </Button>
          <input
            ref={uploadEl}
            type="file"
            accept=".xlsx,.xls,.xlsm,.doc,.docx,.ppt,.pptx,.pps,.ppsx,.txt,.pdf,.csv,.jpg,.jpeg,.heic,.png"
            className="file-style"
            value={fileValue}
            style={{ display: 'none' }}
            onChange={(e) => {
              e.preventDefault();
              handleUpload(e, e.target.files[0]);
            }}
          />
        </>
      )}
    </div>
  );
}

UploadTile.propTypes = {
  uploadData: PropTypes.array.isRequired,
  setUploadData: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  portfolioCompanyId: PropTypes.string.isRequired,
  transactionId: PropTypes.string.isRequired,
  genInfo: PropTypes.bool,
};

UploadTile.defaultProps = {
  genInfo: false,
};
