import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { v4 as uuidv4 } from 'uuid';
import { Button, Dialog, IconButton } from '@mui/material';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorMessageContext } from '../lib/contextLib';

import AddCompanyBlock from './AddCompanyBlock';

import './AddCompanyToProject.scss';
import { createFetchHeaders } from '../utils/apiCalls';
import { getUserCompanyId, getUserId } from '../utils/auth';
import { copy } from '../utils';

export default function AddCompanyToProject({
  addCompany,
  setAddCompany,
  projectId,
  investorCompanyId,
  setCompanies,
  companies,
  enterpriseCompanyIdent,
  numberOfCompanies,
  setNumberOfCompanies,
  investor,
  portfolioCompaniesNames,
  portfolioCompanies,
}) {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);
  const [projectCompanies, setProjectCompanies] = useState([]);
  const [addingCompanies, setAddingCompanies] = useState(false);
  const [blockHasError, setBlockHasError] = useState(false);
  const [companyNameErrorList, setCompanyNameErrorList] = useState([]);
  const [portfolioCompanyData, setPortfolioCompanyData] = useState(portfolioCompanies || {});
  const [portfolioNames, setPortfolioNames] = useState(portfolioCompaniesNames || []);

  async function getPortfolioCompanies() {
    try {
      const requestUserId = getUserId();
      const enterpriseCompanyId = await getUserCompanyId();
      let dbCompanyResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/companies/get_investor_and_portfolio_companies/${enterpriseCompanyId}&${requestUserId}`,
        await createFetchHeaders('get', {}, true),
      );
      dbCompanyResponse = await dbCompanyResponse.json();
      setPortfolioCompanyData(dbCompanyResponse.portfolioCompanies);
      setPortfolioNames(Object.keys(dbCompanyResponse.portfolioCompanies));
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  async function updateProjectCompanies() {
    try {
      setAddingCompanies(true);
      if (!blockHasError) {
        const requestUserId = getUserId();
        const enterpriseCompanyId = investor ? enterpriseCompanyIdent : getUserCompanyId();
        if (!investor) {
          const newCompanyCount = numberOfCompanies + projectCompanies.length;
          const updateProjectCardData = {
            enterpriseCompanyId,
            investorCompanyId,
            companyId: enterpriseCompanyId,
            resource: `project_card_${projectId}`,
            payload: { numberOfCompanies: newCompanyCount },
            requestUserId,
          };
          await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/objects/update-object`,
            await createFetchHeaders('post', updateProjectCardData, true),
          );
        }
        const updatedCompanies = projectCompanies.map((company) => {
          const companyCopy = { ...company };
          if (portfolioCompanyData[companyCopy.companyName]) {
            companyCopy.companyId = portfolioCompanyData[companyCopy.companyName].companyId;
            companyCopy.capIqIdentifier = portfolioCompanyData[companyCopy.companyName].capIqIdentifier;
          } else {
            companyCopy.companyId = '';
          }
          return companyCopy;
        });
        const projectPortfolioCompanyUpdateData = {
          enterpriseCompanyId,
          requestUserId,
          projectId,
          investorCompanyId,
          companies: updatedCompanies,
          assignees: {},
        };
        let response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/companies/portfolio/new-portfolio-companies`,
          await createFetchHeaders('post', projectPortfolioCompanyUpdateData, true),
        );
        if (response.ok) {
          response = await response.json();
          const companiesCopy = copy(companies);
          if (!investor) {
            setCompanies([...companiesCopy, ...response.transactionCards]);
          } else {
            const newPortfolioCompanies = [...companiesCopy];
            response.transactionCards.forEach((company) => {
              const newCompany = {
                companyIndex: newPortfolioCompanies.length,
                companyName: company.portfolioCompanyName,
                portfolioCompanyId: company.portfolioCompanyId,
                transactionId: company.transactionId,
                status: company.status,
                submittedByUser: company.submittedByUser,
                isLocked: company.isLocked,
              };
              newPortfolioCompanies.push(newCompany);
            });
            setCompanies(newPortfolioCompanies);
          }
          setAddCompany(false);
          setProjectCompanies([]);
          setCompanyNameErrorList([]);
          setBlockHasError(false);
        }
        setAddingCompanies(false);
        setNumberOfCompanies(numberOfCompanies + projectCompanies.length);
      }
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  async function checkCompanyNames() {
    const errorArray = [];
    projectCompanies.forEach((company) => {
      if (company.companyName.length < 1) errorArray.push(true);
      else errorArray.push(false);
    });
    if (errorArray.includes(true)) setBlockHasError(true);
    else {
      setBlockHasError(false);
      await updateProjectCompanies();
    }
    setCompanyNameErrorList(errorArray);
  }

  useEffect(() => {
    if (!portfolioCompaniesNames.length || !portfolioCompanies) {
      getPortfolioCompanies();
    }
  }, [portfolioCompaniesNames, portfolioCompanies]);

  return (
    <Dialog
      className="AddCompanyToProject"
      open={addCompany}
      disableScrollLock
    >
      <div className="add-companies-block">
        <div className="header-intro-wrapper">
          <StorefrontIcon />
          <div className="header-text-wrapper">
            <h4>Add companies to this project</h4>
            <p>
              Companies you add here will be added to
              {!investor ? 'both' : ' '}
              your company list
              {!investor ?
                ' and to the form that was sent to your client.' +
                " They'll be able to input information about these companies as they fill out their form." :
                '.'}
            </p>
          </div>
          <IconButton
            className="close-icon"
            onClick={() => {
              setAddCompany(false);
              setProjectCompanies([]);
              setCompanyNameErrorList([]);
              setBlockHasError(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {projectCompanies && (
          <div className="company-block-wrapper">
            {projectCompanies.map((company, index) => (
              <AddCompanyBlock
                key={company.companyId}
                projectCompanies={projectCompanies}
                setProjectCompanies={setProjectCompanies}
                company={company}
                index={index}
                setBlockHasError={setBlockHasError}
                companyNameErrorList={companyNameErrorList}
                setCompanyNameErrorList={setCompanyNameErrorList}
                portfolioCompaniesNames={portfolioNames}
                investor={investor}
              />
            ))}
          </div>
        )}
        <div className="add-company-btn-info-wrapper">
          <Button
            className="add-company-btn"
            onClick={() => {
              const newCompany = {
                companyId: uuidv4(),
                companyName: '',
                note: '',
                prefillData: 0,
                whiteGlove: 0,
              };
              setProjectCompanies([...projectCompanies, newCompany]);
            }}
          >
            <AddBusinessOutlinedIcon />
            {`${projectCompanies.length > 0 ? 'Add another company' : 'Add a company'}`}
          </Button>
        </div>
        <hr />
        <Button
          className={`add-companies-btn${projectCompanies.length > 0 && !blockHasError ? '' : ' disabled'}`}
          disabled={blockHasError && projectCompanies.length > 0}
          onClick={async () => {
            checkCompanyNames();
          }}
        >
          {addingCompanies ? (
            <>
              <div className="dots-circle-spinner" />
              Adding companies...
            </>
          ) : (
            <>
              <AddOutlinedIcon />
              Add Company
            </>
          )}
        </Button>
      </div>
    </Dialog>
  );
}

AddCompanyToProject.propTypes = {
  addCompany: PropTypes.bool.isRequired,
  setAddCompany: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  investorCompanyId: PropTypes.string,
  setCompanies: PropTypes.func,
  companies: PropTypes.arrayOf(PropTypes.shape({})),
  numberOfCompanies: PropTypes.number,
  setNumberOfCompanies: PropTypes.func,
  enterpriseCompanyIdent: PropTypes.string,
  investor: PropTypes.bool,
  portfolioCompaniesNames: PropTypes.arrayOf(PropTypes.string),
  portfolioCompanies: PropTypes.object,
};

AddCompanyToProject.defaultProps = {
  enterpriseCompanyIdent: '',
  investor: false,
  investorCompanyId: '',
  projectId: '',
  companies: [],
  setNumberOfCompanies: () => { },
  numberOfCompanies: 0,
  setCompanies: () => { },
  portfolioCompaniesNames: [''],
  portfolioCompanies: {},
};
