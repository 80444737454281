import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ErrorIcon from '@mui/icons-material/Error';
import { ReactComponent as BlueCalendar } from '../../../images/icons/blue_calendar.svg';

import { industryVerticals } from '../../../utils/globals';
import { commaEvery3rdChar } from '../../../utils';

import './InfoTile.scss';

export default function InfoTile({
  label,
  dataName,
  isEditing,
  values,
  setValues,
  isDate,
  isRadio,
  dropdownValues,
  inputTracking,
  add$,
  addPerc,
  addCommas,
  addMonths,
  errorStates,
  setErrorStates,
  setChangeInProgress,
  setChangeHasBeenMade,
}) {
  const valueToDisplay = () => {
    const value = (inputTracking ? values[dataName]?.value : values[dataName]);
    if (isDate) return (value ? moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY') : '');
    if (value && add$) return `$ ${commaEvery3rdChar(value)}`;
    if (value && addPerc) return `% ${value}`;
    if (value && addMonths) return value === '1' ? `${value} month` : `${value} months`;
    if (dataName === 'companyIndustry') return (value ? value[0] : '');
    return value || '-';
  };
  return (
    <div className={`info-tile${errorStates[dataName] ? ' error' : ''}`}>
      <p>{label}</p>
      {isEditing ? dataName === 'companyIndustry' ? (
        <Autocomplete
          disablePortal
          freeSolo
          options={industryVerticals}
          groupBy={(option) => option.category}
          getOptionLabel={(option) => option.title}
          inputValue={values[dataName] ? values[dataName][0] : ''}
          onInputChange={(e, value) => {
            if (value) {
              const newValue = industryVerticals.find((val) => val.title === value);
              setValues({ ...values, [dataName]: newValue?.title ? [newValue.title] : [value] });
            } else {
              setValues({ ...values, [dataName]: [''] });
            }
            setChangeHasBeenMade(true);
            setChangeInProgress(false);
          }}
          onFocus={() => setChangeInProgress(true)}
          renderInput={(params) => <TextField {...params} />}
        />
      ) : dropdownValues.length ? (
        <TextField
          select
          value={values[dataName] || ''}
          onChange={(e) => {
            setValues({ ...values, [dataName]: e.target.value });
            setChangeHasBeenMade(true);
          }}
          SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
        >
          {dropdownValues.map((menuItem) => (
            <MenuItem key={menuItem} value={menuItem}>
              {menuItem}
            </MenuItem>
          ))}
        </TextField>
      ) : isDate ? (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            inputFormat="MM/DD/YYYY"
            value={values[dataName] ? moment(values[dataName], 'YYYY-MM-DD') : null}
            onChange={(val) => {
              setValues({ ...values, [dataName]: val ? val.format('YYYY-MM-DD') : null });
              setChangeHasBeenMade(true);
              setChangeInProgress(false);
            }}
            slots={{ openPickerIcon: BlueCalendar }}
            onFocus={() => {
              setErrorStates({ ...errorStates, [dataName]: false });
              setChangeInProgress(true);
            }}
            slotProps={{
              textField: {
                variant: 'outlined',
                error: errorStates[dataName],
                helperText: errorStates[dataName] ? (
                  <>
                    <ErrorIcon />
                    Input Required
                  </>
                ) : '',
                onFocus: () => {
                  setErrorStates({ ...errorStates, [dataName]: false });
                  setChangeInProgress(true);
                },
              },
            }}
          />
        </LocalizationProvider>
      ) : isRadio ? (
        <RadioGroup
          row
          value={values[dataName] !== undefined ? values[dataName] : null}
          onChange={(e) => {
            setValues({ ...values, [dataName]: dataName === 'preOrPostMoney' ? e.target.value : parseInt(e.target.value, 10) });
            setChangeHasBeenMade(true);
          }}
        >
          <FormControlLabel
            control={<Radio />}
            value={dataName === 'preOrPostMoney' ? 'pre-money' : 1}
            label={dataName === 'preOrPostMoney' ? 'Pre-money' : 'Yes'}
          />
          <FormControlLabel
            control={<Radio />}
            value={dataName === 'preOrPostMoney' ? 'post-money' : 0}
            label={dataName === 'preOrPostMoney' ? 'Post-money' : 'No'}
          />
        </RadioGroup>
      ) : (
        <TextField
          value={(inputTracking ? values[dataName]?.value : values[dataName]) || ''}
          onChange={(e) => {
            if (inputTracking) setValues({ ...values, [dataName]: { value: addCommas ? commaEvery3rdChar(e.target.value) : e.target.value } });
            else setValues({ ...values, [dataName]: e.target.value });
            setChangeHasBeenMade(true);
          }}
          onFocus={() => setChangeInProgress(true)}
          onBlur={() => setChangeInProgress(false)}
          InputProps={(add$ || addPerc) ? { startAdornment: <InputAdornment position="start">{`${add$ ? '$' : '%'}`}</InputAdornment> } : {}}
        />
      ) : isRadio ? (
        <p>
          {dataName === 'preOrPostMoney' && values[dataName] ? values[dataName].charAt(0).toUpperCase() + values[dataName].slice(1) :
            values[dataName]?.toString() === '1' ? 'Yes' : values[dataName]?.toString() === '0' ? 'No' : '-'}
        </p>
      ) : (
        <p>{valueToDisplay()}</p>
      )}
    </div>
  );
}

InfoTile.propTypes = {
  label: PropTypes.string.isRequired,
  dataName: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  inputTracking: PropTypes.bool,
  isDate: PropTypes.bool,
  isRadio: PropTypes.bool,
  dropdownValues: PropTypes.array,
  add$: PropTypes.bool,
  addPerc: PropTypes.bool,
  addCommas: PropTypes.bool,
  addMonths: PropTypes.bool,
  errorStates: PropTypes.object,
  setErrorStates: PropTypes.func,
  setChangeInProgress: PropTypes.func,
  setChangeHasBeenMade: PropTypes.func,
};

InfoTile.defaultProps = {
  inputTracking: false,
  isDate: false,
  isRadio: false,
  dropdownValues: [],
  add$: false,
  addPerc: false,
  addCommas: false,
  addMonths: false,
  errorStates: {},
  setErrorStates: () => { },
  setChangeInProgress: () => { },
  setChangeHasBeenMade: () => { },
};
