import React, { useEffect, useState } from 'react';
// import { Storage } from 'aws-amplify';
import PropTypes from 'prop-types';
// import moment from 'moment';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import Menu from '@mui/material/Menu';
import Slider from '@mui/material/Slider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import './FilterMenus.scss';

export default function FilterMenus({
  setFilterSelected,
  tabToView,
  growthFilterOptions,
  multiplesFilterOptions,
  yearsFilterOptions,
  fiscalPeriodFilterOptions,
  showCompanyInfo,
  setShowCompanyInfo,
  showRevenue,
  setShowRevenue,
  showEBITDA,
  showGrossProfit,
  setShowGrossProfit,
  setShowEBITDA,
  showMarketCap,
  setShowMarketCap,
  showVolatilityValDate,
  setShowVolatilityValDate,
  showVolatilityTrDate,
  setShowVolatilityTrDate,
  growthFilterValues,
  setGrowthFilterValues,
  multiplesFilterValues,
  setMultiplesFilterValues,
  yearsFilterValues,
  setYearsFilterValues,
  fiscalPeriodFilterValues,
  setFiscalPeriodFilterValues,
  setViewAllShortDescriptions,
  setViewAllLongDescriptions,
  viewAllLongDescriptions,
  viewAllShortDescriptions,
}) {
  // Filter Menus
  const [growthAndMarginsAnchorEl, setGrowthAndMarginsAnchorEl] = useState(null);
  const [growthAndMarginsMenuIsOpen, setGrowthAndMarginsMenuIsOpen] = useState(false);
  const [multiplesMenuAnchorEl, setMultiplesMenuAnchorEl] = useState(null);
  const [multiplesMenuIsOpen, setMultiplesMenuIsOpen] = useState(false);
  const [yearsMenuAnchorEl, setYearsMenuAnchorEl] = useState(null);
  const [yearsMenuIsOpen, setYearsMenuIsOpen] = useState(false);
  const [fiscalYearMenuAnchorEl, setFiscalYearMenuAnchorEl] = useState(null);
  const [fiscalYearMenuIsOpen, setFiscalYearMenuIsOpen] = useState(false);
  const [yearsSliderValue, setYearsSliderValue] = useState([-10, 0]);
  const [yearsIncrementValue, setYearsIncrementValue] = useState(0.25);

  useEffect(() => {
    if (
      (tabToView === 4 &&
        (showRevenue || showVolatilityTrDate || showVolatilityValDate || showMarketCap)) ||
      (tabToView === 5 &&
        (showCompanyInfo || showRevenue || showEBITDA || showGrossProfit || showMarketCap ||
          growthFilterValues.length || multiplesFilterValues.length))
    ) setFilterSelected(true);
    else setFilterSelected(false);
  }, [
    tabToView,
    showCompanyInfo,
    showRevenue,
    showEBITDA,
    showGrossProfit,
    showMarketCap,
    showVolatilityTrDate,
    showVolatilityValDate,
    growthFilterValues,
    multiplesFilterValues,
    fiscalPeriodFilterValues,
  ]);

  useEffect(() => {
    let newYearsFilterValues = [...yearsFilterOptions];
    newYearsFilterValues = newYearsFilterValues
      .filter((_, i) => !((i + 1) % (yearsIncrementValue / 0.25)))
      .filter((val) => parseFloat(val) <= Math.abs(yearsSliderValue[0]) && parseFloat(val) >= Math.abs(yearsSliderValue[1]));
    setYearsFilterValues(newYearsFilterValues);
  }, [yearsSliderValue, yearsIncrementValue]);

  return (
    <div className="FilterMenus">
      <div className="filter-options">
        <span>Filter comp list table:</span>
        {tabToView === 5 && (
          <>
            <ToggleButton
              value={showCompanyInfo}
              selected={showCompanyInfo}
              onChange={() => setShowCompanyInfo(!showCompanyInfo)}
            >
              {showCompanyInfo && <CheckCircleOutlineIcon />}
              Company info
            </ToggleButton>
            <ToggleButton
              value={showRevenue}
              selected={showRevenue}
              onChange={() => setShowRevenue(!showRevenue)}
            >
              {showRevenue && <CheckCircleOutlineIcon />}
              Revenue
            </ToggleButton>
            <ToggleButton
              value={showEBITDA}
              selected={showEBITDA}
              onChange={() => setShowEBITDA(!showEBITDA)}
            >
              {showEBITDA && <CheckCircleOutlineIcon />}
              EBITDA
            </ToggleButton>
            <ToggleButton
              value={showGrossProfit}
              selected={showGrossProfit}
              onChange={() => setShowGrossProfit(!showGrossProfit)}
            >
              {showGrossProfit && <CheckCircleOutlineIcon />}
              Gross Profit
            </ToggleButton>
            <ToggleButton
              value={showMarketCap}
              selected={showMarketCap}
              onChange={() => setShowMarketCap(!showMarketCap)}
            >
              {showMarketCap && <CheckCircleOutlineIcon />}
              Market Capitalization
            </ToggleButton>
            <Button
              className={growthFilterValues.length ? 'selected' : ''}
              onClick={(e) => {
                setGrowthAndMarginsAnchorEl(e.currentTarget);
                setGrowthAndMarginsMenuIsOpen(true);
              }}
            >
              {!!growthFilterValues.length && <CheckCircleOutlineIcon />}
              Growth & Margins
              <ArrowDropDownIcon />
            </Button>
            <Menu
              disableScrollLock
              className="comp-filter-menu"
              anchorEl={growthAndMarginsAnchorEl}
              open={growthAndMarginsMenuIsOpen}
              onClose={() => {
                setGrowthAndMarginsAnchorEl(null);
                setGrowthAndMarginsMenuIsOpen(false);
              }}
            >
              <MenuItem
                onClick={() => {
                  setGrowthFilterValues(growthFilterOptions.length === growthFilterValues.length ? [] : growthFilterOptions);
                }}
              >
                <Checkbox
                  checked={growthFilterOptions.length === growthFilterValues.length}
                  icon={<div className="unchecked-icon" />}
                  checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
                />
                Select All
              </MenuItem>
              <hr />
              {growthFilterOptions.map((itemLabel) => (
                <MenuItem
                  key={itemLabel}
                  onClick={() => {
                    setGrowthFilterValues(growthFilterValues.includes(itemLabel) ?
                      [...growthFilterValues.filter((option) => option !== itemLabel)] : [...growthFilterValues, itemLabel]);
                  }}
                >
                  <Checkbox
                    checked={growthFilterValues.includes(itemLabel)}
                    icon={<div className="unchecked-icon" />}
                    checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
                  />
                  {itemLabel}
                </MenuItem>
              ))}
            </Menu>
            <Button
              className={multiplesFilterValues.length ? 'selected' : ''}
              onClick={(e) => {
                setMultiplesMenuAnchorEl(e.currentTarget);
                setMultiplesMenuIsOpen(true);
              }}
            >
              {!!multiplesFilterValues.length && <CheckCircleOutlineIcon />}
              Multiples
              <ArrowDropDownIcon />
            </Button>
            <Menu
              disableScrollLock
              className="comp-filter-menu"
              anchorEl={multiplesMenuAnchorEl}
              open={multiplesMenuIsOpen}
              onClose={() => {
                setMultiplesMenuAnchorEl(null);
                setMultiplesMenuIsOpen(false);
              }}
            >
              <MenuItem
                onClick={() => {
                  setMultiplesFilterValues(multiplesFilterValues.length === multiplesFilterOptions.length ? [] : multiplesFilterOptions);
                }}
              >
                <Checkbox
                  checked={multiplesFilterOptions.length === multiplesFilterValues.length}
                  icon={<div className="unchecked-icon" />}
                  checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
                />
                Select All
              </MenuItem>
              <hr />
              {multiplesFilterOptions.map((itemLabel) => (
                <MenuItem
                  key={itemLabel}
                  onClick={() => {
                    setMultiplesFilterValues(multiplesFilterValues.includes(itemLabel) ?
                      [...multiplesFilterValues.filter((option) => option !== itemLabel)] : [...multiplesFilterValues, itemLabel]);
                  }}
                >
                  <Checkbox
                    checked={multiplesFilterValues.includes(itemLabel)}
                    icon={<div className="unchecked-icon" />}
                    checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
                  />
                  {itemLabel}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
        {tabToView === 4 && (
          <>
            <ToggleButton
              value={showRevenue}
              selected={showRevenue}
              onChange={() => setShowRevenue(!showRevenue)}
            >
              {showRevenue && <CheckCircleOutlineIcon />}
              Revenue
            </ToggleButton>
            <ToggleButton
              value={showVolatilityValDate}
              selected={showVolatilityValDate}
              onChange={() => setShowVolatilityValDate(!showVolatilityValDate)}
            >
              {showVolatilityValDate && <CheckCircleOutlineIcon />}
              Volatility - valuation date
            </ToggleButton>
            <ToggleButton
              value={showVolatilityTrDate}
              selected={showVolatilityTrDate}
              onChange={() => setShowVolatilityTrDate(!showVolatilityTrDate)}
            >
              {showVolatilityTrDate && <CheckCircleOutlineIcon />}
              Volatility - transaction date
            </ToggleButton>
            <ToggleButton
              value={showMarketCap}
              selected={showMarketCap}
              onChange={() => setShowMarketCap(!showMarketCap)}
            >
              {showMarketCap && <CheckCircleOutlineIcon />}
              Market capitalization
            </ToggleButton>
            <Button
              className={yearsFilterValues.length !== yearsFilterOptions.length ? 'selected' : ''}
              onClick={(e) => {
                setYearsMenuAnchorEl(e.currentTarget);
                setYearsMenuIsOpen(true);
              }}
            >
              {yearsFilterValues.length !== yearsFilterOptions.length && <CheckCircleOutlineIcon />}
              Years
              <ArrowDropDownIcon />
            </Button>
            <Menu
              disableScrollLock
              style={{ position: 'absolute' }}
              className="years-filter"
              anchorEl={yearsMenuAnchorEl}
              open={yearsMenuIsOpen}
              onClose={() => {
                setYearsMenuAnchorEl(null);
                setYearsMenuIsOpen(false);
              }}
            >
              <div className="years-filter-content">
                <div>
                  <h6>Show range of years:</h6>
                  <Slider
                    orientation="vertical"
                    value={yearsSliderValue}
                    onChange={(_, newValue, activeThumb) => {
                      if (activeThumb === 0) {
                        setYearsSliderValue([Math.min(newValue[0], yearsSliderValue[1] - 1), yearsSliderValue[1]]);
                      } else {
                        setYearsSliderValue([yearsSliderValue[0], Math.max(newValue[1], yearsSliderValue[0] + 1)]);
                      }
                    }}
                    step={null}
                    marks={[...Array(11)].map((_, i) => ({ value: -i, label: i }))}
                    min={-11}
                    max={1}
                    scale={(x) => -x}
                    disableSwap
                  />
                </div>
                <div className="vr" />
                <div>
                  <h6>In increments of:</h6>
                  <RadioGroup
                    value={yearsIncrementValue}
                    onChange={(e) => setYearsIncrementValue(e.target.value)}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value={0.25}
                      label="0.25 year increment"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={0.5}
                      label="0.5 year increment"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={1}
                      label="1 year increment"
                    />
                  </RadioGroup>
                </div>
              </div>
            </Menu>
          </>
        )}
        {tabToView === 5 && (
          <>
            <Button
              className={fiscalPeriodFilterValues.length ? 'selected' : ''}
              onClick={(e) => {
                setFiscalYearMenuAnchorEl(e.currentTarget);
                setFiscalYearMenuIsOpen(true);
              }}
            >
              {!!fiscalPeriodFilterValues.length && <CheckCircleOutlineIcon />}
              Fiscal Period
              <ArrowDropDownIcon />
            </Button>
            <Menu
              disableScrollLock
              className="comp-filter-menu"
              anchorEl={fiscalYearMenuAnchorEl}
              open={fiscalYearMenuIsOpen}
              onClose={() => {
                setFiscalYearMenuAnchorEl(null);
                setFiscalYearMenuIsOpen(false);
              }}
            >
              <MenuItem
                onClick={() => {
                  setFiscalPeriodFilterValues(fiscalPeriodFilterValues.length === fiscalPeriodFilterOptions.length ? [] : fiscalPeriodFilterOptions);
                }}
              >
                <Checkbox
                  checked={fiscalPeriodFilterValues.length === fiscalPeriodFilterOptions.length}
                  icon={<div className="unchecked-icon" />}
                  checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
                />
                Select All
              </MenuItem>
              <hr />
              {fiscalPeriodFilterOptions.map((itemLabel) => (
                <MenuItem
                  key={itemLabel}
                  onClick={() => {
                    setFiscalPeriodFilterValues(fiscalPeriodFilterValues.includes(itemLabel) ?
                      [...fiscalPeriodFilterValues.filter((option) => option !== itemLabel)] : [...fiscalPeriodFilterValues, itemLabel]);
                  }}
                >
                  <Checkbox
                    checked={fiscalPeriodFilterValues.includes(itemLabel)}
                    icon={<div className="unchecked-icon" />}
                    checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
                  />
                  {itemLabel}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </div>
      <p className="helper-text">
        Select the table categories you want to see.&nbsp;
        <Button
          onClick={() => {
            if (tabToView === 4) {
              setShowRevenue(false);
              setShowVolatilityValDate(false);
              setShowVolatilityTrDate(false);
              setShowMarketCap(false);
              setYearsFilterValues([]);
              setFiscalPeriodFilterValues([]);
            }
            if (tabToView === 5) {
              setShowCompanyInfo(false);
              setShowRevenue(false);
              setShowEBITDA(false);
              setShowMarketCap(false);
              setGrowthFilterValues([]);
              setMultiplesFilterValues([]);
            }
          }}
        >
          Clear filters
        </Button>
        &nbsp;to see all tables.&nbsp;
        <Button onClick={() => {
          setViewAllShortDescriptions(!viewAllShortDescriptions);
          setViewAllLongDescriptions(false);
        }}
        >
          {viewAllShortDescriptions ? 'Hide ' : 'View '}
          all short descriptions
        </Button>
        &nbsp;or&nbsp;
        <Button onClick={() => {
          setViewAllLongDescriptions(!viewAllLongDescriptions);
          setViewAllShortDescriptions(false);
        }}
        >
          {viewAllLongDescriptions ? 'Hide ' : 'View '}
          all long descriptions
        </Button>
        &nbsp;to open all descriptions at the same time.
      </p>
    </div>
  );
}

FilterMenus.propTypes = {
  tabToView: PropTypes.number.isRequired,
  setFilterSelected: PropTypes.func.isRequired,
  growthFilterOptions: PropTypes.array.isRequired,
  multiplesFilterOptions: PropTypes.array.isRequired,
  yearsFilterOptions: PropTypes.array.isRequired,
  fiscalPeriodFilterOptions: PropTypes.array.isRequired,
  showCompanyInfo: PropTypes.bool.isRequired,
  showRevenue: PropTypes.bool.isRequired,
  showEBITDA: PropTypes.bool.isRequired,
  showGrossProfit: PropTypes.bool.isRequired,
  showMarketCap: PropTypes.bool.isRequired,
  growthFilterValues: PropTypes.array.isRequired,
  setGrowthFilterValues: PropTypes.func.isRequired,
  setShowCompanyInfo: PropTypes.func.isRequired,
  multiplesFilterValues: PropTypes.array.isRequired,
  setMultiplesFilterValues: PropTypes.func.isRequired,
  yearsFilterValues: PropTypes.array.isRequired,
  setYearsFilterValues: PropTypes.func.isRequired,
  fiscalPeriodFilterValues: PropTypes.array.isRequired,
  setFiscalPeriodFilterValues: PropTypes.func.isRequired,
  setShowRevenue: PropTypes.func.isRequired,
  setShowEBITDA: PropTypes.func.isRequired,
  setShowGrossProfit: PropTypes.func.isRequired,
  setShowMarketCap: PropTypes.func.isRequired,
  showVolatilityValDate: PropTypes.bool.isRequired,
  setShowVolatilityValDate: PropTypes.func.isRequired,
  showVolatilityTrDate: PropTypes.bool.isRequired,
  setShowVolatilityTrDate: PropTypes.func.isRequired,
  setViewAllLongDescriptions: PropTypes.func.isRequired,
  setViewAllShortDescriptions: PropTypes.func.isRequired,
  viewAllLongDescriptions: PropTypes.bool.isRequired,
  viewAllShortDescriptions: PropTypes.bool.isRequired,
};
