import { createContext } from 'react';

export const IsSigningOutContext = createContext();
export const ErrorMessageContext = createContext();
export const SuccessModalContext = createContext();
export const SubNavStateContext = createContext();

export default {
  IsSigningOutContext,
  ErrorMessageContext,
  SuccessModalContext,
  SubNavStateContext,
};
