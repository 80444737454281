import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import createList from '../../images/create-list.svg';

import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import { copy } from '../../utils';

import { createFetchHeaders } from '../../utils/apiCalls';

import { getUserCompanyId, getUserId } from '../../utils/auth';

import { ErrorMessageContext } from '../../lib/contextLib';

import './CompaniesList.scss';

export default function CompaniesList({ companiesData, setCompaniesData, setViewCompanyList }) {
  const [companyList, setCompanyList] = useState(companiesData);
  const [keyCompanyIndex, setKeyCompanyIndex] = useState(0);
  const [isPastingData, setIsPastingData] = useState(false);

  const [updatingCompanyList, setUpdatingCompanyList] = useState(false);

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  async function updateCompanyList() {
    setUpdatingCompanyList(true);
    try {
      const userId = getUserId();
      const investorCompanyId = getUserCompanyId();
      const companyListData = {
        enterpriseCompanyId: 'TempEnterpriseID', // TODO Add enterprise Company ID here
        investorCompanyId, // TODO Add investor Company ID here
        fundId: 'TempFundID', // TODO Add fund ID here NOT SURE IF WE ARE USING THIS
        fundName: 'FUND NAME', // TODO Add fund Name here NEED THIS OR NAH?
        insertUserId: userId,
        companies: companyList.filter((company) => company.companyName).map((company) => company.companyName),
      };
      let response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/companies/create-companies-in-fund`,
        await createFetchHeaders('post', companyListData, true),
      );
      response = await response.json();
      const newCompanyData = copy(companyList);
      newCompanyData.forEach((transaction, i) => {
        newCompanyData.push({
          portfolioCompanyId: response.companies[i].companyId,
          transactionId: response.transactions[i].transactionId,
          transactionType: 'ASC820',
          resourceType: 'asc820 transaction',
          ...transaction,
        });
      });
      setCompaniesData(newCompanyData);
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setCompaniesData(companyList);
      setViewCompanyList(false);
      setUpdatingCompanyList(false);
    }
  }

  return (
    <main className="CompaniesList">
      <div className="page-header">
        {/* TODO Get Enterprise name from backend */}
        <p>ASC 820 information</p>
        <p>Fund name here</p>
        <Button
          onClick={() => { updateCompanyList(); }}
          disabled={companyList?.length <= 1 || updatingCompanyList}
        >
          {!updatingCompanyList ? 'Next': <LoadingSpinner className="loading-spinner" />}
        </Button>
      </div>
      <div className="company-list-container">
        <div className="company-list-header">
          <img src={createList} alt="create list" />
          <div>
            <p>List all companies in fund</p>
            <p>Type or copy and paste below. Please use companies&apos; full legal names.</p>
          </div>
        </div>
        <ol>
          {companyList.map((company, i) => (
            <li key={company.companyIndex}>
              <TextField
                multiline
                maxRows={1}
                autoFocus={i !== 0 && i === companyList.length - 1}
                className="company-list-input"
                placeholder="Type one company name per line"
                autoComplete="off"
                value={companyList[i].companyName || ''}
                onPaste={() => setIsPastingData(true)}
                onChange={(e) => {
                  if (!isPastingData) {
                    const newCompanyList = copy(companyList);
                    newCompanyList[i].companyName = e.target.value;
                    setCompanyList(newCompanyList);
                  } else {
                    const newCompanyList = copy(companyList);
                    e.target.value.replaceAll('\t', '').split('\n').forEach((companyName, j) => {
                      newCompanyList[i + j] = { companyIndex: i + j, companyName };
                    });
                    newCompanyList.push({ companyIndex: keyCompanyIndex + i + 1, companyName: '' });
                    setKeyCompanyIndex(keyCompanyIndex + 1);
                    setCompanyList(newCompanyList);
                  }
                }}
                onBlur={(e) => {
                  const newCompanyList = copy(companyList);
                  if (e.target.value && !isPastingData && i === companyList.length - 1) {
                    newCompanyList[i] = { companyIndex: keyCompanyIndex + i, companyName: e.target.value };
                    newCompanyList.push({ companyIndex: keyCompanyIndex + i + 1, companyName: '' });
                    setKeyCompanyIndex(keyCompanyIndex + 1);
                  }
                  companyList.forEach((newCompany, j) => {
                    if (!newCompany.companyName && j !== companyList.length - 1) {
                      newCompanyList.splice(j, 1);
                    }
                  });
                  setCompanyList(newCompanyList);
                  setIsPastingData(false);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                  if (e.key === 'Enter' && i === companyList.length - 1) {
                    const newCompanyList = copy(companyList);
                    newCompanyList[i] = { companyIndex: keyCompanyIndex + i, companyName: e.target.value };
                    newCompanyList.push({ companyIndex: keyCompanyIndex + i + 1, companyName: '' });
                    setKeyCompanyIndex(keyCompanyIndex + 1);
                    setCompanyList(newCompanyList);
                  }
                  if (!e.target.value && e.key === 'Backspace' && i !== 0) {
                    const newCompanyList = copy(companyList);
                    newCompanyList.splice(i, 1);
                    setCompanyList(newCompanyList);
                  }
                }}
              />
            </li>
          ))}
        </ol>
      </div>
      <div className="bottom-btn-container">
        <Button
          onClick={() => { updateCompanyList(); }}
          disabled={companyList?.length <= 1 || updatingCompanyList}
        >
          {!updatingCompanyList ? 'Next': <LoadingSpinner className="loading-spinner" />}
        </Button>
      </div>
    </main>
  );
}

CompaniesList.propTypes = {
  companiesData: PropTypes.array.isRequired,
  setCompaniesData: PropTypes.func.isRequired,
  setViewCompanyList: PropTypes.func.isRequired,
};
