import React, {
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import { Storage } from 'aws-amplify';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';

import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';

import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import { ErrorMessageContext } from '../../lib/contextLib';

import { copy } from '../../utils';

function UploadBlock({
  uploadType,
  transactionsData,
  setTransactionsData,
  index,
  setActiveStep,
  setSaveTransactionData,
}) {
  const [fileIsDraggingOver, setFileIsDraggingOver] = useState(false);
  const [dataLoadingOverlay, setDataLoadingOverlay] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [fileValue, setFileValue] = useState('');

  const [uploadProgress, setUploadProgress] = useState(null);
  const [uploadsCompleted, setUploadsCompleted] = useState([]);

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const uploadEl = useRef(null);

  function handleIntakeChange(dataName, dataValue, dataIndex) {
    setActiveStep(3);
    const newData = copy(transactionsData);
    newData[dataIndex] = { ...newData[dataIndex], [dataName]: dataValue };
    setTransactionsData(newData);
  }

  useEffect(() => {
    setFilesList(uploadType === 'cap-table' ?
      transactionsData[index]?.capTableUploads || [] :
      transactionsData[index]?.articlesUploads || []);
  }, [transactionsData]);

  useEffect(() => {
    // TODO make this more elegant
    window.addEventListener('dragover', (e) => e.preventDefault(), false);
    window.addEventListener('drop', (e) => e.preventDefault(), false);
    return () => {
      window.removeEventListener('dragover', (e) => e.preventDefault());
      window.removeEventListener('drop', (e) => e.preventDefault());
    };
  }, []);

  async function handleFileUpload(event, fileToUpload) {
    event.preventDefault();
    if (!fileToUpload) return;
    if (fileToUpload.size >= 35000000) {
      setShowErrorMessage('File size is too big');
      setFileIsDraggingOver(false);
      return;
    }
    // TODO Handle different file types here...
    setDataLoadingOverlay(true);
    try {
      const fileName = fileToUpload.name;
      const dateTime = Date.now();
      const location = `/${transactionsData[index]?.portfolioCompanyId}/${transactionsData[index]?.transactionId}/${dateTime}-${fileName}`;
      const newFileList = copy(filesList);
      newFileList.push({ fileName, dateTime, location });
      setFilesList(newFileList);
      handleIntakeChange(uploadType === 'cap-table' ? 'capTableUploads' : 'articlesUploads', newFileList, index);
      await Storage.put(location, fileToUpload, {
        customPrefix: { public: '820Uploads' },
        bucket: `${process.env.REACT_APP_UPLOADS_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`,
        progressCallback(progress) { setUploadProgress(progress); },
      });
      setTimeout(() => {
        setUploadsCompleted([...uploadsCompleted, `${dateTime}-${fileName}`]);
        setUploadProgress(null);
      }, 1000);
      setSaveTransactionData(true);
    } catch (e) {
      setShowErrorMessage(e.toString());
      setUploadProgress(null);
    } finally {
      setFileIsDraggingOver(false);
      setDataLoadingOverlay(false);
    }
  }

  async function removeFile(file, fileIndex) {
    setDataLoadingOverlay(true);
    await Storage.remove(`/${transactionsData[index]?.portfolioCompanyId}/` +
      `${transactionsData[index]?.transactionId}/${file.dateTime}-${file.fileName}`, {
      customPrefix: { public: '820Uploads' },
      bucket: `${process.env.REACT_APP_UPLOADS_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`,
    });
    const newFileList = copy(filesList);
    newFileList.splice(fileIndex, 1);
    setFilesList(newFileList);
    handleIntakeChange(uploadType === 'cap-table' ? 'capTableUploads' : 'articlesUploads', newFileList, index);
    setSaveTransactionData(true);
    setDataLoadingOverlay(false);
  }

  return (
    <div className="upload-block">
      <div className="upload-block-header">
        {uploadType === 'cap-table' ? (
          <>
            <AssessmentOutlinedIcon />
            <div>
              <h4>Upload Capitalization Table</h4>
              <p>Post-Close or a Pro Forma Cap Table. It must be current and capture all intended financing.</p>
            </div>
          </>
        ) : (
          <>
            <AccountBalanceIcon />
            <div>
              <h4>Upload Articles of incorporation</h4>
              <p>Upload the latest version, usually filed on the date of closing the latest round of financing</p>
            </div>
          </>
        )}
      </div>
      <div className="file-upload-container">
        <div
          className={`drag-and-drop-area ${fileIsDraggingOver ? 'file-dragging' : ''}`}
          onDrop={(e) => handleFileUpload(e, e.dataTransfer.files[0])}
          onDragEnter={(e) => {
            e.preventDefault();
            setFileIsDraggingOver(true);
          }}
          onDragExit={(e) => {
            e.preventDefault();
            setFileIsDraggingOver(false);
          }}
        >
          {dataLoadingOverlay && (
            <div className="file-upload-loading-wrapper">
              <LoadingSpinner className="loading-spinner" />
            </div>
          )}
          <div className="browse-or-drop">
            <UploadFileIcon />
            <span>Drop files here</span>
            <span>or</span>
            <Button
              onClick={() => {
                setActiveStep(3);
                setFileValue('');
                uploadEl.current.click();
              }}
              onKeyDown={(e) => { e.stopPropagation(); }}
            >
              Browse
            </Button>
          </div>
          <input
            ref={uploadEl}
            type="file"
            accept=".xlsx,.xls,.xlsm,.doc,.docx,.ppt,.pptx,.pps,.ppsx,.txt,.pdf,.csv,.jpg,.jpeg,.heic,.png"
            className="file-style"
            value={fileValue}
            onChange={(e) => {
              e.preventDefault();
              handleFileUpload(e, e.target.files[0]);
            }}
          />
        </div>
        <div className="uploaded-files-display">
          {filesList.map((file, fileIndex) => (
            <div
              className="file"
              key={`${file.fileName.replaceAll(' ', '++--++')}-${file.dateTime}`}
            >
              <div>
                <div>
                  <div className="file-name-and-checkmark-row">
                    <div>
                      {uploadsCompleted.includes(`${file.dateTime}-${file.fileName}`) ? <DownloadDoneIcon /> : <UploadOutlinedIcon />}
                      <span>{(file.fileName.length >= 30) ? `${file.fileName.slice(0, 30).trim()}...` : file.fileName}</span>
                    </div>
                  </div>
                  {uploadProgress && fileIndex === filesList.length - 1 && (
                    <LinearProgress variant="determinate" value={(uploadProgress.loaded / uploadProgress.total) * 100} />
                  )}
                  {uploadsCompleted.includes(`${file.dateTime}-${file.fileName}`) && <span className="upload-complete">Upload complete</span>}
                </div>
              </div>
              <IconButton
                className="clear-icon"
                onClick={() => removeFile(file, fileIndex)}
                onKeyDown={(e) => { e.stopPropagation(); }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

UploadBlock.propTypes = {
  uploadType: PropTypes.string.isRequired,
  transactionsData: PropTypes.array.isRequired,
  setTransactionsData: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setSaveTransactionData: PropTypes.func.isRequired,
};

export default function Step3({
  transactionsData,
  setTransactionsData,
  index,
  setActiveStep,
  setSaveTransactionData,
}) {
  return (
    <>
      <h4>Document Upload</h4>
      <p style={{ marginTop: '12px' }}>
        We accept the following file types, within a max. file size of 35 MB:
        <span style={{ fontWeight: 700 }}> Microsoft Excel </span>
        (.xls, .xlsx)
        <span style={{ fontWeight: 700 }}> Word </span>
        (.doc, .docx),
        <span style={{ fontWeight: 700 }}> PowerPoint </span>
        (.ppt, pptx, .pps, .ppsx), and
        <span style={{ fontWeight: 700 }}> image files </span>
        (.jpg, .jpeg, .heic, and .png).
      </p>
      <UploadBlock
        uploadType="cap-table"
        transactionsData={transactionsData}
        setTransactionsData={setTransactionsData}
        index={index}
        setActiveStep={setActiveStep}
        setSaveTransactionData={setSaveTransactionData}
      />
      <UploadBlock
        uploadType="articles"
        transactionsData={transactionsData}
        setTransactionsData={setTransactionsData}
        index={index}
        setActiveStep={setActiveStep}
        setSaveTransactionData={setSaveTransactionData}
      />
    </>
  );
}

Step3.propTypes = {
  transactionsData: PropTypes.array.isRequired,
  setTransactionsData: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setSaveTransactionData: PropTypes.func.isRequired,
};
