export function cleanValue(inputValue) {
  if (typeof inputValue === 'string') {
    const cleanedValue = inputValue
      .replace(/[^a-zA-Z0-9\s._/-]/g, '')
      .replace(/\.00$/, '');

    return cleanedValue;
  }
  if (typeof inputValue === 'number') {
    // For numbers, convert to string, clean, and then parse back to number
    const stringValue = String(inputValue);
    const cleanedValue = stringValue
      .replace(/[^0-9._/-]/g, '')
      .replace(/\.00$/, '');

    return parseFloat(cleanedValue);
  }

  if (typeof inputValue === 'boolean') {
    return inputValue ? 1 : 0;
  }
  return inputValue; // Return other types unchanged
}

export function cleanSingleObject(obj) {
  const cleanedObj = {};

  Object.keys(obj).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (value === null) {
        cleanedObj[key] = value;
      } else if (Array.isArray(value)) {
        cleanedObj[key] = value.map((item) => {
          if (typeof item === 'object' && item !== null) {
            return cleanSingleObject(item);
          }
          return item;
        });
      } else if (typeof value === 'object' && value !== null) {
        cleanedObj[key] = cleanSingleObject(value);
      } else {
        cleanedObj[key] = cleanValue(value);
      }
    }
  });

  return cleanedObj;
}

export function cleanObjectValues(input) {
  return input.map((obj) => obj !== undefined ? cleanSingleObject(obj) : obj);
}
