import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AnimateHeight from 'react-animate-height';
import {
  Avatar,
  AvatarGroup,
  Badge,
  Button,
  MenuItem,
  Select,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
} from '@mui/material';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import RotatingChevron from '../../components/RotatingChevron';
import { toTitleCase } from '../../utils';
import { darkInitials } from '../../utils/globals';

import './ProjectStatusCard.scss';
import { getUserCompanyId, getUserId } from '../../utils/auth';
import { ErrorMessageContext } from '../../lib/contextLib';
import { createFetchHeaders } from '../../utils/apiCalls';
import determineBadgeColor from './utils';

export default function ProjectStatusCard({
  projectData,
  setProjectToView,
  enterpriseUsers,
  projectList,
  setProjectList,
  setInvestorCompanyDetailToView,
  dataEntry,
  preparer,
  deliver,
}) {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const [priority, setPriority] = useState(projectData.priority);
  const [dropdownIsOpen, setDropdownIsOpen] = useState({
    priorityDropdown: false,
    assignedToDropdown: false,
  });

  const [insideElementHover, setInsideElementHover] = useState(false);

  const [dataEntryPerProject, setDataEntryPerProject] = useState([]);
  const [preparerPerProject, setPreparerPerProject] = useState([]);
  const [reviewerPerProject, setReviewerPerProject] = useState([]);
  const [lastModifiedUser, setLastModifiedUser] = useState(null);

  const [topLevelAvatar, setTopLevelAvatar] = useState([]);

  const findEnterpriseUser = (userId) => enterpriseUsers.find((user) => user.accountId === userId);

  function getInitials(name) {
    if (name) {
      const splitName = name.split(' ');
      const firstInitial = Array.from(splitName[0])[0];
      const lastInitial = splitName[1] ? Array.from(splitName[1])[0] : '';
      return toTitleCase(firstInitial) + toTitleCase(lastInitial);
    }
    return null;
  }

  function parseAssignees() {
    if (projectData.assignedUsers.dataEntry) setDataEntryPerProject(Object.entries(projectData.assignedUsers.dataEntry));
    if (projectData.assignedUsers.preparer) setPreparerPerProject(Object.entries(projectData.assignedUsers.preparer));
    if (projectData.assignedUsers.reviewer) setReviewerPerProject(Object.entries(projectData.assignedUsers.reviewer));
    if (projectData.priority !== 0) setPriority(projectData.priority);
    if (projectData.lastRequestUserId) setLastModifiedUser(findEnterpriseUser(projectData.lastRequestUserId));
  }

  useEffect(() => {
    setTopLevelAvatar(dataEntry ? dataEntryPerProject : preparer ? preparerPerProject : deliver ? reviewerPerProject : []);
  }, [dataEntryPerProject, preparerPerProject, reviewerPerProject]);

  useEffect(() => { parseAssignees(); }, [enterpriseUsers, projectData, dataEntry, preparer, deliver]);

  async function updatePriority(value) {
    try {
      const requestUserId = getUserId();
      const enterpriseCompanyId = getUserCompanyId();
      const priorityData = {
        enterpriseCompanyId,
        projectId: projectData.projectId,
        priority: value,
        requestUserId,
      };
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/homepages/enterprise/update-priority`,
        await createFetchHeaders('post', priorityData, true),
      );
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  function updateProjectList(value) {
    const projectListCopy = [...projectList];
    const projectDataCopy = projectListCopy[projectListCopy.findIndex((project) => project.projectId === projectData.projectId)];
    projectDataCopy.priority = value.toString();
    setProjectList(projectListCopy);
    setPriority(value.toString());
    setDropdownIsOpen({ ...dropdownIsOpen, priorityDropdown: false });
  }

  return (
    <div
      className={`ProjectStatusCard${!insideElementHover ? ' can-hover' : ''}`}
    >
      <div
        role="button"
        className={`card-btn${!insideElementHover ? ' can-hover' : ''}`}
        onClick={() => (!insideElementHover && setProjectToView(projectData))}
        onKeyDown={(e) => (e.key === 'Enter' && setProjectToView(projectData))}
        tabIndex={0}
      >
        <div className="card-header">
          <span className="service-name">
            {'820' || ''}
          </span>
          <div className="card-header-info">
            {projectData.projectName.length > 25 ? (
              <Tooltip
                id="name-tooltip"
                title={projectData?.investorFirmName}
                disableFocusListener
                arrow
                placement="top"
              >
                <span className="fund-name">{`${projectData?.projectName.substring(0, 25)}...`}</span>
              </Tooltip>
            ) : (
              <span className="fund-name">{projectData?.projectName}</span>
            )}
            {projectData.investorFirmName.length > 25 ? (
              <Tooltip
                id="name-tooltip"
                title={projectData?.investorFirmName}
                disableFocusListener
                arrow
                placement="top"
              >
                <span>{`${projectData?.investorFirmName.substring(0, 25)}...`}</span>
              </Tooltip>
            ) : (
              <span>{projectData?.investorFirmName}</span>
            )}
          </div>
          <div className="priority-icon-wrapper">
            {priority === '1' ? (
              <NotificationImportantOutlinedIcon className="priority-icon" />
            ) : priority === '3' && (
              <ArrowDownwardIcon />
            )}
          </div>
        </div>
        <div className="status-badge-wrapper">
          {projectData.blockerStatus && (
            <span className={`status-badge ${determineBadgeColor(projectData.blockerStatus)} sub-status`}>
              {projectData.blockerStatus}
            </span>
          )}
          <span className={`status-badge ${determineBadgeColor(projectData.status)}`}>
            {projectData?.insertUserId}
            {projectData.status}
          </span>
        </div>
        <div className="card-footer-wrapper">
          <div className="card-footer-info">
            <span className={`date-info-due ${!topLevelAvatar.length ? 'no-assignees' : ''}`}>
              <ThemeProvider
                theme={createTheme({
                  components: {
                    MuiAvatarGroup: {
                      styleOverrides: {
                        root: ({ ownerState: { max } }) => ({
                          ...[...Array(max)].reduce(
                            (result, curr, index) => ({
                              ...result,
                              [`& > .MuiAvatar-root:nth-of-type(${index + 1})`]: {
                                zIndex: max - index,
                              },
                            }),
                            {},
                          ),
                        }),
                      },
                    },
                  },
                })}
              >
                <AvatarGroup max={5}>
                  {topLevelAvatar.length > 0 ? (
                    topLevelAvatar.map((user) => (
                      <Tooltip
                        key={user[0]}
                        disableInteractive
                        title={(
                          <Typography style={{ whiteSpace: 'pre-line' }}>
                            {
                              `${dataEntry ? 'Data Entry' : preparer ? 'Preparer' : 'Deliver'}
                      ${findEnterpriseUser(user[0])?.firstName} ${findEnterpriseUser(user[0])?.lastName}`
                            }
                          </Typography>
                        )}
                        PopperProps={{ className: 'bottom-arrow-tooltip' }}
                        placement="bottom"
                        arrow
                      >
                        <Avatar
                          style={{
                            backgroundColor: findEnterpriseUser(user[0])?.avatarColor,
                            color: darkInitials.includes(findEnterpriseUser(user[0])?.avatarColor) ? '#49454F' : '#FAFAFD',
                            marginLeft: 'calc(1.75rem * -0.5)',
                          }}
                          onMouseEnter={() => { if (!insideElementHover) setInsideElementHover(true); }}
                          onMouseLeave={() => setInsideElementHover(false)}
                        >
                          {getInitials(`${findEnterpriseUser(user[0])?.firstName} ${findEnterpriseUser(user[0])?.lastName}`)}
                        </Avatar>
                      </Tooltip>
                    ))
                  ) : (
                    <Tooltip
                      disableInteractive
                      title={(
                        <Typography style={{ whiteSpace: 'pre-line' }}>
                          Unassigned
                        </Typography>
                      )}
                      PopperProps={{ className: 'bottom-arrow-tooltip' }}
                      placement="bottom"
                      arrow
                    >
                      <Avatar
                        onMouseEnter={() => { if (!insideElementHover) setInsideElementHover(true); }}
                        onMouseLeave={() => setInsideElementHover(false)}
                      >
                        <PersonOutlineOutlinedIcon />
                      </Avatar>
                    </Tooltip>
                  )}
                </AvatarGroup>
              </ThemeProvider>
              Info due:&nbsp;
              {moment(projectData?.clientInfoDueDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
              <br />
              {projectData?.numberOfCompanies}
              &nbsp;companies in project
            </span>
          </div>
          <button
            type="button"
            className="card-expand-chevron"
            onClick={(e) => {
              e.stopPropagation();
              setDropdownHeight(dropdownHeight ? 0 : 'auto');
            }}
          >
            <RotatingChevron downwards0Condition={dropdownHeight} />
          </button>
        </div>
        <AnimateHeight
          duration={500}
          height={dropdownHeight}
        >
          <hr className="card-spacer" />
          <div className="expanded-content">
            <div className="links-wrapper">
              <div className="assigned-to-section">
                <h6>Assigned to</h6>
                <hr className="card-spacer" />
                <div className="assigned-to-title">
                  <span>Data entry</span>
                  <div
                    className="assignee-avatars"
                    onMouseEnter={() => { if (!insideElementHover) setInsideElementHover(true); }}
                    onMouseLeave={() => setInsideElementHover(false)}
                  >
                    {dataEntryPerProject.map((assignee) => {
                      const enterpriseUser = findEnterpriseUser(assignee[0]);
                      return (
                        <Tooltip
                          key={`${enterpriseUser?.firstName}${assignee[1].length}${enterpriseUser?.avatarColor}`}
                          disableInteractive
                          title={(
                            <Typography style={{ whiteSpace: 'pre-line' }}>
                              {`${enterpriseUser?.firstName} ${enterpriseUser?.lastName}\n${assignee[1].length} companies in this project`}
                            </Typography>
                          )}
                          PopperProps={{ className: 'bottom-arrow-tooltip' }}
                          placement="bottom"
                          arrow
                        >
                          <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={(
                              <Avatar
                                className="working-companies"
                                sx={{ bgcolor: `${enterpriseUser?.avatarColor}` }}
                              >
                                {assignee[1].length}
                              </Avatar>
                            )}
                          >
                            <Avatar
                              style={{
                                backgroundColor: enterpriseUser?.avatarColor,
                                color: darkInitials.includes(enterpriseUser?.avatarColor) ? '#49454F' : '#FAFAFD',
                              }}
                            >
                              {getInitials(`${enterpriseUser?.firstName} ${enterpriseUser?.lastName}`)}
                            </Avatar>
                          </Badge>
                        </Tooltip>
                      );
                    })}
                  </div>
                </div>
                <div className="assigned-to-title">
                  <span>Preparer</span>
                  <div
                    className="assignee-avatars"
                    onMouseEnter={() => { if (!insideElementHover) setInsideElementHover(true); }}
                    onMouseLeave={() => setInsideElementHover(false)}
                  >
                    {preparerPerProject.map((assignee) => {
                      const enterpriseUser = findEnterpriseUser(assignee[0]);
                      return (
                        <Tooltip
                          key={`${enterpriseUser?.firstName}${assignee[1].length}${enterpriseUser?.avatarColor}`}
                          disableInteractive
                          title={(
                            <Typography style={{ whiteSpace: 'pre-line' }}>
                              {`${enterpriseUser?.firstName} ${enterpriseUser?.lastName}\n${assignee[1].length} companies in this project`}
                            </Typography>
                          )}
                          PopperProps={{ className: 'bottom-arrow-tooltip' }}
                          placement="bottom"
                          arrow
                        >
                          <Badge
                            key={`${enterpriseUser?.firstName}${assignee[1].length}${enterpriseUser?.avatarColor}`}
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={(
                              <Avatar className="working-companies">
                                {assignee[1].length}
                              </Avatar>
                            )}
                          >
                            <Avatar
                              style={{
                                backgroundColor: enterpriseUser?.avatarColor,
                                color: darkInitials.includes(enterpriseUser?.avatarColor) ? '#49454F' : '#FAFAFD',
                              }}
                            >
                              {getInitials(`${enterpriseUser?.firstName} ${enterpriseUser?.lastName}`)}
                            </Avatar>
                          </Badge>
                        </Tooltip>
                      );
                    })}
                  </div>
                </div>
                <div className="assigned-to-title">
                  <span>Reviewer</span>
                  <div
                    className="assignee-avatars"
                    onMouseEnter={() => { if (!insideElementHover) setInsideElementHover(true); }}
                    onMouseLeave={() => setInsideElementHover(false)}
                  >
                    {reviewerPerProject.map((assignee) => {
                      const enterpriseUser = findEnterpriseUser(assignee[0]);
                      return (
                        <Tooltip
                          key={`${enterpriseUser?.firstName}${assignee[1].length}${enterpriseUser?.avatarColor}`}
                          disableInteractive
                          title={(
                            <Typography style={{ whiteSpace: 'pre-line' }}>
                              {`${enterpriseUser?.firstName} ${enterpriseUser?.lastName}\n${assignee[1].length} companies in this project`}
                            </Typography>
                          )}
                          PopperProps={{ className: 'bottom-arrow-tooltip' }}
                          placement="bottom"
                          arrow
                        >
                          <Badge
                            key={`${enterpriseUser?.firstName}${assignee[1].length}${enterpriseUser?.avatarColor}`}
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={(
                              <Avatar className="working-companies">
                                {assignee[1].length}
                              </Avatar>
                            )}
                          >
                            <Avatar
                              className={`initials ${darkInitials.includes(enterpriseUser?.avatarColor) ? 'dark' : 'light'}`}
                              style={{
                                backgroundColor: enterpriseUser?.avatarColor,
                                color: darkInitials.includes(enterpriseUser?.avatarColor) ? '#49454F' : '#FAFAFD',
                              }}
                            >
                              {getInitials(`${enterpriseUser?.firstName} ${enterpriseUser?.lastName}`)}
                            </Avatar>
                          </Badge>
                        </Tooltip>
                      );
                    })}
                  </div>
                </div>
              </div>
              <Button
                className="expanded-links investor-details"
                onClick={() => setInvestorCompanyDetailToView(projectData)}
                onMouseEnter={() => { if (!insideElementHover) setInsideElementHover(true); }}
                onMouseLeave={() => setInsideElementHover(false)}
              >
                {`Go to ${projectData?.investorFirmName} details`}
              </Button>
              <Select
                className="expanded-links dropdown-select"
                open={dropdownIsOpen.priorityDropdown}
                value={priority || ''}
                renderValue={() => <span className="priority-label">Priority</span>}
                onOpen={() => setDropdownIsOpen({ ...dropdownIsOpen, priorityDropdown: true })}
                onChange={async (e) => {
                  updateProjectList(e.target.value);
                  await updatePriority(e.target.value);
                }}
                IconComponent={ExpandMoreIcon}
                MenuProps={{ disableScrollLock: true, disablePortal: true }}
                onMouseEnter={() => { if (!insideElementHover) setInsideElementHover(true); }}
                onMouseLeave={() => setInsideElementHover(false)}
                onClose={() => setDropdownIsOpen({ ...dropdownIsOpen, priorityDropdown: false })}
              >
                <MenuItem value={1}>
                  <NotificationImportantOutlinedIcon className="high-icon" />
                  High
                </MenuItem>
                <MenuItem value={2}>
                  <ArrowDownwardIcon sx={{ visibility: 'hidden', marginRight: '18px' }} />
                  Normal
                </MenuItem>
                <MenuItem value={3}>
                  <ArrowDownwardIcon className="low-icon" />
                  Low
                </MenuItem>
              </Select>
              <div className="due-dates">
                <span>
                  <CalendarMonthOutlinedIcon />
                  {projectData?.clientInfoDueDate}
                  &nbsp;info due&nbsp;
                </span>
                <hr />
                <span>
                  <CalendarMonthOutlinedIcon />
                  {projectData?.auditDate}
                  &nbsp;audit date&nbsp;
                </span>
                <hr />
                <span>
                  <CalendarMonthOutlinedIcon />
                  {projectData?.valuationDate}
                  &nbsp;valuation date&nbsp;
                </span>
              </div>
              <div className="edited-by">
                {lastModifiedUser && (
                  <p>
                    Edited&nbsp;
                    {projectData.lastModified.split(' ')[1]}
                    &nbsp;by&nbsp;
                    {`${findEnterpriseUser(projectData.lastRequestUserId)?.firstName} ${findEnterpriseUser(projectData.lastRequestUserId)?.lastName}`}
                  </p>
                )}
              </div>
            </div>
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
}

ProjectStatusCard.propTypes = {
  projectData: PropTypes.object.isRequired,
  setProjectToView: PropTypes.func.isRequired,
  enterpriseUsers: PropTypes.array.isRequired,
  projectList: PropTypes.array.isRequired,
  setProjectList: PropTypes.func.isRequired,
  setInvestorCompanyDetailToView: PropTypes.func.isRequired,
  dataEntry: PropTypes.bool,
  preparer: PropTypes.bool,
  deliver: PropTypes.bool,
};

ProjectStatusCard.defaultProps = {
  dataEntry: false,
  preparer: false,
  deliver: false,
};
