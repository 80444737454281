import React, { useContext, useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import SplashPage from './SplashPage';
import { ErrorMessageContext } from '../../lib/contextLib';
import { createFetchHeaders } from '../../utils/apiCalls';
import { getUserCompanyId, getUserId } from '../../utils/auth';
import EnterpriseProjects from '../EnterpriseProjects';

export default function Home() {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);
  const [enterpriseProjects, setEnterpriseProjects] = useState([]);
  // const [loadingEnterpriseProjects, setLoadingEnterpriseProjects] = useState(true);
  const [enterpriseUsers, setEnterpriseUsers] = useState([]);
  const [fetchEnterpriseData, setFetchEnterpriseData] = useState(true);

  const [investorCompaniesList, setInvestorCompaniesList] = useState({});
  const [portfolioCompaniesList, setPortfolioCompaniesList] = useState({});
  const [investorCompaniesNames, setInvestorCompaniesNames] = useState([]);
  const [portfolioCompaniesNames, setPortfolioCompaniesNames] = useState([]);

  async function getEnterpriseProjects() {
    try {
      const requestUserId = getUserId();
      const enterpriseCompanyId = await getUserCompanyId();
      let [projectDBData, enterpriseUserList, dbCompanyResponse] = await Promise.all([
        fetch(
          `${process.env.REACT_APP_BACKEND_URL}/homepages/enterprise/${enterpriseCompanyId}&${requestUserId}`,
          await createFetchHeaders('get', {}, true),
        ),
        fetch(
          `${process.env.REACT_APP_BACKEND_URL}/accounts/get-enterprise-users/${enterpriseCompanyId}&${requestUserId}`,
          await createFetchHeaders('get', {}, true),
        ),
        fetch(
          `${process.env.REACT_APP_BACKEND_URL}/companies/get_investor_and_portfolio_companies/${enterpriseCompanyId}&${requestUserId}`,
          await createFetchHeaders('get', {}, true),
        ),
      ]);
      [projectDBData, enterpriseUserList, dbCompanyResponse] = await Promise.all([
        projectDBData.json(),
        enterpriseUserList.json(),
        dbCompanyResponse.json(),
      ]);
      setEnterpriseProjects(projectDBData);
      setEnterpriseUsers(enterpriseUserList);
      const { investorCompanies, portfolioCompanies } = dbCompanyResponse;
      setPortfolioCompaniesList(portfolioCompanies);
      setInvestorCompaniesList(investorCompanies);
      setPortfolioCompaniesNames(Object.keys(portfolioCompanies));
      setInvestorCompaniesNames(Object.keys(investorCompanies));
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setFetchEnterpriseData(false);
    }
  }

  useEffect(() => { getEnterpriseProjects(); }, []);

  if (enterpriseProjects.length > 0) {
    return (
      <EnterpriseProjects
        enterpriseProjects={enterpriseProjects}
        setEnterpriseProjects={setEnterpriseProjects}
        enterpriseUsers={enterpriseUsers}
        investorCompaniesList={investorCompaniesList}
        portfolioCompaniesList={portfolioCompaniesList}
        investorCompaniesNames={investorCompaniesNames}
        portfolioCompaniesNames={portfolioCompaniesNames}
        setPortfolioCompaniesNames={setPortfolioCompaniesNames}
      />
    );
  }

  return (
    <>
      <Dialog className="loading-dialog" open={fetchEnterpriseData} disableScrollLock>
        <div className="dots-circle-spinner" />
      </Dialog>
      {!fetchEnterpriseData && (
        <SplashPage
          setEnterpriseProjects={setEnterpriseProjects}
          enterpriseUsers={enterpriseUsers}
          enterpriseProjects={enterpriseProjects}
          investorCompaniesList={investorCompaniesList}
          portfolioCompaniesList={portfolioCompaniesList}
          investorCompaniesNames={investorCompaniesNames}
          portfolioCompaniesNames={portfolioCompaniesNames}
          setPortfolioCompaniesNames={setPortfolioCompaniesNames}
        />
      )}
    </>
  );
}
