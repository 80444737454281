import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import AnimateHeight from 'react-animate-height';

import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { Checkbox, TextField } from '@mui/material';

import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import InfoTile from './Components/InfoTile';
import Notes from './Components/Notes';

import { getUserCompanyId, getUserId } from '../../utils/auth';
import { createFetchHeaders } from '../../utils/apiCalls';
import SortBy from '../../components/SortBy';
import { toTitleCase } from '../../utils';
import { darkInitials } from '../../utils/globals';
import { ErrorMessageContext } from '../../lib/contextLib';

import './GeneralInfo.scss';
import UploadTile from './Components/UploadTile';
import EditButton from './Components/EditButton';

// eslint-disable-next-line
export default function GeneralInfo({ userData, setUserData }) {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);
  const [companyInfoDropdownHeight, setCompanyInfoDropdownHeight] = useState('auto');
  const [additionalUsersDropdownHeight, setAdditionalUsersDropdownHeight] = useState('auto');
  const [paymentInfoDropdownHeight, setPaymentInfoDropdownHeight] = useState('auto');

  const [editingCompanyInfo, setEditingCompanyInfo] = useState(false);
  const [companyInfoIsSaving, setCompanyInfoIsSaving] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});

  const [editingCompanyDesc, setEditingCompanyDesc] = useState(false);
  const [companyDescSaving, setCompanyDescSaving] = useState(false);

  const [editingPaymentInfo, setEditingPaymentInfo] = useState(false);
  const [paymentInfoIsSaving, setPaymentInfoIsSaving] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({});

  const [editingUploads, setEditingUploads] = useState(false);
  const [uploadsSaving, setUploadsSaving] = useState(false);

  const [reviewer, setReviewer] = useState();
  const [preparer, setPreparer] = useState();
  const [dataEntry, setDataEntry] = useState();
  const [assigneesData, setAssigneesData] = useState([]);

  const [notesList, setNotesList] = useState([]);
  const [noteListLoading, setNoteListLoading] = useState(false);

  const [enterpriseUsers, setEnterpriseUsers] = useState([]);

  const [capTableUploads, setCapTableUploads] = useState([]);
  const [articlesUploads, setArticlesUploads] = useState([]);
  const [optionLedgerUploads, setOptionLedgerUploads] = useState([]);
  const [secondaryTransactionUploads, setSecondaryTransactionUploads] = useState([]);
  const [termSheetsUploads, setTermSheetsUploads] = useState([]);
  const [safeOrConvertibleNoteUploads, setSafeOrConvertibleNoteUploads] = useState([]);
  const [financialForecastUploads, setFinancialForecastUploads] = useState([]);
  const [additionalFinancialUploads, setAdditionalFinancialUploads] = useState([]);
  const [customCompanyDescription, setCustomCompanyDescription] = useState(false);

  async function getNoteList() {
    setNoteListLoading(true);
    try {
      const enterpriseCompanyId = getUserCompanyId();
      const { transactionId, portfolioCompanyId } = userData.transactionData;
      const requestUserId = getUserId();
      const requestOptions = await createFetchHeaders('get', {}, true);
      const urlParams = `${enterpriseCompanyId}&${portfolioCompanyId}&${transactionId}&${requestUserId}`;
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/notes/transaction-note/get-transaction-notes/${urlParams}`,
        requestOptions,
      );
      if (response.ok) {
        const responseBody = await response.text();
        if (responseBody) {
          const responseJSON = JSON.parse(responseBody);
          setNotesList(responseJSON);
        }
      }
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setNoteListLoading(false);
    }
  }

  useEffect(() => {
    if (userData.transactionData) {
      getNoteList();
    }
  }, [userData]);

  async function getEnterpriseUsers() {
    try {
      const userId = getUserId();
      const enterpriseCompanyId = getUserCompanyId();
      let enterpriseUserDBData = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/accounts/get-enterprise-users/${enterpriseCompanyId}&${userId}`,
        await createFetchHeaders('get', {}, true),
      );
      enterpriseUserDBData = await enterpriseUserDBData.json();
      setEnterpriseUsers(enterpriseUserDBData);
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  function parseUserData() {
    if (userData && enterpriseUsers) {
      const parsedOutAssignees = userData.metaData.assignees;
      const preparerAssignee = parsedOutAssignees.preparer.accountId !== 'not assigned' ?
        enterpriseUsers.filter((user) => user.accountId === parsedOutAssignees.preparer.accountId)[0] :
        'not assigned';
      const reviewerAssignee = parsedOutAssignees.reviewer.accountId !== 'not assigned' ?
        enterpriseUsers.filter((user) => user.accountId === parsedOutAssignees.reviewer.accountId)[0] :
        'not assigned';
      const dataEntryAssignee = parsedOutAssignees.dataEntry.accountId !== 'not assigned' ?
        enterpriseUsers.filter((user) => user.accountId === parsedOutAssignees.dataEntry.accountId)[0] :
        'not assigned';
      setAssigneesData([dataEntryAssignee, reviewerAssignee, preparerAssignee]);
      if (dataEntryAssignee !== 'not assigned') setDataEntry(`${dataEntryAssignee?.firstName} ${dataEntryAssignee?.lastName}`);
      if (reviewerAssignee !== 'not assigned') setReviewer(`${reviewerAssignee?.firstName} ${reviewerAssignee?.lastName}`);
      if (preparerAssignee !== 'not assigned') setPreparer(`${preparerAssignee?.firstName} ${preparerAssignee?.lastName}`);
    }
    setPaymentInfo({ paidToDate: userData.metaData.paidToDate, totalCompanyFee: userData.metaData.totalCompanyFee });
  }
  useEffect(() => {
    getEnterpriseUsers();
  }, []);

  useEffect(() => {
    if (userData.metaData && enterpriseUsers) {
      parseUserData();
    }
  }, [userData.metaData, enterpriseUsers]);

  useEffect(() => {
    if (userData.transactionData) {
      setCompanyInfo({
        portfolioCompanyName: userData.companyData?.companyName,
        companyIndustry: userData.transactionData.basicInfo?.companyIndustry,
        whiteGlove: userData.metaData?.whiteGlove,
        userHasAccess: userData.transactionData?.userHasAccess,
        capIqIdentifier: userData.companyData?.capIqIdentifier,
        description: userData.companyData?.description,
        customDescription: userData.companyData?.customDescription,
        rollForward: userData.transactionData?.rollForward,
        fiscalYearEnd: userData.transactionData.basicInfo?.fiscalYearEnd,
      });
      if (!userData.companyData.capIqIdentifier && !userData.companyData.customDescription) {
        setCustomCompanyDescription(false);
      } else {
        setCustomCompanyDescription(true);
      }
      // parse uploads
      setCapTableUploads(userData.transactionData?.capTableUploads || []);
      setArticlesUploads(userData.transactionData?.articlesUploads || []);
      setOptionLedgerUploads(userData.transactionData?.optionLedgerUploads || []);
      setSecondaryTransactionUploads(userData.transactionData?.secondaryTransactionUploads || []);
      setTermSheetsUploads(userData.transactionData?.termSheetsUploads || []);
      setSafeOrConvertibleNoteUploads(userData.transactionData?.safeOrConvertibleNoteUploads || []);
      setFinancialForecastUploads(userData.transactionData?.financialForecastUploads || []);
      setAdditionalFinancialUploads(userData.transactionData?.additionalFinancialUploads || []);
    }
  }, [userData]);

  function getInitials(name) {
    if (!name) return name;
    const splitName = name.split(' ');
    const firstInitial = Array.from(splitName[0])[0];
    const lastInitial = splitName[1] ? Array.from(splitName[1])[0] : '';
    return firstInitial + lastInitial;
  }

  async function assignUserInDB(user, type) {
    let roleType = type.toLowerCase();
    roleType = roleType.split(' ').reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)));
    const { metaData } = userData;
    const {
      investorCompanyId,
      portfolioCompanyId,
      transactionId,
      projectId,
    } = metaData;
    const userId = getUserId();
    const enterpriseCompanyId = getUserCompanyId();
    const assigneeData = {
      enterpriseCompanyId,
      investorCompanyId,
      portfolioCompanyId,
      transactionId,
      projectId,
      assigneeUserId: user.accountId,
      role: roleType,
      requestUserId: userId,
    };
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/transactions/asc820/update-role-assignee`,
        await createFetchHeaders('post', assigneeData, true),
      );
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  async function updatePaymentInfoInDB() {
    try {
      const enterpriseCompanyId = getUserCompanyId();
      const requestUserId = getUserId();
      const { transactionId, investorCompanyId } = userData.transactionData;
      const paymentData = {
        ...paymentInfo,
        enterpriseCompanyId,
        investorCompanyId,
        transactionId,
        requestUserId,
      };
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/transactions/asc820/update-fees`,
        await createFetchHeaders('post', paymentData, true),
      );
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setPaymentInfoIsSaving(false);
      setEditingPaymentInfo(false);
    }
  }

  useEffect(() => { if (paymentInfoIsSaving) updatePaymentInfoInDB(); }, [paymentInfoIsSaving]);

  async function updateGenCompanyData() {
    try {
      const updatedGenData = {
        ...userData.transactionData,
        basicInfo: {
          ...userData.transactionData.basicInfo,
          companyIndustry: companyInfo?.companyIndustry,
          fiscalYearEnd: companyInfo?.fiscalYearEnd,
        },
        capTableUploads,
        articlesUploads,
        optionLedgerUploads,
        secondaryTransactionUploads,
        termSheetsUploads,
        safeOrConvertibleNoteUploads,
        financialForecastUploads,
        additionalFinancialUploads,
        whiteGlove: companyInfo?.whiteGlove,
        userHasAccess: companyInfo?.userHasAccess,
        rollForward: companyInfo?.rollForward,
      };
      const companyData = {
        ...userData.companyData,
        ...companyInfo,
        companyName: companyInfo.portfolioCompanyName,
      };
      let compUpdateResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/companies/portfolio/update-company-data`,
        await createFetchHeaders('post', companyData, true),
      );
      let updatedTransactionData = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/transactions/asc820/enterprise-update-transaction`,
        await createFetchHeaders('post', updatedGenData, true),
      );
      compUpdateResponse = await compUpdateResponse.json();
      updatedTransactionData = await updatedTransactionData.json();
      setCompanyInfo({
        portfolioCompanyName: compUpdateResponse.companyName,
        companyIndustry: userData.transactionData.basicInfo?.companyIndustry,
        whiteGlove: userData.metaData?.whiteGlove,
        userHasAccess: updatedTransactionData?.userHasAccess,
        capIqIdentifier: compUpdateResponse.capIqIdentifier,
        description: compUpdateResponse.description,
        customDescription: compUpdateResponse.customDescription,
      });
      setUserData({ ...userData, companyData: { ...compUpdateResponse }, transactionData: { ...updatedTransactionData } });
      if (compUpdateResponse.description) {
        setCustomCompanyDescription(true);
      }
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setEditingUploads(false);
      setEditingCompanyInfo(false);
      setUploadsSaving(false);
      setCompanyInfoIsSaving(false);
      setEditingCompanyDesc(false);
      setCompanyDescSaving(false);
    }
  }

  async function updateAssigneeData(value, type) {
    let newAssignmentData;
    setAssigneesData((prevAssigneesData) => {
      const newData = [...prevAssigneesData];
      if (type === 'Data entry') {
        setDataEntry(value[0]);
        newData[0] = enterpriseUsers[value[1]];
        newAssignmentData = enterpriseUsers[value[1]];
      } else if (type === 'Preparer') {
        setPreparer(value[0]);
        newData[1] = enterpriseUsers[value[1]];
        newAssignmentData = enterpriseUsers[value[1]];
      } else if (type === 'Reviewer') {
        setReviewer(value[0]);
        newData[2] = enterpriseUsers[value[1]];
        newAssignmentData = enterpriseUsers[value[1]];
      }
      return newData;
    });
    await assignUserInDB(newAssignmentData, type);
  }

  return (
    <main className="GeneralInfo">
      <div className="info-dropdown">
        <div
          role="button"
          className="header-row"
          onClick={() => { setCompanyInfoDropdownHeight(companyInfoDropdownHeight ? 0 : 'auto'); }}
          onKeyDown={(e) => { if (e.key === 'Enter') setCompanyInfoDropdownHeight(companyInfoDropdownHeight ? 0 : 'auto'); }}
          tabIndex={0}
        >
          <IconButton
            className="chevron-btn"
            onClick={() => { setCompanyInfoDropdownHeight(companyInfoDropdownHeight ? 0 : 'auto'); }}
          >
            <ExpandCircleDownOutlinedIcon className={`${companyInfoDropdownHeight ? 'upward' : 'downward'}-circle-chevron`} />
          </IconButton>
          <h5>General company information</h5>
          <EditButton
            isEditing={editingCompanyInfo}
            setIsEditing={setEditingCompanyInfo}
            infoSaving={companyInfoIsSaving}
            setInfoSaving={setCompanyInfoIsSaving}
            updateFunction={() => updateGenCompanyData()}
          />
        </div>
        <div>
          <AnimateHeight duration={500} height={companyInfoDropdownHeight}>
            <div className="info-block">
              <div className="data-tiles first-row">
                <InfoTile
                  label="Company name"
                  dataName="portfolioCompanyName"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setValues={setCompanyInfo}
                />
                <InfoTile
                  label="Cap IQ Identifier (optional)"
                  dataName="capIqIdentifier"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setValues={setCompanyInfo}
                />
                <InfoTile
                  label="Company industry"
                  dataName="companyIndustry"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setValues={setCompanyInfo}
                />
              </div>
              <div className="data-tiles">
                <InfoTile
                  label="Roll forward"
                  dataName="rollForward"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setValues={setCompanyInfo}
                  isRadio
                />
                <InfoTile
                  label="White glove service"
                  dataName="whiteGlove"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setValues={setCompanyInfo}
                  isRadio
                />
                <InfoTile
                  label="Client has access to company&apos;s financial information?"
                  dataName="userHasAccess"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setValues={setCompanyInfo}
                  isRadio
                />
              </div>
              <div className="data-tiles">
                <InfoTile
                  label="Fiscal year end"
                  dataName="fiscalYearEnd"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setValues={setCompanyInfo}
                  dropdownValues={[
                    'March 31st',
                    'June 30th',
                    'September 30th',
                    'December 31st',
                  ]}
                />
              </div>
            </div>
            <div className="info-block">
              <p className="info-block-label">
                Assignments -
                <span> Assign or reassign people to work on this company.</span>
              </p>
              <div className="assignees-avatars-row">
                {['Data entry', 'Preparer', 'Reviewer'].map((type, idx) => (
                  <div className="assignees-avatars-block" key={type}>
                    <SortBy
                      sortByList={enterpriseUsers.map((user) => `${user.firstName} ${user.lastName}`)}
                      sortBy=""
                      setSortBy={(value) => updateAssigneeData(value, type)}
                      type={type}
                      isButton
                      className="info-assigned-to"
                      tooltip
                      tooltipCopy={type === 'Data entry' ?
                        'Inputs and check accuracy of data' :
                        type === 'Preparer' ?
                          'Makes selections to come to initial values' :
                          'Reviews and determines if assumptions, selections, and/or conclusions are reasonable'
                      }
                    />
                    <Avatar
                      className="assignees-avatars"
                      style={{
                        backgroundColor: assigneesData[idx] ? assigneesData[idx].avatarColor : '#DDD9DE',
                        color: darkInitials.includes(assigneesData[idx]?.avatarColor) ? '#49454F' : '#DDD9DE',
                      }}
                    >
                      {type === 'Data entry' && dataEntry ?
                        getInitials(toTitleCase(dataEntry)) :
                        type === 'Preparer' && preparer ?
                          getInitials(toTitleCase(preparer)) :
                          type === 'Reviewer' && reviewer ?
                            getInitials(toTitleCase(reviewer)) :
                            <PersonOutlineOutlinedIcon />}
                    </Avatar>
                  </div>
                ))}
              </div>
            </div>

            <div className="info-block company-description">
              <div className="header-btn-container">
                <p className="info-block-label">Company description</p>
              </div>
              {userData.companyData?.description ? (
                <div>
                  <p className="info-block-label capIQ">Pulled from Cap IQ</p>
                  <TextField
                    className="capIQ-desc"
                    multiline
                    disabled
                    placeholder="This company description will appear in this company's report."
                    value={companyInfo.description || ''}
                  />
                  <div className="custom-desc-copy">
                    <p className="report">
                      <span>For this companies ASC 820 report</span>
                      <br />
                      We&apos;ve provided the Cap IQ company description above so you can see what was pulled from Cap IQ,
                      even if you choose to edit the company description below. The below will be used for the report.
                      You can leave it as is, or choose to edit it at any time.
                    </p>
                    <EditButton
                      isEditing={editingCompanyDesc}
                      setIsEditing={setEditingCompanyDesc}
                      infoSaving={companyDescSaving}
                      setInfoSaving={setCompanyDescSaving}
                      updateFunction={() => updateGenCompanyData()}
                    />
                  </div>
                  {editingCompanyDesc ? (
                    <TextField
                      className="edit-company-desc"
                      multiline
                      placeholder="This company description will appear in this company's report."
                      value={companyInfo.customDescription || ''}
                      onChange={(e) => setCompanyInfo({ ...companyInfo, customDescription: e.target.value })}
                    />
                  ) : (
                    <p id="non-textfield-description">{companyInfo.customDescription}</p>
                  )}
                </div>
              ) : (
                <>
                  <div className="custom-desc-block">
                    <div>
                      <Checkbox
                        className="capIQ-checkbox"
                        checked={customCompanyDescription}
                        onChange={(e) => {
                          setCustomCompanyDescription(e.target.checked);
                          if (!companyInfo.customDescription) {
                            setEditingCompanyDesc(true);
                          }
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      <span className="">
                        Check if&nbsp;
                        {companyInfo.portfolioCompanyName}
                        &nbsp;does not have a CapIq Identifier. If they don&apos;t you&apos;ll need to provide a company description.
                      </span>
                    </div>
                    {customCompanyDescription && (
                      <EditButton
                        isEditing={editingCompanyDesc}
                        setIsEditing={setEditingCompanyDesc}
                        infoSaving={companyDescSaving}
                        setInfoSaving={setCompanyDescSaving}
                        updateFunction={() => updateGenCompanyData()}
                      />
                    )}
                  </div>
                  {editingCompanyDesc && customCompanyDescription ? (
                    <TextField
                      className="no-capIQ"
                      multiline
                      maxRows={4}
                      placeholder="This company description will appear in this company's report."
                      value={companyInfo.customDescription || ''}
                      onChange={(e) => setCompanyInfo({ ...companyInfo, customDescription: e.target.value })}
                    />
                  ) : customCompanyDescription ? (
                    <p id="non-textfield-description">{userData.companyData.customDescription}</p>
                  ) : null}
                </>
              )}
            </div>
            <div className="info-block">
              <p className="info-block-label">Company specific notes</p>
              <Notes transactionData={userData?.transactionData} notesList={notesList} noteListLoading={noteListLoading} />
            </div>
          </AnimateHeight>
        </div>
      </div>
      <div className="info-dropdown">
        <div
          role="button"
          className="header-row"
          onClick={() => { setAdditionalUsersDropdownHeight(additionalUsersDropdownHeight ? 0 : 'auto'); }}
          onKeyDown={(e) => { if (e.key === 'Enter') setAdditionalUsersDropdownHeight(additionalUsersDropdownHeight ? 0 : 'auto'); }}
          tabIndex={0}
        >
          <IconButton
            className="chevron-btn"
            onClick={() => { setAdditionalUsersDropdownHeight(additionalUsersDropdownHeight ? 0 : 'auto'); }}
          >
            <ExpandCircleDownOutlinedIcon
              className={`${additionalUsersDropdownHeight ? 'upward' : 'downward'}-circle-chevron`}
              sx={{ transition: `transform ${0.5}s linear` }}
            />
          </IconButton>
          <h5>Company fees</h5>
          <EditButton
            isEditing={editingPaymentInfo}
            setIsEditing={setEditingPaymentInfo}
            infoSaving={paymentInfoIsSaving}
            setInfoSaving={setPaymentInfoIsSaving}
          />
        </div>
        <div>
          <AnimateHeight duration={500} height={additionalUsersDropdownHeight}>
            <div className="info-block">
              <div className="data-tiles paymentInfo">
                <InfoTile
                  label="To-date paid"
                  dataName="paidToDate"
                  isEditing={editingPaymentInfo}
                  values={paymentInfo}
                  setValues={setPaymentInfo}
                  add$
                  addCommas
                />
                <InfoTile
                  label="Total company fee"
                  dataName="totalCompanyFee"
                  isEditing={editingPaymentInfo}
                  values={paymentInfo}
                  setValues={setPaymentInfo}
                  add$
                  addCommas
                />
              </div>
            </div>
          </AnimateHeight>
        </div>
      </div>
      <hr />
      <div className="info-dropdown">
        <div
          role="button"
          className="header-row"
          onClick={() => { setPaymentInfoDropdownHeight(paymentInfoDropdownHeight ? 0 : 'auto'); }}
          onKeyDown={(e) => { if (e.key === 'Enter') setPaymentInfoDropdownHeight(paymentInfoDropdownHeight ? 0 : 'auto'); }}
          tabIndex={0}
        >
          <IconButton
            className="chevron-btn"
            onClick={() => { setPaymentInfoDropdownHeight(paymentInfoDropdownHeight ? 0 : 'auto'); }}
          >
            <ExpandCircleDownOutlinedIcon
              className={`${paymentInfoDropdownHeight ? 'upward' : 'downward'}-circle-chevron`}
              sx={{ transition: `transform ${0.5}s linear` }}
            />
          </IconButton>
          <h5>Uploads</h5>
          <EditButton
            isEditing={editingUploads}
            setIsEditing={setEditingUploads}
            infoSaving={uploadsSaving}
            setInfoSaving={setUploadsSaving}
            updateFunction={() => updateGenCompanyData()}
          />
        </div>
        <div className="input-block">
          <AnimateHeight duration={500} height={paymentInfoDropdownHeight}>
            <div className="info-block">
              <div className="input-tiles">
                <div className="info-tile">
                  <p>Upload: Cap Table</p>
                  <UploadTile
                    uploadData={capTableUploads}
                    setUploadData={setCapTableUploads}
                    isEditing={editingUploads}
                    portfolioCompanyId={userData.metaData.portfolioCompanyId}
                    transactionId={userData.metaData.transactionId}
                    genInfo
                  />
                </div>
                <div className="info-tile">
                  <p>Upload: Articles of incorporation</p>
                  <UploadTile
                    uploadData={articlesUploads}
                    setUploadData={setArticlesUploads}
                    isEditing={editingUploads}
                    portfolioCompanyId={userData.metaData.portfolioCompanyId}
                    transactionId={userData.metaData.transactionId}
                    genInfo
                  />
                </div>
                <div className="info-tile">
                  <p>Upload: Option ledger</p>
                  <UploadTile
                    uploadData={optionLedgerUploads}
                    setUploadData={setOptionLedgerUploads}
                    isEditing={editingUploads}
                    portfolioCompanyId={userData.metaData.portfolioCompanyId}
                    transactionId={userData.metaData.transactionId}
                    genInfo
                  />
                </div>
                <div className="info-tile">
                  <p>Upload: Secondary transaction documents</p>
                  <UploadTile
                    uploadData={secondaryTransactionUploads}
                    setUploadData={setSecondaryTransactionUploads}
                    isEditing={editingUploads}
                    portfolioCompanyId={userData.metaData.portfolioCompanyId}
                    transactionId={userData.metaData.transactionId}
                    genInfo
                  />
                </div>
                <div className="info-tile">
                  <p>Upload: Term sheets</p>
                  <UploadTile
                    uploadData={termSheetsUploads}
                    setUploadData={setTermSheetsUploads}
                    isEditing={editingUploads}
                    portfolioCompanyId={userData.metaData.portfolioCompanyId}
                    transactionId={userData.metaData.transactionId}
                    genInfo
                  />
                </div>
                <div className="info-tile">
                  <p>Upload: Safe or Convertible notes</p>
                  <UploadTile
                    uploadData={safeOrConvertibleNoteUploads}
                    setUploadData={setSafeOrConvertibleNoteUploads}
                    isEditing={editingUploads}
                    portfolioCompanyId={userData.metaData.portfolioCompanyId}
                    transactionId={userData.metaData.transactionId}
                    genInfo
                  />
                </div>
                <div className="info-tile">
                  <p>Upload: Financial forecasts</p>
                  <UploadTile
                    uploadData={financialForecastUploads}
                    setUploadData={setFinancialForecastUploads}
                    isEditing={editingUploads}
                    portfolioCompanyId={userData.metaData.portfolioCompanyId}
                    transactionId={userData.metaData.transactionId}
                    genInfo
                  />
                </div>
                <div className="info-tile">
                  <p>Upload: Additional financials: (Balance sheets, income statements, etc.)</p>
                  <UploadTile
                    uploadData={additionalFinancialUploads}
                    setUploadData={setAdditionalFinancialUploads}
                    isEditing={editingUploads}
                    portfolioCompanyId={userData.metaData.portfolioCompanyId}
                    transactionId={userData.metaData.transactionId}
                    genInfo
                  />
                </div>
              </div>
            </div>
          </AnimateHeight>
        </div>
      </div>
    </main>
  );
}

GeneralInfo.propTypes = {
  userData: PropTypes.objectOf(PropTypes.any).isRequired,
  setUserData: PropTypes.func.isRequired,
};
