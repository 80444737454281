import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

import { copy } from '../../../utils';

import './Notes.scss';
import { getUserCompanyId, getUserId, getUserName } from '../../../utils/auth';
import { createFetchHeaders } from '../../../utils/apiCalls';
import { ErrorMessageContext } from '../../../lib/contextLib';

export default function Notes({ transactionData, notesList }) {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);
  const [noteList, setNoteList] = useState([]);
  const [noteContent, setNoteContent] = useState('');
  const [editNoteContent, setEditNoteContent] = useState('');

  const [noteMenuAnchorEl, setNoteMenuAnchorEl] = useState(null);
  const [noteMenuIsOpen, setNoteMenuIsOpen] = useState(false);
  const [editNoteIndex, setEditNoteIndex] = useState(-1);

  async function postNote(isEdit, noteIndex) {
    try {
      const requestUserId = getUserId();
      const enterpriseCompanyId = getUserCompanyId();
      const noteAuthor = getUserName();
      const createdDatetime = moment().format('YYYY-MM-DD HH:mm:ss');
      const noteToSend = {
        enterpriseCompanyId,
        transactionId: transactionData?.transactionId,
        portfolioCompanyId: transactionData?.portfolioCompanyId,
        requestUserId,
        note: isEdit ? editNoteContent : noteContent,
        noteAuthor,
        createdDatetime,
      };
      const requestOptions = await createFetchHeaders('post', noteToSend, true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/notes/transaction-note/write-transaction-note`, requestOptions);
      const cloneArray = copy(noteList);
      if (isEdit) {
        cloneArray.splice(noteIndex, 1, noteToSend);
        setNoteList(copy(cloneArray));
      }
      if (response.ok) {
        cloneArray.push(noteToSend);
        cloneArray.splice(noteIndex, 1, noteToSend);
        setNoteList(cloneArray);
      }
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  useEffect(() => {
    if (notesList) setNoteList(notesList);
  }, [notesList]);

  function handleNoteMenuClose() {
    setNoteMenuIsOpen(false);
    setNoteMenuAnchorEl(null);
  }

  function handleNoteMenuOpen(e, noteIndex) {
    setNoteMenuAnchorEl(e.currentTarget);
    setNoteMenuIsOpen(true);
    setEditNoteIndex(noteIndex);
  }

  async function deletingNote(noteToDelete, noteIndex, andEdit) {
    const requestOptions = await createFetchHeaders('delete', noteToDelete, true);
    try {
      const enterpriseCompanyId = getUserCompanyId();
      const requestUserId = getUserId();
      const urlParams = `${enterpriseCompanyId}&${noteToDelete.companyId}&${noteToDelete.transactionId}&${noteToDelete.noteId}&${requestUserId}`;
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/notes/transaction-note/delete-transaction-note/${urlParams}`,
        requestOptions,
      );
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      if (andEdit) {
        await postNote(true, noteIndex);
      } else {
        noteList.splice(noteIndex, 1);
        setNoteList(copy(noteList));
      }
    }
  }

  async function deleteNote(noteIndex, andEdit) {
    const deleteNoteData = {
      companyId: noteList[noteIndex].companyId,
      transactionId: noteList[noteIndex].transactionId,
      userId: noteList[noteIndex].insertUserId,
      noteId: noteList[noteIndex].noteId,
      createdDatetime: noteList[noteIndex].createdDatetime,
      noteAuthor: noteList[noteIndex].currentAdminName,
    };
    const newNoteList = copy(noteList);
    newNoteList[noteIndex].isBeingEdited = andEdit;
    newNoteList[noteIndex].editNote = false;
    setNoteList(newNoteList);
    await deletingNote(deleteNoteData, noteIndex, andEdit);
  }

  async function editNote() { await deleteNote(editNoteIndex, true); }

  function switchNoteToEdit(reset) {
    const newNoteArr = [];
    noteList.forEach((note) => {
      newNoteArr.push({
        ...note,
        editNote: false,
      });
    });
    if (!reset) newNoteArr[editNoteIndex].editNote = true;
    setNoteList(newNoteArr);
  }

  return (
    <main className="Notes">
      <div className="note-list">
        {noteList.length > 0 && (noteList.map((note, noteIndex) => {
          if (note.editNote) {
            return (
              <React.Fragment key={`${Date.now() + note.createdDatetime + Math.random(0, 1000)}`}>
                <div className="edit-note">
                  <TextField
                    multiline
                    maxRows={4}
                    placeholder="Type note"
                    value={editNoteContent}
                    onChange={(e) => setEditNoteContent(e.target.value)}
                    inputProps={{ maxLength: 500 }}
                  />
                  <div className="edit-note-btns">
                    <Button
                      className="edit-note-cancel-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        switchNoteToEdit(true);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="edit-note-save-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        editNote(noteIndex);
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
                <div className="note-length">
                  {editNoteContent.length}
                  / 500
                </div>
              </React.Fragment>
            );
          }
          return (
            <div
              key={`${Date.now() + note.createdDatetime + Math.random(0, 1000)}`}
              className={`note${note.isBeingEdited ? ' isEditing' : ''}`}
            >
              <span className="note-date-time">
                <span className="note-author">{note.noteAuthor}</span>
                <span className="timestamp">
                  {' '}
                  {moment(note.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YY')}
                  {' '}
                  at
                  {' '}
                  {moment(note.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('hh:mma')}
                </span>
              </span>
              <div className="note-content">
                <span>
                  {note.note}
                </span>
              </div>
              <IconButton
                className="note-vert-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNoteMenuOpen(e, noteIndex);
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                className="note-menu"
                anchorEl={noteMenuAnchorEl}
                open={noteMenuIsOpen}
                onClose={() => handleNoteMenuClose()}
                disableScrollLock
              >
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    switchNoteToEdit();
                    setEditNoteContent(noteList[editNoteIndex].note);
                    handleNoteMenuClose();
                  }}
                >
                  <EditIcon />
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteNote(editNoteIndex);
                    handleNoteMenuClose();
                  }}
                >
                  <DeleteOutlineIcon />
                  Delete
                </MenuItem>
              </Menu>
            </div>
          );
        }))}
      </div>
      <TextField
        multiline
        maxRows={4}
        placeholder="Type note"
        className="note-field"
        value={noteContent}
        onChange={(e) => setNoteContent(e.target.value)}
        onClick={(e) => { e.stopPropagation(); }}
        onKeyDown={(e) => { if (e.key === 'Enter') e.stopPropagation(); }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={(e) => {
                e.stopPropagation();
                postNote();
                setNoteContent('');
              }}
            >
              <SendOutlinedIcon />
            </InputAdornment>
          ),
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ maxLength: 500 }}
      />
      <div className="note-length">
        {noteContent.length}
        / 500
      </div>
    </main>
  );
}

Notes.propTypes = {
  transactionData: PropTypes.objectOf(PropTypes.any),
  notesList: PropTypes.any,
};

Notes.defaultProps = {
  notesList: [],
  transactionData: {},
};
