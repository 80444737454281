import React, { useContext, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import './CompanyCard.scss';
import {
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import StatusBadgeDropdown from './StatusBadgeDropdown';
import { darkInitials } from '../../utils/globals';
import { copy, getInitials } from '../../utils';
import { getUserCompanyId, getUserId } from '../../utils/auth';
import { ErrorMessageContext } from '../../lib/contextLib';
import { createFetchHeaders } from '../../utils/apiCalls';

export default function CompanyCard({
  company,
  index,
  enterpriseUsers,
  setTransactionToView,
  projectCompanies,
  setProjectCompanies,
}) {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);
  const { dataEntry, preparer, reviewer } = company.assignees;
  const companyAssignees = [dataEntry, preparer, reviewer];
  const [insideElementHover, setInsideElementHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLocked, setIsLocked] = useState(parseInt(company.isLocked, 10));
  const [deletingCompany, setDeletingCompany] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);
  const enterpriseCompanyId = getUserCompanyId();
  const [currentStatus, setCurrentStatus] = useState(company.status);
  const [blockerStatus, setBlockerStatus] = useState(company.blockerStatus);
  const requestUserId = getUserId();
  const {
    transactionId,
    investorCompanyId,
    portfolioCompanyId,
    projectId,
  } = company;

  const companyData = {
    enterpriseCompanyId,
    investorCompanyId,
    portfolioCompanyId,
    projectId,
    transactionId,
    requestUserId,
  };

  async function updateTransactionLock(unlockOrLock) {
    try {
      if (unlockOrLock === 'lock') setCurrentStatus('820 Data entry');
      else setCurrentStatus('Client in-progress');
      setBlockerStatus('');
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/transactions/asc820/enterprise-${unlockOrLock}-transaction`,
        await createFetchHeaders('post', companyData, true),
      );
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  async function deleteCompany() {
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/transactions/asc820/delete-transaction/${enterpriseCompanyId}&${requestUserId}`,
        await createFetchHeaders('post', companyData, true),
      );
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      const companiesCopy = copy(projectCompanies);
      companiesCopy.splice(index, 1);
      setProjectCompanies(companiesCopy);
      setDeletingCompany(false);
    }
  }

  return (
    <div className="ProjectCompanyCard">
      <Draggable
        draggableId={company.resource.toString()}
        index={index}
        key={company.resource}
      >
        {(provided) => (
          <div
            role="button"
            className={`company-card${!insideElementHover ? ' can-hover' : ''}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            onClick={() => {
              if (!insideElementHover) {
                setTransactionToView(company);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') setTransactionToView(company);
            }}
            tabIndex={0}
          >
            <div className="company-info">
              <Avatar>{index + 1}</Avatar>
              <h6>{company.portfolioCompanyName}</h6>
            </div>
            <div
              className="company-status-info"
              onMouseEnter={() => { if (!insideElementHover) setInsideElementHover(true); }}
              onMouseLeave={() => setInsideElementHover(false)}
            >
              <StatusBadgeDropdown
                setInsideElementHover={setInsideElementHover}
                company={company}
                setProjectCompanies={setProjectCompanies}
                projectCompanies={projectCompanies}
                currentStatus={currentStatus}
                setCurrentStatus={setCurrentStatus}
                blockerStatus={blockerStatus}
                setBlockerStatus={setBlockerStatus}
              />
              <div className={`assignee-avatar ${companyAssignees.every((user) => user.accountId !== 'not assigned') ? '' : 'hidden'}`}>
                {companyAssignees.reduce((avatars, assignee, idx) => {
                  if ((assignee.accountId !== 'not assigned' || assignee.email !== 'not assigned') && enterpriseUsers) {
                    const foundAssignee = enterpriseUsers.find((u) => u.accountId === assignee.accountId);
                    if (foundAssignee) {
                      avatars.push(
                        <Tooltip
                          key={`${foundAssignee.accountId}+=+=${idx + 1}`}
                          disableInteractive
                          title={(
                            <Typography style={{ whiteSpace: 'pre-line' }}>
                              {/* eslint-disable-next-line */}
                              {`${foundAssignee.firstName} ${foundAssignee.lastName}\nAssignment: ${idx === 0 ? 'Data entry' : idx === 1 ? 'Preparer' : 'Reviewer'}`}
                            </Typography>
                          )}
                          PopperProps={{ className: 'bottom-arrow-tooltip' }}
                          placement="bottom"
                          arrow
                        >
                          <Avatar
                            style={{
                              backgroundColor: foundAssignee.avatarColor,
                              color: darkInitials.includes(foundAssignee.avatarColor) ? '#49454F' : '#FAFAFD',
                            }}
                          >
                            {getInitials(`${foundAssignee?.firstName} ${foundAssignee?.lastName}`)}
                          </Avatar>
                        </Tooltip>,
                      );
                    }
                  }
                  return avatars;
                }, [])}
              </div>
              <IconButton
                className={`menu-icon ${anchorEl ? 'menu-open' : ''}`}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                aria-haspopup="true"
              >
                <MoreVertOutlinedIcon />
              </IconButton>
              <Menu
                className="lock-transaction-menu"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => {
                  setAnchorEl(null);
                  setTimeout(() => { setAreYouSure(false); }, 200);
                  setInsideElementHover(false);
                }}
                disableScrollLock
              >
                <MenuItem
                  className={`${isLocked ? '' : 'active'}`}
                  onClick={() => {
                    setIsLocked(0);
                    updateTransactionLock('unlock');
                    setAnchorEl(null);
                    setInsideElementHover(false);
                  }}
                >
                  <LockOpenOutlinedIcon />
                  &nbsp;
                  {isLocked ? 'Unlock so ' : 'Unlocked '}
                  client can edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsLocked(1);
                    updateTransactionLock('lock');
                    setAnchorEl(null);
                    setInsideElementHover(false);
                  }}
                  className={`${isLocked ? 'active' : ''}`}
                >
                  <LockOutlinedIcon />
                  &nbsp;
                  {isLocked ? 'Locked ' : 'Lock so '}
                  client can&apos;t edit
                </MenuItem>
                <MenuItem
                  onClick={() => setAreYouSure(!areYouSure)}
                >
                  {areYouSure ? (
                    <>
                      Are you sure?
                      <Button
                        onClick={() => {
                          setDeletingCompany(true);
                          setAreYouSure(!areYouSure);
                          deleteCompany();
                          setInsideElementHover(false);
                        }}
                      >
                        <CheckOutlinedIcon />
                      </Button>
                      <Button
                        onClick={() => {
                          setAreYouSure(!areYouSure);
                          setInsideElementHover(false);
                        }}
                      >
                        <ClearOutlinedIcon />
                      </Button>
                    </>
                  ) : deletingCompany ? (
                    <>
                      <div className="dots-circle-spinner" />
                      Deleting company
                    </>
                  ) : (
                    <>
                      <DeleteOutlineOutlinedIcon style={{ marginRight: '0.25rem' }} />
                      Delete company
                    </>
                  )}
                </MenuItem>
              </Menu>
              <div
                className="drag-handle"
                // eslint-disable-next-line
                {...provided.dragHandleProps}
              >
                <DragHandleOutlinedIcon />
              </div>
            </div>
          </div>
        )
        }
      </Draggable>
    </div>
  );
}

CompanyCard.propTypes = {
  company: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  enterpriseUsers: PropTypes.array.isRequired,
  setTransactionToView: PropTypes.func.isRequired,
  setProjectCompanies: PropTypes.func.isRequired,
  projectCompanies: PropTypes.array.isRequired,
};
