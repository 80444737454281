export default function determineBadgeColor(status) {
  const blackStatuses = ['Client not started 820', 'Client in-progress', 'Audit draft', 'Draft delivered'];
  const purpleStatuses = ['Big iteration', 'Small iteration', 'On hold'];
  const greenStatuses = ['820 Done'];
  const redStatuses = ['Stuck'];
  if (blackStatuses.includes(status)) return 'black';
  if (purpleStatuses.includes(status)) return 'purple';
  if (greenStatuses.includes(status)) return 'green';
  if (redStatuses.includes(status)) return 'red';
  return 'blue';
}
