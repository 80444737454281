import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';

import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DataThresholdingOutlinedIcon from '@mui/icons-material/DataThresholdingOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';

import HowCanIHelpDrawing from '../images/support_sidebar.svg';
import ASC820Dialog from './ASC820Dialog';

import './SideNavBar.scss';

export default function SideNavBar({
  tooltips,
  setTooltips,
  currentUrl,
}) {
  const nav = useNavigate();
  const [navBarIsOpen, setNavBarIsOpen] = useState(false);
  const [startASC820, setStartASC820] = useState(false);

  return (
    <>
      <div className="SideNavBar">
        <Button
          className={`nav-toggle ${navBarIsOpen ? 'isOpen' : ''} ${tooltips[1] ? 'first' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            setNavBarIsOpen(!navBarIsOpen);
            setTooltips([tooltips[0], tooltips[1] ? !tooltips[1] : false]);
          }}
        >
          <Tooltip
            id="first-tooltip"
            open={tooltips[1] === null ? false : tooltips[1]}
            placement="bottom-end"
            arrow
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={(
              <>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setTooltips([tooltips[0], !tooltips[1]]);
                  }}
                >
                  <CloseIcon />
                </Button>
                <p>
                  Once you start adding to this
                  <br />
                  account you can use this hamburger
                  <br />
                  icon to:
                </p>
                <ul>
                  <li>see all of your investor firms,</li>
                  <li>see all ASC 820 statuses,</li>
                  <li>or simply come back home.</li>
                </ul>
              </>
            )}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            {navBarIsOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </Tooltip>
        </Button>
        <div className={`nav-bar ${navBarIsOpen ? 'open' : 'closed'}`}>
          <Button
            className={`nav-link ${currentUrl.length === 0 ? 'active' : ''}`}
            onClick={() => {
              nav('/');
              setNavBarIsOpen(!navBarIsOpen);
            }}
          >
            <HomeOutlinedIcon />
            Home
          </Button>
          <hr />
          <span>My initio</span>
          <Button
            className={`nav-link ${currentUrl === '820-valuations' ? 'active' : ''}`}
            onClick={() => {
              nav('/');
              setNavBarIsOpen(!navBarIsOpen);
            }}
          >
            <DataThresholdingOutlinedIcon />
            ASC 820 valuations
          </Button>
          <Button className="nav-link">
            <AccountBalanceOutlinedIcon />
            Investor firms
          </Button>
          <hr />
          <Button
            className={`nav-link ${currentUrl === 'profile-settings' ? 'active' : ''}`}
            onClick={() => {
              nav('/profile-settings');
              setNavBarIsOpen(!navBarIsOpen);
            }}
          >
            <AccountCircleOutlinedIcon />
            Profile
          </Button>
          <hr />
          <div className="how-can-i-help">
            <img src={HowCanIHelpDrawing} alt="how can I help?" />
            Support
            <br />
            555.555.5555
          </div>
        </div>
      </div>
      <ASC820Dialog startASC820={startASC820} setStartASC820={setStartASC820} />
    </>
  );
}

SideNavBar.propTypes = {
  tooltips: PropTypes.array.isRequired,
  setTooltips: PropTypes.func.isRequired,
  currentUrl: PropTypes.string.isRequired,
};
