import { Auth } from 'aws-amplify';

export async function createFetchHeaders(method, body, addAuthToken) {
  const authHeaders = { method, headers: { 'Content-Type': 'application/json' }, ...(Object.keys(body).length && { body: JSON.stringify(body) }) };

  if (addAuthToken) {
    const currentUserSession = await Auth.currentSession();
    const JWT = currentUserSession.getIdToken().getJwtToken();
    authHeaders.headers.Authorization = `Bearer ${JWT}`;
  }

  return authHeaders;
}

export async function sendHtmlEmail(recipient, templateName, templateData, metadata = {}) {
  const emailData = {
    recipient,
    templateName,
    templateData,
    metadata,
  };
  await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/email/send-html-email`,
    await createFetchHeaders('post', emailData, true),
  );
}

export default {
  createFetchHeaders,
  sendHtmlEmail,
};
