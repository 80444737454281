import React from 'react';
import PropTypes from 'prop-types';

import { commaEvery3rdChar } from '../../../utils';

import './SummariesTable.scss';

export default function SummariesTable({
  tabToView,
  filterSelected,
  summariesData,
  reSummarizing,
  showCompanyInfo,
  showRevenue,
  showEBITDA,
  showGrossProfit,
  showMarketCap,
  showVolatilityValDate,
  showVolatilityTrDate,
  growthFilterValues,
  multiplesFilterValues,
  yearsFilterValues,
  fiscalPeriodFilterValues,
  summariesRef,
  transactionDate,
  valuationDate,
}) {
  const getYear = (dataIndex) => {
    const increment = dataIndex / 4 + 0.25;
    return `${increment.toFixed(increment.toFixed(2).toString().charAt(increment.toFixed(2).toString().length - 1) === '0' ? 1 : 2)} year`;
  };

  const getPrice = (dataName, index) => summariesData[dataName]?.[index] ?
    commaEvery3rdChar((summariesData[dataName][index] * 1000000).toFixed(0)) : '-';

  const getPerc = (dataName, index, isGrossMargin) => summariesData[dataName]?.[index] ?
    `${(summariesData[dataName][index] * (!isGrossMargin ? 100 : 1)).toFixed(2)}%` : '-';

  const getMultiple = (dataName, index) => summariesData[dataName]?.[index] ? `${(summariesData[dataName][index]).toFixed(2)}x` : '-';

  return (
    <main className="SummariesTable">
      <div className="fixed-left">
        <div className="summaries-table-rows">
          {summariesData.stats?.length && ['Maximum', '95th percentile', '90th percentile',
            '75th percentile', 'Median', '25th percentile',
            '10th percentile', '5th percentile', 'Minimum', 'Average',
          ].map((rowName) => (
            <div key={rowName} className="summaries-table-row-data">
              <div className="row-data-group summaries-list">
                <div className="table-cell">{rowName}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="right-scrollable">
        {reSummarizing && <div className="loading-overlay"><div className="dots-circle-spinner" /></div>}
        <div className="summaries-table-rows" ref={summariesRef}>
          {summariesData.stats?.length && ['Maximum', '95th percentile', '90th percentile',
            '75th percentile', 'Average', 'Median', '25th percentile',
            '10th percentile', '5th percentile', 'Minimum',
          ].map((rowName, index) => (
            <div key={rowName} className="summaries-table-row-data">
              {(!filterSelected || showCompanyInfo || tabToView === 4) && (
                <div className="row-data-group basic-info">
                  <div className="table-cell" />
                  <div className="table-cell" />
                  {tabToView === 5 && (
                    <>
                      <div className="table-cell" />
                      <div className="table-cell" />
                      <div className="table-cell" />
                      <div className="table-cell" />
                      <div className="table-cell" />
                      <div className="table-cell" />
                    </>
                  )}
                </div>
              )}
              {tabToView === 4 && (!filterSelected || showVolatilityValDate) && (
                <div className="row-data-group val-volatility">
                  {[...Array(40)].map((el, dataIndex) => (
                    (!yearsFilterValues.length ||
                      yearsFilterValues.includes(dataIndex / 4 + 0.25 <= 1 ? `${dataIndex / 4 + 0.25} year` : `${dataIndex / 4 + 0.25} years`)) && (
                      <div key={getYear(dataIndex)} className="table-cell">
                        {summariesData[`Volatility - Valuation Date | ${getYear(dataIndex)}`][index] ?
                          `${((summariesData[`Volatility - Valuation Date | ${getYear(dataIndex)}`][index]) * 100).toFixed(2)}%` : '-'}
                      </div>
                    )))}
                </div>
              )}
              {tabToView === 4 && (!filterSelected || showVolatilityTrDate) && (
                <div className="row-data-group tr-volatility">
                  {[...Array(40)].map((el, dataIndex) => (
                    (!yearsFilterValues.length ||
                      yearsFilterValues.includes(dataIndex / 4 + 0.25 <= 1 ? `${dataIndex / 4 + 0.25} year` : `${dataIndex / 4 + 0.25} years`)) && (
                      <div key={getYear(dataIndex)} className="table-cell">
                        {summariesData[`Volatility - Transaction Date | ${getYear(dataIndex)}`][index] ?
                          `${((summariesData[`Volatility - Transaction Date | ${getYear(dataIndex)}`][index]) * 100).toFixed(2)}%` : '-'}
                      </div>
                    )
                  ))}
                </div>
              )}
              {(!filterSelected || showMarketCap) && (
                <div className="row-data-group market-cap">
                  <div className="table-cell">{getPrice(`Market Capitalization | Transaction Date - ${transactionDate}`, index)}</div>
                  <div className="table-cell">{getPrice(`Market Capitalization | Valuation Date - ${valuationDate}`, index)}</div>
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Market growth')) && (
                <div className="row-data-group market-growth">
                  <div className="table-cell">{getPerc('Market Growth | Transaction Date to Valuation Date', index)}</div>
                </div>
              )}
              {(!filterSelected || showRevenue) && (
                <div className="row-data-group revenue">
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year -1 (LFY-1)')) && (
                    <div className="table-cell">{getPrice('Revenue | LFY-1', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year (LFY)')) && (
                    <div className="table-cell">{getPrice('Revenue | LFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last 12 months (LTM)')) && (
                    <div className="table-cell">{getPrice('Revenue | LTM', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Current fiscal year (CFY)')) && (
                    <div className="table-cell">{getPrice('Revenue | CFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next 12 months (NTM)')) && (
                    <div className="table-cell">{getPrice('Revenue | NTM', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year (NFY)')) && (
                    <div className="table-cell">{getPrice('Revenue | NFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +1 (NFY+1)')) && (
                    <div className="table-cell">{getPrice('Revenue | NFY+1', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +2 (NFY+2)')) && (
                    <div className="table-cell">{getPrice('Revenue | NFY+2', index)}</div>
                  )}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || showEBITDA) && (
                <div className="row-data-group EBITDA">
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year -1 (LFY-1)')) && (
                    <div className="table-cell">{getPrice('EBITDA | LFY-1', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year (LFY)')) && (
                    <div className="table-cell">{getPrice('EBITDA | LFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last 12 months (LTM)')) && (
                    <div className="table-cell">{getPrice('EBITDA | LTM', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Current fiscal year (CFY)')) && (
                    <div className="table-cell">{getPrice('EBITDA | CFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next 12 months (NTM)')) && (
                    <div className="table-cell">{getPrice('EBITDA | NTM', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year (NFY)')) && (
                    <div className="table-cell">{getPrice('EBITDA | NFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +1 (NFY+1)')) && (
                    <div className="table-cell">{getPrice('EBITDA | NFY+1', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +2 (NFY+2)')) && (
                    <div className="table-cell">{getPrice('EBITDA | NFY+2', index)}</div>
                  )}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || showGrossProfit) && (
                <div className="row-data-group gross-profit">
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year -1 (LFY-1)')) && (
                    <div className="table-cell">{getPrice('Gross Profit | LFY-1', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year (LFY)')) && (
                    <div className="table-cell">{getPrice('Gross Profit | LFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last 12 months (LTM)')) && (
                    <div className="table-cell">{getPrice('Gross Profit | LTM', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Current fiscal year (CFY)')) && (
                    <div className="table-cell">{getPrice('Gross Profit | CFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next 12 months (NTM)')) && (
                    <div className="table-cell">{getPrice('Gross Profit | NTM', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year (NFY)')) && (
                    <div className="table-cell">{getPrice('Gross Profit | NFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +1 (NFY+1)')) && (
                    <div className="table-cell">{getPrice('Gross Profit | NFY+1', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +2 (NFY+2)')) && (
                    <div className="table-cell">{getPrice('Gross Profit | NFY+2', index)}</div>
                  )}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Revenue growth')) && (
                <div className="row-data-group revenue-growth">
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year -1 (LFY-1)')) && (
                    <div className="table-cell">-</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year (LFY)')) && (
                    <div className="table-cell">{getPerc('Revenue Growth Rate | LFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last 12 months (LTM)')) && (
                    <div className="table-cell">-</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Current fiscal year (CFY)')) && (
                    <div className="table-cell">{getPerc('Revenue Growth Rate | CFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next 12 months (NTM)')) && (
                    <div className="table-cell">-</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year (NFY)')) && (
                    <div className="table-cell">-</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +1 (NFY+1)')) && (
                    <div className="table-cell">-</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +2 (NFY+2)')) && (
                    <div className="table-cell">-</div>
                  )}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('EBITDA growth')) && (
                <div className="row-data-group EBITDA-growth">
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year -1 (LFY-1)')) && (
                    <div className="table-cell">-</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year (LFY)')) && (
                    <div className="table-cell">{getPerc('EBITDA Growth Rate | LFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last 12 months (LTM)')) && (
                    <div className="table-cell">-</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Current fiscal year (CFY)')) && (
                    <div className="table-cell">{getPerc('EBITDA Growth Rate | CFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next 12 months (NTM)')) && (
                    <div className="table-cell">-</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year (NFY)')) && (
                    <div className="table-cell">-</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +1 (NFY+1)')) && (
                    <div className="table-cell">-</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +2 (NFY+2)')) && (
                    <div className="table-cell">-</div>
                  )}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Gross Profit growth')) && (
                <div className="row-data-group gross-profit-growth">
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year -1 (LFY-1)')) && (
                    <div className="table-cell">-</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year (LFY)')) && (
                    <div className="table-cell">{getPerc('Gross Profit Growth Rate | LFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last 12 months (LTM)')) && (
                    <div className="table-cell">-</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Current fiscal year (CFY)')) && (
                    <div className="table-cell">{getPerc('Gross Profit Growth Rate | CFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next 12 months (NTM)')) && (
                    <div className="table-cell">-</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year (NFY)')) && (
                    <div className="table-cell">-</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +1 (NFY+1)')) && (
                    <div className="table-cell">-</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +2 (NFY+2)')) && (
                    <div className="table-cell">-</div>
                  )}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || multiplesFilterValues.includes('Revenue multiples')) && (
                <div className="row-data-group revenue-multiples">
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year -1 (LFY-1)')) && (
                    <div className="table-cell">{getMultiple('Revenue Multiple | LFY-1', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year (LFY)')) && (
                    <div className="table-cell">{getMultiple('Revenue Multiple | LFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last 12 months (LTM)')) && (
                    <div className="table-cell">{getMultiple('Revenue Multiple | LTM', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Current fiscal year (CFY)')) && (
                    <div className="table-cell">{getMultiple('Revenue Multiple | CFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next 12 months (NTM)')) && (
                    <div className="table-cell">{getMultiple('Revenue Multiples | NTM', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year (NFY)')) && (
                    <div className="table-cell">{getMultiple('Revenue Multiple | NFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +1 (NFY+1)')) && (
                    <div className="table-cell">{getMultiple('Revenue Multiple | NFY+1', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +2 (NFY+2)')) && (
                    <div className="table-cell">{getMultiple('Revenue Multiple | NFY+2', index)}</div>
                  )}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || multiplesFilterValues.includes('EBITDA multiples')) && (
                <div className="row-data-group EBITDA-multiples">
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year -1 (LFY-1)')) && (
                    <div className="table-cell">{getMultiple('EBITDA Multiple | LFY-1', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year (LFY)')) && (
                    <div className="table-cell">{getMultiple('EBITDA Multiple | LFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last 12 months (LTM)')) && (
                    <div className="table-cell">{getMultiple('EBITDA Multiple | LTM', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Current fiscal year (CFY)')) && (
                    <div className="table-cell">{getMultiple('EBITDA Multiple | CFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next 12 months (NTM)')) && (
                    <div className="table-cell">{getMultiple('EBITDA Multiples | NTM', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year (NFY)')) && (
                    <div className="table-cell">{getMultiple('EBITDA Multiple | NFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +1 (NFY+1)')) && (
                    <div className="table-cell">{getMultiple('EBITDA Multiple | NFY+1', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +2 (NFY+2)')) && (
                    <div className="table-cell">{getMultiple('EBITDA Multiple | NFY+2', index)}</div>
                  )}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('EBITDA Margin')) && (
                <div className="row-data-group EBITDA-margin sub-comp">
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year -1 (LFY-1)')) && (
                    <div className="table-cell">{getPerc('EBITDA Margin | LFY-1', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year (LFY)')) && (
                    <div className="table-cell">{getPerc('EBITDA Margin | LFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last 12 months (LTM)')) && (
                    <div className="table-cell">{getPerc('EBITDA Margin | LTM', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Current fiscal year (CFY)')) && (
                    <div className="table-cell">{getPerc('EBITDA Margin | CFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next 12 months (NTM)')) && (
                    <div className="table-cell">{getPerc('EBITDA Margin | NTM', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year (NFY)')) && (
                    <div className="table-cell">{getPerc('EBITDA Margin | NFY', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +1 (NFY+1)')) && (
                    <div className="table-cell">{getPerc('EBITDA Margin | NFY+1', index)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +2 (NFY+2)')) && (
                    <div className="table-cell">{getPerc('EBITDA Margin | NFY+2', index)}</div>
                  )}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || growthFilterValues.includes('Gross Margin')) && (
                <div className="row-data-group gross-margin sub-comp">
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year -1 (LFY-1)')) && (
                    <div className="table-cell">{getPerc('Gross Margin | LFY-1', index, true)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last fiscal year (LFY)')) && (
                    <div className="table-cell">{getPerc('Gross Margin | LFY', index, true)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Last 12 months (LTM)')) && (
                    <div className="table-cell">{getPerc('Gross Margin | LTM', index, true)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Current fiscal year (CFY)')) && (
                    <div className="table-cell">{getPerc('Gross Margin | CFY', index, true)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next 12 months (NTM)')) && (
                    <div className="table-cell">{getPerc('Gross Margin | NTM', index, true)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year (NFY)')) && (
                    <div className="table-cell">{getPerc('Gross Margin | NFY', index, true)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +1 (NFY+1)')) && (
                    <div className="table-cell">{getPerc('Gross Margin | NFY+1', index, true)}</div>
                  )}
                  {(!fiscalPeriodFilterValues.length || fiscalPeriodFilterValues.includes('Next fiscal year +2 (NFY+2)')) && (
                    <div className="table-cell">{getPerc('Gross Margin | NFY+2', index, true)}</div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </main>
  );
}

SummariesTable.propTypes = {
  tabToView: PropTypes.number.isRequired,
  filterSelected: PropTypes.bool.isRequired,
  reSummarizing: PropTypes.bool.isRequired,
  showCompanyInfo: PropTypes.bool.isRequired,
  showRevenue: PropTypes.bool.isRequired,
  showEBITDA: PropTypes.bool.isRequired,
  showGrossProfit: PropTypes.bool.isRequired,
  showMarketCap: PropTypes.bool.isRequired,
  showVolatilityValDate: PropTypes.bool.isRequired,
  showVolatilityTrDate: PropTypes.bool.isRequired,
  multiplesFilterValues: PropTypes.array.isRequired,
  yearsFilterValues: PropTypes.array.isRequired,
  growthFilterValues: PropTypes.array.isRequired,
  summariesData: PropTypes.object.isRequired,
  summariesRef: PropTypes.func.isRequired,
  fiscalPeriodFilterValues: PropTypes.array.isRequired,
  transactionDate: PropTypes.string.isRequired,
  valuationDate: PropTypes.string.isRequired,
};
