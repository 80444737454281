import React, { useState, useContext, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import Avatar from '@mui/material/Avatar';
import { Menu, MenuItem } from '@mui/material';

import SideNavBar from './SideNavBar';
import SubNav from './SubNav';

import RedwoodLogo from '../images/redwood_logo.svg';
import { ErrorMessageContext, IsSigningOutContext } from '../lib/contextLib';

import AddUserDialog from './AddUserDialog';
import {
  getFirstTimeAttribute,
  getUserCompanyId,
  getUserId,
  getUserInitials,
  getUserType,
} from '../utils/auth';
import { toTitleCase } from '../utils';

import './NavBar.scss';
import { createFetchHeaders } from '../utils/apiCalls';

export default function NavBar() {
  const nav = useNavigate();
  const url = window.location.href;
  const { setIsSigningOut } = useContext(IsSigningOutContext);
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [tooltips, setTooltips] = useState([null, null]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [addingUser, setAddingUser] = useState(false);
  const [enterpriseCompanyName, setEnterpriseCompanyData] = useState('');
  const [currentUrl, setCurrentUrl] = useState(url);
  const [initial, setInitial] = useState('');

  async function handleLogout() {
    try {
      setIsSigningOut(true);
      await Auth.signOut();
      window.location.replace(process.env.REACT_APP_AUTH_HOSTNAME);
    } catch (e) {
      setShowErrorMessage(toString(e));
    }
  }

  async function getFirstTimeExpAttribute() {
    setInitial(toTitleCase(getUserInitials('firstInitial')));
    const splitUrl = url.split('/');
    const currUrl = splitUrl[splitUrl.length - 1];
    setCurrentUrl(currUrl);
    const fTAttribute = getFirstTimeAttribute();
    if (
      (fTAttribute === '1' && currUrl !== 'first-enterprise-sign-in') &&
      (tooltips[0] === null && tooltips[1] === null)
    ) {
      setTooltips([true, true]);
    } else if ((tooltips[0] === false && tooltips[1] === false)) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, { 'custom:firstTimeExp': '0' });
      } catch (e) {
        setShowErrorMessage(toString(e));
      }
    }
    try {
      const enterpriseCompanyId = getUserCompanyId();
      const requestUserId = getUserId();
      let enterpriseCompanyInfo = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/companies/enterprise/get-enterprise-company-info/${enterpriseCompanyId}&${requestUserId}`,
        await createFetchHeaders('get', {}, true),
      );
      enterpriseCompanyInfo = await enterpriseCompanyInfo.json();
      setEnterpriseCompanyData(enterpriseCompanyInfo.companyName);
      localStorage.setItem('enterpriseCompanyInfo', JSON.stringify(enterpriseCompanyInfo));
    }
    catch (e) {
      setShowErrorMessage(toString(e));
    }
  }

  useEffect(() => {
    getFirstTimeExpAttribute();
  }, [tooltips, url]);

  return (
    <>
      <div className="NavBar">
        {getUserType() !== 'investor' && (
          <SideNavBar
            tooltips={tooltips}
            setTooltips={setTooltips}
            currentUrl={currentUrl}
          />
        )}
        <div className={`logo-container${getUserType() === 'investor' ? ' investor' : ''}`}>
          <img className="logo" src={RedwoodLogo} alt="logo" />
          <span>Powered by initio Software</span>
        </div>
        {getUserType() === 'investor' && (
          <div className="right-nav">
            <IconButton
              className={`profile-icon ${tooltips[0] ? 'first' : ''}`}
              onClick={(e) => {
                setMenuOpen(!menuOpen);
                setAnchorEl(e.currentTarget);
                setTooltips([tooltips[0] ? !tooltips[0] : false, tooltips[1]]);
              }}
            >
              <Avatar>{initial}</Avatar>
            </IconButton>
            <Menu
              id="nav-bar-dropdown"
              anchorEl={anchorEl}
              open={menuOpen}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={() => setMenuOpen(!menuOpen)}
              disableScrollLock
            >
              <MenuItem
                onClick={() => {
                  setMenuOpen(!menuOpen);
                  handleLogout();
                }}
              >
                <LogoutOutlinedIcon />
                Sign out
              </MenuItem>
            </Menu>
          </div>
        )}
        {getUserType() !== 'investor' && (
          <div className="right-nav">
            {/*
            Search will be implemented when there is criteria to search
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search"
              />
            </Search> */}
            <Tooltip
              id="first-tooltip-right"
              open={tooltips[0] === null ? false : tooltips[0]}
              placement="bottom-end"
              arrow
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={(
                <>
                  <Button
                    onClick={() => {
                      setTooltips([false, tooltips[1]]);
                    }}
                  >
                    <CloseIcon />
                  </Button>
                  <p>Click your profile icon to:</p>
                  <ul>
                    <li>
                      add additional users from your
                      <br />
                      <b>company&apos;s page</b>
                      ,
                    </li>
                    <li>
                      set up multi-factor authentication
                      <br />
                      or change your password
                      <br />
                      from&thinsp;
                      <b>Profile & permissions</b>
                      ,
                    </li>
                    <li>sign out</li>
                  </ul>
                </>
              )}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <IconButton
                className={`profile-icon ${tooltips[0] ? 'first' : ''}`}
                onClick={(e) => {
                  setMenuOpen(!menuOpen);
                  setAnchorEl(e.currentTarget);
                  setTooltips([tooltips[0] ? !tooltips[0] : false, tooltips[1]]);
                }}
              >
                <Avatar>{initial}</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              id="nav-bar-dropdown"
              anchorEl={anchorEl}
              open={menuOpen}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={() => setMenuOpen(!menuOpen)}
              disableScrollLock
            >
              <MenuItem
                className={currentUrl === 'profile-settings' ? 'active' : ''}
                onClick={() => {
                  setMenuOpen(!menuOpen);
                  nav('/profile-settings');
                }}
              >
                <AccountCircleOutlinedIcon />
                Profile & preferences
              </MenuItem>
              <MenuItem
                className={currentUrl === 'enterprise-info' ? 'active' : ''}
                onClick={() => {
                  setMenuOpen(!menuOpen);
                  nav('/enterprise-info');
                }}
              >
                <DomainOutlinedIcon />
                {/* TODO Get Enterprise name from backend */}
                {(enterpriseCompanyName.length >= 20) ? `${enterpriseCompanyName.slice(0, 20).trim()}...` : enterpriseCompanyName}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setMenuOpen(!menuOpen);
                  setAddingUser(true);
                }}
              >
                <PersonAddAltOutlinedIcon />
                Add User
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setMenuOpen(!menuOpen);
                  handleLogout();
                }}
              >
                <LogoutOutlinedIcon />
                Sign out
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
      <AddUserDialog addingUser={addingUser} setAddingUser={setAddingUser} />
      <SubNav />
    </>
  );
}
