import React from 'react';

import './Security.scss';

export default function Security() {
  return (
    <main className="Security">
      <div className="info-dropdown">
        <div className="header-row">
          <h5>Email</h5>
        </div>
        <div>
          Email
        </div>
      </div>
    </main>
  );
}
