import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';

import CloseIcon from '@mui/icons-material/Close';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ErrorIcon from '@mui/icons-material/Error';

import { ErrorMessageContext } from '../lib/contextLib';

import {
  checkForEmailErrors,
  isAValidUSPhoneNumber,
  formatPhoneNumber,
} from '../utils';

import {
  createFetchHeaders,
  sendHtmlEmail,
} from '../utils/apiCalls';

import { getUserCompanyId, getUserId } from '../utils/auth';

import './AddUserDialog.scss';

export default function AddUserDialog({
  addingUser,
  setAddingUser,
  setEnterpriseUsers,
  enterpriseUsers,
}) {
  const [userInfo, setUserInfo] = useState({});

  const [addingUserSuccess, setAddingUserSuccess] = useState(false);
  const [addingUserInProgress, setAddingUserInProgress] = useState(false);

  const [emailInputHasError, setEmailInputHasError] = useState(false);
  const [firstNameInputHasError, setFirstNameInputHasError] = useState(false);
  const [lastNameInputHasError, setLastNameInputHasError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [phoneNumberInputHasError, setPhoneNumberInputHasError] = useState(false);

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  useEffect(() => {
    if (addingUser) {
      setUserInfo({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      });
    }
  }, [addingUser]);

  async function addUser(event) {
    event.preventDefault();
    if (userInfo.firstName === '' || userInfo.lastName === '' || userInfo.email === '') {
      if (userInfo.firstName === '') setFirstNameInputHasError(true);
      if (userInfo.lastName === '') setLastNameInputHasError(true);
      if (userInfo.email === '') {
        setEmailInputHasError(true);
        setEmailErrorMessage('Please enter a valid email address.');
      }
      return;
    }

    if (emailInputHasError || phoneNumberInputHasError) return;

    try {
      setAddingUserInProgress(true);
      const enterpriseUsersCopy = [...enterpriseUsers];
      enterpriseUsersCopy.push(userInfo);
      setEnterpriseUsers([...enterpriseUsersCopy, userInfo]);
      const userCompanyId = await getUserCompanyId();
      const userId = getUserId();
      const newUserData = {
        enterpriseCompanyId: userCompanyId,
        requestUserId: userId,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        phoneNumber: userInfo.phoneNumber,
      };
      await sendHtmlEmail(
        userInfo.email,
        'newEnterpriseUser',
        [
          userInfo.firstName,
          encodeURIComponent(userInfo.email),
          encodeURIComponent(userInfo.firstName),
          encodeURIComponent(userInfo.lastName),
          encodeURIComponent(userInfo.phoneNumber),
          encodeURIComponent(newUserData.enterpriseCompanyId),
        ],
      );
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/accounts/create-enterprise-user`,
        await createFetchHeaders('post', newUserData, true),
      );
      setAddingUser(false);
      setAddingUserSuccess(true);
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setAddingUserInProgress(false);
    }
  }

  return (
    <main className="AddUserDialog">
      <Dialog className="add-user-dialog" open={addingUser}>
        <IconButton
          className="close-icon"
          onClick={() => setAddingUser(false)}
        >
          <CloseIcon />
        </IconButton>
        <div className="box-header">
          <h4>Add user</h4>
        </div>
        <p className="dialog-text">
          This person will have the same permissions you do on Redwood Valuations account.
        </p>
        <form onSubmit={(e) => addUser(e)}>
          <div className="form-grid">
            <TextField
              label="First name"
              value={userInfo.firstName || ''}
              onChange={(e) => setUserInfo({ ...userInfo, firstName: e.target.value })}
              onFocus={() => setFirstNameInputHasError(false)}
              error={firstNameInputHasError}
              helperText={firstNameInputHasError ? (
                <>
                  <ErrorIcon />
                  Missing input
                </>
              ) : ' '}
            />
            <TextField
              label="Last name"
              value={userInfo.lastName || ''}
              onChange={(e) => setUserInfo({ ...userInfo, lastName: e.target.value })}
              onFocus={() => setLastNameInputHasError(false)}
              error={lastNameInputHasError}
              helperText={lastNameInputHasError ? (
                <>
                  <ErrorIcon />
                  Missing input
                </>
              ) : ' '}
            />
            <TextField
              label="Email"
              value={userInfo.email || ''}
              onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
              onFocus={() => setEmailInputHasError(false)}
              onBlur={() => {
                if (userInfo.email) {
                  const { errorStatus, errorMessage } = checkForEmailErrors(userInfo.email);
                  setEmailInputHasError(errorStatus);
                  setEmailErrorMessage(errorMessage);
                }
              }}
              error={emailInputHasError}
              helperText={emailInputHasError ? (
                <>
                  <ErrorIcon />
                  {emailErrorMessage}
                </>
              ) : ' '}
            />
            <TextField
              label="Phone number"
              value={userInfo.phoneNumber || ''}
              onChange={(e) => setUserInfo({ ...userInfo, phoneNumber: formatPhoneNumber(e.target.value) })}
              onFocus={() => setPhoneNumberInputHasError(false)}
              onBlur={() => userInfo.phoneNumber && setPhoneNumberInputHasError(!isAValidUSPhoneNumber(userInfo.phoneNumber))}
              error={phoneNumberInputHasError}
              helperText={phoneNumberInputHasError ? (
                <>
                  <ErrorIcon />
                  Invalid US Phone number format
                </>
              ) : ' '}
            />
          </div>
          <div className="dialog-box-buttons">
            {/* <Button>
            Add another user
          </Button> */}
            <Button type="submit">
              {!addingUserInProgress ? 'Save' : (
                <>
                  <div className="dots-circle-spinner" />
                  Adding user...
                </>
              )}
            </Button>
          </div>
        </form>
      </Dialog>
      <Dialog className="add-user-success-dialog" open={addingUserSuccess}>
        <IconButton
          className="close-icon"
          onClick={() => setAddingUserSuccess(false)}
        >
          <CloseIcon />
        </IconButton>
        <div className="box-header">
          <h4>Success!</h4>
        </div>
        <div className="success-msg-container">
          <div className="check-icon"><CheckRoundedIcon /></div>
          <p>
            You&apos;ve successfully added
            {' '}
            {userInfo.firstName}
            {' '}
            to the initio platform. An invite email has been sent.They&apos;ll have access to
            our platform once they&apos;ve finished setting up their account.
          </p>
        </div>
      </Dialog>
    </main>
  );
}


AddUserDialog.propTypes = {
  addingUser: PropTypes.bool.isRequired,
  setAddingUser: PropTypes.func.isRequired,
  setEnterpriseUsers: PropTypes.func,
  enterpriseUsers: PropTypes.array,
};

AddUserDialog.defaultProps = {
  setEnterpriseUsers: () => { },
  enterpriseUsers: [],
};
