import { Auth } from 'aws-amplify';
import JSON5 from 'json5';

export default async function authenticationCheck() {
  async function handleLogOut() {
    await Auth.signOut();
    window.location.replace(process.env.REACT_APP_AUTH_HOSTNAME);
  }

  // Local Testing Sign-In
  if (process.env.REACT_APP_AUTO_SIGN_IN === 'true') {
    let authInfo;
    try {
      authInfo = await Auth.currentSession();
    } catch {
      try {
        await Auth.signIn(process.env.REACT_APP_TESTING_LOCAL_EMAIL, process.env.REACT_APP_TESTING_LOCAL_PASSWORD);
      } catch {
        // bypass sign-in already in progress alert in dev strict mode
      }
    }
    if (authInfo && authInfo.idToken.payload.email !== process.env.REACT_APP_TESTING_LOCAL_EMAIL) {
      await Auth.signOut();
      try {
        await Auth.signIn(process.env.REACT_APP_TESTING_LOCAL_EMAIL, process.env.REACT_APP_TESTING_LOCAL_PASSWORD);
      } catch {
        // bypass sign-in already in progress alert in dev strict mode
      }
    }
  }

  // Return auth status or redirect
  try {
    const cognitoResponse = await Auth.currentSession();
    if (cognitoResponse.accessToken.payload['cognito:groups']?.includes('enterprise-users')) return;
    if (cognitoResponse.accessToken.payload['cognito:groups']?.includes('investor-users')) return;
  } catch {
    await handleLogOut();
  }
  await handleLogOut();
}

function getCognitoCookies() {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  const cookieData = [];
  cookieArray.forEach((data) => {
    let cookie = data;
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    cookieData.push(cookie);
  });
  return cookieData.length > 0 ? cookieData : null;
}

function getUserIdd() {
  const cognitoCookies = getCognitoCookies();
  const cognitoAuthPrefix = `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID}`;
  const authUser = `${cognitoAuthPrefix}.LastAuthUser=`;
  let accountId = null;

  cognitoCookies.filter((cookie) => {
    if (cookie.startsWith(authUser)) {
      const cookieValueArr = cookie.split('=')[1];
      accountId = cookieValueArr;
    }
    return null;
  });

  return accountId;
}

function getCognitoUserAttributes() {
  const sub = getUserIdd();
  const cognitoCookies = getCognitoCookies();
  const cognitoAuthPrefix = `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID}`;
  const name = `${cognitoAuthPrefix}.${sub}.userData=`;
  let attributes = null;
  cognitoCookies.forEach((cookie) => {
    if (cookie.startsWith(name)) {
      const cookieValue = cookie.substring(name.length, cookie.length);
      attributes = JSON5.parse(cookieValue);
    }
    return attributes;
  });
  return attributes;
}

function getSpecificAttribute(attributeName) {
  const AuthCookies = getCognitoUserAttributes();
  const attributeData = AuthCookies.UserAttributes.filter((attribute) => {
    if (attribute.Name === attributeName) {
      return attribute;
    }
    return null;
  });
  const { Value } = attributeData[0];
  return Value;
}

export function getUserId() {
  const accountId = getSpecificAttribute('sub');
  return accountId;
}

export function getUserCompanyId() {
  const companyId = getSpecificAttribute('custom:companyId');
  return companyId;
}

export function getUserInitials(type = null) {
  const firstInitial = Array.from(getSpecificAttribute('custom:firstName'))[0];
  const lastInitial = Array.from(getSpecificAttribute('custom:lastName'))[0];
  if (type === 'firstInitial') return `${firstInitial}`;
  return `${firstInitial}${lastInitial}`;
}

export function getUserName(value) {
  if (value === 'firstName') return getSpecificAttribute('custom:firstName');
  if (value === 'lastName') return getSpecificAttribute('custom:lastName');
  return `${getSpecificAttribute('custom:firstName')} ${getSpecificAttribute('custom:lastName')}`;
}

export function getFirstTimeAttribute() {
  return getSpecificAttribute('custom:firstTimeExp');
}

export function getUserType() {
  return getSpecificAttribute('custom:userType');
}

export function getUserPhoneNumber() {
  return getSpecificAttribute('custom:phoneNumber');
}

export function getUserEmail() {
  return getSpecificAttribute('email');
}
