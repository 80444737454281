import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import GeneralInfo from './GeneralInfo';
import ClientInputs from './ClientInputs';
import CapStructure from './CapStructure';
import Comps from './Comps';
import Calculations from './Calculations';

import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import { ErrorMessageContext } from '../../lib/contextLib';

import { getUserId, getUserCompanyId } from '../../utils/auth';

import { createFetchHeaders } from '../../utils/apiCalls';

import { copy } from '../../utils';

import './index.scss';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

export default function CalcInputs({ transactionToView }) {
  const [tabToView, setTabToView] = useState(null);
  const [exitingToTab, setExitingToTab] = useState(false);
  const [userData, setUserData] = useState({ metaData: transactionToView });

  const [runningComps, setRunningComps] = useState(false);
  const [showRunningCompSnackbar, setShowRunningCompSnackbar] = useState(false);
  const [compSuccess, setCompSuccess] = useState(false);

  const [DBDataIsLoading, setDBDataIsLoading] = useState(false);
  const [saveCapData, setSaveCapData] = useState(false);

  const [checkForMissingCompInputs, setCheckForMissingCompInputs] = useState(false);

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const nav = useNavigate();

  async function getCapData() {
    const userId = getUserId();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const endpointData = `${userData.metaData.portfolioCompanyId}&${userData.metaData.transactionId}&${userId}`;
    let capDBResponse = await fetch(
      `${backendURL}/calc-engine/get-cap-structure-object/${endpointData}`,
      await createFetchHeaders('get', {}, true),
    );
    capDBResponse = await capDBResponse.json();
    return capDBResponse || null;
  }

  async function getCalcData() {
    const requestUserId = getUserId();
    let calcResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/calc-engine/get-calc-engine-object/` +
      `${userData.metaData.portfolioCompanyId}&${userData.metaData.transactionId}&${requestUserId}`,
      await createFetchHeaders('get', {}, true),
    );
    if (calcResponse.status === 200) {
      calcResponse = await calcResponse.json();
      return calcResponse;
    }
    return null;
  }

  async function getGridData() {
    const requestUserId = getUserId();
    const enterpriseCompanyId = getUserCompanyId();
    let asc820Response = await fetch(
      `${process.env.REACT_APP_CALC_ENGINE_URL}/calcEngine/load-asc820-page/` +
      `${enterpriseCompanyId}&${userData.metaData.portfolioCompanyId}&${userData.metaData.transactionId}&${requestUserId}`,
      await createFetchHeaders('get', {}, true),
    );
    if (asc820Response.status === 200) {
      asc820Response = await asc820Response.json();
      return asc820Response;
    }
    return null;
  }

  async function getTransactionData() {
    const userId = getUserId();
    const enterpriseCompanyId = getUserCompanyId();
    try {
      const transactionDBData = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}` +
        '/transactions/asc820/get-enterprise-transaction-data/' +
        `${enterpriseCompanyId}&${transactionToView.portfolioCompanyId}&${transactionToView.transactionId}&${userId}`,
        await createFetchHeaders('get', {}, true),
      );
      return await transactionDBData.json();
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
    return null;
  }

  async function getTransactionInfo() {
    setDBDataIsLoading(true);
    try {
      const newData = copy(userData);
      const { transactionData, companyData } = await getTransactionData();
      newData.transactionData = transactionData;
      newData.companyData = companyData;
      newData.capData = await getCapData();
      if (newData.transactionData && newData.transactionData.compsLocation) {
        newData.calcData = await getCalcData();
        try {
          newData.gridData = await getGridData();
        } catch {
          newData.gridData = null;
        }
      }
      setUserData(newData);
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setDBDataIsLoading(false);
      const urlParamsOnPgLoad = new URLSearchParams(window.location.search);
      const paramTabToView = urlParamsOnPgLoad.get('tabToView');
      if (paramTabToView) setTabToView(parseInt(paramTabToView, 10));
      else setTabToView(1);
    }
  }

  useEffect(() => { getTransactionInfo(); }, []);

  useEffect(() => {
    if (tabToView) {
      nav(`?tabToView=${tabToView}` +
        `&pId=${transactionToView.projectId}` +
        `&iId=${transactionToView.investorCompanyId}` +
        `&tId=${transactionToView.transactionId}`);
    }
  }, [tabToView]);

  // Keep for now, for dev purposes
  if (process.env.REACT_APP_ENV_LABEL === 'dev') {
    // eslint-disable-next-line no-console
    console.log('userData', userData);
  }

  return (
    <main className="CalcInputs">
      <div className="top-tool-bar">
        <Button disabled={saveCapData} onClick={() => { if (tabToView === 3) setSaveCapData(true); }}>
          {!saveCapData ? (
            <>
              <SaveOutlinedIcon />
              Save
            </>
          ) : (
            <>
              <span className="dots-circle-spinner" />
              Saving
            </>
          )}
        </Button>
      </div>
      <div className="top-tabs-nav">
        {['General info',
          '820 inputs',
          'Cap structure',
          'Volatility',
          'Comps',
          'Calculations',
        ].map((tabLabel, i) => (
          <Button
            key={tabLabel}
            className={`tab-button ${i + 1 === tabToView ? 'active-tab' : ''} `}
            onClick={() => {
              if (tabToView === 2) setExitingToTab(i + 1);
              else setTabToView(i + 1);
            }}
          >
            {tabLabel}
          </Button>
        ))}
      </div>
      <div className="tabs-content">
        {(tabToView === 1) && <GeneralInfo userData={userData} setUserData={setUserData} />}
        {tabToView === 2 && (
          <ClientInputs
            userData={userData}
            setUserData={setUserData}
            checkForMissingCompInputs={checkForMissingCompInputs}
            setCheckForMissingCompInputs={setCheckForMissingCompInputs}
            exitingToTab={exitingToTab}
            setExitingToTab={setExitingToTab}
            setTabToView={setTabToView}
          />
        )}
        {tabToView === 3 && <CapStructure userData={userData} saveCapData={saveCapData} setSaveCapData={setSaveCapData} />}
        {(tabToView === 4 || tabToView === 5) && (
          <Comps
            tabToView={tabToView}
            userData={userData}
            setUserData={setUserData}
            setTabToView={setTabToView}
            runningComps={runningComps}
            setRunningComps={setRunningComps}
            setShowRunningCompSnackbar={setShowRunningCompSnackbar}
            setCompSuccess={setCompSuccess}
            setCheckForMissingCompInputs={setCheckForMissingCompInputs}
          />
        )}
        {tabToView === 6 && <Calculations userData={userData} setUserData={setUserData} />}
      </div>
      <Dialog
        open={DBDataIsLoading}
        className="loading-inputs-dialog"
        disableScrollLock
      >
        <div className="loading-wrapper">
          <LoadingSpinner className="loading-spinner" />
        </div>
        Loading Data . . .
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showRunningCompSnackbar}
        onClose={() => setShowRunningCompSnackbar(false)}
        TransitionComponent={SlideTransition}
        message={(
          <>
            <span>Running comps. This could take a minute.</span>
            <Button onClick={() => setShowRunningCompSnackbar(false)}> Dismiss</Button>
          </>
        )}
        autoHideDuration={5000}
        ClickAwayListenerProps={{ onClickAway: () => null }}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={compSuccess}
        onClose={() => setCompSuccess(false)}
        TransitionComponent={SlideTransition}
        message={(
          <>
            <span>Successfully ran comps.</span>
            <Button onClick={() => setCompSuccess(false)}> Dismiss</Button>
          </>
        )}
        autoHideDuration={5000}
        ClickAwayListenerProps={{ onClickAway: () => null }}
      />
    </main>
  );
}

CalcInputs.propTypes = { transactionToView: PropTypes.objectOf(PropTypes.any).isRequired };
