import React, { useState, useEffect, useContext } from 'react';
import Alert from '@mui/material/Alert';

import { ErrorMessageContext } from '../lib/contextLib';

export default function AlertDisplay() {
  const { showErrorMessage, setShowErrorMessage } = useContext(ErrorMessageContext);
  const [open, setOpen] = useState(false);

  useEffect(() => setOpen(!!showErrorMessage), [showErrorMessage]);

  return (
    open && (
      <Alert
        severity="error"
        style={{ position: 'absolute', top: '20px', left: '45%' }}
        onClose={() => {
          setShowErrorMessage(null);
          setOpen(false);
        }}
      >
        {showErrorMessage}
      </Alert>
    )
  );
}
