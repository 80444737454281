import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import {
  copy, concatCharacter, onlyNums, commaEvery3rdChar,
} from '../../../utils';

import { createFetchHeaders } from '../../../utils/apiCalls';

import { getUserId, getUserCompanyId } from '../../../utils/auth';

import { ErrorMessageContext } from '../../../lib/contextLib';

import './EquityInputs.scss';

export default function EquityInputs({ userData, setUserData }) {
  const [equityResults, setEquityResults] = useState({});
  const [equityRows, setEquityRows] = useState([{ rowId: 0 }]);
  const [rowsAdded, setRowsAdded] = useState(0);

  const [inputWasChanged, setInputWasChanged] = useState(false);

  const [fiscalPeriodDropdownValues, setFiscalPeriodDropdownValues] = useState([]);
  const [kpiDropdownValues, setKpiDropdownValues] = useState([[]]);
  const [fiscalValueSelected, setFiscalValueSelected] = useState(null);

  const [showFootnoteHeights, setShowFootnoteHeights] = useState([]);

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const EBITDAFiscalYearValues = [
    {
      fiscalYear: 'Last fiscal year -1 (LFY-1)',
      variableName: 'lastFiscalYearEBITDAM1',
    },
    {
      fiscalYear: 'Last fiscal year (LFY)',
      variableName: 'lastFiscalYearEBITDA',
    },
    {
      fiscalYear: 'Last 12 months (LTM)',
      variableName: 'last12MonthsEBITDA',
    },
    {
      fiscalYear: 'Current fiscal year (CFY)',
      variableName: 'currentFiscalYearEBITDA',
    },
    {
      fiscalYear: 'Next 12 months (NTM)',
      variableName: 'next12MonthsEBITDA',
    },
    {
      fiscalYear: 'Next fiscal year (NFY)',
      variableName: 'nextFiscalYearEBITDA',
    },
    {
      fiscalYear: 'Next fiscal year +1 (NFY+1)',
      variableName: 'nextFiscalYearEBITDAP1',
    },
    {
      fiscalYear: 'Next fiscal year +2 (NFY+2)',
      variableName: 'nextFiscalYearEBITDAP2',
    },
  ];

  const revenueFiscalYearValues = [
    {
      fiscalYear: 'Last fiscal year -1 (LFY-1)',
      variableName: 'lastFiscalYearRevenueM1',
    },
    {
      fiscalYear: 'Last fiscal year (LFY)',
      variableName: 'lastFiscalYearRevenue',
    },
    {
      fiscalYear: 'Last 12 months (LTM)',
      variableName: 'last12MonthsRevenue',
    },
    {
      fiscalYear: 'Current fiscal year (CFY)',
      variableName: 'currentFiscalYearRevenue',
    },
    {
      fiscalYear: 'Next 12 months (NTM)',
      variableName: 'next12MonthsRevenue',
    },
    {
      fiscalYear: 'Next fiscal year (NFY)',
      variableName: 'nextFiscalYearRevenue',
    },
    {
      fiscalYear: 'Next fiscal year +1 (NFY+1)',
      variableName: 'nextFiscalYearRevenueP1',
    },
    {
      fiscalYear: 'Next fiscal year +2 (NFY+2)',
      variableName: 'nextFiscalYearRevenueP2',
    },
  ];

  function getVariableName(fiscalYear, kpi) {
    let variableName = '';
    if (kpi === 'EBITDA') variableName = EBITDAFiscalYearValues.find((el) => el.fiscalYear === fiscalYear).variableName;
    if (kpi === 'Revenue') variableName = revenueFiscalYearValues.find((el) => el.fiscalYear === fiscalYear).variableName;
    return variableName;
  }

  async function calculateSubMethods(equityData) {
    try {
      const requestUserId = getUserId();
      const enterpriseCompanyId = getUserCompanyId();
      const equityRowsData = copy(equityData || equityRows);
      (equityData || equityRows).forEach((el, rowIndex) => {
        const variableName = getVariableName(el.period, el.kpi);
        equityRowsData[rowIndex].kpiValue = userData.transactionData.enterpriseFinancialInfo?.[variableName]?.value || null;
        equityRowsData[rowIndex].weight = equityRowsData[rowIndex].weight ? equityRowsData[rowIndex].weight.replaceAll('%', '') : '';
        equityRowsData[rowIndex].multiple = equityRowsData[rowIndex].multiple ? equityRowsData[rowIndex].multiple.replaceAll('x', '') : '';
      });
      if (equityRowsData.some((el) => el.kpiValue === null)) {
        setEquityResults({
          concludedEnterpriseValue: '',
          concludedEquityValue: '',
          totalWeight: '',
        });
        return;
      }
      const cash = parseInt(userData.transactionData.clientFinancialInfo.currentCash?.value.replaceAll(',', ''), 10) || 0;
      const convertibleDebt = parseInt(userData.transactionData.clientFinancialInfo.convertibleDebt?.value.replaceAll(',', ''), 10) || 0;
      const nonConvertibleDebt = parseInt(userData.transactionData.clientFinancialInfo.nonConvertibleDebt?.value.replaceAll(',', ''), 10) || 0;
      const subMethodData = {
        enterpriseCompanyId,
        requestUserId,
        method: 'pubco',
        cash,
        totalDebt: convertibleDebt + nonConvertibleDebt,
        subMethods: equityRowsData,
      };
      let subMethodDBResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/calc-engine/calculate-sub-methods`,
        await createFetchHeaders('post', subMethodData, true),
      );
      subMethodDBResponse = await subMethodDBResponse.json();
      const equityRowsCopy = [...equityRows];
      equityRows.forEach((el, rowIndex) => {
        equityRowsCopy[rowIndex].enterpriseValue = subMethodDBResponse.subMethods[rowIndex].enterpriseValue || '';
        equityRowsCopy[rowIndex].weightedEnterpriseValue = subMethodDBResponse.subMethods[rowIndex].weightedEnterpriseValue || '';
      });
      setEquityRows(equityRowsCopy);
      setEquityResults({
        concludedEnterpriseValue: subMethodDBResponse.concludedEnterpriseValue,
        concludedEquityValue: subMethodDBResponse.concludedEquityValue,
        totalWeight: subMethodDBResponse.totalWeight,
      });
      const newCalcData = copy(userData.calcData);
      newCalcData.methods.pubCo.enterpriseValue = subMethodDBResponse.concludedEnterpriseValue;
      newCalcData.methods.pubCo.equityValue = subMethodDBResponse.concludedEquityValue;
      newCalcData.methods.pubCo.subMethods = subMethodDBResponse.subMethods;
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/calc-engine/update-calc-engine-object`,
        await createFetchHeaders('post', newCalcData, true),
      );
      setUserData({ ...userData, calcData: newCalcData });
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  function getKpiOptions(fiscalVal, rowIndex) {
    const kpiOptions = [];
    const revenueVariableName = revenueFiscalYearValues.find((el) => el.fiscalYear === fiscalVal)?.variableName;
    if (userData.transactionData.enterpriseFinancialInfo[revenueVariableName]?.value &&
      !(equityRows.some((equityRow, index) => equityRow.period === fiscalVal && equityRow.kpi === 'Revenue' && index !== rowIndex))) {
      kpiOptions.push('Revenue');
    }
    const EBITDAVariableName = EBITDAFiscalYearValues.find((el) => el.fiscalYear === fiscalVal)?.variableName;
    if (userData.transactionData.enterpriseFinancialInfo[EBITDAVariableName]?.value &&
      !(equityRows.some((equityRow, index) => equityRow.period === fiscalVal && equityRow.kpi === 'EBITDA' && index !== rowIndex))) {
      kpiOptions.push('EBITDA');
    }
    return kpiOptions;
  }

  useEffect(() => {
    if (inputWasChanged) {
      if (equityRows.every((equityInput) => (
        equityInput.period &&
        equityInput.kpi
      ))) {
        calculateSubMethods();
      }
    }
    setInputWasChanged(false);
  }, [inputWasChanged]);

  useEffect(() => {
    const newFiscalDropdownValues = [];
    [...revenueFiscalYearValues, ...EBITDAFiscalYearValues].forEach((fiscalPeriodOption) => {
      if (userData.transactionData.clientFinancialInfo[fiscalPeriodOption.variableName]?.value ||
        userData.transactionData.enterpriseFinancialInfo[fiscalPeriodOption.variableName]?.value) {
        if (!newFiscalDropdownValues.includes(fiscalPeriodOption.fiscalYear)) newFiscalDropdownValues.push(fiscalPeriodOption.fiscalYear);
      }
    });
    setFiscalPeriodDropdownValues(newFiscalDropdownValues);
    if (userData.calcData?.methods?.pubCo?.subMethods?.length) {
      const newEquityValues = copy(userData.calcData.methods.pubCo.subMethods);
      setEquityRows(newEquityValues.map((el, index) => ({
        ...el,
        rowId: index + 1,
        weight: el.weight ? `${el.weight}%` : '',
        multiple: el.multiple ? `${el.multiple}x` : '',
      })));
      setRowsAdded(newEquityValues.length + 1);
      const newKpiDropdownValues = [...kpiDropdownValues];
      newEquityValues.forEach((el, rowIndex) => { newKpiDropdownValues[rowIndex] = getKpiOptions(el.period, rowIndex); });
      setKpiDropdownValues(newKpiDropdownValues);
      setInputWasChanged(true);
    }
  }, []);

  useEffect(() => {
    if (fiscalValueSelected) {
      const newKpiDropdownValues = [...kpiDropdownValues];
      newKpiDropdownValues[fiscalValueSelected.index] = getKpiOptions(fiscalValueSelected.value, fiscalValueSelected.index);
      setKpiDropdownValues(newKpiDropdownValues);
    }
    setFiscalValueSelected(null);
  }, [fiscalValueSelected]);

  return (
    <div className="EquityInputs">
      <div className="header-row">
        <h6>Calculate equity</h6>
        <p>
          Run scenarios and get results in real-time. Fiscal period data not looking right? Edit on &apos;820 input form&apos; tab.
          <br />
          You can run calc engine once total weight equals 100%.
        </p>
        <div className="vl" />
        <div className="value-block">
          <span>
            {equityResults.concludedEnterpriseValue && equityResults.concludedEnterpriseValue !== 'N/A' ?
              `$${commaEvery3rdChar(equityResults.concludedEnterpriseValue.toFixed(2))}` : '$'}
          </span>
          <span>
            Concluded
            <br />
            enterprise value
          </span>
        </div>
        <div className="value-block">
          <span>
            {equityResults.concludedEquityValue && equityResults.concludedEquityValue !== 'N/A' ?
              `$${commaEvery3rdChar(equityResults.concludedEquityValue.toFixed(2))}` : '$'}
          </span>
          <span>
            Concluded
            <br />
            equity value
          </span>
        </div>
        <div className="value-block">
          <span>
            {equityResults.totalWeight && equityResults.totalWeight !== 'N/A' ?
              `${commaEvery3rdChar(equityResults.totalWeight)}%` : '%'}
          </span>
          <span>
            Total weight
            <br />
            (must total 100%)
          </span>
        </div>
      </div>
      <div className="equity-inputs-rows">
        {equityRows.map((equityRow, index) => {
          const variableName = getVariableName(equityRow.period, equityRow.kpi);
          return (
            <div className="equity-row" key={equityRow.rowId}>
              <div className="equity-row-title">
                <h6>{`Scenario ${index + 1}`}</h6>
                <Button
                  onClick={() => {
                    setShowFootnoteHeights(showFootnoteHeights.includes(index) ?
                      showFootnoteHeights.filter((item) => item !== index) : [...showFootnoteHeights, index]);
                  }}
                >
                  Show footnote
                </Button>
              </div>
              <div className="inputs-row">
                <TextField
                  select
                  label="Fiscal period"
                  value={equityRow.period || ''}
                  onChange={(e) => {
                    const equityRowsCopy = [...equityRows];
                    equityRowsCopy[index].period = e.target.value;
                    equityRowsCopy[index].kpi = '';
                    setEquityRows(equityRowsCopy);
                  }}
                  SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown fiscal-period' } } }}
                  onBlur={(e) => {
                    setInputWasChanged(true);
                    setFiscalValueSelected({ index, value: e.target.value });
                  }}
                >
                  {fiscalPeriodDropdownValues.map((menuItem) => (
                    <MenuItem
                      key={menuItem}
                      value={menuItem}
                      className={equityRows.slice(0, index).some((row) => row.period === menuItem &&
                        getKpiOptions(row.period, index).every((kpiOption) => equityRows.some((eRow) => eRow.period === menuItem &&
                          eRow.kpi === kpiOption))) ? ' hidden' : ''}
                    >
                      {menuItem}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  label="KPI"
                  value={equityRow.kpi || ''}
                  onChange={(e) => {
                    const equityRowsCopy = [...equityRows];
                    equityRowsCopy[index].kpi = e.target.value;
                    setEquityRows(equityRowsCopy);
                  }}
                  SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                  onBlur={() => setInputWasChanged(true)}
                >
                  {kpiDropdownValues[index].map((menuItem) => (
                    <MenuItem key={menuItem} value={menuItem}>
                      {menuItem}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Multiple"
                  value={equityRow.multiple || ''}
                  onChange={(e) => {
                    const equityRowsCopy = [...equityRows];
                    equityRowsCopy[index].multiple = onlyNums(e.target.value);
                    setEquityRows(equityRowsCopy);
                  }}
                  onBlur={(e) => {
                    const equityRowsCopy = [...equityRows];
                    equityRowsCopy[index].multiple = concatCharacter(e.target.value, 'x');
                    setEquityRows(equityRowsCopy);
                    setInputWasChanged(true);
                  }}
                />
                <TextField
                  label="Weight"
                  value={equityRow.weight || ''}
                  onChange={(e) => {
                    const equityRowsCopy = [...equityRows];
                    equityRowsCopy[index].weight = onlyNums(e.target.value);
                    setEquityRows(equityRowsCopy);
                  }}
                  onBlur={(e) => {
                    const equityRowsCopy = [...equityRows];
                    equityRowsCopy[index].weight = concatCharacter(e.target.value, '%');
                    setEquityRows(equityRowsCopy);
                    setInputWasChanged(true);
                  }}
                />
                <div className="vl" />
                <div className={`value-block${!equityRow.kpi || !equityRow.period ? ' awaiting-input' : ''}`}>
                  {!equityRow.kpi || !equityRow.period ? (
                    <span>
                      Fiscal period data
                      <br />
                      will show here
                    </span>
                  ) : (
                    <>
                      <span>
                        {userData.transactionData.enterpriseFinancialInfo?.[variableName]?.value ?
                          `$${commaEvery3rdChar(userData.transactionData.enterpriseFinancialInfo?.[variableName]?.value)}` :
                          '-'}
                      </span>
                      <span>
                        {equityRow.period}
                        <br />
                        {equityRow.kpi}
                      </span>
                    </>
                  )}
                </div>
                <div className={`value-block${!equityRow.enterpriseValue ? ' awaiting-input' : ''}`}>
                  {!equityRow.enterpriseValue ? (
                    <span>
                      Scenario&apos;s enterprise
                      <br />
                      will show here
                    </span>
                  ) : (
                    <>
                      <span>{`$${commaEvery3rdChar(equityRow.enterpriseValue)}`}</span>
                      <span>Enterprise value</span>
                    </>
                  )}
                </div>
                <div className={`value-block${!equityRow.weightedEnterpriseValue ? ' awaiting-input' : ''}`}>
                  {!equityRow.weightedEnterpriseValue ? (
                    <span>
                      Probability weighted
                      <br />
                      enterprise value
                      <br />
                      will show here
                    </span>
                  ) : (
                    <>
                      <span>{`$${commaEvery3rdChar(equityRow.weightedEnterpriseValue)}`}</span>
                      <span>
                        Probability weighted
                        <br />
                        enterprise value
                      </span>
                    </>
                  )}
                </div>
                <IconButton
                  className="industry-add-btn"
                  onClick={() => {
                    setEquityRows([...equityRows, { rowId: rowsAdded + 1 }]);
                    setKpiDropdownValues([...kpiDropdownValues, []]);
                    setRowsAdded(rowsAdded + 1);
                  }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
                {equityRows.length > 1 && (
                  <IconButton onClick={() => {
                    setEquityRows([...equityRows.slice(0, index), ...equityRows.slice(index + 1)]);
                    setKpiDropdownValues([...kpiDropdownValues.slice(0, index), ...kpiDropdownValues.slice(index + 1)]);
                    setInputWasChanged(true);
                  }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                )}
              </div>
              <AnimateHeight duration={500} height={showFootnoteHeights.includes(index) ? 'auto' : 0}>
                <div className="footnote">
                  <div className="footnote-title">
                    <h6>{`Scenario ${index + 1} footnote`}</h6>
                    <p>The below text will automatically appear in the client&apos;s ASC 820 report.</p>
                  </div>
                  <TextField
                    value={equityRow.footNote || ''}
                    onChange={(e) => setEquityRows({ ...equityRows, [index]: { ...equityRow, footNote: e.target.value } })}
                  />
                  <div className="footnote-characters-left">{`${equityRow.footNote?.length || 0}/1000`}</div>
                </div>
              </AnimateHeight>
            </div>
          );
        })}
      </div>
    </div>
  );
}

EquityInputs.propTypes = {
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
};
