import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import DataThresholdingOutlinedIcon from '@mui/icons-material/DataThresholdingOutlined';
import { useNavigate } from 'react-router-dom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ASC820Dialog from '../../components/ASC820Dialog';
import ProjectStatusCard from './ProjectStatusCard';
import { SubNavStateContext } from '../../lib/contextLib';
import {
  sortByList,
  dataEntryFilterBy,
  preparerFilterBy,
  deliverFilterBy,
} from '../../utils/globals';
import ProjectCompanies from './ProjectCompanies';
import SortBy from '../../components/SortBy';
import InvestorCompanyDetails from '../InvestorCompanyDetails/InvestorCompanyDetails';

import './index.scss';
import CompletedProjects from './CompletedProjects';

export default function EnterpriseProjects({
  setEnterpriseProjects,
  enterpriseProjects,
  enterpriseUsers,
  investorCompaniesList,
  portfolioCompaniesList,
  investorCompaniesNames,
  portfolioCompaniesNames,
  setPortfolioCompaniesNames,
}) {
  const nav = useNavigate();
  const { setSubNavState } = useContext(SubNavStateContext);
  const [startASC820, setStartASC820] = useState(false);
  const [projectToView, setProjectToView] = useState(null);
  // eslint-disable-next-line
  const [investorCompanyDetailToView, setInvestorCompanyDetailToView] = useState(null);
  const [dataEntryProjects, setDataEntryProjects] = useState([]);
  const [prepareProjects, setPrepareProjects] = useState([]);
  const [deliverProjects, setDeliverProjects] = useState([]);
  const [sortBy, setSortBy] = useState('Most-recent');
  const [dataEntryFilter, setDataEntryFilter] = useState('View all');
  const [prepareFilter, setPrepareFilter] = useState('View all');
  const [deliverFilter, setDeliverFilter] = useState('View all');
  const [searchQuery, setSearchQuery] = useState('');
  const [showCompletedProjects, setShowCompletedProjects] = useState(false);
  const [completedProjects, setCompletedProjects] = useState([]);
  const [filteredUser, setFilteredUser] = useState('All users');
  const [filterByUsers, setFilterByUsers] = useState([]);

  useEffect(() => {
    if (enterpriseProjects.length) {
      const urlParams = new URLSearchParams(window.location.search);
      const projectId = urlParams.get('pId');
      if (projectId) setProjectToView(enterpriseProjects.find((project) => project.projectId === projectId));
      const completeProjects = enterpriseProjects.filter((project) => project.column === 'Complete');
      setCompletedProjects(completeProjects);
      setFilterByUsers(['All users', ...enterpriseUsers.map((user) => `${user.firstName} ${user.lastName}`)]);
    }
  }, [enterpriseProjects]);

  useEffect(() => {
    let filteredDataEntryProjects = enterpriseProjects.filter((company) => company.column.toLowerCase() === 'data entry');
    let filteredPreparerProjects = enterpriseProjects.filter((company) => company.column.toLowerCase() === 'prepare');
    let filteredDeliverProjects = enterpriseProjects.filter((company) => company.column.toLowerCase() === 'deliver');

    if (sortBy === 'Most-recent') {
      const sortProjects = (projects) => projects.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate));
      filteredDataEntryProjects = sortProjects(filteredDataEntryProjects);
      filteredPreparerProjects = sortProjects(filteredPreparerProjects);
      filteredDeliverProjects = sortProjects(filteredDeliverProjects);
    }

    if (sortBy === 'Alphabetical') {
      const sortProjects = (projects) => projects.sort((a, b) => a.investorFirmName.localeCompare(b.investorFirmName));
      filteredDataEntryProjects = sortProjects(filteredDataEntryProjects);
      filteredPreparerProjects = sortProjects(filteredPreparerProjects);
      filteredDeliverProjects = sortProjects(filteredDeliverProjects);
    }
    if (sortBy === 'Info due date') {
      const sortProjects = (projects) => projects.sort((a, b) => new Date(a.clientInfoDueDate) - new Date(b.clientInfoDueDate));
      filteredDataEntryProjects = sortProjects(filteredDataEntryProjects);
      filteredPreparerProjects = sortProjects(filteredPreparerProjects);
      filteredDeliverProjects = sortProjects(filteredDeliverProjects);
    }
    if (sortBy === 'Audit date') {
      const sortProjects = (projects) => projects.sort((a, b) => new Date(a.auditDate) - new Date(b.auditDate));
      filteredDataEntryProjects = sortProjects(filteredDataEntryProjects);
      filteredPreparerProjects = sortProjects(filteredPreparerProjects);
      filteredDeliverProjects = sortProjects(filteredDeliverProjects);
    }
    if (sortBy === 'High to low priority') {
      const sortProjects = (projects) => projects.sort((a, b) => a.priority - b.priority);
      filteredDataEntryProjects = sortProjects(filteredDataEntryProjects);
      filteredPreparerProjects = sortProjects(filteredPreparerProjects);
      filteredDeliverProjects = sortProjects(filteredDeliverProjects);
    }

    if (filteredUser !== 'All users') {
      filteredDataEntryProjects = filteredDataEntryProjects.filter((company) => {
        const dataEntryAssignment = enterpriseUsers.filter((u) => Object.keys(company.assignedUsers.dataEntry).includes(u.accountId));
        return dataEntryAssignment.some((user) => `${user.firstName} ${user.lastName}` === filteredUser);
      });
      filteredPreparerProjects = filteredPreparerProjects.filter((company) => {
        const prepareAssignment = enterpriseUsers.filter((u) => Object.keys(company.assignedUsers.preparer).includes(u.accountId));
        return prepareAssignment.some((user) => `${user.firstName} ${user.lastName}` === filteredUser);
      });
      filteredDeliverProjects = filteredDeliverProjects.filter((company) => {
        const reviewerAssignment = enterpriseUsers.filter((u) => Object.keys(company.assignedUsers.reviewer).includes(u.accountId));
        return reviewerAssignment.some((user) => `${user.firstName} ${user.lastName}` === filteredUser);
      });
    }

    /* eslint-disable */
    function filterProjects(column, compareValue, filterValue) {
      return column.filter((project) => project[`${compareValue}`] === filterValue);
    }

    if (dataEntryFilter === 'High-priority') filteredDataEntryProjects = filterProjects(filteredDataEntryProjects, 'priority', '1');
    if (dataEntryFilter === 'Low-priority') filteredDataEntryProjects = filterProjects(filteredDataEntryProjects, 'priority', '3');
    if (dataEntryFilter === 'Client not started 820') filteredDataEntryProjects = filterProjects(filteredDataEntryProjects, 'status', 'Client not started 820');
    if (dataEntryFilter === 'Client in-progress') filteredDataEntryProjects = filterProjects(filteredDataEntryProjects, 'status', 'Client in-progress');
    if (dataEntryFilter === '820 Data entry') filteredDataEntryProjects = filterProjects(filteredDataEntryProjects, 'status', '820 Data entry');

    if (prepareFilter === 'High-priority') filteredPreparerProjects = filterProjects(filteredPreparerProjects, 'priority', '1');
    if (prepareFilter === 'Low-priority') filteredPreparerProjects = filterProjects(filteredPreparerProjects, 'priority', '3');
    if (prepareFilter === '820 Analysis') filteredPreparerProjects = filterProjects(filteredPreparerProjects, 'status', '820 Analysis');
    if (prepareFilter === '820 Review') filteredPreparerProjects = filterProjects(filteredPreparerProjects, 'status', '820 Review');
    if (prepareFilter === 'Draft in-progress') filteredPreparerProjects = filterProjects(filteredPreparerProjects, 'status', 'Draft in-progress');
    if (prepareFilter === 'Draft review') filteredPreparerProjects = filterProjects(filteredPreparerProjects, 'status', 'Draft review');

    if (deliverFilter === 'High-priority') filteredDeliverProjects = filterProjects(filteredDeliverProjects, 'priority', '1');
    if (deliverFilter === 'Low-priority') filteredDeliverProjects = filterProjects(filteredDeliverProjects, 'priority', '3');
    if (deliverFilter === 'Draft delivered') filteredDeliverProjects = filterProjects(filteredDeliverProjects, 'status', 'Draft delivered');
    if (deliverFilter === 'Iterate draft') filteredDeliverProjects = filterProjects(filteredDeliverProjects, 'status', 'Iterate draft');
    if (deliverFilter === 'Audit Draft') filteredDeliverProjects = filterProjects(filteredDeliverProjects, 'status', 'Audit Draft');
    if (deliverFilter === 'Financial statements ready') filteredDeliverProjects = filterProjects(filteredDeliverProjects, 'status', 'Financial statements ready');
    // /* eslint-enable */

    function filterBySearch(projects, value, query) {
      return projects.filter((project) => project[`${value}`].toLowerCase().includes(query.toLowerCase()));
    }
    if (searchQuery.length) {
      setDataEntryFilter('View all');
      setPrepareFilter('View all');
      setDeliverFilter('View all');
      filteredDataEntryProjects = filterBySearch(filteredDataEntryProjects, 'investorFirmName', searchQuery);
      filteredPreparerProjects = filterBySearch(filteredPreparerProjects, 'investorFirmName', searchQuery);
      filteredDeliverProjects = filterBySearch(filteredDeliverProjects, 'investorFirmName', searchQuery);
    }

    setDataEntryProjects(filteredDataEntryProjects);
    setPrepareProjects(filteredPreparerProjects);
    setDeliverProjects(filteredDeliverProjects);
  }, [enterpriseProjects, filteredUser, sortBy, dataEntryFilter, prepareFilter, deliverFilter]);


  useEffect(() => {
    if (projectToView) {
      const urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.get('tId')) nav(`?pId=${projectToView.projectId}&iId=${projectToView.investorCompanyId}`);
      setSubNavState({
        ...projectToView,
        currentPage: 'company-list',
        pagesVisited: ['project-list'],
      });
    }
  }, [projectToView]);

  // TODO implement investor company details
  useEffect(() => {
    if (investorCompanyDetailToView) {
      const urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.get('tId')) nav(`?iId=${investorCompanyDetailToView.investorCompanyId}`);
      setSubNavState({
        ...investorCompanyDetailToView,
        currentPage: 'project-list',
        pagesVisited: ['project-list'],
      });
    }
  }, [investorCompanyDetailToView]);

  if (projectToView) {
    return (
      <ProjectCompanies
        enterpriseUsers={enterpriseUsers}
        projectCompaniesData={projectToView}
        portfolioCompaniesList={portfolioCompaniesList}
        portfolioCompaniesNames={portfolioCompaniesNames}
      />
    );
  }

  if (investorCompanyDetailToView) return <InvestorCompanyDetails investorDetails={investorCompanyDetailToView} />;

  if (showCompletedProjects) {
    return (
      <CompletedProjects
        enterpriseUsers={enterpriseUsers}
        completedProjects={completedProjects}
        setCompletedProjects={setCompletedProjects}
        setShowCompletedProjects={setShowCompletedProjects}
        projectToView={projectToView}
        setProjectToView={setProjectToView}
        setInvestorCompanyDetailToView={setInvestorCompanyDetailToView}
      />
    );
  }

  return (
    <>
      <main className="enterprise-home">
        <div className="header-group">
          <TextField
            className="search"
            placeholder="Search 820 valuations"
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <SearchIcon />
              ),
            }}
          />
          <Button onClick={() => setStartASC820(true)}>
            <AddOutlinedIcon />
            Start new ASC 820
          </Button>
        </div>
        <div className="sub-header">
          <h4>
            <DataThresholdingOutlinedIcon />
            ASC 820 valuations
          </h4>
          <div className='filter-sort-container'>
            <Button
              className='view-completed'
              onClick={() => setShowCompletedProjects(true)}
            >
              View Completed
            </Button>
            <SortBy
              sortBy={filteredUser}
              setSortBy={setFilteredUser}
              sortByList={filterByUsers}
              type="Filter by user"
            />
            <SortBy
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortByList={sortByList}
              type="Sort by"
              isButton={false}
            />
          </div>
        </div>
        <div className="progress-columns" style={{ paddingRight: 'calc(100vw - 100%' }}>
          <div className="col to-do">
            <h4>Data entry</h4>
            <div className="filter-by">
              <SortBy
                sortBy={dataEntryFilter}
                setSortBy={setDataEntryFilter}
                sortByList={dataEntryFilterBy}
                type="Filter by"
              />
            </div>
            {dataEntryProjects.map((project) => (
              <ProjectStatusCard
                key={project.resource}
                projectData={project}
                setProjectToView={setProjectToView}
                enterpriseUsers={enterpriseUsers}
                projectList={dataEntryProjects}
                setProjectList={setDataEntryProjects}
                setInvestorCompanyDetailToView={setInvestorCompanyDetailToView}
                dataEntry
              />
            ))}
          </div>
          <div className="col in-progress">
            <h4>Prepare 820</h4>
            <div className="filter-by">
              <SortBy
                sortBy={prepareFilter}
                setSortBy={setPrepareFilter}
                sortByList={preparerFilterBy}
                type="Filter by"
              />
            </div>
            {prepareProjects.map((project) => (
              <ProjectStatusCard
                key={project.resource}
                projectData={project}
                projectToView={projectToView}
                setProjectToView={setProjectToView}
                enterpriseUsers={enterpriseUsers}
                projectList={prepareProjects}
                setProjectList={setPrepareProjects}
                setInvestorCompanyDetailToView={setInvestorCompanyDetailToView}
                preparer
              />
            ))}
          </div>
          <div className="col complete">
            <h4>Deliver</h4>
            <div className="filter-by">
              <SortBy
                sortBy={deliverFilter}
                setSortBy={setDeliverFilter}
                sortByList={deliverFilterBy}
                type="Filter by"
              />
            </div>
            {deliverProjects.map((project) => (
              <ProjectStatusCard
                key={project.resource}
                projectData={project}
                projectToView={projectToView}
                setProjectToView={setProjectToView}
                enterpriseUsers={enterpriseUsers}
                projectList={deliverProjects}
                setProjectList={setDeliverProjects}
                setInvestorCompanyDetailToView={setInvestorCompanyDetailToView}
                deliver
              />
            ))}
          </div>
        </div>
      </main>
      <ASC820Dialog
        startASC820={startASC820}
        setStartASC820={setStartASC820}
        setEnterpriseProjects={setEnterpriseProjects}
        enterpriseUsers={enterpriseUsers}
        enterpriseProjects={enterpriseProjects}
        investorCompanies={investorCompaniesList}
        portfolioCompanies={portfolioCompaniesList}
        investorCompaniesNames={investorCompaniesNames}
        portfolioCompaniesNames={portfolioCompaniesNames}
        setPortfolioCompaniesNames={setPortfolioCompaniesNames}
      />
    </>
  );
}

EnterpriseProjects.propTypes = {
  setEnterpriseProjects: PropTypes.func.isRequired,
  enterpriseProjects: PropTypes.arrayOf(PropTypes.object),
  enterpriseUsers: PropTypes.arrayOf(PropTypes.object),
  investorCompaniesList: PropTypes.object,
  portfolioCompaniesList: PropTypes.object,
  investorCompaniesNames: PropTypes.arrayOf(PropTypes.string),
  portfolioCompaniesNames: PropTypes.arrayOf(PropTypes.string),
  setPortfolioCompaniesNames: PropTypes.func.isRequired,
};

EnterpriseProjects.defaultProps = {
  enterpriseProjects: [],
  enterpriseUsers: [],
  investorCompaniesList: [],
  portfolioCompaniesList: [],
  portfolioCompaniesNames: [],
  investorCompaniesNames: [],
};
