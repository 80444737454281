import React, { useState, useEffect, useContext } from 'react';

import Dialog from '@mui/material/Dialog';

import RouterProvider from './Routes';
import NavBar from './components/NavBar';
import AlertDisplay from './components/AlertDisplay';
import InactivityTimeout from './lib/InactivityTimeout';

import authenticationCheck, { getUserType } from './utils/auth';

import { IsSigningOutContext } from './lib/contextLib';

import './App.scss';
import Intake from './pages/InvestorIntake/Intake';

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { isSigningOut } = useContext(IsSigningOutContext);

  async function checkCurrentAuthSession() {
    await authenticationCheck();
    setIsAuthenticated(true);
    setIsAuthenticating(false);
  }

  useEffect(() => { checkCurrentAuthSession(); }, []);

  if (!isAuthenticating && isAuthenticated) {
    return (
      <main className="App">
        <AlertDisplay />
        <NavBar />
        {getUserType() === 'investor' ? <Intake userType={getUserType()} /> : <RouterProvider />}
        <InactivityTimeout />
        <Dialog className="loading-dialog" open={isSigningOut} disableScrollLock>
          <div className="dots-circle-spinner" />
        </Dialog>
      </main>
    );
  }
}

export default App;
