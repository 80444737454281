import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {
  commaEvery3rdChar,
  copy,
  formatUSCurrencyWithFourDecimals,
  onlyNums,
} from '../../utils';
import { cleanValue } from './utils';

export default function Step4({
  transactionsData,
  setTransactionsData,
  index,
  setActiveStep,
}) {
  const [holdingsInputs, setHoldingsInputs] = useState([]);

  function handleIntakeChange(dataName, dataValue, dataIndex) {
    const newHoldingData = copy(holdingsInputs);
    const sharesOwned = dataName === 'sharesOwned' ? dataValue : cleanValue(holdingsInputs[dataIndex]?.sharesOwned);
    const pricePerShare = dataName === 'pricePerShare' ? dataValue : cleanValue(holdingsInputs[dataIndex]?.pricePerShare);
    const valueSum = parseFloat(sharesOwned, 10) * parseFloat(pricePerShare, 10);
    newHoldingData[dataIndex] = { ...newHoldingData[dataIndex], carryingValue: valueSum ? valueSum.toFixed(4) : '' };
    newHoldingData[dataIndex] = { ...newHoldingData[dataIndex], [dataName]: dataValue };
    setHoldingsInputs(newHoldingData);
  }

  function handleOnBlurChange(dataName, dataValue, dataIndex) {
    let formattedValue = dataValue;
    const denomValues = ['pricePerShare', 'carryingValue', 'estimatedAsOfDate'];
    const commaValues = ['sharesOwned'];
    if (denomValues.includes(dataName) && dataValue.length > 0) {
      formattedValue = formatUSCurrencyWithFourDecimals.format(cleanValue(dataValue));
    } else if (commaValues.includes(dataName) && dataValue.length > 0) {
      formattedValue = commaEvery3rdChar(onlyNums(dataValue));
    }
    const newHoldingData = copy(holdingsInputs);
    const newData = copy(transactionsData);
    newHoldingData[dataIndex] = { ...newHoldingData[dataIndex], [dataName]: formattedValue };
    newData[index] = { ...newData[index], holdings: newHoldingData };
    setTransactionsData(newData);
  }

  function removeHoldingInput(inputIndex) {
    const newHoldingData = copy(holdingsInputs);
    const newData = copy(transactionsData);
    newHoldingData.splice(inputIndex, 1);
    newData[index] = { ...newData[index], holdings: newHoldingData };
    setHoldingsInputs(newHoldingData);
    setTransactionsData(newData);
  }

  function setValue(data) {
    const denomValues = ['pricePerShare', 'carryingValue', 'estimatedAsOfDate'];
    const commaValues = ['sharesOwned'];
    if (data) {
      const dataCopy = copy(data);
      Object.entries(dataCopy).forEach((value) => {
        if (value[1] && denomValues.includes(value[0])) {
          dataCopy[value[0]] = formatUSCurrencyWithFourDecimals.format(cleanValue(value[1]));
        } else if (value[1] && commaValues.includes(value[0])) {
          dataCopy[value[0]] = commaEvery3rdChar(onlyNums(value[1]));
        }
      });
      return dataCopy;
    }
    return transactionsData[index]?.holdings;
  }

  useEffect(() => setHoldingsInputs(transactionsData[index]?.holdings?.length ?
    transactionsData[index]?.holdings.map((v, i) => (setValue({ ...v, rowID: i }))) : [{ rowID: 0 }]), [transactionsData]);

  return (
    <>
      <h4>Holdings</h4>
      <p style={{ marginTop: '12px' }}>Add all company holdings below.</p>
      <div className="holdings-block">
        <div className="column-names">
          <p>Class of stock</p>
          <p>Fund name</p>
          <p>Shares owned</p>
          <p>Price per share</p>
          <p>Total cost of shares</p>
          <p>Total holding value</p>
        </div>
        {holdingsInputs.map((holding, holdingIndex) => (
          <div className="holdings-inputs" key={holding.rowID}>
            <TextField
              value={holdingsInputs[holdingIndex]?.classOfStock || ''}
              onChange={(e) => { handleIntakeChange('classOfStock', e.target.value, holdingIndex); }}
              onFocus={() => setActiveStep(4)}
              onBlur={(e) => { handleOnBlurChange('classOfStock', e.target.value, holdingIndex); }}
            />
            <TextField
              value={holdingsInputs[holdingIndex]?.fundName || ''}
              onChange={(e) => { handleIntakeChange('fundName', e.target.value, holdingIndex); }}
              onFocus={() => setActiveStep(4)}
              onBlur={(e) => { handleOnBlurChange('fundName', e.target.value, holdingIndex); }}
            />
            <TextField
              value={holdingsInputs[holdingIndex]?.sharesOwned || ''}
              onChange={(e) => { handleIntakeChange('sharesOwned', e.target.value, holdingIndex); }}
              onFocus={() => setActiveStep(4)}
              onBlur={(e) => { handleOnBlurChange('sharesOwned', e.target.value, holdingIndex); }}
            />
            <TextField
              value={holdingsInputs[holdingIndex]?.pricePerShare || ''}
              onChange={(e) => { handleIntakeChange('pricePerShare', e.target.value, holdingIndex); }}
              onFocus={() => setActiveStep(4)}
              onBlur={(e) => { handleOnBlurChange('pricePerShare', e.target.value, holdingIndex); }}
            />
            <TextField disabled value={holdingsInputs[holdingIndex]?.carryingValue || ''} />
            <TextField
              value={holdingsInputs[holdingIndex]?.estimatedAsOfDate || ''}
              onChange={(e) => { handleIntakeChange('estimatedAsOfDate', commaEvery3rdChar(e.target.value), holdingIndex); }}
              onFocus={() => setActiveStep(4)}
              onBlur={(e) => { handleOnBlurChange('estimatedAsOfDate', e.target.value, holdingIndex); }}
            />
            <IconButton
              className="clear-icon"
              onClick={() => {
                setActiveStep(4);
                removeHoldingInput(holdingIndex);
              }}
              disabled={holdingIndex === 0}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </div>
        ))}
        <Button onClick={() => {
          setActiveStep(4);
          setHoldingsInputs([...holdingsInputs, { rowID: holdingsInputs.length + 1 }]);
        }}
        >
          <AddCircleOutlineIcon />
          Add Class
        </Button>
      </div>
    </>
  );
}

Step4.propTypes = {
  transactionsData: PropTypes.array.isRequired,
  setTransactionsData: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
};
